<template>
    <nav v-if="isLoggedIn()" class="main-nav">
        <div class="mobile">

            <div class="navigation-head-row">
                <div class="select-lang">
                    <div class="selected-lang icon icon-lang" @click="openListLang = !openListLang"
                        v-text="$store.state.localeTitle"></div>
                    <div class="list-lang" :class="{ 'open': openListLang }">
                        <ul>
                            <li v-for="(lang, index) in $store.state.locales" :key="index"
                                @click="$ml.change(index), changeLang(index)" v-text="index"></li>
                        </ul>
                    </div>
                </div>
                <span @click="closeNavBar" class="close-modal">✕</span>
            </div>


            <div class="user-info">
                <img v-if="registrationData.gender == 'MALE'" src="../assets/images/profile_img.png" alt=""
                    class="im-user" @click="goUserProfile()" />
                <img v-else src="../assets/images/female_profile_img.png" alt="" class="im-user"
                    @click="goUserProfile()" />
                <div class="user-title" @click="goUserProfile()">{{ registrationData.firstName }}
                    {{ registrationData.lastName }}</div>
                <div v-if="balances">
                    <span class="user-subtitle" v-text="$ml.get('wallet.balance')"></span>
                    <span class="user-subtitle" v-if="areBalancesUpdated">{{ balances }}</span>
                </div>
            </div>
        </div>
        <ul class="item">
            <li v-if="this.registrationData.isCustomerHaveCards">
                <router-link v-bind:to="'/card_main'">
                    <i class="icon icon-card-max"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav12')" @click="openLink()"></span>
                </router-link>
            </li>
            <li v-if="showWallet || balances">
                <router-link v-bind:to="'/wallet'" @click.native="onLinkClick">
                    <i class="icon icon-wallet"></i>
                    <span v-text="$ml.get('wallet.menu')" @click="openLink()"></span>
                </router-link>
            </li>
            <li>
                <router-link v-bind:to="'/transfer'" @click.native="onLinkClick">
                    <i class="icon icon-transfer"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav1')" @click="openLink()"></span>
                </router-link>
            </li>
            <li>
                <router-link v-bind:to="'/recent_actions'">
                    <i class="icon icon-clock"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav2')" @click="openLink()"></span>
                </router-link>
            </li>
            <li v-if="this.registrationData.country == 'ISR'">
                <router-link v-bind:to="'/cashio'">
                    <i class="icon icon-airplane"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav13')" @click="openLink()"></span>
                </router-link>
            </li>
            <li>
                <router-link v-bind:to="'/beneficiaries'">
                    <i class="icon icon-user-2"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav4')" @click="openLink()"></span>
                </router-link>
            </li>
            <li>
                <router-link v-bind:to="'/wallet/invite'" @click.native="onLinkClick">
                    <i class="fas fa-gift gift-in-nav"></i>
                    <span v-text="$ml.with('VueJS').get('cashBackReceive')" @click="openLink()"></span>
                </router-link>
            </li>
            <li v-if="!this.registrationData.isCustomerHaveCards">
                <router-link v-bind:to="'/card_main'">
                    <i class="icon icon-card-max"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav12')" @click="openLink()"></span>
                </router-link>
            </li>
            <!-- <li v-show="cashBackBalances">
                <router-link v-bind:to="'/cash-back'">
                    <i class="icon icon-gift"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav0')" @click="openLink()"></span>
                </router-link>
            </li>             -->
            <li>
                <a v-bind:href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('locationLink')" target="_blank">
                    <i class="icon icon-location"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav5')"></span>
                </a>
            </li>
            <li>
                <a v-bind:href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('faqLink')" target="_blank">
                    <i class="icon icon-answer"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav6')"></span>
                </a>
            </li>
            <li v-if="dataLang.lang != 'cn'">
                <a v-bind:href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('contactUsLink')" target="_blank">
                    <i class="icon icon-message"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav7')"></span>
                </a>
            </li>
            <li>
                <a v-bind:href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('protocolLink')" target="_blank">
                    <i class="icon icon-rules"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav8')"></span>
                </a>
            </li>
            <li>
                <a @click="goUserProfile()" target="_blank">
                    <img class="settings-svg" src="../assets/images/settings.svg" />
                    <span v-text="$ml.with('VueJS').get('itemNavUserProfile')"></span>
                </a>
            </li>
            <li>
                <a @click="logOut()">
                    <i class="icon icon-logout"></i>
                    <span v-text="$ml.with('VueJS').get('itemNav9')"></span>
                </a>
            </li>
            <li>
                <span class="footer" v-text="$ml.with('num', version).get('version')"></span>
            </li>
        </ul>
    </nav>
</template>

<script>
import { logOut } from "../services/auth.js";
import { EventBus } from '../event-bus.js';
import store from '../store';
import { updateLang } from "../services/api";
import { formatComma } from "../filters/AppFilters";

export default {
    name: 'MainNav',
    data() {
        return {
            registrationData: {},
            openListLang: false,
            dataLang: {
                'lang': this.$store.getters.getLang,
                'dir': this.$store.getters.getDir
            },
        }
    },
    computed: {
        balances() {
            return this.$store.getters.getWalletBalances.map((el) => {
                return el.symbol + formatComma(el.amount);
            }).join(", ");
        },
        showWallet() {
            return localStorage.getItem('showWallet') === 'true'
        },
        areBalancesUpdated() {
            return this.$store.getters.areWalletBalancesUpdated;
        },
        cashBackBalances() {
            return this.$store.getters.getCashBackBalances && this.$store.getters.getCashBackBalances.length;
        },
        version() {
            return process.env.VUE_APP_VERSION;
        },
        getDir() {
            return this.$store.getters.getDir;
        },
    },
    created() {
        this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
        this.swipeSideMwnu();
    },
    beforeDestroy() {
        window.removeEventListener('click', this.clickedOutsideMenuMobile, true);
    },
    methods: {
        swipeSideMwnu() {
            window.addEventListener('click', this.clickedOutsideMenuMobile, true);
            window.addEventListener('touchstart', handleTouchStart, false);
            window.addEventListener('touchmove', handleTouchMove, false);

            function getTouches(evt) { return evt.touches || evt.originalEvent.touches; }
            let self = this;
            var xDown = null;
            var yDown = null;

            function handleTouchStart(evt) {
                const firstTouch = getTouches(evt)[0];
                xDown = firstTouch.clientX;
                yDown = firstTouch.clientY;
            }

            function handleTouchMove(evt) {
                let lang = self.dataLang.lang;
                if (!xDown || !yDown) { return; }
                var xUp = evt.touches[0].clientX;
                var yUp = evt.touches[0].clientY;
                var xDiff = xDown - xUp;
                var yDiff = yDown - yUp;
                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    if (lang != 'il' && xDiff > 0 || lang == 'il' && xDiff < 0) {
                        self.closeNavBar(evt);
                    }
                    if (lang != 'il' && xDiff < 0 || lang == 'il' && xDiff > 0) {
                        // self.openNavBer(evt); 
                        // we are disabling this for now because it collides with iphones swipe back = navigate back
                        // other direction is good!
                    }
                }
                xDown = null;
                yDown = null;
            }
        },
        clickedOutsideMenuMobile(e) {
            //hamburger should open and close regardless
            if (e.target.parentElement.classList.contains('navbar-button'))
                return;
            //if menu is open and clicked outside it
            if (!this.$el.contains(e.target) && $('.header').hasClass('open')) {
                let self = this;
                self.closeNavBar(e);
            }
        },
        logOut() {
            let self = this;
            self.closeNavBar();
            logOut();
        },
        openLink() {
            // $('.main-nav').css('display', 'none');
            let self = this;
            self.closeNavBar();
        },
        isLoggedIn() {
            return store.getters.getLoggedIn && !store.getters.getNotSigned;
        },
        onLinkClick() {
            EventBus.$emit('clearTransferState');
        },
        goUserProfile() {
            // $('.main-nav').css('display', 'none');
            let self = this;
            self.closeNavBar();
            this.$router.push('user-profile');
        },
        changeLang(lang) {
            this.openListLang = false;
            this.dataLang.lang = lang;
            store.commit('changeLang', this.dataLang);
            store.commit('setLangTitle', lang);
            if (store.getters.getLoggedIn)
                updateLang({ "lang": lang });
            EventBus.$emit('changeLang', lang);
            $('.main-nav').css('display', 'none');
            let self = this;
            self.closeNavBar();
        },
        closeNavBar(e) {
            if (e) e.stopPropagation();
            $('.main-nav').removeClass('open');
            $('.header').removeClass('open');
            $('html').removeClass('overflow');
            this.openListLang = false;
        },
        openNavBer(e) {
            if (e) e.stopPropagation();
            $('.main-nav').css('display', 'block');
            $('.header').addClass('open');
            $('.main-nav').addClass('open');
            $('html').addClass('overflow');
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.main-nav {
    display: block;
    max-width: 60px;
    width: 100%;
    height: 100%;
    background-color: $mainColor;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 6;
    padding: 8px 0;
    right: 0;
    @include transition(all .4s ease);

    ul .item {
        width: 190px;
    }

    &:hover {
        max-width: 210px;

        li i {}
    }

    li {

        i,
        span {
            display: inline-block;
            vertical-align: middle;
        }

        span {
            color: #ffffff;
            padding-right: 60px;
            @include transition(all .4s ease);

            &.footer {
                margin-inline-start: -40px;
                bottom: 5px;
                position: fixed;
                font-size: 13px
            }
        }

        i {
            width: 60px;
            position: absolute;
            top: 5px;
            bottom: 0;
            margin: auto;
            right: 0;

            &:before {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }

        .settings-svg {
            display: inline;
            width: 25px;
            position: absolute;
            top: 5px;
            bottom: 0;
            margin: auto;
            right: 17px;
        }

        .gift-in-nav {
            margin-inline-start: 22px;
            margin-top: 13px;
            color: white;
        }

        a {
            height: 51px;
            padding: 14px 0;
            position: relative;
            cursor: pointer;

            &.router-link-exact-active,
            &:hover {
                span {
                    color: #d4c742;
                }

                .icon-gift:before {
                    background-image: url(../assets/images/icons/i-gift.png);
                }

                .icon-transfer:before {
                    background-image: url(../assets/images/icons/i-transfer-selected.png);
                }

                .icon-clock:before {
                    background-image: url(../assets/images/icons/i-clock-selected.png);
                }

                .icon-calculator:before {
                    background-image: url(../assets/images/icons/i-calculator-selected.png);
                }

                .icon-user-2:before {
                    background-image: url(../assets/images/group-small-selected.png);
                }

                .icon-location:before {
                    background-image: url(../assets/images/icons/i-location-selected.png);
                }

                .icon-answer:before {
                    background-image: url(../assets/images/icons/i-answer-selected.png);
                }

                .icon-message:before {
                    background-image: url(../assets/images/icons/i-message-selected.png);
                }

                .icon-rules:before {
                    background-image: url(../assets/images/icons/i-rules-selected.png);
                }

                .icon-logout:before {
                    background-image: url(../assets/images/icons/i-logout-selected.png);
                }

                .icon-card-max:before {
                    background-image: url(../assets/images/visa_icon-selected.png);
                }

                .icon-wallet:before {
                    background-image: url(../assets/images/icons/i-wallet-selected.png);
                }

                .icon-airplane:before {
                    background-image: url(../assets/images/icons/i-airplane-selected.png);
                }
            }
        }
    }

    &.open {
        position: absolute;
        display: block;
        flex: 0 0 229px;
        max-width: 229px;
        padding: 20px 0;
        z-index: 11;
        overflow-y: scroll;

        ul .item {
            width: 229px;
        }

        li {
            border: none;

            a {
                font-size: 20px;
            }
        }

        &+.content {
            margin-left: 0;
        }
    }

    .mobile {
        display: none;
        padding: 0 12px;
    }

    .selected-lang {
        font-size: 18px;
        color: #ffffff;

        &:before {
            //background-image: url(../assets/images/icons/i-lang-white.png);
            width: 13px;
            height: 13px;
            -webkit-background-size: cover;
            background-size: cover;
        }
    }

    .user-info {
        text-align: center;

        &>* {
            margin: 0 auto;
        }

        img {
            display: block;
            max-width: 87px;
        }

        .user-title {
            font-size: 20px;
            line-height: 22px;
            color: #fdffff;
            margin-top: 12px;
        }

        .user-subtitle {
            font-size: 13px;
            line-height: 18px;
            color: #eedd4a;
        }
    }
}

// .window-overlay {
//     &.open{
//     background-color: #50556967;
//     height: 100%;
//     position: absolute;
//     width: 100%;
//     z-index: 8;
//     display: block;
//     @include transition(all .3s ease);
//     }
// }


.navigation-head-row {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: space-between;

    .close-modal {
        position: relative;
        font-size: 22px;
        top: 0px;
        left: 0px;
    }
}
</style>
