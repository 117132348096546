<template>
  <div class="mygmt-greetings-line">
    <img :src="getTimeOfDaySvg()" alt="timeOfDayIcon" />
    <h6><strong>{{ $ml.get(`greeting_${getTimeOfDayString()}`) }}</strong>{{ getUserFirstName() ? ", " +
      getUserFirstName() : '' }}</h6>
  </div>
</template>
<script>
import { getTimeOfDayString, getUserFirstName, isApp } from "@/services/utils";

export default {
  name: 'Greetings',
  components: {},
  props: {},
  data() {
    return {
      isApp: isApp(),
    }
  },
  created() {
    this.registrationData = JSON.parse(localStorage.getItem('registrationData'));

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {

  },
  methods: {
    getUserFirstName,
    getTimeOfDayString,
    getTimeOfDaySvg() {
      switch (this.getTimeOfDayString()) {
        case ("morning"):
          return require(`../assets/mygmt_v2_images/Sunset.svg`)
        case ("afternoon"):
          return require(`../assets/mygmt_v2_images/Sunny.svg`)
        case ("evening"):
          return require(`../assets/mygmt_v2_images/Cloudy.svg`)
        case ("night"):
          return require(`../assets/mygmt_v2_images/Night.svg`)
      }
    },
  },
  watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.mygmt-greetings-line {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  img {
    height: 25px;
    width: 25px;
  }

  h6 {
    font-size: 14px;
    margin: 10px;
    font-weight: normal;
  }
}

@media only screen and (min-width :$screen-tablet) {
  .mygmt-greetings-line {
    display: none;
  }
}
</style>
