/* Hebrew locals for flatpickr */


const fp =
  typeof window !== "undefined" && window.flatpickr !== undefined
    ? window.flatpickr
    : ({
      l10ns: {},
    });


//

const cn = {
  weekdays: {
    shorthand: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    longhand: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
  },

  months: {
    shorthand: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    longhand: [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月",
    ],
  },
  rangeSeparator: " 至 ",
  time_24hr: true,
};





const il = {
  weekdays: {
    shorthand: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
    longhand: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
  },

  months: {
    shorthand: [
      "ינו׳",
      "פבר׳",
      "מרץ",
      "אפר׳",
      "מאי",
      "יוני",
      "יולי",
      "אוג׳",
      "ספט׳",
      "אוק׳",
      "נוב׳",
      "דצמ׳",
    ],
    longhand: [
      "ינואר",
      "פברואר",
      "מרץ",
      "אפריל",
      "מאי",
      "יוני",
      "יולי",
      "אוגוסט",
      "ספטמבר",
      "אוקטובר",
      "נובמבר",
      "דצמבר",
    ],
  },
  rangeSeparator: " אל ",
  time_24hr: true,
};




const ru = {
  weekdays: {
    shorthand: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    longhand: [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ],
  },
  months: {
    shorthand: [
      "Янв",
      "Фев",
      "Март",
      "Апр",
      "Май",
      "Июнь",
      "Июль",
      "Авг",
      "Сен",
      "Окт",
      "Ноя",
      "Дек",
    ],
    longhand: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
  },
  firstDayOfWeek: 1,
  ordinal: function () {
    return "";
  },
  rangeSeparator: " — ",
  weekAbbreviation: "Нед.",
  scrollTitle: "Прокрутите для увеличения",
  toggleTitle: "Нажмите для переключения",
  amPM: ["ДП", "ПП"],
  yearAriaLabel: "Год",
  time_24hr: true,
};

const th = {
  weekdays: {
    shorthand: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
    longhand: [
      "อาทิตย์",
      "จันทร์",
      "อังคาร",
      "พุธ",
      "พฤหัสบดี",
      "ศุกร์",
      "เสาร์",
    ],
  },

  months: {
    shorthand: [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ],
    longhand: [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],
  },

  firstDayOfWeek: 1,
  rangeSeparator: " ถึง ",
  scrollTitle: "เลื่อนเพื่อเพิ่มหรือลด",
  toggleTitle: "คลิกเพื่อเปลี่ยน",
  time_24hr: true,

  ordinal: () => {
    return "";
  },
};














export const pickrLocales = {
  cn, ru, il, th
}

fp.l10ns.ch = cn;
fp.l10ns.ru = ru;
fp.l10ns.he = il;
fp.l10ns.th = th;




export default fp.l10ns;