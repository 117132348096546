<template>
  <div>
    <Header />
    <div class="main-wrapper">
      <div class="content block block-card-main">
        <div class="wrap">
          <h1 class="title" v-text="$ml.get('wallet.deposit.cash.title')" />
          <img class="gif" src="../../../assets/images/gifs/cash-deposit.gif" alt="">
          <p class="message-body">
            {{ $ml.get('wallet.deposit.cash.walletOrderExplanation.depositAnywhere') }}
          <ol class="ol-cash-deposit">
            <li>{{ $ml.get('wallet.deposit.cash.walletOrderExplanation.amount') }}</li>
            <li>{{ $ml.get('wallet.deposit.cash.walletOrderExplanation.verificationCode') }}</li>
            <li>{{ $ml.get('wallet.deposit.cash.walletOrderExplanation.teller') }}</li>
          </ol>
          <br />
          {{ $ml.get('wallet.deposit.cash.walletOrderExplanation.finishTitle') }} <br />
          {{ $ml.get('wallet.deposit.cash.walletOrderExplanation.finishText') }}
          </p>
          <div class="row">
            <button class="btn go-to-locations" v-text="$ml.get('bntContinue')" @click="nextStep()"></button>
          </div>
          <div class="row">
            <a class="go-to-locations-ref" @click="goToGmtLocationsPage">
              <i class="fa fa-map-marker" aria-hidden="true"></i> {{ $ml.get('wallet.deposit.cash.locations') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />
  </div>
</template>
<script>

import Header from "../../../components/Header";
import MainNav from "../../../components/MainNav";
import Action from "@/components/Action";
import Footer from "@/components/Footer.vue";
import { isMobile } from "../../../services/utils";

export default {
  name: 'WalletCashDeposit',
  components: { Action, MainNav, Header, Footer },
  data() {
    return {
      isMobileDevice: isMobile()
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.$analyticsService.transmitSwitchCase('wallet_deposit','wallet_order_stage_1', {
                        page_title: "Wallet Order",
                        screen_name: "Wallet Order",
                        event_category: 'wallet_deposit',
                        event_lable: 'cash_deposit',
                        method:'cash_deposit',
                        event_category: 'wallet_deposit'
                    }) 
  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
  },
  methods: {
    goToGmtLocationsPage() {
      window.open('https://gmtonline.co.il/' + this.$ml.get('locationLink'), '_blank')
    },
    nextStep() {
      this.$router.push('/wallet/transfer/walletOrder')
    },
    prevStep() {
      this.$router.push('/wallet')
    },
  },
}

</script>

<style lang="scss" scoped>
@import "../../../assets/styles/partials/variables";
@import "../../../assets/styles/partials/mixins";

.block {
  .gif {
    width: 231px;
    height: 231px;
  }

  .title {
    font-size: 30px;
    padding-top: 20px;
  }

  text-align: center;

  .message-body {
    white-space: pre-line;
    font-size: 17px;
    margin-top: 1%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  .go-to-locations {
    width: 200px;
  }

  .go-to-locations-ref {
    display: inline-block;
    margin-top: 20px;
    cursor: pointer;
  }

  .btn {
    font-size: 18px;
    height: 38px;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;

    &.btn-border {
      border-color: $mainColor;
      color: $mainColor;

      &:hover {
        background-color: $mainColor;
        border-color: $mainColor;
        color: #ffffff;
      }
    }
  }

  .ol-cash-deposit {
    list-style: decimal;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    margin-top: 20px;
  }
}

@include max-screen($screen-phones) {

  .title-icon {
    margin-top: 8%;
    margin-bottom: 8%;
  }

  .block {
    .title {
      text-align: center;
      font-size: 26px;
      padding-top: 20px;
    }

    .go-to-locations {
      margin-top: 10px;
    }

    .message-body {
      white-space: pre-line;
      font-size: 17px;
      margin-top: 1%;
      margin-bottom: 4%;
    }
  }
}
</style>