<template>
  <div class="drawer-line-container pointer"  :class="{'w-auto': isCurrentPath('/Cards')}"  @click="$emit('lineAction')">
    <div class="line-flex" :class="{ 'highlight-line': this.highlighted }">
      <img v-if="this.icon" :src="imgSrc()" :alt="this.text" class="action-line-svgs" />
      <slot name="custom-icon" v-else />
      <span class="action-text" :class="{ 'highlight-text': this.highlighted }">{{ this.text || '' }}</span>
    </div>
    <div class="action-text" v-if="!hideArrow">
      <img src="../assets/mygmt_v2_images/Right-Chevron.svg" alt="Right Chevron" class="chevron-flaticon"
        :class="{ 'mirror': getDir === 'rtl' }" />
    </div>
    <div class="action-text" v-if="active">
      <img src="../assets/images/icons/Selected.svg">
    </div>
  </div>
</template>
<script>
import { isApp } from "@/services/utils";

export default {
  name: 'ActionLine',
  components: {},
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    hideArrow: {
      type: Boolean,
      required: false
    },
    active: {
      type: Boolean
    }
  },
  data() {
    return {
      isApp: isApp(),
      iconPageMap: [
        { "icon": "Home.svg", "route": "Home" },
        { "icon": "Menu-Group.svg", "route": "Beneficiaries" },
        { "icon": "Menu-Present.svg", "route": "invite" },
        { "icon": "Menu-Document.svg", "route": "TransactionsHistory" },
        { "icon": "Edit.svg", "route": "User-Profile" },
        { "icon": "Locations.svg", "route": "Locations" },
        { "icon": "Cards.svg", "route": "Cards" }],
      highlighted: false
    }
  },
  created() {

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
  },
  mounted() {
    if (this.icon && this.icon.indexOf('Highlighted') > -1) {
      this.highlighted = true;
    }
  },
  methods: {
    imgSrc() {
      return require(`../assets/mygmt_v2_images/${this.icon}`);
    },
    isCurrentPath(path){
      const currentPath = this.$route.path;
      return currentPath === path
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.highlight-text {
  color: $mainColor !important;
}

.highlight-line {
  background-color: rgba(9, 32, 134, 0.02);
}

.drawer-line-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;

  .line-flex {
    border-radius: 8px;
    height: 35px;
    display: flex;
    align-items: center;
    width: 100%;

    //padding-inline-start: 5px;
    .action-line-svgs {
      width: 24.5px;
      // width: 32.5px;
      
    }

    span {
      margin-inline-start: 10px;
    }

    &:hover {
      background-color: #F0FAFF;
    }

  }

  .action-text {
    color: black;
    font-weight: 400;
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}

@media only screen and (min-width: $screen-tablet) {

  .drawer-line-container:first-child {
    margin-top: 21px;
    margin-bottom: 0px;
  }

  .drawer-line-container {
    margin: 9.5px 0;

    .line-flex {
      height: 55px;
      padding: 0px 19.5px;
    }

  }

  .w-auto {
    width: auto !important;
  }
}
</style>
