<template>
  <div>
    <div class="wallet-container">
      <h6 class="title">{{ $ml.get('wallet.main.card.title') }}</h6>
      <h1><span v-formatDecimal="[balance, getSymbole]"></span></h1>
      <img src="../assets/mygmt_v2_images/wallet-buttons.png" alt="wallet-belt"
        :class="{ 'arrow-mirror': getDir === 'rtl' }" />
      <button class="btn btn-white" @click="walletActions()">{{ $ml.get('deposit_to_wallet') }}</button>
    </div>
    <Drawer ref="walletActions" :title="$ml.get('actions')" drawerId="walletAction" class="drawer-wrapper-wallet"
      inLineDrawer>
      <template v-slot:content>
        <WalletActions />
      </template>
    </Drawer>
  </div>
</template>
<script>
import { isApp, isMobile ,getCurrencySymbol} from "@/services/utils";
import { polledBalances } from "@/services/api";
import WalletActions from "@/components/WalletActions.vue";
import Drawer from "@/components/Drawer.vue";
import { formatDecimalAndStyleTitle } from '../directives/AppDirectives'

export default {
  name: 'Wallet',
  components: { Drawer, WalletActions },
  props: {
    currency: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isApp: isApp(),
    }
  },
  directives: {
    'formatDecimal': formatDecimalAndStyleTitle
  },
  created() {
   },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    balance() {
      let balance = this.$store.getters.getWalletBalances.find(balance => {
        return balance.currency === this.currency
      })
      return balance ? balance.amount : 0
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    getSymbole() {
      return getCurrencySymbol(this.currency);
    },
  },
  mounted() {

  },
  methods: {
    updateBalances() {
      polledBalances(response => {
        this.$store.commit('setCashBackBalances', response.CASHBACK);
        if (response.WALLET && response.WALLET.length > 0) {
          this.$store.commit("setWalletBalancesAreUpdated", true)
          this.$store.commit('setWalletBalances', response.WALLET);
        } else {
          this.$store.commit("setWalletBalancesAreUpdated", false)
        }
      }, true)
    },
    walletActions() {
      if (isMobile()) {
        this.$emit('onActionClick')
      } else {
        this.$refs.walletActions.openDrawer();
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.wallet-container {
  padding: 0 5%;
  height: 220px;
  max-width: 300px;
  width: 300px;
  margin: auto;
  background: url("../assets/mygmt_v2_images/wallet_decorations.png") no-repeat right, linear-gradient(223.24deg, #2538A9 6.89%, #0E1F81 82.79%) no-repeat;
  background-size: contain, cover;
  border-radius: 21.5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 20px;

  h6 {
    font-size: 13px;
    font-weight: 400;
  }

  h1 {
    font-size: 36px;
    font-weight: 600;
  }

  img {
    width: 64px;
    align-self: flex-end;
    position: relative;
    inset-inline-start: 35px;
    bottom: 7.5px;
  }

  .btn-white {
    color: black;
    height: 33px;
    font-size: 13px;
    font-weight: 400;
    margin-top: 15px;
    padding: 0 12.32px 6.16px 12.32px;
    gap: 12px;
  }
}

@media only screen and (min-width: $screen-tablet) {
  .wallet-container {
    height: 212px;

    .btn-white {
      width: 180px;
      height: 33px;
    }
  }

  .drawer-wrapper-wallet {
    border-radius: 20px;
    padding: 15px 10px 15px 13px;
    position: absolute;
    inset-inline-start: 20px !important;
    inset-block-start: 154px !important;
    width: 220px;
  }
}
</style>
