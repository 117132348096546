<template>
    <modal 
        ref="modalRef"
        image="/img/gifs/megaphone.gif"
        :title="$ml.get('termsUpdate.title')"
        :confirm="$ml.get('btnOk')" 
        no-cancel-btn no-action
        :cancel="$ml.get('customerEmailNotification.cancel')"
    >
    <p></p>
    <p v-text="$ml.get('termsUpdate.body.first')"></p>
    <a v-text="$ml.get('termsUpdate.body.second')"
        :href="'https://gmtonline.co.il/'+ $ml.get('protocolLink')" 
        target="_blank" style="color:white !important;"></a>
               
    </modal>
</template>
<script>

import Modal from "@/components/Modal";
import {acceptTermsVersion} from "../services/api";

export default {
    name: 'TermsUpdate',
    components: {Modal},
    methods: {
        checkModalTerms(){
            const registrationData = JSON.parse(localStorage.getItem('registrationData'));
            if (registrationData && registrationData.termsOfUseExpired) {
                this.$refs.modalRef.openModal();
                registrationData.termsOfUseExpired = false;
                localStorage.setItem('registrationData', JSON.stringify(registrationData));
                acceptTermsVersion();
                return true;
            }
        },
    }
}
</script>