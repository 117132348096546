<template>
  <div class="wrap wallet-amount">
    <div v-if="mode === this.$walletTransferType.FRIEND">
      <h1 v-text="$ml.with('name', name).get('wallet.transfer.amount.title')" class="title"/>
    </div>
    <div v-else-if="mode === this.$walletTransferType.CARD">
      <h1 v-text="$ml.get('wallet.card.amount.title')" class="title" />
    </div>
    <div v-else-if="mode === this.$walletTransferType.WALLETORDER">
      <h1 v-text="$ml.get('wallet.deposit.cash.title')" class="title" />
    </div>
    <div v-else>
      <h1 v-text="$ml.get('wallet.load.amount.title')" class="title"/>
    </div>
    <lookup
        ref="lookup"
        :next-btn="$ml.get('nextLevel')"
        :logged-in="true"
        :label-names="this.mode === this.$walletTransferType.WALLETORDER && this.$store.getters.getLang === 'il' ? { amount: $ml.get('walletOrder.amount') } : {}"
        :fileds="{'amount' : true, 'currency': true}"
        :currencies-prop="this.currencies"
        :wallet-mode=this.mode
        @execute="execute"
        @next-step="nextStep">
    </lookup>
    <div class="message-box shield" style="margin-bottom:1px;">
      <div class="iconInfo">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
              d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
          <circle cx="8" cy="4.5" r="1"/>
        </svg>
      </div>
      <div>{{ $ml.get('wallet.balance') + (walletBalances || "₪0") }}</div>
      <div v-if="this.hasCashBack && this.cashBackBalance > 0">{{ $ml.get('cashBackBalance') + getSymbol + cashBackBalance }}</div>
    </div>
  </div>
</template>
<script>

import Lookup from "@/components/Lookup";
import {formatComma} from "@/filters/AppFilters";

export default {
  name: 'WalletAmount',
  components: {Lookup},
  props: {
    mode: {
      type: String,
      required: false
    },
    skip: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      currencies: [{currency: 'ILS', rate: 1}]
    }
  },
  computed: {
    name() {
      const beneficiary = JSON.parse(localStorage.getItem('currentWalletBeneficiary'))
      return beneficiary.firstName + ' ' + beneficiary.lastName
    },
    walletBalances() {
      return this.$store.getters.getWalletBalances.map((el) => {
        return el.symbol + formatComma(el.amount);
      }).join(", ");
    },
    getSymbol() {
      return this.$store.getters.getLookupCurrencySymbol;
    },
    hasCashBack() {
      return this.$store.getters.hasCashBack(this.getCurrency);
    },
    cashBackBalance() {
      return this.$store.getters.getCashBackBalance(this.getCurrency);
    },
  },
  created () {
    if (this.mode === this.$walletTransferType.WALLETORDER) {
      this.$store.commit('setLookupAmount', 0);
    }
  },
  methods: {
    execute() {
      if (this.mode === this.$walletTransferType.FRIEND) {
        this.$refs.lookup.doLookup('WALLET_TO_WALLET', 'ISR', 'GMT');
      } else if (this.mode === this.$walletTransferType.CARD) {
        this.$refs.lookup.doLookup('CARD_DEPOSIT', 'ISR', 'MAX');
      } else if (this.mode === this.$walletTransferType.WALLETORDER) {
        this.$refs.lookup.doLookup('WALLET_DEPOSIT', 'ISR', 'GMT', 'BRANCH');
      } else {
        this.$refs.lookup.doLookup('WALLET_DEPOSIT', 'ISR', 'GMT');
      }
    },
    nextStep() {
      this.$emit('onNextStep', true)
    },
  }
}

</script>

<style lang="scss" scoped>
@import "../../../assets/styles/partials/variables", "../../../assets/styles/partials/mixins";

.title {
  white-space: pre-line;
}

</style>