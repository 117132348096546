<template>
  <div>
    <Header />
    <div v-show="this.loaded" class="main-wrapper">
      <h2 style="width: 100%;">{{ $ml.get('walletOrder.deposited') }} {{ this.$store.getters.getOrderData.amount |
      formatComma }} {{ this.$store.getters.getOrderData.currency }}</h2>
      <h4>{{ $ml.get('walletOrder.verification') }} <br />
        {{ $ml.get('walletOrder.showTo') }}
      </h4>
      <h4 class="order-code">{{ this.$store.getters.getOrderData.code }}</h4>
      <lottie-player id="walking-gif-elm" autoplay loop mode="normal"
        :class="{ mirror: this.$store.getters.getDir === 'rtl' }"></lottie-player>
      <h4>{{ $ml.get('walletOrder.expiry') }} <span id="timeLeft" /> {{ $ml.get('walletOrder.minutes') }} <br />
        {{ $ml.get('walletOrder.returnHere') }} <br /> {{ $ml.get('walletOrder.mainPage') }}
      </h4>
      <div class="btn-link-container">
        <button @click="goBack()" class="btn">{{ $ml.get('btnClose') }}</button>
        <a @click="openCancelOrderModal()"><i class="fa fa-trash" aria-hidden="true"></i> {{
      $ml.get('walletOrder.cancel') }}</a>
      </div>
      <!-- modal successModal -->
      <div class="modal-mask modal-mask-card" id="successModal">
        <div class="modal-container modal-message" id="modal-mask-block" @click.stop>
          <div class="modal-body">
            <div class="successModal-body">
              <lottie-player id="complete-gif-elm" autoplay loop mode="normal"></lottie-player>
              <h2>{{ $ml.get('walletOrder.hooray') }} 🥳</h2>
              <h4>{{ $ml.get('walletOrder.depositedToYourWallet') }} {{ this.$store.getters.getOrderData.amount |
      formatComma }} {{ `${this.$store.getters.getOrderData.currency}` }} {{
      $ml.get('walletOrder.depositedToYourWallet1') }}</h4>
              <button class="btn btn-yellow btnModalSuccess" @click="goBack()">{{ $ml.get('walletOrder.close')
                }}</button>
            </div>
          </div>
        </div>
      </div>
      <!-- end modal successModal -->
      <!--Modal orderCancel dialogue -->
      <div class="modal-mask modal-mask-message toast" @click="closeCancelOrderModal()">
        <div class="modal-container container-dialogue modal-message" id="modal-message" @click.stop>
          <div class="modal-body body-dialogue">
            <div class="modal-header">
              <a class="a-close" @click="closeCancelOrderModal()"
                :class="{ 'a-close-rtl': getDir === 'rtl' }">&#10005;</a>
            </div>
            <div class="title">
              <h4>{{ $ml.get('walletOrder.cancelModalTitle') }}</h4>
            </div>
            <div class="title">{{ $ml.get('walletOrder.cancelModalText') }}</div>
            <div class="form-group group-btn">
              <button class="btn btn-small btn-border" @click="closeCancelOrderModal()">{{
      $ml.get('walletOrder.btnReturn') }}</button>
              <button type="submit" class="btn btn-small btn-yellow" @click="cancelOrder()">{{
                $ml.get('walletOrder.btnOk') }}</button>
            </div>
          </div>
        </div>
      </div>
      <!--End Modal orderCancel dialogue-->
    </div>
    <Footer />
  </div>
</template>
<script>

import Header from "../../components/Header";
import MainNav from "../../components/MainNav";
import "@lottiefiles/lottie-player";
import { cancelWalletOrder } from "../../services/api";
import { loadLottieJson, modalHandler } from "../../services/utils"
import Footer from "@/components/Footer.vue";

export default {
  name: 'WalletOrder',
  components: { Header, MainNav, Footer },
  data() {
    return {
      minutesLeft: 0,
      secondsLeft: 0,
      loaded: false,
      timeLeftTimer: null
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    }
  },
  watch: {
  },
  created() {
    window.scrollTo(0, 0);
    let updateTimeFunc = () => this.updateTimeEl()
    let popSuccess = () => this.handleStatusStateChanges()
    this.timeLeftTimer = setInterval(function () {
      popSuccess()
      updateTimeFunc()
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timeLeftTimer)
  },
  mounted() {
    loadLottieJson("walking-gif-elm", "walking")
    loadLottieJson("complete-gif-elm", "cash_deposit")
    this.loaded = true;
  },
  methods: {
    goBack() {
      this.$router.push({ path: '/home' });
    },
    closeCancelOrderModal() {
      modalHandler('.modal-mask-message', 'removeClassOpen');
    },
    openCancelOrderModal() {
      modalHandler('.modal-mask-message', 'addClassOpen');
    },
    cancelOrder() {
      cancelWalletOrder(this.$store.getters.getOrderId, (res) => {
        this.goBack();
        this.$analyticsService.transmitSwitchCase('wallet_order','wallet_order_canceled') 
      });
    },
    handleStatusStateChanges() {
      if (this.$store.getters.getOrderStatus === 'COMPLETED') {
        modalHandler('#successModal', 'show', '092086', 'light');
      }
      if (this.$store.getters.getOrderStatus === 'EXPIRED') {
        this.goBack()
      }
    },
    updateTimeEl() {
      this.minutesLeft = moment.unix(this.$store.getters.getOrderData.expiryDate).diff(moment(new Date()), 'minutes')
      this.secondsLeft = moment.unix(this.$store.getters.getOrderData.expiryDate).diff(moment(new Date()), 'seconds') % 60
      if (this.secondsLeft < 0) {
        this.goBack()
        return
      }
      $("#timeLeft").text(`${this.minutesLeft < 10 ? "0" + this.minutesLeft : this.minutesLeft}:${this.secondsLeft < 10 ? "0" + this.secondsLeft : this.secondsLeft}`)
    }
  },
}

</script>

<style lang="scss" scoped>
@import "../../assets/styles/partials/variables";
@import "../../assets/styles/partials/mixins";

.main-wrapper {

  display: flex;
  flex-direction: column;
  align-items: center;


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 25px;
    font-weight: normal;
    margin: 15px 0px;
    text-align: center;
  }

  h2 {
    margin-bottom: 0px;
  }

  h4 {
    font-size: 20px;
    margin: 0px;
  }

  .order-code {
    margin: 0px;
    margin-top: 10px;
    font-size: 135px;
    font-weight: bold;
    letter-spacing: 5px;
    line-height: 120px;
  }

  .successModal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .cancelOrderModal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .cancelOrderModal-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }

  .buttonWalletOrder {
    color: #092086;
    position: absolute;
    left: 4.3%;
    top: 1.4%;
    font-style: normal;
    font-size: 35px;
    font-weight: 500;
    font-family: Arial, sans-serif;
    transform: scale(1.1, 1);
  }

  .buttonWalletOrderRtl {
    left: auto;
    right: 4.3%;
  }

  .reverseColumn {
    display: flex;
    flex-direction: row-reverse;
  }

  .fa-trash {
    margin-top: 5px;
    margin-inline-end: 5px;
  }

  #walking-gif-elm {
    width: 15%;
  }

  #complete-gif-elm {
    width: 231px;
    height: 231px;
  }

  .btnModalSuccess {
    margin: 20px;
  }

  .btn-link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .btn {
      width: 150px;
    }

    a {
      margin-top: 20px;
      cursor: pointer;
    }
  }

  @include max-screen($screen-phones) {
    #walking-gif-elm {
      width: 75%;
    }

    .btn-link-container {
      margin-bottom: 30px;
    }
  }
}
</style>