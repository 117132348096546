<template>
  <div class="wrap">
    <form class="registration-step-1" @submit.prevent="nextStep">
      <div class="form-group">
        <label for="firstName" v-text="$ml.with('VueJS').get('labelName')"></label>
        <input type="text" name="firstName" id="firstName" class="form-control"
               v-validate="{ required: true, regex: /^[^\s][a-zA-Z\s-]*[^\s]$/}" v-model.trim="firstName">
        <span class="error">{{ errors.first('firstName') }}</span>
      </div>
      <div class="form-group">
        <label for="middleName" v-text="$ml.with('VueJS').get('middleName')"></label>
        <input type="text" name="middleName" id="middleName" class="form-control" :placeholder="$ml.get('notRequired')" v-validate="{ regex: /^[^\s][a-zA-Z\s-]*[^\s]$/}" v-model.trim="mname">
        <span class="error">{{ errors.first('middleName') }}</span>
      </div>
      <div class="form-group">
        <label for="lastName" v-text="$ml.with('VueJS').get('labelSurname')"></label>
        <input type="text" name="lastName" id="lastName"
               class="form-control" v-validate="{ required: true, regex: /^[^\s][a-zA-Z\s-]*[^\s]$/}" v-model.trim="lastName">
        <span class="error">{{ errors.first('lastName') }}</span>
      </div>
      <div class="form-group">
        <label for="anotherSurname" v-text="$ml.with('VueJS').get('anotherSurname')"></label>
        <input type="text" name="anotherSurname" id="anotherSurname" :placeholder="$ml.get('notRequired')"
               class="form-control" v-validate="{regex: /^[^\s][a-zA-Z\s-]*[^\s]$/}" v-model.trim="anotherLName">
        <span class="error">{{ errors.first('anotherSurname') }}</span>
      </div>

      <div class="form-group">
        <label for="gender" v-text="$ml.with('VueJS').get('gender')"></label>
        <div class="custom-select">
          <select name="gender" id="gender" class="form-control" v-validate="'required'" v-model="gender">
            <option v-for="(gender, index) in genders" :key="index" :value="gender.iso">
              {{ gender[$store.state.locale.lang] }}
            </option>
          </select>
          <span class="error">{{ errors.first('gender') }}</span>
        </div>
      </div>
      <div class="form-group">
        <label for="business" v-text="$ml.with('VueJS').get('business')"></label>
        <div class="custom-select">
          <select name="business" id="business" class="form-control" v-validate="'required'" v-model="occupation">
            <option v-for="(occupation, index) in occupations.filter(item => {return item.enabled!=false})" :key="index" :value="occupation['enum']">
              {{ occupation[$store.state.locale.lang] }}
            </option>
          </select>
          <span class="error">{{ errors.first('business') }}</span>
        </div>
      </div>
      <div class="form-group">

        <label for="birthDate" v-text="$ml.with('VueJS').get('dateBirth')+$ml.with('VueJS').get('myDateBirthday')"

               style="width:300px;"></label>

        <date-dropdown name="birthDate" id="birthDate" :min="dateRange.min"
                       v-validate="{date_format:'dd.MM.yyyy', after: after, age18: age18}" v-model="birthDate" :max="dateRange.max"
                       months-names="1,2,3,4,5,6,7,8,9,10,11,12" style="display: inline-flex;
                                                                                 text-align: center;"/>

        <span class="error">{{ errors.first('birthDate') }}</span>
      </div>

      <button class="btn btn-next" v-text="$ml.with('VueJS').get('bntContinued')"></button>
      <div class="message-box shield">
        <div class="iconInfo">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
                d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
            <circle cx="8" cy="4.5" r="1"/>
          </svg>
        </div>
        <div class="message">{{ this.$ml.with('VueJS').get("REGISTER_INFO_DETAILS_REQUIRED") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import genders from '../../data/gender';
import occupations from '../../data/occupation';
import {EventBus} from "../../event-bus";
import DateDropdown from 'vue-date-dropdown';
import {localesDictionary, transmitEvent} from "../../services/utils";
// import moment from "moment";

export default {
  name: 'Step1',

  components: {
    DateDropdown
  },

  data() {
    return {
      genders,
      occupations,
      firstName: this.$store.state.registration.firstName,
      mname: this.$store.state.registration.middleName,
      lastName: this.$store.state.registration.lastName,
      anotherLName: this.$store.state.registration.anotherLastName,
      birthDate: this.$store.state.registration.birthDate,
      gender: this.$store.state.registration.gender,
      occupation: this.$store.state.registration.occupation,
      errorCount: 0
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.$validator.extend('age18', {
      validate(value, args) {
        var birthday = moment(value, "DD.MM.YYYY");
        return moment().diff(birthday, 'years') >= 18;
      }
    });
    this.$validator.extend('ageLimit', {
      validate(value, args) {
        var birthday = moment(value, "DD.MM.YYYY");
        return moment().diff(birthday, 'years') <= 85;
      }
    });
    EventBus.$on('changeLang', lang => {
      this.$validator.localize(this.dictionary());
      this.$validator.localize(lang);
    })
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    dateRange() {
      const year = moment().get('year')
      return {min: '' + (year - 80), max: '' + (year - 18)}
    },
    after() {
      return moment([this.dateRange.min]).startOf('year').format('DD.MM.YYYY');
    },
    age18() {
      const birthday = moment(this.birthDate, "DD.MM.YYYY");
      return moment().diff(birthday, 'years') < 18;
    }
  },
  mounted() {
    this.$validator.localize(this.dictionary());
    this.$validator.localize(this.getLang);
    setTimeout(() => {
      this.showPicker = true
    }, 200);
  },
  methods: {
    nextStep() {
      this.$validator.validate().then(result => {
        if (result) {
          this.$store.commit('setRegistration', {
            'firstName': this.firstName,
            'middleName': this.mname,
            'lastName': this.lastName,
            'anotherLastName': this.anotherLName,
            'birthDate': this.birthDate,
            'gender': this.gender,
            'occupation': this.occupation,
            'language': this.$store.state.locale.lang
          });
          this.$analyticsService.transmitSwitchCase('registration', 'registration_stage_4_1', {error_count: this.errorCount})
          this.$emit('onNextStep', 1)
        } else {
        this.errorCount = this.errorCount + 1
        }
      })
    },
    format(value, event) {
      return moment(value).format('YYYY-MM-DD')
    },

    clickOnGender() {
      const ciList = this.$refs.smoothPicker.getCurrentIndexList()
      window.alert('Clicked index:' + ciList[0])
    },
    dataChange(gIndex, iIndex) {
      console.info('list', gIndex, iIndex)
    },
    confirm() {
      const ciList = this.$refs.smoothPicker.getCurrentIndexList()
      window.alert('Selected: ' + this.data[0].list[ciList[0]])
    },
    dictionary() {
      const custom = {
        birthDate: {
          date_format: this.$ml.with('VueJS').get("E_REGEX"),
          after: this.$ml.with('VueJS').get("E_REQUIRED"),
          age18: this.$ml.with('VueJS').get("E_REGEX_birthDateAge"),
          ageLimit: this.$ml.with('VueJS').get("E_REGEX_birthDateAge")
        }
      }
      const messages = {
        required: this.$ml.with('VueJS').get("E_REQUIRED"),
        regex: this.$ml.with('VueJS').get("E_REGEX_english"),
      }
      return localesDictionary({custom: custom, messages: messages});
    }
  }
}
</script>

<style lang="scss">
@import "~air-datepicker/dist/css/datepicker.min.css";

.date-dropdown-container {
  display: flex;
  direction: ltr;


}

.date-dropdown-select.day,
.date-dropdown-select.year,
.date-dropdown-select.month {
  text-align: center;
  display: block;
  border: 1px solid #dadada;
  margin-top: 5px;
  background-color: white;
  height: 34px;
  border-radius: 2px;
  background-color: white;
  padding: 0 9px !important;
  font-size: 18px;
  font-weight: 400;
  color: #4a4a4a;
}

</style>

<style scoped>
.datepickers-container {
  position: fixed;

}

input:invalid {
  color: red;
}

</style>
