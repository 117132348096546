<template>
    <div>
        <Header />
        <div class="main-wrapper">
            <div class="content block block-paymentResponse">
                <div class="wrap">

                    <div v-if="loading" class="loader_page">
                        <img src="../assets/images/loading.gif" />
                        <div class="loader_page_msg" v-text="$ml.get('paymentProcessing')"></div>
                    </div>

                    <div class="modal-mask modal-mask-transfer" @click="closeModal">
                        <div class="modal-container modal-transfer" id="modal-transfer" @click.stop>
                            <div class="modal-body">
                                <img src="../assets/images/success_once.gif" alt="">
                                <div class="modal-text">
                                    <strong v-if="cardDeposit"
                                        v-text="$ml.with('VueJS').get('billingSuccessfulCardDeposit')"></strong>
                                    <strong v-else-if="bankTransfer"
                                        v-text="$ml.with('VueJS').get(inIsrael ? 'billingSuccessfulBankTransferISR' : 'billingSuccessfulBankTransfer')"></strong>
                                    <strong v-else v-text="$ml.with('VueJS').get('billingSuccessful')"></strong>
                                    <p v-text="$ml.with('VueJS').get('messageSend')"></p>
                                </div>
                                <button class="btn btn-yellow btn-close" v-text="$ml.with('VueJS').get('btnClose')"
                                    @click="recentActions()"></button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-mask modal-mask-yaad-error">
                        <div class="modal-container modal-transfer" id="modal-transfer">
                            <div class="modal-body">
                                <img src="../assets/images/gifs/error_card.gif" alt="">
                                <div class="modal-text">
                                    <p v-text="$ml.with('VueJS').get('YAAD_SARIG_' + this.CCode)"></p>
                                </div>
                                <button class="btn btn-yellow btn-close" v-text="$ml.with('VueJS').get('btnReturn')"
                                    @click="backToTransfer()"></button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <Footer />

    </div>

</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import { commitTransaction } from "../services/api";
import { EventBus } from "@/event-bus";
import { isApp, modalHandler, transmitEvent } from "@/services/utils";
import Footer from "@/components/Footer.vue";

export default {
    name: 'PaymentResponse',
    components: { MainNav, Header, Footer },
    data() {
        return {
            loading: false,
            referenceNumber: '',
            selectedOption: [],
            CCode: '',
        }
    },
    watch: {
        CCode(value) {
            if (value != '800') {
                modalHandler('.modal-mask-yaad-error', 'show', '092086', 'light');
                sessionStorage.removeItem("cc_tempInfo");
            }
        },
    },
    created() {
        this.CCode = this.$route.query.CCode;
        if (this.CCode != '800' && !this.$ml.with('VueJS').get('YAAD_SARIG_' + this.CCode))
            this.CCode = 'GENERAL';

        if (this.CCode == '800') {
            this.loading = true;
            this.selectedOption = JSON.parse(localStorage.getItem('selectedTransferOption'));
            let mgparams = JSON.parse(localStorage.getItem('mgparams'));
            var url = window.location.href;
            var queryParams = url.split("?")[1];
            var YaadSarigId = this.$route.query.Id;
            var amountILS = this.$route.query.Amount
            var ACode = this.$route.query.ACode
            var clientCardNumber = this.$route.query.L4digit
            commitTransaction({
                "queryParams": queryParams,
                "transaction": {
                    "id": mgparams.transactionId,
                    "sessionId": this.selectedOption.sessionId,
                    "paymentType": 'CREDIT_CARD',
                    "amountILS": parseFloat(amountILS),
                    "aCode": ACode,
                    "yaadSarigId": YaadSarigId,
                    "clientCardNumber": clientCardNumber,
                    "deliveryOptionCorrespondentReference": this.selectedOption.deliveryOptionCorrespondentReference,
                }
            }, responseCommit => {
                this.loading = false;
                if (responseCommit.result) {
                    this.referenceNumber = responseCommit.result;
                    modalHandler('.modal-mask-transfer', 'show', '092086', 'light');
                    if (this.selectedOption.transactionClass === 'WALLET_DEPOSIT') {
                        setTimeout(() => EventBus.$emit('updateWalletBalances'), 5000);
                    }
                    if (this.$analyticsService && localStorage.getItem('analyticsEvent')) {
                        try {
                            const analyticsEvent = JSON.parse(localStorage.getItem('analyticsEvent'));
                            this.$analyticsService.transmitSwitchCase(analyticsEvent.group,analyticsEvent.eventId, {reference_number: this.referenceNumber})
                        } catch (error) {
                        console.log("Error in trasmitting to analytics:", error)
                        }
                    }
                } else {
                    this.$store.commit('setShowModalError', true);
                    this.$store.commit('setErrorMessage', responseCommit.errorMessage);
                    this.$store.commit('setErrorCode', responseCommit.errorCode);
                    this.$analyticsService.transmitSwitchCase('transaction','transaction_error', {errorCode:  responseCommit.errorCode}) 
                }
                //remove all localstorage items for credit payment
                localStorage.removeItem('selectedTransferOption');
                localStorage.removeItem('selectedBeneficiary');
                localStorage.removeItem('customerFields');
                localStorage.removeItem('beneficiaryFields');
                localStorage.removeItem('responseValidation');
                localStorage.removeItem('analyticsEvent');

            });
        }
    },
    computed: {
        getDir() {
            return this.$store.getters.getDir;
        },
        cardDeposit() {
            return this.selectedOption.transactionClass === 'CARD_DEPOSIT'
        },
        bankTransfer() {
            return this.selectedOption.transactionClass === 'BANK_TRANSFER'
        },
        inIsrael() {
            return this.selectedOption.destinationCountry === 'ISR'
        }
    },
    mounted() {
        document.body.setAttribute('dir', this.getDir);
    },
    methods: {
        closeModal() {
            modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
        },
        recentActions() {
            modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
            this.$router.push('recent_actions');
        },
        backToTransfer() {
            modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
            this.$router.push('transfer');
        },

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.block-paymentResponse {
    max-width: 739px;
    margin: 0 auto;
    padding: 40px 0;

    .wrap {
        padding: 0;
    }

    .title {
        margin-top: 17px;
    }

    .subtitle {
        text-align: right;
        margin-top: 25px;

        a {
            display: inline;
            text-decoration: underline;
            padding-right: 5px;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
</style>
