<template>
  <div>
    <Header class="header-registration"></Header>
    <div class="main-wrapper main-wrapper-logged-out">
      <div class="content block block-registration registration-main">
        <div v-show="typeRegistration != ''" class="content-top">
          <div class="breadcrumbs breadcrumbs-main">
            <ul>
              <li v-for="(step, index) in steps" :key="index">{{ $ml.with('VueJS').get(step) }}</li>
            </ul>
          </div>
        </div>
        <div class="content-registration beforeSteps">
          <div class="wrap text-center">
            <h1 class="title" v-text="$ml.with('VueJS').get('registerSystem')"></h1>
            <h2 v-show="typeRegistration == ''" class="subtitle registerHeader"
              v-text="$ml.with('VueJS').get('registerText1')"></h2>
            <h2 v-show="typeRegistration == ''" class="subtitle" v-text="$ml.with('VueJS').get('registerText2')"></h2>
            <template v-if="typeRegistration == 'israeli_resident'">
              <h2 v-show="typeRegistration" class="subtitle" v-text="$ml.with('VueJS').get('israeliResident')"></h2>
            </template>
            <template v-else>
              <h2 v-show="typeRegistration" class="subtitle" v-text="$ml.with('VueJS').get('nonIsraeliResident')"></h2>
            </template>
            <div class="body">
              <div class="wrap">
                <div v-show="!typeRegistration" class="type-registration israeli_resident">
                  <img class="gif-registration" src="../assets/images/israeli.gif" alt=""
                    @click="showForm('israeli_resident')">
                  <button class="btn" @click="showForm('israeli_resident')"
                    v-text="$ml.with('VueJS').get('israeliID')"></button>
                </div>
                <div v-show="!typeRegistration" class="type-registration expatriate">
                  <img class="gif-registration" src="../assets/images/foreign.gif" alt=""
                    @click="showForm('expatriate')">
                  <button class="btn" @click="showForm('expatriate')"
                    v-text="$ml.with('VueJS').get('foreignPassport')"></button>
                </div>
                <div v-show="!typeRegistration" class="type-registration expatriate">
                  <router-link v-bind:to="'/login'" class="elementor-text-editor elementor-clearfix"
                    v-text="$ml.with('VueJS').get('linkToLoginPage')"></router-link>
                </div>
                <div class="info-type-registration">



                 <!-- STEP 2 -->


                  <div v-if="typeRegistration == 'israeli_resident'" class="form-type">
                    <form class="form-registration-step-1" @submit.prevent="onRegistration">
                      <div class="form-group">
                        <label for="id" v-text="$ml.with('VueJS').get('myId')"></label>
                        <input type="text" name="id" id="id" class="form-control" v-model.trim="user_id"
                          v-validate="'required|validIsraeliId'"
                          v-bind:placeholder="$ml.with('VueJS').get('placeholderDigit')">
                        <span class="error">{{ errors.first('id') }}</span>
                      </div>
                      <div class="form-group">
                        <label for="mobile_phone" v-text="$ml.with('VueJS').get('mobilePhoneNumber')"></label>
                        <input type="tel" name="mobile_phone" id="mobile_phone" placeholder="0599999999"
                          class="form-control" v-model.trim="user_phone"
                          v-validate="{ required: 'required', numeric: 'numeric', regex: [/^05\d([-]{0,1})\d{7}$/] }">
                        <span class="error">{{ errors.first('mobile_phone') }}</span>
                      </div>
                      <button class="btn btn-next" v-text="$ml.with('VueJS').get('btnSignIn')"></button>
                      <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" size="invisible"
                        :sitekey="sitekey"></vue-recaptcha>
                    </form>
                  </div>
                  <div v-if="typeRegistration == 'expatriate'" class="form-type">
                    <form class="form-registration-step-1 step-expatriate" @submit.prevent="onRegistration">
                      <div class="form-group">
                        <label for="country" v-text="$ml.with('VueJS').get('selectCountry')"></label>
                        <div class="custom-select">
                          <!--<select name="country" id="country" class="form-control" v-model="user_country" v-validate="'required'">
                              <option v-for="country in countries" :value="country.iso">
                                  {{country[$store.state.locale.lang]}}
                              </option>
                          </select>-->
                          <model-list-select :list="countries" name="country" v-validate="'required'"
                            class="form-control" v-model="user_country" data-vv-validate-on="input" option-value="iso"
                            :option-text="$store.state.locale.lang"></model-list-select>
                          <span class="error">{{ errors.first('country') }}</span>
                        </div>
                      </div>
                      <div v-if="this.user_country in provinces" class="form-group">
                        <label for="country" v-text="$ml.with('VueJS').get('selectProvince')"></label>
                        <div class="custom-select">
                          <select name="province" id="province" class="form-control" v-model="user_province"
                            v-validate="'required'">
                            <option v-for="province in provinces[this.user_country]" :value="province.code">
                              {{ province.name }}
                            </option>
                          </select>
                          <span class="error">{{ errors.first('province') }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="passport" v-text="$ml.with('VueJS').get('numberPassport')"></label>
                        <input type="text" name="passport" id="passport" class="form-control" v-model.trim="user_id"
                          v-validate="{ required: true, regex: /^[a-zA-Z0-9]*$/, min: 5 }">
                        <span class="error">{{ errors.first('passport') }}</span>
                      </div>
                      <div class="form-group">
                        <label for="phone" v-text="$ml.with('VueJS').get('mobilePhoneNumber')"></label>
                        <input type="tel" name="phone" id="phone" class="form-control" v-model.trim="user_phone"
                          placeholder="0599999999"
                          v-validate="{ required: 'required', regex: [/^05\d([-]{0,1})\d{7}$/] }">
                        <span class="error">{{ errors.first('phone') }}</span>
                      </div>
                      <button class="btn btn-next" v-text="$ml.with('VueJS').get('btnSignIn')"></button>
                      <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" size="invisible"
                        :sitekey="sitekey"></vue-recaptcha>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="shield" v-show="typeRegistration == ''">
              <div class="iconInfo">
                <img class="imgInfo" src="../assets/images/icons/shield.png" />
              </div>
              <div class="message">
                <h2 class="subtitle" v-text="$ml.with('VueJS').get('registerFooter3')"></h2>
                <h2 class="subtitle" v-text="$ml.with('VueJS').get('registerFooter')"></h2>
                <h2 class="subtitle" v-text="$ml.with('VueJS').get('registerFooter2')"></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <select-lang ref="selectLangModal" @post-upadte="postUpdate"></select-lang>

    <!--Message with code-->
    <div class="modal-mask modal-mask-message" @click="closeModal">
      <div class="modal-container modal-message" id="modal-message" @click.stop>
        <div>
          <span @click="closeModal" class="close-modal" :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
          <div class="modal-body">
            <img src="../assets/images/sms_once.gif" alt="">
            <div class="title" v-text="$ml.with('VueJS').get('messageCode')"></div>
            <input type="text" inputmode="numeric" maxlength="6" class="form-control sms-code" id="smsCode"
              name="smsCode" v-model="smsCode" :class="{ 'form-error': errorCode }" @keyup.enter="registrationNext()">
            <span class="error">{{ error }}</span>
            <div class="form-group group-btn">
              <button type="submit" class="btn btn-small btn-yellow" v-text="$ml.with('VueJS').get('btnOk')"
                @click="registrationNext()"></button>
            </div>
          </div>
        </div>
        <div v-if="isAndroidMobile" class="footer" v-text="$ml.get('messageNotSent')"></div>
      </div>
    </div>
    <!--End Message with code-->
  </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import store from '../store';
import router from '../router';
import { Validator } from 'vee-validate';
import VueRecaptcha from 'vue-recaptcha';
import { EventBus } from "../event-bus";
import { registrationSMS, validateRegistrationSMS } from '../services/api';
import countries from '../data/countries';
import provinces from '../data/provinces';
import { localesDictionary, modalHandler, detectUserOs } from "@/services/utils";
import SelectLang from '../components/SelectLang.vue'

export default {
  name: 'Registration',
  components: { MainNav, Header, VueRecaptcha, SelectLang },
  props: {
    invitedBy: {
      type: String
    }
  },
  data() {
    return {
      isAuthorization: false,
      currentStep: 1,
      currentStepClass: 'step-1',
      typeRegistration: '',
      smsCode: '',
      errorCode: '',
      errorCount: 0,
      error: '',
      steps: ['r_step_name', 'r_step_address', 'r_step_doc'],
      countries: countries,
      user_country: null,
      user_id: null,
      user_phone: null,
      user_province: null,
      provinces: provinces,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
    }
  },
  mounted() {
    store.commit('resetRegistration');
    document.body.setAttribute('dir', this.getDir);
    Validator.localize(this.dictionary());
    Validator.localize(this.getLang);
    this.closeModal();
    this.$refs.selectLangModal.checkLangs();
  },
  created() {
    this.$analyticsService.transmitSwitchCase('registration', 'registration_stage_1')
    store.commit('resetAllState');
    store.commit('setNotSigned', false);

    if (!localStorage.getItem("lang") && this.$route.query.lang) {
      var lang = this.$route.query.lang;
      this.$ml.change(lang);
      this.$store.commit('changeLang', { 'lang': lang, 'dir': '' });
      this.$store.commit('setLangTitle', lang);
    }

    this.countries = _.orderBy(this.countries, this.getLang).filter(item => {
      return item.en.indexOf('Israel') < 0
    });

    EventBus.$on('changeLang', lang => {
      this.$validator.localize(this.dictionary());
      this.$validator.localize(lang);
    })

    this.$validator.extend('validIsraeliId', {
      validate(value, args) {
        var id = String(value);
        var pattern = new RegExp(/^\d{9}$/);
        if (!pattern.test(id)) return false;
        return Array.from(id, Number).reduce((counter, digit, i) => {
          const step = digit * ((i % 2) + 1);
          return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
      }
    });
    if (this.invitedBy) {
      sessionStorage.setItem('invitedBy', this.invitedBy);
    }
  },

  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    currentTabComponent: function () {
      return 'Step' + this.currentStep;
    },
    isAndroidMobile() {
      // return detectUserOs() == 'Android';
      return true
    }
  },
  methods: {
    showForm(type) {
      $("#header-back-btn").show()
      $("#header-back-btn").click(() => {
        window.location.replace('/#/Registration')
        window.location.reload()
      })
      $(".unregistered-nav-box").hide()
      this.typeRegistration = type;
      this.$store.commit('setType', type);
      this.$analyticsService.transmitSwitchCase('registration', 'registration_stage_2', {type})
      if (type == 'israeli_resident') this.user_country = 'ISR'
    },
    closeModal() {
      this.smsCode = '';
      this.error = '';
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
    },
    onRegistration() {
      this.$validator.validate().then(result => {
        if (result) {
          this.$refs.recaptcha.execute();
        }
      });
    },
    onVerify: function (recaptchaToken) {
      let self = this;
      let data = {
        'country': this.user_country,
        'province': this.user_province,
        'identification': this.user_id,
        'mobileNumber': this.user_phone,
        'recaptchaToken': recaptchaToken,
        'lang': this.$store.getters.getLang,
        'invitedBy': sessionStorage.getItem('invitedBy')
      };

      if (this.typeRegistration === '') {
        return false;
      }

      registrationSMS(data, function (response) {
        if (response.data.errorCode != '') {
          this.$refs.recaptcha.reset();
          return false;
        }

        store.commit('setRegistration', data);
        modalHandler('.modal-mask-message', 'show', '092086', 'light');
        $('#smsCode').focus();
      });
    },
    onExpired: function () {
      console.log('Expired');
      this.$refs.recaptcha.reset();
    },
    validCode: function (code) {
      var re = /^\d+$/;
      return re.test(code);
    },
    registrationNext() {
      if (!this.validCode(this.smsCode)) {
        this.errorCode = true;
        this.errorCount = this.errorCount +1
        return true;
      }
      let data = {
        'country': this.user_country,
        'province': this.user_province,
        'identification': this.user_id,
        'mobileNumber': this.user_phone,
        'smsCode': this.smsCode,
        'lang': this.$store.getters.getLang,
      };
      let self = this;
      validateRegistrationSMS(data, (response) => {
        if (!response.data.errorCode) {
          self.errorCode = false;
          this.$store.commit('setRegistration', {
            'smsCode': this.smsCode,
          });
          this.$analyticsService.transmitSwitchCase('registration', 'registration_stage_3', {error_count: this.errorCount, user_phone: this.user_phone})
          router.push('/registration_steps');
        }
        if (response.data.errorCode == 'REGISTRATION_TOKEN_NOT_FOUND')
          self.errorCount = this.errorCount + 1
          self.error = self.$ml.with('VueJS').get(response.data.errorCode);
      });
    },
    dictionary() {
      const custom = {
        phone: {
          regex: this.$ml.with('VueJS').get("E_REGEX_mobileNumber")
        },
        mobile_phone: {
          regex: this.$ml.with('VueJS').get("E_REGEX_mobileNumber")
        },
        id: {
          validIsraeliId: this.$ml.with('VueJS').get("E_REGEX_israeliId")
        },
        passport: {
          regex: this.$ml.with('VueJS').get("E_REGEX_passport"),
          min: this.$ml.with('VueJS').get("E_MIN_passport"),
        }
      }
      const messages = {
        required: this.$ml.with('VueJS').get("E_REQUIRED"),
        numeric: this.$ml.with('VueJS').get("E_NUMERIC"),
        regex: this.$ml.with('VueJS').get("E_REGEX"),
      }
      return localesDictionary({ custom: custom, messages: messages });
    },
    postUpdate() {
      Validator.localize(this.dictionary());
      Validator.localize(this.getLang);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.block-registration {

  padding-top: 0;

  .wrap {
    padding: 0;
    max-width: 739px;
    margin: 0 auto;
  }

  &.registration-main {
    .body {
      margin-top: 30px;
    }
  }

  .registerHeader {
    padding-top: 3px;
  }

  .type-registration {
    .btn {
      margin: 15px auto 0;
      font-size: 18px;
    }

    &.israeli_resident {
      .btn {
        min-width: 221px;
        width: auto !important;
        padding: 0 10px;
      }
    }

    &.expatriate {
      margin-top: 42px;

      .btn {
        min-width: 221px;
        width: auto !important;
        padding: 0 10px;
      }
    }
  }

  .title {
    line-height: 42px;
  }

  .form-registration {
    margin-top: 19px;

    .form-group,
    .btn {
      display: inline-block;
    }

    .form-group {
      margin-left: 16px;
    }

    label {
      text-align: right;
      color: #4a4a4a;
      padding-right: 10px;
    }

    .form-control {
      width: 260px;
      height: 34px;
      margin-top: 5px;
      color: #4a4a4a;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #4a4a4a;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #4a4a4a;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #4a4a4a;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: #4a4a4a;
      }
    }

    .btn {
      height: 34px;
      line-height: 34px;
      width: 180px;
      font-size: 18px;
    }
  }

  .info-type-registration {
    text-align: center;
  }

  .form-registration-step-1 {

    .form-group,
    .btn-next {
      display: inline-block;
      vertical-align: middle;
    }

    .form-group {
      text-align: right;
      margin-left: 14px;

      label {
        color: #4a4a4a;
        padding-right: 10px;
      }

      .form-control {
        width: 260px;
        margin-top: 5px;
        /*&[aria-invalid=true]{
            border-color: #ff6a68;
        }*/
      }
    }

    .btn-next {
      min-width: 180px;
      width: auto;
      padding: 0 10px;
      font-size: 18px;
      vertical-align: bottom;
      height: 38px;
      line-height: 38px;
      display: block;
      margin: 19px auto 0;
    }
  }

  .step-expatriate {
    .form-group .form-control {
      width: 230px !important;
    }

    .btn-next {
      display: block;
      min-width: 130px !important;
      margin-top: 55px;
    }
  }

  .error {
    width: 100%;
    right: 0;
  }

  .terms-transfer {
    text-align: start;
    margin-top: 5px;
    margin-inline-start: 11%;

    textarea.form-control {
      color: #092086;
      width: 100%;
      height: 429px;
      border: 2px solid rgb(236, 236, 236);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background-color: #ffffff;
      padding: 13px 14px;
      line-height: 21px;

      &::-webkit-scrollbar-button {
        background-image: url('');
        background-repeat: no-repeat;
        width: 5px;
        height: 0px
      }

      &::-webkit-scrollbar-track {
        background-color: #ecedee
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0px;
        border-radius: 0px;
        background-color: $mainColor;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: $mainColor;
      }

      &::-webkit-resizer {
        background-image: url('');
        background-repeat: no-repeat;
        width: 4px;
        height: 0px
      }

      &::-webkit-scrollbar {
        width: 4px;
      }
    }

    .form-group {
      margin-right: 0 !important;
      margin-left: 0 !important;
      width: 100%;
    }

    .group-btn {
      margin: 30px 0 0;
      display: block;
      text-align: center;

      .btn {
        margin: 0 10px;
        display: inline;
      }
    }

    .btn-clear {
      background-color: transparent;
      border: 1px solid #092086;
      color: #092086;
      line-height: 31px;

      &:hover {
        background-color: #092086;
        color: #ffffff;
      }
    }
  }

  .checkbox {
    margin-top: 10px;
    margin-right: 5px;
    position: relative;

    input[type=checkbox] {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;

      &:checked+label {
        &:before {
          background-color: $mainColor;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    label {
      color: #092086 !important;
      position: relative;
    }

    label:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 13px;
      height: 13px;
      border: 1px solid rgb(9, 32, 134);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      margin-left: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }

    label:after {
      content: '';
      display: block;
      width: 9px;
      height: 8px;
      background-image: url(../assets/images/icons/i-check.png);
      position: absolute;
      top: 3px;
      bottom: 0;
      right: 3px;
      margin: auto;
      opacity: 0;
    }
  }

}
</style>
