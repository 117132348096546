<template>
    <div class="wrap">
        <form class="registration-step-2" @submit.prevent="nextStep">
            <div class="form-group">
                <label for="cityCode" v-text="$ml.with('VueJS').get('city')"></label>
                <div class="custom-select">
                    <model-list-select :list="cities" class="form-control" v-model="cityCode" name="cityCode"  v-validate="'required'" option-value="code" :option-text="$store.state.locale.lang=='il'?'il':'en'" @input="getCityStreets()" data-vv-validate-on="input"></model-list-select>
                    <span class="error">{{ errors.first('cityCode') }}</span>
                </div>
            </div>
            <div class="form-group">
                <label for="streetCode" v-text="$ml.with('VueJS').get('streetName')"></label>
                <div class="custom-select">
                    <model-list-select :list="streets" class="form-control" v-model="streetCode" name="streetCode"  option-value="code" :option-text="$store.state.locale.lang=='il'?'il':'en'" v-validate="'required'" data-vv-validate-on="input"></model-list-select>
                    <span class="error">{{ errors.first('streetCode') }}</span>
                </div>
            </div>
            <div class="form-group">
                <label for="streetNumber" v-text="$ml.with('VueJS').get('houseNumber')"></label>
                <input type="text" name="streetNumber" id="streetNumber" class="form-control" v-validate="{ required: true, regex: /^[0-9\s,'-]*$/}" v-model.trim="streetNumber">
                <span class="error">{{ errors.first('streetNumber') }}</span>
            </div>
            <!-- <div class="form-group zipCode">
                <label for="zipCode" v-text="$ml.with('VueJS').get('zipCode')"></label> -->
                <!-- <i class="icon icon-search-blue" v-on:click="getZip()"></i> -->
                <!-- <input type="text" name="zipCode" id="zipCode" class="form-control" maxlength="7" v-bind:placeholder="$ml.with('VueJS').get('zipCodePlaceholder')" v-validate="'required|numeric|min:7|max:7|validZipcode'" v-model.trim="zipCode">
                <span class="error">{{ errors.first('zipCode') }}</span>
            </div> -->
            <div class="form-group">
                <label for="countryBirth" v-text="$ml.with('VueJS').get('countryBirth')"></label>
                <div class="custom-select">
                    <model-list-select :list="countries" name="countryBirth" v-validate="'required'" class="form-control"
                             v-model="birthCountry"
                             option-value="iso"
                             data-vv-validate-on="input"
                             :option-text="$store.state.locale.lang">
                    </model-list-select>

                    <span class="error">{{ errors.first('countryBirth') }}</span>
                </div>
            </div>
            <div class="form-group email">
                <label for="email" v-text="$ml.with('VueJS').get('email')"></label>
                <input type="text" name="email" id="email" class="form-control" v-validate="'required|email'" v-model.trim="email">
                <span class="error">{{ errors.first('email') }}</span>
            </div>
            <button class="btn btn-next" v-text="$ml.with('VueJS').get('bntContinued')"></button>
            
            <!-- <div class="message-box shield">
                <div class="iconInfo">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
                        <circle cx="8" cy="4.5" r="1"/>
                    </svg>
                </div>
                <div class="message">
                    {{this.$ml.with('VueJS').get("REGISTER_INFO_SEARCH_ZIP")}}
                </div>
            </div> -->

        </form>
    </div>
</template>

<script>
    import store from '../../store';
    import countries from '../../data/countries';
    import {registration, getStreets, getCities} from '../../services/api';
    import {EventBus} from "../../event-bus";
    import {localesDictionary} from "../../services/utils";

    export default {
        name: 'Step2',
        data() {
            return {
                countries,
                cities:[],
                streets:[],
                streetCode: this.$store.state.registration.streetCode,
                cityCode: this.$store.state.registration.cityCode,
                // zipCode: this.$store.state.registration.zipCode,
                birthCountry: this.$store.state.registration.birthCountry,
                email: this.$store.state.registration.email,
                streetNumber: this.$store.state.registration.streetNumber,
                address: this.$store.state.registration.address,
                city: this.$store.state.registration.city,
                isSubmitted:false,
                errorCount: 0
            }
        },
        created() {
            window.scrollTo(0,0);
            this.countries = _.orderBy(this.countries, this.getLang);
            getCities({"country": this.$store.state.registration.country,
                       "identification": this.$store.state.registration.identification,
                       "mobileNumber": this.$store.state.registration.mobileNumber,
                       "smsCode": this.$store.state.registration.smsCode},(data) => {
                this.cities = data;
            });
            EventBus.$on('changeLang', lang => {
                this.$validator.localize(this.dictionary());
                this.$validator.localize(lang);
            })
            // this.$validator.extend('validZipcode', {
            //     validate(value, args) {
            //         var pattern = new RegExp("([0-9])\\1{6}");
            //         return !pattern.test(value);
            //     }
            // });

        },
        computed: {
            getLang () {
                return this.$store.getters.getLang;
            },

        },
        mounted() {
            this.$validator.localize(this.dictionary());
            this.$validator.localize(this.getLang);
        },
        methods: {
            nextStep() {
                this.$validator.validate().then(result => {
                    if (result && !this.isSubmitted) {
                        this.isSubmitted = true;
                        store.commit('setRegistration', {
                            'streetCode': this.streetCode,
                            'address': this.streets[this.streets.findIndex(f => f.code === this.streetCode)].en,
                            'cityCode': this.cityCode,
                            'city': this.cities[this.cities.findIndex(f => f.code === this.cityCode)].en,
                            // 'zipCode': this.zipCode,
                            'birthCountry': this.birthCountry,
                            'email': this.email,
                            'streetNumber': this.streetNumber,
                        });
                        this.$analyticsService.transmitSwitchCase('registration', 'registration_stage_4_2', {error_count: this.errorCount})
                        let self = this;
                        registration(this.$store.getters.getRegistrationData, (response) => {
                            self.isSubmitted = false;
                            if (!response.data.errorCode || response.data.errorCode == 'undefined') {
                                this.$emit('onNextStep', 2)

                            } else {
                            this.errorCount = this.errorCount + 1
                            }
                        }, (error) => {
                            self.isSubmitted = false;
                            console.log(error);
                        });
                        setTimeout(() => this.isSubmitted = false, 10000);
                    } else {
                        this.errorCount = this.errorCount + 1
                    }
                });
            },
            getCityStreets(){
                getStreets({"cityCode":this.cityCode,"country": this.$store.state.registration.country,
                              "identification": this.$store.state.registration.identification,
                              "mobileNumber": this.$store.state.registration.mobileNumber,
                              "smsCode": this.$store.state.registration.smsCode},(data) => {
                    this.streets = data;
                });
            },
            getZip(){
                if(!this.cityCode || !this.streetCode || !this.streetNumber){
                    this.$store.commit('setShowModalError', true);
                    this.$store.commit('setErrorCode', '');
                    this.$store.commit('setErrorMessage', this.$ml.with('VueJS').get('zipCodeRequiredFields'));
                    return;
                }
                var streetHebrew = this.streets[this.streets.findIndex(f => f.code === this.streetCode)].il;
                var cityHebrew = this.cities[this.cities.findIndex(f => f.code === this.cityCode)].il;
                var url ="https://www.israelpost.co.il/zip_data.nsf/SearchZip?OpenAgent&Location="+cityHebrew+"&Street="+streetHebrew+"&House=" + this.streetNumber
                url = url.replace(' ','%20')
                var self = this;
                $.ajax(url).done(function(resp){
                    if(resp && resp!='') {
                        var myRegexp = /(RES[0-9]*\d)/g;
                        var match = myRegexp.exec(resp);
                        var zipCode = match[0].substring(4);
                        if (zipCode == '11' || zipCode =='12' || zipCode =='13'){
                            this.$store.commit('setShowModalError', true);
                            this.$store.commit('setErrorCode', '');
                            this.$store.commit('setErrorMessage', this.$ml.with('VueJS').get('zipCodeNotFound'));
                            return;
                        }
                        else if (zipCode.length==9)
                            zipCode = zipCode.substring(2)
                        self.zipCode=zipCode;
                    }
                    else{
                        this.$store.commit('setShowModalError', true);
                        this.$store.commit('setErrorCode', '');
                        this.$store.commit('setErrorMessage', this.$ml.with('VueJS').get('zipCodeNotFound'));
                        return;
                    }
                });
            },
            dictionary(){
                const custom = {
                    zipCode: { 
                        min: this.$ml.with('VueJS').get("E_REGEX_zipCode"),
                        max: this.$ml.with('VueJS').get("E_REGEX_zipCode"),
                        validZipcode: this.$ml.with('VueJS').get("E_INVALID_zipCode")
                    },
                    email: { 
                        email: this.$ml.with('VueJS').get("E_REGEX_email"),
                    }
                }
                const messages = {
                    required: this.$ml.with('VueJS').get("E_REQUIRED"),
                    regex: this.$ml.with('VueJS').get("E_NUMERIC"),
                }
                return localesDictionary({ custom: custom, messages: messages });
            }
        }
    }
</script>
<style lang="scss">
.zipCode{
    .icon-search-blue{
        position: absolute;
        margin-top: 15px;
        left: 15px;
    }
}
.v-select .dropdown-toggle .clear {
    visibility: hidden;
}

</style>
