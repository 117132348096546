<template>
    <div>
        <Header class="header-registration" :currentStep="currentStep" @stepBack="changeStep(currentStep - 2, true)">
        </Header>
        <div class="main-wrapper main-wrapper-logged-out">
            <div class="content block block-registration registration-main">
                <div v-if="!isOldCustomer" class="content-top">
                    <div class="breadcrumbs breadcrumbs-main">
                        <ul>
                            <li v-for="(step, index) in steps" v-bind:key="index"
                                :class="{ 'active': currentStep === index + 1, 'visited': index + 1 < currentStep }"
                                @click="changeStep(index, true)"> {{ $ml.with('VueJS').get(step) }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="content-registration">
                    <div class="wrap text-center">
                        <h1 v-if="currentStep < 3" class="title titleSpace"
                            v-text="$ml.with('VueJS').get('registerSystem')"></h1>
                        <!-- <h1 class="title titleSpace" v-text="$ml.with('VueJS').get('registerSystemAddDocuments')"></h1> -->

                        <div class="body registration-steps">
                            <component :is="currentTabComponent" @onNextStep="changeStep"></component>
                        </div>
                    </div>
                </div>

                <div class="modal-mask modal-mask-message" @click="closeModal">
                    <div class="modal-container modal-message" id="modal-message" @click.stop>
                        <div>
                            <div class="modal-body">
                                <img src="../assets/images/uploadID.gif" alt="">
                                <p class="title" v-text="$ml.with('VueJS').get('messageRegistration1')"></p>
                                <p class="title" id="secondRow" v-text="$ml.with('VueJS').get('messageRegistration2')">
                                </p>

                                <div class="form-group group-btn">
                                    <button class="btn btn-small btn-border" v-text="$ml.with('VueJS').get('btnReturn')"
                                        @click="cancelSteps()"></button>
                                    <button type="submit" class="btn btn-small btn-yellow"
                                        v-text="$ml.with('VueJS').get('btnFinishREgistration')"
                                        @click="closeModal()"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-mask modal-mask-message-oldCustomer" @click="closeModal">
                    <div class="modal-container modal-message" id="modal-message" @click.stop>
                        <div>
                            <div class="modal-body">
                                <img src="../assets/images/uploadID.gif" alt="" style="max-width: 113px">
                                <p class="title" v-text="$ml.with('VueJS').get('messageRegistration1')"></p>
                                <p class="title" id="secondRow"
                                    v-text="$ml.with('VueJS').get('messageUploadDocuments')"></p>

                                <div class="form-group group-btn">
                                    <button class="btn btn-small btn-border" v-text="$ml.with('VueJS').get('btnReturn')"
                                        @click="cancelSteps()"></button>
                                    <button type="submit" class="btn btn-small btn-yellow"
                                        v-text="$ml.with('VueJS').get('btnUploadDocuments')"
                                        @click="closeModal()"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <!--      <Footer/>-->
    </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import Step1 from "./Registration/Step1";
import Step2 from "./Registration/Step2";
import Step3 from "./Registration/Step3";
import DatePicker from 'vue2-datepicker'
import { modalHandler } from "@/services/utils";

export default {
    name: 'Registration',
    components: { DatePicker, MainNav, Header, Step1, Step2, Step3 },
    data() {
        return {
            isAuthorization: false,
            currentStep: 1,
            currentStepClass: 'step-1',
            typeRegistration: this.$store.state.registration.type,
            isOldCustomer: this.$store.getters.getOldCustomerd,
            steps: ['r_step_name', 'r_step_address', 'r_step_doc'],
        }
    },
    computed: {
        getDir() {
            return this.$store.getters.getDir;
        },
        currentTabComponent: function (isBack) {
            return (this.$store.getters.getNotSigned) ? 'Step3' : 'Step' + this.currentStep;
        },
    },
    mounted() {
        if (this.$store.getters.getNotSigned) {
            this.currentStep = 3;
            this.currentStepClass = 1;
            if (this.$store.getters.getOldCustomerd) {
                modalHandler('.modal-mask-message-oldCustomer', 'show', '092086', 'light');
            } else {
                modalHandler('.modal-mask-message', 'show', '092086', 'light');
            }
            document.body.setAttribute('dir', this.getDir);
            return;
        }
        if (this.$store.getters.isFirstStep) {
            this.$router.push('registration');
        }
        document.body.setAttribute('dir', this.getDir);
    },
    created() {
    },
    methods: {
        changeStep(index, isClicked) {
            if (index < 0) {
                this.$router.push({ name: 'Registration' })
                return
            }
            if (isClicked && (index + 1) > this.currentStep) {
                return false;
            }
            this.currentStep = index + 1;
            this.currentStepClass = 'step-' + index + 1;
        },
        closeModal() {
            modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
        },
        cancelSteps() {
            this.$router.push('login');
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.block-registration {
    padding-top: 0;

    .wrap {
        padding: 0;
        max-width: 739px;
        margin: 0 auto;
    }

    &.registration-main {
        .body {
            margin-top: 30px;
        }
    }

    .type-registration {
        .btn {
            margin: 15px auto 0;
            font-size: 18px;
        }

        &.israeli_resident {
            .btn {
                width: 221px;
            }
        }

        &.expatriate {
            margin-top: 42px;

            .btn {
                width: 140px;
            }
        }
    }

    .title {
        line-height: 42px;
    }

    .titleSpace {
        margin-bottom: 10px;
    }

    .form-registration {
        margin-top: 19px;

        .form-group,
        .btn {
            display: inline-block;
        }

        .form-group {
            margin-left: 16px;
        }

        label {
            text-align: right;
            color: #4a4a4a;
            padding-right: 10px;
        }

        .form-control {
            width: 260px;
            height: 34px;
            margin-top: 5px;
            color: #4a4a4a;

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #4a4a4a;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: #4a4a4a;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: #4a4a4a;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: #4a4a4a;
            }
        }

        .btn {
            height: 34px;
            line-height: 34px;
            width: 180px;
            font-size: 18px;
        }
    }

    .form-registration-step-1 {

        .form-group,
        .btn-next {
            display: inline-block;
            vertical-align: middle;
        }

        .form-group {
            text-align: right;
            margin-left: 14px;

            label {
                color: #4a4a4a;
                padding-right: 10px;
            }

            .form-control {
                width: 260px;
                margin-top: 5px;
                /*&[aria-invalid=true] {
                        border-color: #ff6a68;
                    }*/
            }
        }

        .btn-next {
            min-width: 180px;
            width: auto;
            padding: 0 30px;
            font-size: 18px;
            vertical-align: bottom;
            height: 38px;
            line-height: 38px;
            display: block;
            margin: 24px auto 0;
        }
    }

    .error {
        width: 100%;
        right: 0;
    }

    .registration-steps {
        text-align: right;
        margin-top: -10px !important;

        label {
            color: #4a4a4a;
        }

        .btn {
            width: 220px;
            display: inline-block;
            vertical-align: bottom;
            display: block;
            margin: 21px auto 0;
            height: 38px;
        }

        .form-group {
            display: inline-block;
            vertical-align: middle;
            margin-left: 24px;
            margin-top: 30px;

            &:nth-child(3n) {
                margin-left: 0;
            }
        }

        .form-control {
            width: 230px;
            margin-top: 5px;
            color: #4a4a4a;
        }
    }

    .registration-step-1 {
        .icon-calendar {
            position: absolute;
            bottom: 10px;
            left: 10px;
        }

        #dateBirth {
            padding-left: 9px;
        }
    }

    .registration-step-2 {
        .btn-border {
            color: $mainColor;
            border-color: $mainColor;
            margin-left: 18px;

            &:hover {
                background-color: $mainColor;
                color: #ffffff;
            }
        }

        .form-group {
            width: 230px;
        }
    }

    .registration-step-3 {
        .btn-next {
            display: block;
            margin: 55px auto 0;
        }
    }

    .registration-step-4 {
        text-align: center;
        margin-top: 20px;

        &>div {
            display: flex;
            margin: 0 -10px;
        }

        .error {
            margin-left: 0;
        }

        .col {
            width: 100%;
            height: 241px;
            border: 1px dashed rgb(218, 218, 218);
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            background-color: rgb(255, 255, 255);
            margin: 0 85px 0 85px;
            position: relative;
        }

        /*.file-upload {
                position: relative;
                display: block;
                width: 85%;
                height: 192px;
                border: 1px solid rgb(255, 0, 252);
                margin: 23px auto 0;
                cursor: pointer;
                span.file-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: {
                        repeat: no-repeat;
                        position: center;
                        size: cover;
                    }
                    &.is-pdf {
                        background: url(../assets/images/icons/i-pdf.png) no-repeat center/contain !important;
                    }
                }
                input[type=file] {
                    display: none;
                }
            }
            .file-upload-info {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                margin: auto;
            }*/
        .group-btn {
            margin: 30px 0 0;
            display: block;

            .btn {
                margin: 0 10px;
                display: inline;
            }
        }

        .btn-clear {
            background-color: transparent;
            border: 1px solid #092086;
            color: #092086;
            line-height: 31px;

            &:hover {
                background-color: #092086;
                color: #ffffff;
            }

            &:disabled {
                border: none;
                color: #ffffff;
            }
        }
    }

    .breadcrumbs ul {
        max-width: 700px;
    }

    .mx-datepicker {
        width: 230px;

        input {
            height: 34px;
            border: 1px solid #dadada;
            border-radius: 2px;
            background-color: white;
            padding: 0 9px;
            font-size: 18px;
            font-weight: 400;
            line-height: 31px;
            width: 100%;
            margin-top: 5px;
            color: #4a4a4a;
            padding-right: 35px;

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #ffffff;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: #ffffff;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: #ffffff;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: #ffffff;
            }
        }

        .mx-input-append {
            display: block;
            width: 17px;
            height: 16px;
            background-image: url(../assets/images/icons/i-calendar.png);
            cursor: pointer;
            bottom: 9px;
            right: 10px;
            top: auto;
            background-clip: border-box;

            svg {
                display: none;
            }
        }

        .mx-input-wrapper .mx-clear-wrapper {
            display: none;
        }

        .mx-calendar-content .cell.actived,
        .mx-calendar-content .cell:hover {
            background-color: $mainColor;
            color: #ffffff;
        }
    }
}

.modal-registration-success {
    width: 582px;
    height: auto;
    text-align: center;
    padding-top: 37px;

    .modal-text {
        font-size: 18px;
        line-height: 22px;
        color: #fdffff;
        max-width: 275px;
        margin: 48px auto 0;

        span {
            color: #d4c54b;
        }
    }

    .btn-yellow {
        width: 105px;
        height: 38px;
        line-height: 34px;
        margin: 50px auto 20px !important;
        font-size: 18px;
    }

    .btn-border {
        width: 185px;
        height: 34px;
        line-height: 32px;
        margin: 24px auto 20px;
        border-color: #d4c54b;
        color: #d4c54b;

        &+.btn {
            margin: 33px 15px 20px;
        }
    }

    img {
        max-width: 150px;
    }
}

.modal-message {
    .title {
        font-size: 18px !important;
    }

    .btn-yellow {
        min-width: 155px;
        height: 34px;
        line-height: 34px;
        font-size: 18px;
    }

    #secondRow {
        margin-top: 10px;
        line-height: 24px;
        margin-bottom: 22px;
    }
}
</style>