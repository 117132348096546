<template>
    <div>
        <Header />
        <div class="main-wrapper">
            <div class="content block block-recent-actions block-cashBack">
                <!-- <div v-if="!loaded">
                    <img class="loader_payment" src="../assets/images/loading.gif"/>
                </div> -->
                <div class="wrap">
                    <h1 class="title" v-text="$ml.with('VueJS').get('cashBack')"></h1>
                    <div class="body">
                        <div class="tab-content">
                            <div class="full-page-box">
                                <div class="cashBack img">
                                    <div class="img-cashback">
                                        <img class="imgInfo cashback" src="../assets/images/icons/img-cashback2.png" />
                                    </div>
                                </div>
                                <div>
                                    <div v-show="hasBalances" class="message">
                                        <p class="message-title"
                                            v-text="$ml.with('VueJS').get('cashBackPageTitlePlus')"></p>
                                        <div class="message-inline" v-for="(it, key) in cashBackBalances" :key="key">
                                            <label>{{ it.symbol }}{{ it.amount }}&nbsp;&nbsp;</label>
                                        </div>
                                    </div>
                                    <div v-show="!hasBalances" class="message">
                                        <p class="message-title"
                                            v-text="$ml.with('VueJS').get('cashBackPageTitleZero')"></p>
                                    </div>
                                    <div class="message-second">
                                        <strong>
                                            <p v-show="hasBalances"
                                                v-text="$ml.with('VueJS').get('cashBackPageMessagePlus')"></p>
                                            <p v-show="!hasBalances"
                                                v-text="$ml.with('VueJS').get('cashBackPageMessageZero')"></p>
                                            <p v-text="$ml.with('VueJS').get('cashBackPageMessage2')"></p>
                                            <p class="message-inline"
                                                v-text="$ml.with('VueJS').get('cashBackPageMessage3')"></p>
                                            {{ minimum }}
                                            <p class="message-inline"
                                                v-text="$ml.with('VueJS').get('cashBackPageMessage4')"></p>
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div class="full-page-box footer">
                                <div class="no-actions cashBack">
                                    <p v-text="$ml.with('VueJS').get('cashBackPageFotter')"></p>
                                    <p v-text="$ml.with('VueJS').get('cashBackPageFotter2')"></p>
                                    <p v-text="$ml.with('VueJS').get('cashBackPageFotter3')"></p>
                                    <a v-bind:href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('protocolLink')"
                                        target="_blank" v-text="$ml.with('VueJS').get('cashBackPageFotter4')"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer v-if="!isMobileDevice" />

    </div>
</template>
<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import Footer from "@/components/Footer.vue";
import { isMobile } from "../services/utils";

export default {
    name: 'CashBack',
    components: { MainNav, Header, Footer },

    data() {
        return {
            minimum: '',
            loaded: false,
            hasBalances: false,
            isMobileDevice: isMobile()
        }
    },
    created() {
        this.minimum = localStorage.getItem('cashBackMin');
    },
    computed: {
        cashBackBalances() {
            this.hasBalances = this.$store.getters.getCashBackBalances && this.$store.getters.getCashBackBalances.length;
            return this.$store.getters.getCashBackBalances;
        }
    },
    methods: {
        showActions() {
            this.$router.push('cash-back-actions');
        },
    }
}

</script>

<style lang="scss">
.block-cashBack {
    .body {
        max-width: 739px;
        margin-top: 5px auto;
    }

    .title {
        margin-top: 16px;
    }
}

.full-page-box {
    margin: 0px auto 12px;
    border-radius: 8px;
    text-align: center;
    background-color: #ffff;
    padding-top: 30px;
    padding-bottom: 15px;

    // height: auto;
    // display: flex;
    .message {
        text-align: center;
        font-size: 28px;

        .message-subtitle {
            font-size: 22px;
        }

        .message-content {
            font-size: 18px;
        }
    }

    .message-second {
        font-size: 15px;
        margin-top: 12px;
    }

    .message-inline {
        display: inline;
    }

    +.footer {
        font-size: 13px;
        margin-top: 12px;
        padding: 10px 0 10px 0;

        a {
            margin-top: 2px;
            font-weight: bold;
            color: grey;
        }
    }
}

.no-actions.cashBack {
    margin-top: 2px;
}

.cashBack.img {
    margin-top: 10px;
    text-align: center;
}

.imgInfo.cashback {
    margin-top: -30px;
    max-width: 60%;
}
</style>
