<template>
  <div class="grey-bgc">
    <Header />
    <div class="main-wrapper">
      <div class="content block block-beneficiaries">
        <div class="content-top">
          <div class="form-search">
            <input type="text" class="form-control" v-bind:placeholder="$ml.with('VueJS').get('freeSearch')"
              v-model="searchBeneficiary">
            <i class="icon icon-search"></i>
          </div>
        </div>
        <div class="body">
          <div class="wrap">
            <h1 class="title" v-text="$ml.with('VueJS').get('itemNav4')"></h1>
            <router-link :to="createBeneficiaryRoute" class="btn centered-btn">
              <span v-text="$ml.with('VueJS').get('newBeneficiary')"></span>
            </router-link>
            <div class="list-beneficiaries">
              <div class="item-beneficiary" v-for="(beneficiary, index) in beneficiaries" :key="index"
                @click="showDetails(beneficiary, index)">
                <div class="wrap-row">
                  <div class="item-row">
                    <ul>
                      <li class="item-title">{{ beneficiary.firstName }} {{ beneficiary.lastName }}</li>
                      <li>{{ country(beneficiary.countryIso3) }}</li>
                    </ul>
                  </div>
                  <div class="sub-row">
                    <ul
                      v-if="beneficiary.maskedCardNumber || beneficiary.accountIdentifier || beneficiary.walletIdentifier || beneficiary.upiIdentifier || beneficiary.iban || beneficiary.phoneNumber ">
                      <li v-show="beneficiary.maskedCardNumber">
                        <span class="caption" v-text="$ml.with('VueJS').get('cardNumber') + ': '"></span>
                        {{ beneficiary.maskedCardNumber | formatCardNumber }}
                      </li>
                      <li v-show="beneficiary.accountIdentifier">
                        <span class="caption"
                          v-text="$ml.with('VueJS').get('beneficiary.accountIdentifier') + ': '"></span>
                        {{ beneficiary.accountIdentifier }}
                      </li>
                      <li v-show="beneficiary.iban">
                        <span class="caption" v-text="$ml.with('VueJS').get('beneficiary.iban') + ': '"></span>
                        {{ beneficiary.iban }}
                      </li>
                      <li v-show="beneficiary.walletIdentifier">
                        <span class="caption"
                          v-text="$ml.with('VueJS').get('beneficiary.walletIdentifier') + ': '"></span>
                        {{ beneficiary.walletIdentifier }}
                      </li>  
                         <li v-if="beneficiary.upiIdentifier">
                        <span class="caption"
                          v-text="$ml.get(`beneficiary.upi_${beneficiary.countryIso3}`) + ': '"></span>
                        {{ beneficiary.upiIdentifier }}
                      </li>
                      <li v-show="beneficiary.phoneNumber">
                        <span class="caption" v-text="$ml.with('VueJS').get('beneficiary.phoneNumber') + ': '"></span>
                        {{ beneficiary.phoneNumber }}
                      </li>
                    </ul>
                    <div class="group-btn">
                      <button @click="openDialgue(beneficiary)" class="btn btn-border btn-delete">
                        <span v-text="$ml.with('VueJS').get('btnDelete')"></span>
                      </button>
                      <button @click="selectBeneficiary(index, beneficiary)" class="btn">
                        <span v-text="$ml.with('VueJS').get('btnTransfer')"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <i class="icon icon-arrow-down-circle"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Modal beneficiary dialogue -->
    <div class="modal-mask modal-mask-message toast" @click="closeDialgue">
      <div class="modal-container container-dialogue modal-message" id="modal-message" @click.stop>
        <div class="modal-body body-dialogue">
          <div class="modal-header">
            <a class="a-close" @click="closeDialgue" :class="{ 'a-close-rtl': dir === 'rtl' }">&#10005;</a>
          </div>
          <div class="title">
            <span v-text="$ml.with('beneficiary', selectedBeneficiaryName).get('beneficiaryRemoveDialogue')"></span>
          </div>
          <div class="title" v-text="$ml.with('VueJS').get('beneficiaryRemoveDialogue2')"></div>
          <div class="form-group group-btn">
            <button class="btn btn-small btn-border" v-text="$ml.with('VueJS').get('btnReturn')"
              @click="closeDialgue()"></button>
            <button type="submit" class="btn btn-small btn-yellow" v-text="$ml.with('VueJS').get('btnOk')"
              @click="removeBeneficiary()"></button>
          </div>
        </div>
      </div>
    </div>
    <!--End Modal beneficiary dialogue-->
    <Footer v-if="!isMobileDevice" />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from "../components/Header";
import { deleteBeneficiaries } from "../services/api";
import { countryByIso, modalHandler, isMobile } from "../services/utils"
import Footer from "@/components/Footer.vue";

export default {
  name: 'Beneficiaries',
  components: { Header, Footer },
  data() {
    return {
      searchBeneficiary: '',
      selectedBeneficiary: '',
      isMobileDevice: isMobile()
    }
  },
  created() {
    if (!this.beneficiaries.length)
      this.$store.dispatch('loadBeneficiary');
  },
  computed: {
    dir() {
      return this.$store.getters.getDir;
    },
    ...mapGetters({
      'beneficiaries': 'getListBeneficiary'
    }),
    createBeneficiaryRoute() {
      return { name: 'beneficiary', params: { action: 'create' } }
    },
    beneficiaries() {
      return Object.filter(this.$store.getters.getListBeneficiary, item => {
        return (item.firstName.toLowerCase().indexOf(this.searchBeneficiary.toLowerCase()) > -1) || (item.lastName.toLowerCase().indexOf(this.searchBeneficiary.toLowerCase()) > -1)
      });
    },
    selectedBeneficiaryName() {
      return this.selectedBeneficiary.firstName + ' ' + this.selectedBeneficiary.lastName
    }
  },
  methods: {
    country(iso3) {
      return countryByIso(iso3);
    },
    openDialgue(beneficiary) {
      this.selectedBeneficiary = beneficiary;
      modalHandler('.modal-mask-message', 'addClassOpen');
    },
    closeDialgue() {
      modalHandler('.modal-mask-message', 'removeClassOpen')
    },
    showDetails(it, index) {
      let action = $('.item-beneficiary:eq(' + index + ')');
      if (action.hasClass('open')) {
        action.removeClass('open');
      } else {
        $('.item-beneficiary').removeClass('open');
        action.addClass('open');
      }
    },
    selectBeneficiary(index, beneficiary) {
      this.$store.commit('setCurrentBeneficiary', beneficiary.id);
      sessionStorage.setItem('currentBeneficiary', beneficiary.id); // for reload .
      this.$store.commit('setTransferStep1', {
        amount: null,
        currency: null,
        destinationCountry: beneficiary.countryIso3,
      });
      this.$store.commit('setHasCard', (beneficiary.maskedCardNumber));
      this.$router.push('transfer');
    },
    removeBeneficiary() {
      deleteBeneficiaries(this.selectedBeneficiary.id, (response) => {
        this.closeDialgue();
        this.$store.dispatch('loadBeneficiary');
        this.selectedBeneficiary = '';
      });
    }
  },

  mounted() {
    document.body.setAttribute('dir', this.dir);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.block-beneficiaries {
  padding-top: 0;

  .content-top {
    height: 69px;
    padding: 10px 0;
  }

  .wrap {
    padding: 0;
  }

  .form-search {
    max-width: 739px;
    margin: 0 auto;
    position: relative;

    .form-control {
      width: 100%;
      height: 48px;
      font-size: 20px;
      padding: 0 18px 0 40px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 20px;
        color: #808080;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 20px;
        color: #808080;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 20px;
        color: #808080;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 20px;
        color: #808080;
      }
    }

    .icon-search {
      position: absolute;
      top: 17px;
      left: 23px;
      cursor: auto;
    }
  }

  .body {
    max-width: 739px;
    margin: 43px auto 0;
  }

  .list-beneficiaries {
    margin-top: 26px;
    text-align: start;
  }

  .item-beneficiary {
    width: 100%;
    height: 82px;
    overflow: hidden;
    border: 2px solid rgb(236, 236, 236);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 12px 12px 10px;
    display: flex;
    line-height: 22px;
    font-size: 16px;
    color: #767676;
    position: relative;
    @include transition(all .4s ease);

    &:hover {
      background-color: #f2f2f2;
    }

    .icon-arrow-down-circle {
      width: 15px;
      height: 15px;
      background-size: cover;
    }

    &.open {
      height: auto;
      padding-bottom: 22px;
      overflow: visible;

      .icon-arrow-down-circle {
        background-image: url(../assets/images/icons/i-arrow-up-circle.png);
      }

      .wrap-row {
        .sub-row {
          margin-top: 2px;
          display: block;
        }
      }
    }

    .item-title {
      font-size: 20px;
      color: #092086;
    }

    &+.item-beneficiary {
      margin-top: 4px;
    }

    .caption-title {
      border: #092086;
      top: 6px;
      border-top: 0.5px solid #d1cfcf;
      padding-top: 4px;
      min-width: 220px;
    }

    .wrap-row {
      width: 95%;

      .sub-row {
        display: none;
        position: relative;
        top: 2px;
        color: #092086;

        button {
          min-width: 100px;
          height: 29px;
          padding: 0px 10px;
          line-height: 27px;
          margin: 10px 4px 0px;
          display: inline-block;
        }

        & .btn-delete {
          border-color: $mainColor;
          color: $mainColor;
          width: 90px;

          &:hover {
            background-color: $mainColor;
            border-color: $mainColor;
            color: #ffffff;
          }
        }
      }
    }
  }

  .icon-transfer {
    width: 38px;
    height: 38px;
    background-color: #092086;
    border-radius: 50%;
    position: relative;
    margin-left: 18px;
    margin-top: 5px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .icon-remove {
    position: absolute;
    top: 10px;
    left: 12px;
    right: auto;
  }

  .centered-btn {
    display: block !important;
    margin: 20px auto 0 !important;
    width: 170px !important;
  }
}
</style>
