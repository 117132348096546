<template>
    <div class="wrap">
        <form class="profile-step-2">
            <div class="form-group">
                <label for="city" v-text="$ml.with('VueJS').get('city')"></label>
                <input type="text" name="city" id="city" class="form-control" v-model="registrationData.city" disabled>
            </div>
            <div class="form-group">
                <label for="address" v-text="$ml.with('VueJS').get('streetName')"></label>
                <input type="text" name="address" id="address" class="form-control" v-model="registrationData.address" disabled>
            </div>
            <div class="form-group">
                <label for="streetNumber" v-text="$ml.with('VueJS').get('houseNumber')"></label>
                <input type="text" name="streetNumber" id="streetNumber" class="form-control" v-model="registrationData.streetNumber" disabled>
            </div>
            <!-- <div class="form-group">
                <label for="zipCode" v-text="$ml.with('VueJS').get('zipCode')"></label>
                <input type="text" name="zipCode" id="zipCode" class="form-control" v-model="registrationData.zipCode" disabled>
            </div> -->
            <div class="form-group">
                <label for="countryBirth" v-text="$ml.with('VueJS').get('countryBirth')"></label>
                <div class="custom-select">
                    <select name="countryBirth" id="countryBirth" class="form-control" v-model="registrationData.birthCountry" disabled>
                        <option v-for="country in countries" :value="country.iso">{{country[$store.state.locale.lang]}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label for="email" v-text="$ml.with('VueJS').get('email')"></label>
                <input type="email" name="email" id="email" class="form-control" v-model="registrationData.email" disabled>
            </div>
        </form>
    </div>
</template>

<script>
    import countries from '../../data/countries';

      export default {
        name: 'Step2',
          data() {
              return {
                  registrationData: {},
                  countries: countries

              }
          },
          created(){
              this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
          }
    }
</script>