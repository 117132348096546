<template>
  <div class="mygmt2-page-wrapper">
    <div
      :class="{ 'small': !isLongHeader && isMobileDevice, 'medium': isLongHeader && isMobileDevice, 'large': isDisplayingWallet && isMobileDevice }"
      class="mygmt2-page-wrapper-container">
      <div class="mygmt2-page-wrapper-container-center">
        <div class=" mygmt2-page-wrapper-container-desktop flex flex-column gap-25-40">
          <Header greetings class="header-grid-box" :longHeader="isLongHeader" />
          <div class="flex gap-25-40 top-container">
            <NewCustomerCard style="height: 212px;" class="payment-balances-card-grid-box"
              :class="{ 'new-customer-card-section-rtl': getDir === 'rtl', 'new-customer-card-section': getDir !== 'rtl' }"
              v-if="isLongHeader && !isMobileDevice" />
            <PaymentBalancesCard class="payment-balances-card-grid-box"
              :filterBy="[{ blockCode: '!BLOCKED_PERMANENTLY' }]" id="paymentBalancesCard" v-if="isDisplayingWallet" />
            <QuickSendCard class="quick-send-card-grid-box grid-fix keep-size" v-if="!getIsBeneficiariesListEmpty"
              id="quickSend" />
          </div>

          <div v-if="!isMobileDevice" class="flex flex-column gap-25-0">
          <div class="flex gap-25-40 mid-container">
            <QuickActionsCard class="quick-actions-card-grid-box grid-fix" />
            <CashBackCard v-if="!this.$store.getters.isOrderActive"
              class="cash-back-card-grid-box grid-fix" />
            <DepositCodeCard v-if="this.$store.getters.isOrderActive" />
          </div>
          <div   class="flex flex-row gap-25-40 bottom-container" :style="{paddingRight: !showBanner && isIsraeli ? '10px':'0px'}">
            <TransactionList :style="{width: !showBanner && isIsraeli ?  '100%' : '64.6%'  }" :maxFetch="'8'" class=" transaction-list-grid-box grid-fix"
             />

            <div v-if="showBanner || !isIsraeli"  class="flex flex-column gap-25-0">
              <MarketingBanners style="width: 100%;" ref="marketingBannersCmp" class="marketing-banner-card-grid-box grid-fix" />
              <div  v-if="!isIsraeli" :class="{ 'h-100': !showBanner }">
                <YoutubeCard class="youtube-card-grid-box grid-fix h-100" />
              </div>
            </div>
          </div>
          </div>

          <div v-else>
          <div class="flex gap-25-40 mid-container">
            <DepositCodeCard v-if="this.$store.getters.isOrderActive" />
            <CashBackCard
            class="cash-back-card-grid-box grid-fix" />
            <MarketingBanners style="margin-bottom: 70px;" class="marketing-banner-card-grid-box grid-fix h-50" />
          </div>
          <div class="flex gap-25-40 bottom-container">
            <QuickActionsCard  class="quick-actions-card-grid-box grid-fix" />
            <TransactionList :maxFetch="'5' " class=" transaction-list-grid-box grid-fix"
              :class="{ 'w-100': isIsraeli }" />
            <div v-if="!isIsraeli" class="flex flex-column gap-25-0">
              <!-- Disabled untill the feature is fully implemented -->
              <div class="h-65">
                <YoutubeCard class="youtube-card-grid-box grid-fix h-100" />
              </div>
            </div>
          </div>
          </div>


          <Footer />

          <!-- modals -->
          <!-- modal BLOCK CARD -->
          <div v-if="getSelectedCard">
            <div class="modal-mask modal-mask-card" id="blockCardModal" @click="closeModal()">
              <div class="modal-container modal-message" id="modal-mask-block" @click.stop>
                <span @click="closeModal" class="close-modal" :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
                <div class="modal-body">
                  <div v-if="!dialogueDone" class="title body-title">
                    <i class="icon icon-card-block-fab"></i>
                    <strong>
                      <p class="header">{{
                        $ml.with('VueJS').get('cardBlock') + ' ' + getSelectedCard ? getSelectedCard.last4digits : ''
                        }}</p>
                      <p v-text="$ml.with('VueJS').get('cardBlockModalMessage.1')"></p>
                      <p v-text="$ml.with('VueJS').get('cardBlockModalMessage.2')"></p>
                    </strong>
                    <p v-text="$ml.with('VueJS').get('cardBlockModalMessage.3')"></p>
                  </div>
                  <div v-else class="title body-title">
                    <strong>
                      <p><img src="../assets/images/gifs/card_lock.gif" alt=""></p>
                      <p>{{ $ml.with('VueJS').get('cardBlockConfirm.1') }} </p>
                    </strong>
                    <p>{{ $ml.with('VueJS').get('cardBlockConfirm.2') }}
                      <a :href="hrefSrc()" target="_blank" style="color:#d4c641;text-decoration: underline;">{{
                        $ml.with('VueJS').get('cardBlockConfirm.3')
                        }}</a>
                    </p>
                  </div>
                  <div class="form-group group-btn">
                    <div v-if="!data_loaded" class="no-actions">
                      <p><img src="../assets/images/loading_w.gif" /></p>
                    </div>
                    <div v-else>
                      <button v-if="!dialogueDone" class="btn btn-small btn-border"
                        v-text="$ml.with('VueJS').get('btnReturn')" @click="closeModal()"></button>
                      <button type="submit" class="btn btn-small btn-yellow"
                        v-text="$ml.with('VueJS').get(dialogueDone ? 'btnOk' : 'cardBlockBtn')"
                        @click=" dialogueDone ? closeModal() : blockUnblock()">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end modal BLOCK CARD -->

            <!-- modal UNBLOCK CARD -->
            <div class="modal-mask modal-mask-card" id="unBlockCardModal" @click="closeModal()">
              <div class="modal-container modal-message" id="modal-mask-block" @click.stop>
                <span @click="closeModal" class="close-modal" :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
                <div class="modal-body">
                  <div v-if="!dialogueDone" class="title body-title">
                    <i class="icon icon-card-block-fab"></i>
                    <strong>
                      <p class="header">{{
                        $ml.with('VueJS').get('cardUnBlock') + ' ' + getSelectedCard ? getSelectedCard.last4digits : ''
                        }}</p>
                      <p v-text="$ml.with('VueJS').get('cardUnBlockModalMessage.1')"></p>
                    </strong>
                    <p v-text="$ml.with('VueJS').get('cardUnBlockModalMessage.2')"></p>
                  </div>
                  <div v-else class="title body-title">
                    <strong>
                      <p><img src="../assets/images/gifs/card_unlock.gif" alt=""></p>
                      <p>{{ $ml.with('VueJS').get('cardUnBlockConfirm') }} </p>
                    </strong>
                  </div>
                  <div class="form-group group-btn">
                    <div v-if="!data_loaded" class="no-actions">
                      <p><img src="../assets/images/loading_w.gif" /></p>
                    </div>
                    <div v-else>
                      <button v-if="!dialogueDone" class="btn btn-small btn-border"
                        v-text="$ml.with('VueJS').get('btnReturn')" @click="closeModal()"></button>
                      <button type="submit" class="btn btn-small btn-yellow"
                        v-text="$ml.with('VueJS').get(dialogueDone ? 'btnOk' : 'cardUnBlockBtn')"
                        @click=" dialogueDone ? closeModal() : blockUnblock()">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end modal UNBLOCK CARD -->

            <!-- modal PIN CARD -->
            <div class="modal-mask modal-mask-card" id="pinModal" @click="closeModal()">
              <div class="modal-container modal-message" id="modal-mask-pin" @click.stop>
                <span @click="closeModal" class="close-modal" :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
                <div class="modal-body">
                  <div v-if="!dialogueDone" class="title">
                    <i class="icon icon-card-pass-fab"></i>
                    <span
                      v-text="$ml.with('digits', getSelectedCard ? getSelectedCard.last4digits : '').get('pinCodeModalMessage')"></span>
                    ?
                  </div>
                  <div v-else class="title">
                    <p><img src="../assets/images/sms_once2.gif" alt=""></p>
                    <span v-text="$ml.with('VueJS').get('pinCodeModalMessage2')"></span>
                  </div>
                  <div class="form-group group-btn">
                    <div v-if="!data_loaded" class="no-actions">
                      <p><img src="../assets/images/loading_w.gif" /></p>
                    </div>
                    <div v-else>
                      <button v-if="!dialogueDone" class="btn btn-small btn-border"
                        v-text="$ml.with('VueJS').get('btnReturn')" @click="closeModal()"></button>
                      <button type="submit" class="btn btn-small btn-yellow" v-text="$ml.with('VueJS').get('btnOk')"
                        @click="dialogueDone ? closeModal() : getPinCode()">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end modal PIN CARD -->
            <!-- end modals -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Header from "../components/Header.vue";
import Action from "@/components/Action";
import QuickSendCard from "@/components/QuickSendCard.vue";
import CashBackCard from "@/components/CashBackCard.vue";
import DepositCodeCard from "@/components/DepositCodeCard.vue";
import QuickActionsCard from "@/components/QuickActionsCard.vue";
import Footer from "@/components/Footer.vue";
import MarketingBanners from "@/components/MarketingBanners.vue";
import YoutubeCard from "@/components/YoutubeCard.vue";
import PaymentBalancesCard from "@/components/PaymentBalancesCard.vue";
import { getCurrencySymbol, isMobile, modalHandler, loadLottieJson } from "@/services/utils";
import NewCustomerCard from "@/components/NewCustomerCard.vue";
import { cardBlockUnblock, getPinCode } from "@/services/api";
import TransactionList from "@/components/TransactionsList.vue";


export default {
  name: 'Home',
  components: {
    TransactionList,
    NewCustomerCard,
    QuickSendCard,
    Action,
    Header,
    CashBackCard,
    DepositCodeCard,
    QuickActionsCard,
    Footer,
    MarketingBanners,
    YoutubeCard,
    PaymentBalancesCard
  },
  data() {
    return {
      isMobileDevice: isMobile(),
      registrationData: JSON.parse(localStorage.getItem('registrationData')),
      status: '',
      cards: [],
      balance: '',
      page_loaded: false,
      data_loaded: true,
      transactions: [],
      noTransactions: false,
      dialogueDone: false,
      showBanner: true
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    },
    isLongHeader() {
      return !this.isDisplayingWallet && this.registrationData.isBeneficiariesListEmpty;
    },

    isDisplayingWallet() {
      return this.registrationData.hasWallet || this.registrationData.showWallet;
    },
    isIsraeli() {
      return (this.registrationData.country === 'ISR')
    },
    getIsBeneficiariesListEmpty() {
      return (this.registrationData.isBeneficiariesListEmpty)
    },
    getSymbole() {
      return getCurrencySymbol(this.getSelectedCard ? this.getSelectedCard.currency : '');
    },
    blocked() {
      return this.getSelectedCard && this.getSelectedCard.blockCode === 'BLOCKED'
    },
    showCardLoad() {
      return localStorage.getItem('showCardLoad') === 'true'
    },
    hasWallet() {
      return this.$store.getters.hasWallet('ILS');
    },
    getSelectedCard() {
      return this.$store.getters.getSelectedCard;
    },
    getCardsList() {
      return this.$store.getters.getCardsList
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    if (this.$refs.marketingBannersCmp &&  this.$refs.marketingBannersCmp.banners) {
      this.showBanner =  this.$refs.marketingBannersCmp.banners.length > 0
    }
    document.body.setAttribute('dir', this.getDir);
    loadLottieJson("walking-gif-elm", "walking")
    this.initializePageLayout()

  },
  methods: {
    initializePageLayout() {
      this.isMobileDevice ? this.initializeMobileLayout() : this.initializeDesktopLayout();
    },
    initializeMobileLayout() {
      if (!this.registrationData.isCustomerHaveCards && !this.registrationData.hasWallet && !(this.registrationData.country === 'ISR')) {
        $("#paymentBalancesCard").hide()
      }
    },
    initializeDesktopLayout() {
      if (!this.registrationData.isBeneficiariesListEmpty && !this.registrationData.isCustomerHaveCards && !this.registrationData.hasWallet) {
        $("#paymentBalancesCard").hide()
        $("#quickSend").css('grid-area', '2 / 1 / 5 / 5')
      }
    },
    imgSrc(curr) {
      return require(`../assets/images/gmt-card_${curr}.png`)
    },
    hrefSrc() {
      return `https://gmtonline.co.il/${this.$store.state.locale.lang}/contact-us/`
    },
    closeModal() {
      modalHandler('.add_action', 'hide', 'ffffff', 'dark')
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
      this.dialogueDone = false;
    },
    goToCardDeposit() {
      this.$router.push('/wallet/transfer/card')
      localStorage.setItem('maxCardNumber', this.getSelectedCard.cid);
    },

    blockUnblock() {
      this.data_loaded = false;
      cardBlockUnblock({ "cid": this.getSelectedCard.cid, "blockCode": this.blocked ? '0' : 'T' }, (response) => {
        this.getSelectedCard.blockCode = response
        const cards = this.getCardsList
        const card = cards.find((card) => card.cid === this.getSelectedCard.cid)
        card.blockCode = response
        this.data_loaded = true;
        this.dialogueDone = true;
        this.$analyticsService.transmitSwitchCase('card_block','card_block_stage_2',  { errorCode: response.errorCode}) 

      });
    },
    getPinCode() {
      this.data_loaded = false;
      getPinCode({ "cid": this.getSelectedCard.cid }, (response) => {
        this.dialogueDone = this.data_loaded = true;
        this.$analyticsService.transmitSwitchCase('card_pin_code', 'card_pincode_stage_2',  { errorCode: response.errorCode})
      });
    },
  }
}

</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";


.gap-25-40 {
  gap: 20px 0px;
}

.gap-25-0 {
  gap: 20px 0px;
}

.header-grid-box {
  padding-top: 45px;
}

.top-container {
  height: auto;
  flex-direction: column;
}

.mid-container {
  height: auto;
  flex-direction: column;
}

.bottom-container {
  flex-direction: column;
}

.small {
  height: 310px;
}

.medium {
  height: 445px;
}

.large {
  height: 460px;
}



@media only screen and (min-width: $screen-tablet) {


  .gap-25-40 {
    gap: 25px 40px;
  }

  .gap-25-0 {
    gap: 25px 0px;
  }

  .top-container,
  .mid-container,
  .bottom-container  {
    flex-direction: row;

    > :nth-child(1) {
      width: 64.6%;
    }

    > :nth-child(2) {
      width: 32.3%;
    }

  }

  .top-container {
    height: 212px;
  }

  .mid-container {
    min-height: 142px;
  }

  .bottom-container {
    padding-bottom: 25px;
    height: 100%;
  }

  .keep-size {
    width: 32.3% !important;
  }

  .new-customer-card-section {
    background: url('../assets/mygmt_v2_images/Hero-Section-Background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 20px;
  }

  .mygmt2-header-wrapper {
    padding: 0;
  }
}

.new-customer-card-section-rtl {
  background-image: url('../assets/mygmt_v2_images/Hero-Section-Background-RTL.png') !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 20px;
}
</style>