import { formatDecimal, formatComma } from "@/filters/AppFilters";

export function formatDecimalAndStyleTitle(el, binding) {
  const formatted = formatDecimal(binding.value[0]).split('.');
  if (formatted.length === 2) {
    el.innerHTML = `<span dir="ltr"><small style="margin:0 1px;">${binding.value[1]}</small><span>${formatted[0]}.</span><small>${formatted[1]}</small></span>`
  } else {
    el.innerHTML = `<span dir="ltr"><small style="margin:0 1px;">${binding.value[1]}</small><span>${formatted[0]}</span></span>`
  }
}



export function formatDecimalAndStyle(el, binding) {

  if (binding.value !== undefined) {
    const str = typeof binding.value === 'string' ? binding.value : `${binding.value}`
    const formatted = formatDecimal(str).split('.');

    if (formatted.length === 2) {
      el.innerHTML = `<span dir="ltr"><span>${formatComma(formatted[0])}.</span><small>${formatted[1]}</small></span>`
    } else {
      el.innerHTML = `<span dir="ltr"><span>${formatComma(formatted[0])}</span></span>`

    }
  }
}

export const panDown = {
  bind: function (el, binding) {
    let startY, endY;

    el.addEventListener('touchstart', (e) => {
      startY = e.touches[0].clientY;
    });

    el.addEventListener('touchmove', (e) => {
      endY = e.touches[0].clientY;
    });

    el.addEventListener('touchend', (e) => {
      if (endY && (endY > startY)) {
        binding.value();
      }
    });
  }
}