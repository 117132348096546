<template>
  <div class="card-actions-wrapper">
    <div class="card-container-container" v-show="getStatus === 'ACTIVATED' && !canceled"
      :class="{ 'flexCol': inlineCardActions, 'flexRow': !inlineCardActions }">
      <ActionLine v-if="isCurrentPath('/Home')" :hideArrow="!isMobileDevice" :text="$ml.get('lastTransactions')" icon="Activity.svg"
     @lineAction="showCardActivity" />
      <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('pinCode')" icon="Pincode.svg"
        @lineAction="showPinModal" /> 
      <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get( blocked ? 'cardUnBlock' : 'cardBlock')" icon="Blockcard.svg"
        @lineAction="showBlockModal" />
      <ActionLine v-if="!blocked" :hideArrow="!isMobileDevice" :text="$ml.get('loadCard')" icon="Reload.svg"
        @lineAction="goToCardDeposit" />
    </div>
    <div class="card-container-container activate-modal" v-show="getStatus === 'CUSTOMER'">
      <label for="inActiveLast4digits">{{ $ml.get('enterLastDigits') }}</label>
      <input class="form-control" type="text" name="inActiveLast4digits" id="inActiveLast4digits"
        v-model="inActiveLast4digits" />
      <button class="btn btn-primary action-text" @click="activateCard()">{{ $ml.get('activateCardAction') }}</button>
    </div>
  </div>
</template>
<script>

import ActionLine from "@/components/ActionLine.vue";
import { isMobile, modalHandler } from "@/services/utils";
import { activateMaxCard } from "@/services/api";

export default {
  name: 'CardActions',
  components: { ActionLine },
  props: {
    card: {
      type: Object,
      required: true
    },
    inlineCardActions: {
      type: Boolean
    }
  },
  data() {
    return {
      isMobileDevice: isMobile(),
      status: '',
      inActiveLast4digits: ''
    }
  },
  created() {
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getStatus() {
      return this.card.status
    },
    getSelectedCard() {
      return this.$store.getters.getSelectedCard;
    },
    blocked() {
      return this.card.blockCode === 'BLOCKED'
    },
    canceled(){
     return this.card.blockCode === 'BLOCKED_PERMANENTLY'
    }
  },
  mounted() {
  },
  methods: {
    async activateCard() {
      if (this.card.last4digits === this.inActiveLast4digits) {
        await activateMaxCard({ cid: this.card.cid })
      }
    },
    goToCardDeposit() {
      this.$router.push('/wallet/transfer/card')
      localStorage.setItem('maxCardNumber', this.getSelectedCard.cid);
    },
    showBlockModal() {
      this.closeModal();
      modalHandler(this.blocked ? '#unBlockCardModal' : '#blockCardModal', 'show', '092086', 'light');
      this.$analyticsService.transmitSwitchCase('card_block','card_block_stage_1', {method: this.blocked ? 'unblock' : 'block'}) 
    },
    showPinModal() {
      this.closeModal();
      modalHandler('#pinModal', 'show', '092086', 'light');
     this.$analyticsService.transmitSwitchCase('card_pin_code', 'card_pincode_stage_1')
    },
    isCurrentPath(path){
      const currentPath = this.$route.path;
      return currentPath === path
    },
    showCardActivity() {
      this.$store.commit('setSelectedCard', {
        blockCode: this.card.blockCode,
        balance: this.card.balance,
        cid: this.card.cid,
        currency: this.card.currency,
        last4digits: this.card.last4digits,
        status: this.card.status,
      })
      this.$router.push('/Cards')
      this.closeModal();
    },
    closeModal() {
      modalHandler('.add_action', 'hide', 'ffffff', 'dark')
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
      this.dialogueDone = false;
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.btn {
  padding: 0 6px;
}

.card-actions-wrapper {
  display: flex;
  height: 100%;
}

.flexRow {
  flex-direction: row;
}

.flexCol {
  flex-direction: column;
}

.card-container-container {
  .drawer-line-container {
    width: 100% ;
  }

  width: 100%;
  justify-content: start;
  align-items: start;
}

.activate-modal {
  color: black;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  input {
    margin: 10px 0;
    text-align: center;
    font-size: 14px;
  }
}


@media only screen and (min-width: $screen-tablet) {
  .flex-actions {
    padding-left: 15px !important;
  }



  .card-container-container {
    width: 100%;
    display: flex;
    align-items: center;

    .drawer-line-container {
      // width: auto;
      margin: 5px 15px;

      .line-flex {
        width: 100%;
      }
    }
  }
}
</style>
