

import { isApp } from "./utils";


export class AnalyticsService {
  eventsMap = {}
  pageName = ""
  lang = ""

  constructor(store, router) {
    this.store = store;
    this.router = router;
    this.lang = this.store.getters.getLang;

  }

  handlePreviousErrorCount(previousEvent, newEvent) {
    if (!previousEvent) return
    const previousErrorCount = previousEvent.data["error_count"]
    if (previousErrorCount && newEvent.data && newEvent.data.error_count > -1) {
      newEvent.data.error_count = newEvent.data.error_count + previousErrorCount
    }
  }

  transmitEventToGroup(eventGroupName, event, isFirstStep = false) {
    if (isFirstStep) this.destroyEventGroup(eventGroupName)
    const previousEvent = this.eventsMap[eventGroupName]
    this.handlePreviousErrorCount(previousEvent, event)
    this.eventsMap[eventGroupName] = previousEvent ? { ...this.eventsMap[eventGroupName], name: event.name, data: { ...previousEvent.data, ...event.data } } : event;
    this.transmitEvent(this.eventsMap[eventGroupName]);
  }

  addToEventGroup(eventGroupName, data) {
    this.eventsMap[eventGroupName] = { ...this.eventsMap[eventGroupName], data: { ...data } }
  }

  destroyEventGroup(eventGroupName) {
    delete this.eventsMap[eventGroupName];
  }



  static setUserParams() {

    try {
      const userData = JSON.parse(localStorage.getItem('registrationData'))
      const publicUserData = {
        country: userData.country,
        city: userData.city,
        nationality: userData.birthCountry,
        job_title: userData.occupation,
        age: moment().diff(moment(userData.birthDate, "DD/MM/YYYY"), "years"),
        birthday: userData.birthDate,
        gender: userData.gender,
        language: this.lang,
        user_agent: navigator.userAgent,
        user_id: userData.id,
        uid: userData.id,
      };

      const sensitiveUserData = {
        phone_number: userData.phoneNumber,
        email: userData.email,
        first_name: userData.firstName,
        last_name: userData.lastName,
        postal_code: userData.zipCode
      };

      const combinedUserData = { ...publicUserData, ...this.hashProperties(sensitiveUserData) };

      if (isApp()) {
        gonative.firebaseAnalytics.event.setUser({
          ID: combinedUserData.user_id
        });

        gonative.deviceInfo().then((data) => {
          const appUserData = { ...combinedUserData, platform: data.platform, app_stores: data.distribution };
          Object.entries(appUserData).forEach(([key, value]) => {
            gonative.firebaseAnalytics.event.setUserProperty({
              key: key,
              value: value
            });
          });
        });
      } else {
        gtag('set', { 'user_id': combinedUserData.user_id });
        gtag('set', {
          'user_properties': combinedUserData
        });
      }
    } catch (error) {
      console.log("Analytics Error:", error);
    }
  }

  transmitEvent(event) {
    isApp() ? event.data["screen_id"] = this.setPageId() : event.data["page_id"] = this.setPageId()
    try {
      if (isApp()) {
        gonative.firebaseAnalytics.event.logEvent({ "event": event.name, "data": event.data });
      } else {

        gtag("event", event.name, event.data);
      }
    } catch (e) {
      console.log("failed to sent an event " + e);
    }
  }

  static hashProperties(data) {
    const hashedData = this.hashObjectValues(data)
    return hashedData
  }

  clearUserProperties(properties) {
    const clearedProperties = {};
    for (const key of Object.keys(properties)) {
      clearedProperties[key] = null;
    }
    gtag("set", "user_properties", clearedProperties);
  }

  transmitSwitchCase(evenGroup, eventId, data) {
    try {
       let transmissionData = {}
    let isFirstOfType = false

    switch (evenGroup) {

      case "registration":
        switch (eventId) {

          case "registration_stage_1":
            transmissionData = {
              page_title: "myGMT Registration - Welcome",
              screen_name: "myGMT App Registration- Welcome",
              page_id: 1,
              screen_id: 1,
              referral_source: "",
              event_category: "registration",
              event_lable: "registration_1",
              funnel_stage: "6_1_start",
              ...this.router.currentRoute.query
            }
            isFirstOfType = true
            break;

          case "registration_stage_2":
            transmissionData = {
              page_title: "myGMT Registration - Nationality",
              screen_name: "myGMT App Registration - Nationality",
              method: data.type,
              event_lable: "registration_2",
              funnel_stage: "6_2_nationality"
            }
            break;

          case "registration_stage_3":
            transmissionData = {
              page_title: "myGMT Registration - OTP Verification",
              screen_name: "myGMT App Registration - OTP Verification",
              error_count: data.error_count,
              event_lable: "registration_3",
              funnel_stage: "6_3_otp_verification",
            }

            break;

          case "registration_stage_4_1":
            transmissionData = {
              page_title: "myGMT Registration - Personal Data 1",
              screen_name: "myGMT App Registration - Personal Data 1",
              page_id: 2,
              screen_id: 2,
              error_count: data.error_count,
              event_lable: "registration_4_1",
              funnel_stage: "6_4_1_personal_data"
            }
            break;

          case "registration_stage_4_2":
            transmissionData = {
              page_title: "myGMT Registration - Personal Data 2",
              screen_name: "myGMT App Registration - Personal Data 2",
              page_id: 2,
              screen_id: 2,
              error_count: data.error_count,
              event_lable: "registration_4_2",
              funnel_stage: "6_4_2_personal_data"
            }
            break;

          case "registration_stage_5":
            transmissionData = {
              page_title: "myGMT Registration - ID Upload",
              screen_name: "myGMT App Registration - ID Upload",
              event_lable: "registration_5",
              funnel_stage: "6_5_id_upload"
            }
            break;

          case "registration_stage_6":
            transmissionData = {
              page_title: "myGMT Registration - Terms Agreement & Marketing Consent",
              screen_name: "myGMT App Registration- Terms Agreement & Marketing Consent",
              page_id: 2,
              screen_id: 2,
              event_lable: "registration_6",
              funnel_stage: "6_6_personal_data",
              marketing_consent: "true"
            }
            break;

          case "registration_stage_7":
            transmissionData = {
              page_title: "myGMT Registration - myGMT Registration - Complete",
              screen_name: "myGMT App Registration- myGMT App Registration- Complete",
              event_lable: "registration_7",
              funnel_stage: "6_7_registration_complete",
              country: data.country,
              job_title: data.occupation,
              age: moment().diff(moment(data.birthDate, "DD/MM/YYYY"), "years"),
              birthday: data.birthDate,
              gender: data.gender,
              marketing_consent: this.eventsMap["registration"]["marketing_consent"] ? "true" : "false"
            }

            break;
        }
        break;

      case "transaction":

        switch (eventId) {
          case "transaction_s_1":
            transmissionData = {
              page_title: "Transaction Details",
              screen_name: "Transaction Details",
              page_id: 3,
              screen_id: 3,
              referral_source: "",
              event_category: "registration",
              event_lable: "transaction_1",
              funnel_stage: "6-1",
              currency: this.store.getters.getLookupCurrency,
              amount: this.store.getters.getLookupAmount,
              destination_country: this.store.getters.getLookupCountryIso,
              event_category: "transaction"
            }
            isFirstOfType = true
            break;

          case "transaction_s_2":
            transmissionData = {
              page_title: "Transfer Method",
              screen_name: "Transfer Method",
              event_lable: "transaction_2",
              funnel_stage: "6-2",
              method: data.transactionClass,
              total_pay: data.amount + data.commission,
              commission: data.commission,
              amount: data.amount,
              currency: data.currency
            }
            break;

          case "transaction_s_3":
            transmissionData = {
              page_title: "Beneficiary Selection",
              screen_name: "Beneficiary Selection",
              event_lable: "transaction_3",
              funnel_stage: "6-3",
              beneficiary: data.beneficiary
            }

            break;

          case "transaction_s_4":
            transmissionData = {
              page_title: "Transaction - Summary",
              screen_name: "Transaction - Summary",
              event_lable: "transaction_4",
              funnel_stage: "6-4",
            }
            break;

          case "transaction_s_5":
            transmissionData = {
              page_title: "Transaction - Payment Method Selection",
              screen_name: "Transaction - Payment Method Selection",
              event_lable: "transaction_5",
              funnel_stage: "6-5",
              payment_type: data.selectedType,
              total_pay: data.amountToPay
            }
            break;

          case "transaction_s_6":
            transmissionData = {
              page_title: "Transaction - Payment",
              screen_name: "Transaction - Payment",
              event_lable: "transaction_6",
              funnel_stage: "6-6",
            }
            localStorage.setItem('transactionEventData', JSON.stringify(this.eventsMap['transaction'].data))
            break;
          case "transaction_wallet":
          case "transaction_branch":
          case "transaction_credit_card":
            const previousData = JSON.parse(localStorage.getItem('transactionEventData')) || {}
            transmissionData = {
              page_title: "Transaction - Complete",
              screen_name: "Transaction - Complete",
              event_lable: "transaction_completed",
              funnel_stage: "6-success",
              ...previousData,
              ...data
            }
            localStorage.removeItem('transactionEventData')
            break;
          case "transaction_error":
            transmissionData = {
              page_title: "Transaction - Error",
              screen_name: "Transaction - Error",
              event_lable: "transaction_error",
              funnel_stage: "6-error",
              error_code: data.errorCode
            }
            localStorage.removeItem('transactionEventData')
            break;

        }
        break;

      case "wallet_deposit":
      case "wallet_order":

        switch (eventId) {
          case "wallet_deposit_stage_1":
          case "wallet_order_stage_1":
            transmissionData = {
              page_title: data.page_title,
              screen_name: data.screen_name,
              event_lable: data.event_lable,
              method: data.method,
              event_category: data.event_category,
              page_id: 4,
              screen_id: 4,
            }
            isFirstOfType = true
            break;

          case "wallet_deposit_stage_2":
          case "wallet_order_stage_2":
            transmissionData = {
              ...data
            }  
            break;

          case "wallet_order_stage_3":
            transmissionData = {
              page_title: `Wallet Order - Confirmation Code`,
              screen_name: `Wallet Order - Confirmation Code`,
              currency: this.store.getters.getSelectedTransferOption.currency,
              amount: this.store.getters.getSelectedTransferOption.amount,
            }
            break; 

            case "wallet_deposit_stage_3":
              transmissionData = {
                page_title: `Wallet Deposit- Payment`,
                screen_name: `Wallet Deposit- Payment`,
                currency: this.store.getters.getSelectedTransferOption.currency,
                amount: this.store.getters.getSelectedTransferOption.amount,
              }
              break; 
          case "wallet_deposit_card":
            const previousData = JSON.parse(localStorage.getItem('transactionEventData')) || {}
            transmissionData = {
              page_title: "Wallet myGMT Deposit - success",
              screen_name: "Wallet myGMT Deposit - success",
              event_lable: "credit_card",
              funnel_stage: "final_success",
              ...previousData,
              ...data
            }
            localStorage.removeItem('transactionEventData')
            break;

          case "wallet_deposit_error":
          case "wallet_order_stage_3_error":
            transmissionData = {
              page_title: "Wallet Deposit - Payment Error",
              screen_name: "Wallet Deposit - Payment Error",
              event_lable: "wallet_deposit_3",
              event_category: "wallet_deposit",
              error_code: data.errorCode
            }
            break;

          case "wallet_order_canceled":
            transmissionData = {
              page_title: "Wallet Order - Canceled Wallet Order",
              screen_name: "Wallet Order - Canceled Wallet Order",
            }
            break;

        }
        break;

      case "card_deposit":
        switch (eventId) {
          case "card_deposit_stage_1":
            transmissionData = {
              page_title: data.page_title,
              screen_name: data.screen_name,
              event_lable: data.event_lable,
              method: data.method,
              event_category: data.event_category,
              page_id: 4,
              screen_id: 4,
            }
            isFirstOfType = true
            break;

          case "card_deposit_stage_2":
            transmissionData = {
              page_title: data.page_title,
              screen_name: data.screen_name,
              event_lable: data.event_lable,
              method: data.method,
              event_category: data.event_category
            }
            break;

          case "card_deposit_stage_3":
            transmissionData = {
              page_title: "card Deposit - Payment",
              screen_name: "card Deposit - Payment",
              event_lable: "card_deposit_3",
              event_category: "card_deposit",
              reference_number: data.referenceNumber
            }
            break;

          case "card_deposit_stage_3_error":
            transmissionData = {
              page_title: "card Deposit - Payment Error",
              screen_name: "card Deposit - Payment Error",
              event_lable: "card_deposit_3",
              event_category: "card_deposit",
              error_code: data.errorCode,
            }
            break;

        }
        break;

      case "card_block":
        switch (eventId) {
          case "card_block_stage_1":
            transmissionData = {
              page_title: "MyGMT Card",
              screen_name: "MyGMT Card",
              event_lable: "card_block_modal",
              event_category: "mygmt_card",
              method: data.method
            }
            isFirstOfType = true
            break;
          case "card_block_stage_2":
            transmissionData = data.errorCode ? { error_code: data.errorCode, event_lable: "card_block_error" } : { event_lable: "card_block" }
            break;


        }
        break;

      case "card_pincode":
        switch (eventId) {
          case "card_pincode_stage_1":
            transmissionData = {
              page_title: "MyGMT Card",
              screen_name: "MyGMT Card",
              event_lable: "card_pin_code_modal",
              event_category: "mygmt_card",
            }
            isFirstOfType = true

            break;
          case "card_pincode_stage_2":
            transmissionData = data.errorCode ? { ...data, error_code: data.errorCode, event_lable: "card_pin_code_error" } : { event_lable: "card_pin_code_view" }
            break;

        }
        break;

    }
    this.transmitEventToGroup(evenGroup, { name: eventId, data: transmissionData }, isFirstOfType)
    } catch (error) {
      console.log("🚀 ~ AnalyticsService ~ transmitSwitchCase ~ error:", error)
      
    }
   
  }

  setPageId() {
    return `${this.detectPlatform()}_${this.router.currentRoute.name.toLowerCase()}_${this.lang}`
  }

  detectPlatform() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    if (/Mobi|Android/i.test(userAgent)) {
      return "mobile";
    }

    return "web";
  }

  static hashObjectValues(obj) {
    const crypto = require("crypto");
    const hashedObj = {};
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      const hash = crypto.createHash("sha256");
      hash.update(`${value}`);
      hashedObj[key] = hash.digest("hex");
    });
    return hashedObj;
  }
}