<template>
  <div>
    <Header @stepBack="changeStep(currentStep - 2), false" :currentStep="step + 1"></Header>
    <div class="main-wrapper">
      <div class="content block block-transfer">
        <div class="content-top">
          <div class="breadcrumbs breadcrumbs-main">
            <ul>
              <li v-for="(step, index) in possibleSteps" :class="{ 'active': step.active, 'visited': step.visited }" @click="changeStep(index, false)">
                {{ $ml.get(step.code) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="body">
          <component :is="possibleSteps[currentStep].name" :mode="mode" :skip="steps[currentStep].skip" @onNextStep="changeStep(currentStep + 1, true, ...arguments)"
                     @onPrevStep="changeStep"></component>
        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />
  </div>
</template>
<script>

import Header from "../../components/Header";
import MainNav from "../../components/MainNav";
import Action from "@/components/Action";
import WalletAmount from "./Transfer/Amount";
import WalletSend from "./Transfer/Send";
import WalletSummary from "./Transfer/Summary";
import WalletPay from "./Transfer/Pay";
import Footer from "@/components/Footer.vue";
import { isMobile } from "../../services/utils";

export default {
  name: 'WalletTransfer',
  components: {Footer, Action, MainNav, Header, WalletAmount, WalletSend, WalletSummary, WalletPay},
  props: {
    mode: {
      mode: String,
      required: false
    }
  },
  data() {
    return {
      step: 0,
      steps: [
        {name: 'WalletAmount', code: 't_step_amount', active: true, visited: false, skip: false},
        {name: 'WalletSend', code: 't_step_transfer', active: false, visited: false, skip: false},
        {name: 'WalletSummary', code: 't_step_summary', active: false, visited: false, skip: false},
        {name: 'WalletPay', code: 't_step_pay', active: false, visited: false, skip: false}
      ],
      isMobileDevice: isMobile()
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    },
    currentStep: {
      get() {
        return this.step;
      },
      set(newStep) {
        this.step = newStep
      }
    },
    possibleSteps() {
      return this.steps;
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.changeStep(0, false);
    if (this.$store.getters.isOrderActive && this.mode === this.$walletTransferType.WALLETORDER) {
      this.$router.push({ path: '/wallet' });
    }
    if (this.mode === 'walletOrder') {
      this.$analyticsService.transmitSwitchCase('wallet_deposit','wallet_order_stage_2',  {
                        page_title: "Wallet Order - 2",
                        screen_name: "Wallet Order - 2",
                        page_id: 4,
                        screen_id: 4,
                        event_lable: 'wallet_order_2',
                    }) 
    } else {
      const eventMode = this.mode === 'card' ? 'card' : 'wallet'
      this.$analyticsService.transmitSwitchCase(eventMode + '_deposit', eventMode + '_deposit_stage_1', {
        page_title: _.capitalize(eventMode) + " Deposit",
        screen_name: _.capitalize(eventMode) + " Deposit",
        page_id: 4,
        screen_id: 4,
        event_category: eventMode + '_deposit',
        event_lable: eventMode === 'card' ? 'credit_card' : 'wallet_deposit',
        method: 'credit_card',
      })
    }


  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
  },
  methods: {
    changeStep(step, onNext, skip) {
      if (step < 0) {
        this.$router.push({ name: localStorage.getItem('homePage') || 'wallet-main' })
        return
      }
      if (!onNext && step > this.currentStep) {
        return false
      } else if (step === this.currentStep) {
        this.steps[this.currentStep].active = true
      } else {
        this.steps[this.currentStep].active = false
        this.currentStep = step
        this.steps[this.currentStep].skip = skip || false
        this.handleStepChange(step)
        this.steps[this.currentStep].active = true
        for (let i = 0; i < this.steps.length; i++) {
          this.steps[i].visited = i < step;
        }
      }
    },
    handleStepChange(nextStep) {
      const currentStepName = this.steps[this.currentStep].name
      const nextStepName = this.steps[nextStep].name
      if (currentStepName === 'WalletSend') {
        if (nextStep < this.currentStep) {
          this.$store.commit('setTransferStep1Response', []);
        }
      } else if (currentStepName === 'WalletSummary') {
        if (nextStep < this.currentStep) {
          this.$store.commit('setCurrentOption', null);
        }
        if (nextStepName === 'WalletSend' || nextStepName === 'WalletAmount') {
          this.$store.commit('setTransferStep1Response', []);
        }
      } else if (currentStepName === 'WalletPay') {
        if (nextStep < this.currentStep) {
          this.$store.commit('setCurrentOption', null);
        }
        if (nextStepName === 'WalletSend' || nextStepName === 'WalletAmount') {
          this.$store.commit('setTransferStep1Response', []);
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/styles/partials/variables";
@import "../../assets/styles/partials/mixins";

.block-transfer {
  padding-top: 0;

  & .wallet-amount {
    text-align: center;

    label {
      text-align: start;
    }

    .form-group {
      display: flex;
      flex-direction: row;
    }
  }
}

@include max-screen($screen-phones) {
  .block-transfer .body {
    margin-top: 10px;
  }
}
</style>