<template>
    <div>
        <div class="content block block-card-recent-actions">
            <div class="body">
                <div class="wrap">
                    <span class="header-title" v-text="$ml.with('VueJS').get('cardHystory')"></span>
                    <div class="form-group custom-select">

                        <!-- <model-select class="form-control custom-select"
                                    v-model="selectedMonth"
                                    :options="months"
                                    @input="loadCardTrans"
                                ></model-select> -->

                        <select class="form-control" v-model="selectedMonth">
                            <option v-for="(month, idx) in months" :key="idx" :value="month">
                                {{ month.text }}
                            </option>
                        </select>
                    </div>
                    <div class="tab-content">
                        <div v-show="loaded" class="no-actions">
                            <p><img src="../assets/images/loading.gif" /></p>
                        </div>
                        <div v-show="noTransactions && !loaded" class="no-actions">
                            <p><span v-text="$ml.with('VueJS').get('noCardRecentActions')"></span></p>
                        </div>
                        <div class="list-actions">
                            <div class="item-action" v-for="(action, index) in transactions" :key="index"
                                @click="showDetail(action, index)" :class="[{ 'open': action.isActive }]">
                                <!-- <i class="icon icon-action"></i> -->
                                <div class="wrap-row">
                                    <div class="item-row">
                                        <ul>
                                            <li>
                                                <span v-if="action.TotalAmount"
                                                    v-formatTitleAmount="[Math.abs(action.TotalAmount), getSymbole(action.Currency == '004' ? 'EUR' : action.Currency == '003' ? 'USD' : 'ILS')]"
                                                    class="total-amount"></span>
                                                <span v-else
                                                    v-formatTitleAmount="[Math.abs(action.OrigAmount), getSymbole(action.OrigCurrency)]"
                                                    class="total-amount"></span>
                                                <span v-if="action.TotalAmount < 0 || action.ChargeAmount < 0"
                                                    v-text="$ml.with('VueJS').get('crediting')" class="crediting"></span>
                                                <span v-else-if="action.TransStatus == 1"
                                                    v-text="$ml.with('VueJS').get('inProcess')" class="crediting"></span>
                                            </li>
                                            <li>{{ merchantName(action) }}</li>
                                            <li class="text-gray">
                                                <span
                                                    v-if="action.DateTimeBuy && action.DateTimeBuy.length > 0">{{ format_date(action.DateTimeBuy) }}</span>
                                                <span
                                                    v-else-if="action.ApproveDateTime && action.ApproveDateTime.length > 0">{{ format_date(action.ApproveDateTime) }}</span>
                                                <span v-else style="opacity:0;">date</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="sub-row">
                                        <ul>
                                            <li>
                                                {{ $ml.with('VueJS').get('transactionAmount') }}:
                                                {{ getSymbole(action.OrigCurrency) }}{{ action.OrigAmount | formatDecimal }}
                                            </li>
                                            <li v-show="action.ExchangeRate > 0">
                                                {{ $ml.with('VueJS').get('exchangeRate') }}: {{ action.ExchangeRate }}
                                            </li>
                                            <li>
                                                {{ $ml.with('VueJS').get('cardPresented') }}: {{
                                                    $ml.with('VueJS').get(action.TranModeCode != 1 ? 'no' : 'yes') }}
                                            </li>
                                            <li v-show="action.MerchantAddress">
                                                {{ $ml.with('VueJS').get('cardMerchantAddress') }}:
                                                {{ action.MerchantAddress }}, {{ action.City }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <i class="icon icon-arrow-down-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import { getCardTransactions } from "../services/api";
import DateDropdown from 'vue-date-dropdown';
import moment from 'moment';
import { EventBus } from "../event-bus";
import { getPreviousMonths, getCurrencySymbol } from "../services/utils"

export default {
    name: 'CardRecentActions',
    components: { MainNav, Header, DateDropdown },
    data() {
        return {
            transactions: [],
            noTransactions: false,
            months: getPreviousMonths(),
            selectedMonth: {},
            cid: '',
            loaded: false,
        }
    },
    computed: {
        getDir() {
            return this.$store.getters.getDir;
        },
    },
    watch: {
        selectedMonth() {
            this.loadCardTrans();
        }
    },
    created() {
        window.scrollTo(0, 0);
        this.selectedMonth = this.months[0]
        EventBus.$on('changeLang', lang => {
            this.months = getPreviousMonths();
        });
    },
    mounted() {
        document.body.setAttribute('dir', this.getDir);
    },
    methods: {
        showDetail(it, index) {
            let $action = $('.item-action:eq(' + index + ')');
            if ($action.hasClass('open')) {
                $action.removeClass('open');
            } else {
                $('.item-action').removeClass('open');
                $action.addClass('open');
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        },
        isApprovedOrSameMonth(action) {
            if (action.TransStatus == 1)
                return moment(action.ApproveDateTime, 'YYYY-MM-DD').isSame(moment().set({ 'year': this.selectedMonth.year, 'month': this.selectedMonth.month - 1 }), 'month');
            return true;
        },
        getSymbole(code) {
            return getCurrencySymbol(code);
        },
        merchantName(action) {
            return (this.$store.state.locale.lang != 'il' && action.MerchantNameEng) ? action.MerchantNameEng : action.MerchantName
        },
        getData() {
            return {
                month: this.selectedMonth.month,
                year: this.selectedMonth.year,
                cid: this.$store.getters.getSelectedCard.cid,
            };
        },
        loadCardTrans() {
            this.transactions = [];
            this.loaded = true;
            getCardTransactions(this.getData(), (data) => {
                this.transactions = data.filter(action => { return this.isApprovedOrSameMonth(action) });
                this.noTransactions = this.transactions.length == 0
                this.loaded = false;
            });
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.block-card-recent-actions {
    padding-top: 0;

    .tab-content {
        text-align: start;
    }

    .wrap {
        padding: 0;

        .header-title {
            text-align: start;
            margin-inline-start: 15px;
        }
    }

    .form-group {
        width: 40%;
        margin: 5px auto;
        text-align: start;
        margin-inline-start: 5px;

        label {
            color: #4a4a4a;
            margin-inline-start: 10px;
            text-align: start;
            margin-bottom: 2px;
        }
    }

    .form-control {
        font-size: 18px;
        width: 100%;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-size: 20px;
            color: #808080;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            font-size: 20px;
            color: #808080;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            font-size: 20px;
            color: #808080;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            font-size: 20px;
            color: #808080;
        }
    }

    .icon-search {
        position: absolute;
        top: 17px;
        left: 23px;
    }

    .body {
        max-width: 739px;
        margin: 23px auto 0;
    }

    .nav-tabs {
        margin-top: 22px;

        li {
            display: inline-block;
            vertical-align: middle;

            &+li {
                margin-right: 15px;
            }

            a {
                font-size: 18px;

                &:before {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }
        }
    }

    .loader_icon {
        width: 6%;
        margin: auto;
        margin-top: 10px;
    }

    .no-actions {
        color: grey;
        text-align: center;
        margin-top: 10%;

        img {
            width: 30px;
        }
    }

    .list-actions {
        margin-top: 17px;
    }

    .item-action {
        width: 100%;
        height: 78px;
        overflow: hidden;
        border: 2px solid #ececec;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background-color: #ffffff;
        position: relative;
        padding: 6px 16px 20px;
        line-height: 22px;
        font-size: 16px;
        display: flex;
        @include transition(all .4s ease);

        &:hover {
            background-color: #f2f2f2;
        }

        &.open {
            height: auto;
            overflow: visible;

            .icon-arrow-down-circle {
                background-image: url(../assets/images/icons/i-arrow-up-circle.png);
            }
        }

        &+.item-action {
            margin-top: 6px;
        }

        .item-title {
            font-size: 20px;
        }

        .text-gray {
            color: #767676;
        }

        .text-blue {
            color: #092086;
        }

        .crediting {
            font-weight: bold;
            margin: 0 3px;
        }

        .icon-arrow-down-circle {
            position: absolute;
            top: 10px;
            left: 15px;
        }

        .icon-action {
            width: 38px;
            height: 38px;
            background-color: $mainColor;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            position: relative;
            margin-left: 18px;
            margin-top: 5px;
            cursor: pointer;

            &:before {
                content: '';
                display: block;
                width: 23px;
                height: 19px;
                background-image: url(../assets/images/icons/i-shopping-cart.png);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        &.action-grey {
            .icon-action {
                background-color: #d8d8d8;
            }
        }
    }

    .sub-row {
        line-height: 19px;
        margin-top: 10px;

        .cashBack {
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            margin-top: 6px;
        }

        .btn-border {
            border-color: $mainColor;
            color: $text-color;
            margin-top: 16px;
            margin-right: 2px;
            min-width: 111px;
            width: 180px;
            display: inline-block;
            padding: 0 5px;

            &.active {
                color: #ffffff;
                background-color: $mainColor;
            }
        }

        .btn-small {
            width: 120px;
            margin-right: 15px;
        }

    }

    .border {
        margin-top: 6px;
        border-top: 0.5px solid rgb(209, 207, 207);
        // border-top: 0.5px solid rgb(6, 26, 136);
        padding-top: 4px;
        min-width: 220px;
    }

    .mobile-icon {
        display: none;
        position: fixed;
        left: 15px;
        bottom: 25px;
        width: 53px;
        height: 53px;

        &:before {
            width: 23px;
            height: 23px;
        }
    }

    .total-amount {
        font-size: 22px;
        margin: 1px;
    }
}</style>
