<template>
  <div class="modal-mask modal-mask-login" @click="closeModal">
    <div class="modal-container modal-login" id="modal-login" @click.stop>
      <div>
        <div class="modal-title">
          <div class="title" v-text="$ml.get('titleFormLang')"></div>
          <div class="subtitle" v-text="$ml.get('surtitleFormLang')"></div>
        </div>
        <div class="modal-body">
          <div class="list-lang">
            <div class="radio item-lang" v-for="(lang, index) in $ml.list" :key="index">
              <input type="radio" name="lang" @click="setLang(lang)">
              <label v-text="$store.getters.getLangTitleShort(lang)"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { modalHandler } from "@/services/utils";

export default {
  name: 'SelectLang',
  methods: {
    checkLangs() {
      if (!localStorage.getItem("lang")) {
        if (this.$route.query.lang) {
          this.setLang(this.$route.query.lang);
        } else
          modalHandler('.modal-mask-login', 'show', '092086', 'light');
      }
    },
    setLang(lang) {
      this.closeModal()
      this.$ml.change(lang);
      this.$store.commit('changeLang', { 'lang': lang, 'dir': '' });
      this.$store.commit('setLangTitle', lang);
      this.$emit('post-upadte');
    },
    closeModal() {
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
    }
  }
}
</script>

<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.modal-login {
  text-align: center;
  padding: 60px 30px 25px;
  font-family: 'OpenSansHebrew', sans-serif;

  .modal-title {
    color: #fff;

    .title {
      font-size: 30px;
    }

    .subtitle {
      font-size: 17px;
    }
  }

  .list-lang {
    margin-top: 22px;

    .item-lang {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      position: relative;
      margin: 0 10px;
      text-transform: uppercase;

      label {
        display: block;
        width: 73px;
        height: 73px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid #fff;
        font-size: 18px;
        color: #ffffff;
        line-height: 71px;
        @include transition(all .4s ease);
      }

      input[type=radio] {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;

        &:hover+label,
        &:checked+label {
          background-color: #d5c641;
          border-color: #d5c641;
          color: $text-color;
        }
      }
    }
  }
}
</style>
