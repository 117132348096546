<template>
  <div class="grey-bgc">
    <Header />
    <div class="main-wrapper">
      <div class="content block block-card-main">
        <h1 class="title" v-text="$ml.get('wallet.invite.title')" />
        <p><img src="../../assets/images/gif-cashback-gift.gif" alt=""></p>
        <p class="message-body" v-text="$ml.with('amount', amount).get('wallet.invite.body')" />
        <div class="row">
          <button class="btn copy-action" v-if="this.isApp" @click="shareOnApp()">
            <span>
              <i class="fa fa-share" aria-hidden="true" :class="{ 'mirror-share': getDir === 'rtl' }"></i>
            </span>
            {{ $ml.get('wallet.invite.shareActions.general') }}
          </button>
          <button class="btn copy-action" v-else @click="this.copy">
            <span>
              <i class="fas fa-copy"></i>
            </span>
            {{ this.copied ? this.$ml.get('wallet.invite.shareActions.copied') :
              this.$ml.get('wallet.invite.shareActions.copy') }}
          </button>
        </div>
        <div class="row">
          <button v-if="this.country === 'THA'" class="btn btn-border share-action line-action"
            @click="shareWithLineApp()">
            <span>
              <i class="far fa-comment-dots"></i>
            </span>
            {{ $ml.get('wallet.invite.shareActions.line') }}
          </button>
          <button v-else class="btn btn-border share-action" @click="shareWithWhatsApp()">
            <span>
              <i class="fab fa-whatsapp"></i>
            </span>
            {{ $ml.get('wallet.invite.shareActions.whatsapp') }}
          </button>

        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />

  </div>
</template>
<script>

import Header from "../../components/Header";
import MainNav from "../../components/MainNav";
import Action from "@/components/Action";
import { invitationCashBackAmount } from "@/services/api";
import { isApp, isMobile } from "../../services/utils";
import Footer from "@/components/Footer.vue";

export default {
  name: 'WalletInvite',
  components: { Action, MainNav, Header, Footer },
  data() {
    return {
      amount: '',
      copied: false,
      isApp: isApp(),
      isMobileDevice: isMobile()
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    },
    country() {
      return localStorage.getItem('country');
    },
    identifier() {
      return localStorage.getItem('identifier');
    },
    inviteLink() {
      return window.location.protocol + "//" + window.location.host + '/#/i/' + this.identifier
    }
  },
  created() {
    window.scrollTo(0, 0);
    invitationCashBackAmount(result => {
      this.amount = result
    })
  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
  },
  methods: {
    shareWithWhatsApp() {
      window.open('https://api.whatsapp.com/send/?phone&text=' + encodeURIComponent(this.$ml.get('wallet.invite.inviteMessages.firstLine')) + '%0a' + encodeURIComponent(this.$ml.get('wallet.invite.inviteMessages.secondLine')) + '%0a' + encodeURIComponent(this.inviteLink))
    },
    shareWithLineApp() {
      window.open('https://line.me/R/share?text=' + encodeURIComponent(this.$ml.get('wallet.invite.inviteMessages.firstLine')) + '%0a' + encodeURIComponent(this.$ml.get('wallet.invite.inviteMessages.secondLine')) + '%0a' + encodeURIComponent(this.inviteLink))
    },
    shareOnApp() {
      gonative.share.sharePage({ url: `${window.location.protocol}//${window.location.host}/#/i/${this.identifier}` });
    },
    prevStep() {
      this.$router.push('/wallet')
    },
    copy() {
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = this.inviteLink;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.copied = true
    }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/styles/partials/variables";
@import "../../assets/styles/partials/mixins";

.copy-action {
  background-color: $mainColor;
  color: white;
}

.mirror-share {
  transform: scale(-1, 1);
}


.block {
  text-align: center;

  .title {
    padding: 0 15px;
    padding-top: 20px;
    font-size: 30px;
  }

  .message-body {
    padding: 0 15px;
    white-space: pre-line;

    font-size: 20px;
    margin-top: 1%;
    margin-bottom: 2%;
  }

  .btn {
    font-size: 18px;
    height: 38px;
    margin-left: 5px;
    margin-right: 5px;

    &.btn-border {
      border-color: $mainColor;
      color: $mainColor;

      &:hover {
        background-color: $mainColor;
        border-color: $mainColor;
        color: #ffffff;
      }
    }
  }

  .mock {
    visibility: hidden;
  }

  .row {
    margin-top: 1%;

    display: flex;
    justify-content: center;

    .copy-action {
      font-weight: normal;
      min-width: 287px;
    }
  }

  .btn.share-action {
    min-width: 287px;
  }
}

@include max-screen($screen-phones) {

  .block {
    .title {
      padding: 0 10px;
      text-align: center;
      font-size: 26px;
      padding-top: 20px;
    }

    .message-body {
      padding: 0 15px;
      font-size: 20px;
      margin-top: 1%;
      margin-bottom: 8%;
      white-space: pre-line;
    }

    .row {
      margin-top: 2%;

      display: flex;
      justify-content: center;

      .copy-action {
        font-weight: normal;
      }
    }
  }
}
</style>