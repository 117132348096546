<template>
    <transition  name="custom-classes" :enter-active-class="getEntranceAnimation()"
      :leave-active-class="getLeaveAnimation()">
      <div  v-show="this.show" class="drawer-wrapper" :id="this.drawerId"
        :class="{ 'drawer-wrapper-ltr': getDir === 'ltr', 'inlineDrawerHeight': this.inLineDrawer, 'darken': !this.inLineDrawer }">
        <div v-pan-down="handlePanDown" class="drag-bar">-</div>
        <div :class="{'action-headline': isActionDrawer}" class="head-line">
          <h1>{{ this.title || '' }}</h1>
          <div class="go-back-wrapper" @click.stop="closeDrawer">✕</div>
  
        </div>
        <div class="lines-box-wrapper">
          <slot name="content" />
        </div>
      </div>
    </transition>

</template>
<script>
import { isApp, isMobile } from "@/services/utils";
import { panDown } from '../directives/AppDirectives';

export default {
  name: 'Drawer',
  components: {},
  directives: {
    'pan-down': panDown
  },

  props: {
    drawerId: {
      type: String,
      required: true
      // make unique
    },
    title: {
      type: String,
      required: false
    },
    customClose: {
      type: Boolean,
      required: false
    },
    inLineDrawer: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isApp: isApp(),
      isMobileDevice: isMobile(),
      show: false,
      isFinishedClosing: true,
      showContent: false,
      dataLang: {
        'lang': this.$store.getters.getLang,
        'dir': this.$store.getters.getDir
      },
      ignoreNextClick: false
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.ignoreNextClick = true; 
        this.$nextTick(() => {
        document.addEventListener('click', this.handleOutsideClick);
      });
      } else {
        document.removeEventListener('click', this.handleOutsideClick);
      }
    }
  },
  created() {
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    isActionDrawer(){
      return this.drawerId === 'actionDrawer'
    }
  },
  mounted() {

  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
    this.removeAllTouchBlockers()
  },
  methods: {
    disableScrolling() {
        document.addEventListener('touchmove', this.preventTouchMove, { passive: false });
    },
    enableScrolling() {
        document.removeEventListener('touchmove', this.preventTouchMove);
    },
    preventTouchMove(e) {
    const drawerElement = document.getElementById(this.drawerId);
    if (drawerElement && !drawerElement.contains(e.target) && !e.target.getAttribute('role') === 'option') {
      e.preventDefault();
    }
    },
    handlePanDown() {
      this.closeDrawer()
    },
    handleOutsideClick(e) {
      if (!this.show) return
      if (this.ignoreNextClick) {
        this.ignoreNextClick = false;
        return;
      }
      const drawerElement = document.getElementById(this.drawerId);
      const isClickInsideDrawer = drawerElement.contains(e.target);
      const isClickInsideFlatpickr = e.target.closest('.flatpickr-calendar');
      const isClickInsideLineFlex = e.target.closest('.line-flex');
      if ((!isClickInsideDrawer && !isClickInsideFlatpickr) || isClickInsideLineFlex) {
        e.stopPropagation()

        setTimeout(() => {
          this.closeDrawer();
        }, 0)
      }
    },

    openDrawer() {
      if (!this.isFinishedClosing) return
      $('body').addClass('stop-scrolling')
      const openingScrollPosition = $(window).scrollTop()
      this.disableScrolling();
      this.show = true;
      $("#app").css({ 'pointerEvents': 'none' })
      $(".drawer-wrapper").css({ 'pointerEvents': 'auto' })
      let drawerElement = $(`#${this.drawerId}`)
      if (drawerElement.height() > 450) {
        drawerElement.css({ 'z-index': '1000' });
      }

      //TODO:Find a real fix!
      if (!this.inLineDrawer) {
        setTimeout(() => {
          window.scrollTo(0, openingScrollPosition)
        }, 0)
      }
    },

    closeInlineDrawer() {
      if (this.inLineDrawer) {
        this.closeDrawer()
      }
    },
    closeDrawer() {
      const delay = this.isMobileDevice ? 1000 : 100
      this.isFinishedClosing = false
      this.$emit('beforeClose')

      this.show = false;
      setTimeout(()=>{
       this.removeAllTouchBlockers()
      }, delay)
    },

    removeAllTouchBlockers(){
        $("#app").css({ 'pointerEvents': 'auto' })
        $(".drawer-wrapper").css({ 'pointerEvents': 'auto' })
        $('body').removeClass('stop-scrolling')
        $('#app').removeClass('stop-scrolling')
        this.enableScrolling();
        this.isFinishedClosing = true
    },

    getEntranceAnimation() {
      if (this.inLineDrawer) return
      if (this.isMobileDevice) {
        return 'animate__animated animate__slideInUp'
      } else {
        if (this.getDir === 'rtl') {
          return 'animate__animated animate__slideInLeft'
        } else {
          return 'animate__animated animate__slideInRight'
        }
      }
    },

    getLeaveAnimation() {
      if (this.inLineDrawer) return
      if (this.isMobileDevice) {
        return 'animate__animated animate__slideOutDown'
      } else {
        if (this.getDir === 'rtl') {
          return 'animate__animated animate__slideOutLeft'
        } else {
          return 'animate__animated animate__slideOutRight'
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";
.drawer-ghost {
  z-index: 999;
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0px;
}
.drawer-wrapper {
  z-index: 1002;
  box-shadow: 0 3px 10px 0 black;
  width: 100%;
  padding: 45px 25px 35px 25px;
  position: fixed;
  bottom: 0;
  // z index should be highest
  background-color: white;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .head-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: black;
    font-weight: 700;
    padding: 10px 5px 10px 5px;
    z-index: 210;

    h1 {
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .lines-box-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding: 10px 5px;
  }



  .drag-bar {
    transform: scale(10, 2);
    color: #E3E3E3;
    position: relative;
    bottom: 40px;
    height: 0;
  }
}

.go-back-wrapper {
  justify-content: end;
  font-size: 20px;
  color: black;
  cursor: pointer;
  width: 17px;
}

.darken {
  box-shadow: 0 0 20px 999px #00000099 !important;
  -webkit-box-shadow: 0 0 20px 999px #00000099 !important;
  -webkit-appearance: none;
  z-index: 999;
  
}

@media only screen and (min-width: $screen-tablet) {
  .darken {
    box-shadow: 0 0 20px 9999px #00000060 !important;
    -webkit-box-shadow: 0 0 20px 9999px #00000060 !important;
    -webkit-appearance: none;
  }

  .inlineDrawerHeight {
    height: max-content !important;
   overflow-y: visible;
  }

  .drawer-wrapper-ltr {
    inset-inline: 0;
    inset-inline-end: auto !important;
  }

  .drawer-wrapper {
    // box-shadow: 0 4px 24px 0 black;
    width: 20%;
    inset-inline: auto !important;
    inset-inline-end: 0 !important;
    border-radius: 0;
    height: 100%;

    .lines-box-wrapper {
      // height: 100vh;
      max-height: none;
      overflow-y: inherit;
      padding: 0px 5px;

    }
  }

  .head-line {
    h1 {
      margin-left: 10px;
    }
}

  .drag-bar {
    display: none !important;
  }
}
</style>
