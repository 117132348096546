<template>
  <div class="deposit-code-container">
    <div class="row lottie-and-link" @click="goToWalletOrder()">
      <div class="flex row align-center w-100">
        <lottie-player id="walking-gif-elm" autoplay loop mode="normal"
          :class="{ mirror: this.$store.getters.getDir === 'rtl' }"></lottie-player>
        <a class="sub-title">
          {{ $ml.get('walletOrder.goToActiveOrder') }}
        </a>
      </div>
      <img class="arrow-next" src="../assets/mygmt_v2_images/Right-Chevron.svg"
        :class="{ 'arrow-mirror': this.$store.getters.getDir === 'rtl' }">
    </div>
  </div>
</template>
<script>

export default {
  name: 'DepositCodeCard',
  components: {},
  props: {},
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
  },
  mounted() { },
  methods: {
    goToWalletOrder() {
      this.$router.push('/wallet/walletOrder')
    },

  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.deposit-code-container {
  height: 100%;
  padding: 0 15px;

  .lottie-and-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    height: 90px;
    padding: 0 15px;
    background-color: white;
    border-radius: 20px;
    -webkit-box-shadow: 0px -3px 18px 0px #D2D2D240;
    box-shadow: 0px -3px 18px 0px #D2D2D240;

    a {
      width: 40%;
    }

    #walking-gif-elm {
      margin-inline-end: 10px;
      width: 40%;
    }
  }
}



@media only screen and (min-width: $screen-tablet) {

  .deposit-code-container {
    padding: 0;

    .lottie-and-link {
      height: 100%;

      a {
        width: 35%;
      }

      #walking-gif-elm {
        width: 30%;
      }
    }

  }

}
</style>
