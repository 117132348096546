<template>
    <div>
        <div v-show="!showTerms" class="wrap upload">
            <div>
                <h3 class="docsTitle"
                    v-text="$ml.get(isLoadedRightImg || isLoadedLeftImg ? 'docsTitleUploaded' : 'docsTitle')"
                    style="text-align:center; padding-top:12px;"></h3>
            </div>
            <form class="registration-step-4" @submit.prevent="openTerms()">
                <div>
                    <div class="col col-left">
                        <image-upload doc-type="WEB_CUSTOMER" @onError="onError" :isLoadedImg="isLoadedLeftImg"
                            @fileUpload="loadedLeftImg()" @startUpload="startedUploadLeft()"
                            @fileNotUpload="notLoadedLeftImg()" ref="leftImageRef"
                            placeholder-code="photoID"></image-upload>

                    </div>
                    <div class="col col-right" v-show="false">
                        <image-upload doc-type="WEB_CUSTOMER" @onError="onError" :isLoadedImg="isLoadedRightImg"
                            @fileUpload="loadedRightImg()" @startUpload="startedUploadRight()"
                            @fileNotUpload="notLoadedRightImg()" ref="rightImageRef"
                            placeholder-code="photoID"></image-upload>
                    </div>
                </div>
                <div v-show="isLoadedRightImg || isLoadedLeftImg" class="wrap">
                    <div class="terms-transfer">
                        <div class="checkbox">
                            <input type="checkbox" id="sendMarketingContent" name="sendMarketingContent"
                                v-model="sendMarketingContent">
                            <label for="sendMarketingContent"
                                v-text="$ml.with('VueJS').get('sendMarketingContentLbl')"></label>
                            <span class="error">{{ errors.first('sendMarketingContent') }}</span>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="agreement" name="agreement" v-model="agreement"
                                v-validate="'required'">
                            <label for="agreement">
                                {{ $ml.get('captionAgreement') }}
                                <a :href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('protocolLink')"
                                    style="text-decoration: underline;display:inline;">{{
                                        $ml.with('VueJS').get('captionAgreementLink') }}</a>
                            </label>
                            <br class="checkbox-br" />
                            <span class="policy">
                                {{ $ml.with('VueJS').get('and') }}<a
                                    :href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('privacyLink')"
                                    style="text-decoration: underline;display:inline;">{{
                                        $ml.with('VueJS').get('privacyPolicy') }}</a>
                            </span>
                            <span class="error">{{ errors.first('agreement') }}</span>
                        </div>
                    </div>
                </div>
                <div class="group-btn" :class="{ 'hide': !isLoadedRightImg && !isLoadedLeftImg }">
                    <button type="submit" class="btn btn-next btn-docs" v-text="$ml.with('VueJS').get('btnDone')"></button>
                </div>
                <div class="group-btn" :class="{ 'hide': !isLoadedRightImg && !isLoadedLeftImg }">
                    <a class="link" v-text="$ml.with('VueJS').get('bntImgDelete')" @click.prevent="deleteAllImages()"></a>
                </div>
            </form>
            <div class="message-box shield"
                :class="{ 'hide': isLoadedRightImg || isLoadedLeftImg || isProcessLoadingLeftImg || isProcessLoadingRightImg }">
                <div class="iconInfo">
                    <img class="imgInfo" src="../../assets/images/icons/shield.png" />
                </div>
                <div class="message">
                    <p>{{ this.$ml.with('VueJS').get("uploadDocumentMsg1") }}</p>
                    <p>{{ this.$ml.with('VueJS').get("uploadDocumentMsg2") }}</p>
                </div>
            </div>

            <div class="modal-mask modal-mask-success" @click="closeModal">
                <div class="modal-container modal-registration-success" id="modal-registration-success" @click.stop>
                    <div>
                        <div class="modal-body">
                            <img src="../../assets/images/registration_once.gif" alt="">
                            <div class="modal-text">
                                <strong v-text="$ml.with('VueJS').get('registrationSuccess1')"></strong>
                                <p v-text="$ml.with('VueJS').get('registrationSuccess2')"></p>
                            </div>
                            <button class="btn btn-yellow btn-close" v-text="$ml.with('VueJS').get('btnEnter')"
                                @click="nextStep()"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isProcessLoadingLeftImg || isProcessLoadingRightImg" style="text-align:center;padding-top: 15px;">
            <div>
                <img class="loader_reg3" src="../../assets/images/loading.gif" />
            </div>
            <div>
                <p>{{ $ml.with('VueJS').get('documentsUploading1') }}</p>
                <p>{{ $ml.with('VueJS').get('documentsUploading2') }}</p>
                <!-- <p>{{$ml.with('VueJS').get('documentsUploading3')}}</p> -->
            </div>
        </div>
        <div v-if="isDeletingImgs" style="text-align:center;padding-top: 15px;">
            <div>
                <img class="loader_reg3" src="../../assets/images/loading.gif" />
            </div>
        </div>

    </div>
</template>

<script>

import ImageUpload from "../../components/ImageUpload";
import { deleteCustomerWebDocuments } from "../../services/api";
import Terms from "../../components/Terms";
import { completeRegistration } from "../../services/api";
import { feeLookup } from '../../services/api'
import router from "@/router";
import { EventBus } from '../../event-bus.js';
import { localesDictionary, modalHandler } from "@/services/utils";

export default {
    components: { ImageUpload, Terms },
    name: 'Step4',
    data() {
        return {
            isError: false,
            isLoadedLeftImg: false,
            isLoadedRightImg: false,
            showTerms: false,
            sendMarketingContent: false,
            isProcessLoadingLeftImg: false,
            isProcessLoadingRightImg: false,
            isDeletingImgs: false,
            agreement: false
        }
    },
    created() {
        window.scrollTo(0, 0);
        this.$store.commit('setLoaded', false);
        this.localize();
        EventBus.$on('changeLang', lang => { this.localize(lang) });
    },
    computed: {
        getLang() {
            return this.$store.getters.getLang;
        },
    },
    methods: {
        localize(lang) {
            this.$validator.localize(localesDictionary({
                messages: {
                    required: this.$ml.get("E_REQUIRED_agreement")
                }
            }))
            this.$validator.localize(lang || this.$store.getters.getLang);
        },
        deleteAllImages() {
            this.isDeletingImgs = true;
            deleteCustomerWebDocuments((response) => {
                this.isDeletingImgs = false;
                this.isLoadedRightImg = false;
                this.isLoadedLeftImg = false;
                this.$store.commit('setLoaded', false);
                $('.file-upload__image').css('background-image', 'none');
                this.$refs.leftImageRef.$refs.fileRef.value = ''
                this.$refs.rightImageRef.$refs.fileRef.value = ''

            });
        },
        showSuccessModal() {
            this.$validator.validate().then(result => {
                if (result) {
                    completeRegistration({ "sendMarketingContent": this.sendMarketingContent, "acceptTerms": this.agreement }, (response) => {
                        if (this.sendMarketingContent) {
                            this.$analyticsService.transmitSwitchCase('registration', 'registration_stage_6')
                        }
                        localStorage.setItem('userName', this.$store.state.registration.firstName + ' ' + this.$store.state.registration.lastName);
                        localStorage.setItem('registrationData', JSON.stringify(this.$store.getters.getRegistrationData));

                        const userData = response.data.result.user
                        localStorage.setItem('showWallet', userData.showWallet);
                        localStorage.setItem('country', userData.country);
                        this.showTerms = false;
                        this.$analyticsService.transmitSwitchCase('registration', 'registration_stage_7', response.data.result.user)
                        modalHandler('.modal-mask-success', 'show', '092086', 'light');
                    });
                }
            });
        },
        nextStep() {
            this.$store.commit('setNotSigned', false);
            const fromUnregisteredCalc = (localStorage.getItem('fromUnregisteredCalc') && this.$store.state.transfer.amount && this.$store.state.transfer.currency && this.$store.state.transfer.destinationCountry)
            sessionStorage.removeItem('invitedBy')
            localStorage.removeItem('fromUnregisteredCalc');
            const showWallet = localStorage.getItem('showWallet') === 'true'
            if (fromUnregisteredCalc) {
                //another lookup for security (saving amounts in server in customer session)
                feeLookup({ "amount": this.$store.state.transfer.amount, "currency": this.$store.state.transfer.currency, "destinationCountry": this.$store.state.transfer.destinationCountry }, (response) => {
                    modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
                    if (!response.errorCode) {
                        this.$store.commit('setTransferStep1Response', response.result);
                        router.push('/transfer');
                    } else if (showWallet) {
                        router.push('/wallet');
                    } else {
                        router.push('/recent_actions');
                    }
                });
            } else {
                modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
                router.push('Home');
            }
        },
        onFinished() {
            this.$store.commit('setNotSigned', false);
            this.$router.push('recent_actions');
        },
        onError(data) {
            console.log(data);
            this.isError = data;
        },
        closeModal() {
            modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
        },
        loadedLeftImg(doc) {
            this.isLoadedLeftImg = true;
            this.isProcessLoadingLeftImg = false;
            this.$analyticsService.transmitSwitchCase('registration', 'registration_stage_5')
        },
        loadedRightImg(doc) {
            this.isLoadedRightImg = true;
            this.isProcessLoadingRightImg = false;
            this.$analyticsService.transmitSwitchCase('registration', 'registration_stage_5')
        },
        startedUploadLeft() {
            this.isProcessLoadingLeftImg = true;
        },
        startedUploadRight() {
            this.isProcessLoadingRightImg = true;
        },
        notLoadedLeftImg(doc) {
            this.isLoadedLeftImg = false;
            this.isProcessLoadingLeftImg = false;
        },
        notLoadedRightImg(doc) {
            this.isLoadedRightImg = false;
            this.isProcessLoadingRightImg = false;
        },
        openTerms() {
            if (this.isLoadedLeftImg || this.isLoadedRightImg) {
                if (this.$store.getters.getOldCustomerd) {
                    this.nextStep();
                    return;
                }
                this.showSuccessModal();
            }
            else {
                this.$store.commit('setShowModalError', true);
                this.$store.commit('setErrorCode', '');
                this.$store.commit('setErrorMessage', this.$ml.with('VueJS').get('photoRequired'));
            }
        },
    }
}
</script>

<style lang="scss">
.loader_reg3 {
    z-index: 9999;
}

.checkbox {
    .checkbox-br {
        display: none;
    }
}
</style>