<template>
  <div>
    <Header />
    <div class="main-wrapper">
      <div class="content block block-card-main">
        <i class="icon title-icon fas fa-wallet" />
        <h1 class="title" v-text="$ml.get('wallet.receive.title')" />
        <p class="message-body" v-text="$ml.get('wallet.receive.body1')" />
        <p class="message-body" v-text="$ml.get('wallet.receive.body2')" />
        <p class="message-body" v-text="$ml.with('phoneNumber', this.phoneNumber).get('wallet.receive.link')" />
        <div class="row">
          <button class="btn btn-border" v-text="$ml.get('btnReturn')" @click="prevStep()"></button>
          <button class="btn copy-action" @click="this.copy">
            <span>
              <i class="fas fa-copy"></i>
            </span>
            {{ copyToClipboardMsg }}
          </button>
        </div>
        <div class="row">
          <button class="btn mock" hidden />
          <button v-if="this.country === 'CHN'" class="btn btn-border share-action wechat-action ">
            <span>
              <i class="far fa-comment-dots"></i>
            </span>
            {{ $ml.get('wallet.receive.shareActions.wechat') }}
          </button>
          <button v-else-if="this.country === 'THA'" class="btn btn-border share-action line-action">
            <span>
              <i class="far fa-comment-dots"></i>
            </span>
            {{ $ml.get('wallet.receive.shareActions.line') }}
          </button>
          <button v-else class="btn btn-border share-action whatsapp-action " @click="shareWithWhatsApp()">
            <span>
              <i class="fab fa-whatsapp"></i>
            </span>
            {{ $ml.get('wallet.receive.shareActions.whatsapp') }}
          </button>

        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>

import Header from "../../components/Header";
import MainNav from "../../components/MainNav";
import Action from "@/components/Action";
import Footer from "@/components/Footer.vue";

export default {
  name: 'WalletReceive',
  components: { Action, MainNav, Header, Footer },
  data() {
    return {
      copyToClipboardMsg: this.$ml.get('wallet.receive.shareActions.copy')
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    },
    country() {
      return localStorage.getItem('country');
    },
    phoneNumber() {
      return localStorage.getItem('phoneNumber').replace('+972', '0');
    },
    walletLink() {
      return window.location.protocol + "//" + window.location.host + '/#/w/' + this.phoneNumber
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
  },
  methods: {
    shareWithWhatsApp() {
      console.log(this.walletLink)
      window.open('https://api.whatsapp.com/send/?phone&text=' + encodeURIComponent(this.$ml.get('wallet.receive.shareMessages.firstLine')) + '%0a' + encodeURIComponent(this.$ml.get('wallet.receive.shareMessages.secondLine')) + '%0a' + encodeURIComponent(this.walletLink))
    },
    prevStep() {
      this.$router.push('/wallet')
    },
    copy() {
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = this.phoneNumber;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.copyToClipboardMsg = this.$ml.get('wallet.receive.shareActions.copied')
    }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/styles/partials/variables";
@import "../../assets/styles/partials/mixins";

.copy-action {
  background-color: $mainColor;
  color: white;
}

.title-icon {
  font-size: 40px;
  color: $mainColor;
}

.block {
  text-align: center;

  .message-body {
    white-space: pre-line;

    font-size: 20px;
    margin-top: 1%;
    margin-bottom: 2%;
  }

  .btn {
    font-size: 18px;
    height: 38px;
    margin-left: 5px;
    margin-right: 5px;

    &.btn-border {
      border-color: $mainColor;
      color: $mainColor;

      &:hover {
        background-color: $mainColor;
        border-color: $mainColor;
        color: #ffffff;
      }
    }
  }

  .mock {
    visibility: hidden;
  }

  .row {
    margin-top: 1%;

    display: flex;
    justify-content: center;

    .copy-action {
      font-weight: normal;
      min-width: 220px;
    }
  }

  .btn.share-action {
    min-width: 220px;
  }
}

@include max-screen($screen-phones) {

  .block {
    .title {
      text-align: center;
      font-size: 26px;
      margin-top: 4%;
    }

    .message-body {
      font-size: 20px;
      margin-top: 1%;
      margin-bottom: 8%;
      white-space: pre-line;
    }

    .row {
      margin-top: 2%;

      display: flex;
      justify-content: center;

      .copy-action {
        font-weight: normal;
        min-width: 220px;
      }
    }

    .btn.share-action {
      min-width: 220px;
    }
  }
}
</style>