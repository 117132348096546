<template>
  <div>
    <Header />
    <div class="main-wrapper">
      <div class="content block block-card-main bank-block">
        <div class="wrap">
          <h1 class="title" v-text="$ml.get('wallet.deposit.bank.title')" />
          <img class="gif" src="../../../assets/images/gifs/bank-transfer.gif" alt="">
          <p class="message-body" v-text="$ml.get('wallet.deposit.bank.body')" />
          <p class="message-body transfer-details" v-text="$ml.get('wallet.deposit.bank.transferDetails')" />
          <p class="message-body upload-details" v-text="$ml.get('wallet.deposit.bank.uploadDetails')" />
          <button class="btn go-to-upload" @click="goToUploadConfirmationPage">
            {{ $ml.get('wallet.deposit.bank.uploadConfirmationBtn') }}
          </button>
        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />
  </div>
</template>
<script>

import Header from "../../../components/Header";
import MainNav from "../../../components/MainNav";
import Action from "@/components/Action";
import Footer from "@/components/Footer.vue";
import { isMobile } from "../../../services/utils";

export default {
  name: 'WalletBankDeposit',
  components: { Action, MainNav, Header, Footer },
  data() {
    return {
      isMobileDevice: isMobile()
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.$analyticsService.transmitSwitchCase('wallet_deposit','wallet_deposit_stage_1', {
                        page_title: "Wallet Deposit",
                        screen_name: "Wallet Deposit",
                        page_id: 4,
                        screen_id: 4,
                        event_category: 'wallet_deposit',
                        event_lable: 'bank_transfer',
                        method:'bank_transfer',
                        event_category: 'wallet_deposit'
                    })
  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
  },
  methods: {
    goToUploadConfirmationPage() {
      this.$router.push('/wallet/deposit/bank/upload')
    },
    prevStep() {
      this.$router.push('/wallet')
    },
  },
}

</script>

<style lang="scss" scoped>
@import "../../../assets/styles/partials/variables";
@import "../../../assets/styles/partials/mixins";

.block {
  text-align: center;

  .title {
    padding-top: 20px;
    font-size: 30px;
  }

  .gif {
    width: 231px;
    height: 231px;
  }

  .message-body {
    white-space: pre-line;

    font-size: 20px;
    margin-top: 1%;
    margin-bottom: 2%;
  }

  .upload-details {
    font-weight: bold;
  }

  .go-to-upload {
    width: 220px;
  }

  .btn {
    font-size: 18px;
    height: 38px;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;

    &.btn-border {
      border-color: $mainColor;
      color: $mainColor;

      &:hover {
        background-color: $mainColor;
        border-color: $mainColor;
        color: #ffffff;
      }
    }
  }
}

@include max-screen($screen-phones) {

  .title-icon {
    margin-top: 8%;
    margin-bottom: 8%;
  }

  .gif {
    margin-top: -6%;
    margin-bottom: -4%;
  }

  .block {
    .title {
      text-align: center;
      font-size: 26px;
      padding-top: 20px;
    }

    .go-to-upload {
      margin-top: 2%;
    }

    .message-body {
      font-size: 20px;
      margin-top: 1%;
      margin-bottom: 6%;
      white-space: pre-line;
    }
  }
}
</style>