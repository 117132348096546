<template>
  <div class="quick-send-card-container">
    <h4>{{ $ml.get('quickSend') }}</h4>
    <span v-if="!isMobileDevice" class="quick-send-card-paragraph">{{ $ml.get('quickSendActionsParagraph') }}</span>
    <div class="quick-send-card-box">
      <ol :class="{ 'around': beneficiaries().length > 3 }">
        <li v-for="(beneficiary, index) in beneficiaries()" :key="index" class="beneficiaries-list">
          <div class="circle pointer" @click="quickSend(beneficiary)"><strong>{{ beneficiary.firstName[0].toUpperCase()
          }}</strong></div>
          <span class="pointer" @click="quickSend(beneficiary)">{{ beneficiary.firstName }}</span>
        </li>
        <li class="beneficiaries-list"
          @click="$router.push({ name: 'transfer-beneficiary', params: { action: 'create' } })">
          <div class="circle circle-add pointer"><strong>+</strong></div>
          <span class="pointer"> {{ $ml.get('quickActionsAdd') }}</span>
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
import { isApp, isMobile } from "@/services/utils";

export default {
  name: 'QuickSendCard',
  components: {},
  props: {},
  data() {
    return {
      isApp: isApp(),
      isMobileDevice: isMobile()
    }
  },
  created() {
    this.$store.dispatch('loadBeneficiary');

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {

  },
  methods: {
    beneficiaries() {
      return _.take(this.$store.getters.getListBeneficiary, 4)
    },
    quickSend(beneficiary) {
      this.$store.commit('setCurrentBeneficiary', beneficiary.id);
      sessionStorage.setItem('currentBeneficiary', beneficiary.id); // for reload .
      this.$store.commit('setTransferStep1', {
        amount: null,
        currency: null,
        destinationCountry: beneficiary.countryIso3,
      });
      this.$store.commit('setHasCard', (beneficiary.maskedCardNumber));
      this.$router.push('transfer');
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.around>li {
  margin-left: 100px;
}

.quick-send-card-container {
  margin-top: 20px;
  border-radius: 25px;

  h4 {
    padding: 10px 15px;
    color: black;
    font-size: 14px;
  }

  .quick-send-card-box {
    height: 90px;
    margin-top: 10px;
    border-radius: 0 0 20px 20px;

    ol {
      height: 100%;
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
    }

    .beneficiaries-list {
      list-style-type: none;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0px 15px;

      .circle {
        background: #0E1F81;
        border-radius: 35px;
        height: 45px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;

        strong {
          color: white;
          font-weight: 600;
          height: 25px;
          font-size: 18px;
        }
      }

      .circle-add {
        border: 1px dotted;
        background: transparent;

        &:hover {
          background: #0E1F81;

          strong {
            color: white;
          }
        }

        strong {
          color: #0E1F81;
          transform: scale(1.5);
        }
      }

      span {
        color: black;
        margin-top: 7px;
        font-size: 12px;
        max-width: 65px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

@media only screen and (min-width: $screen-tablet) {
  .quick-send-card-container {
    margin-top: 0;
    background-color: white;
    box-shadow: 0px -3px 18px 0px #D2D2D240;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      padding: 5px 15px;
      color: black;
      font-size: 14px;
    }

    .quick-send-card-box {
      margin-top: 5px;
      box-shadow: none;
      background-color: transparent;
    }

    .quick-send-card-paragraph {
      color: #7F818D;
      font-size: 14px;
      padding: 0 15px;
    }
  }
}
</style>
