<template>
    <div id="app">
      <SmartAppBanner/>
      <modal-error></modal-error>
      <router-view/>
      <vue-progress-bar></vue-progress-bar>
    </div>
</template>
<script>
import ModalError from "./components/ModalError"
import SmartAppBanner from "@/components/SmartAppBanner.vue";

export default {
    components: {ModalError, SmartAppBanner},
}
</script>

<style lang="scss">
@import "./assets/styles/template";
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent";
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom";
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition";

</style>
