import countries from '../data/countries';
import currencies from '../data/currencies';
import store from '../store'
import months from '../data/months';

export function getCurrencySymbol(code) {
    let cs = code
    for (var i in currencies) {
        if (currencies[i].code == code)
            return cs = currencies[i].symbol || code;
    }
    return cs;
}

export function getCurrencyName(code) {
    let cuurName = '';
    currencies.forEach((value, index, array) => {
        if (value.code === code) {
            cuurName = value[store.state.locale.lang];
            return false;
        }
    });
    return cuurName;
}

export function countryByIso(iso3) {
    let countryName = '';
    countries.forEach((value, index, array) => {
        if (value.iso === iso3) {
            countryName = value[store.state.locale.lang];
            return false;
        }
    });
    return countryName;
}

export function getPreviousMonths() {
    const arr = [...new Array(36)];
    const currentMonth = new Date().getMonth();
    const lang = store.state.locale.lang;

    arr.forEach((value, index, array) => {
        const date = new Date(new Date().setMonth(currentMonth - index, 1));
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const yearShort = (date.getFullYear() + "").substring(2);

        arr[index] = { month: month.number, year: year, text: `${month[lang]} ${year}`, textShort: `${month[lang]} ${yearShort}` };

    });

    return arr;
}

export function localesDictionary(dictionaryObj) {
    let locales = {};
    Object.keys(store.state.locales).forEach(val => {
        locales[val] = dictionaryObj;
    });
    return locales;
}

export function loadLottieJson(elm, fileName) {
    const elOnPage = $(`#${elm}`)[0];
    if (elOnPage) {
        elOnPage.addEventListener("rendered", (e) => {
            elOnPage.load(
                require(`../assets/lottie_jsons/${fileName}.json`)
            );
        });
    }
}

export function isApp() {
    return navigator.userAgent.indexOf('gonative') > -1
}

export function addFloatingLabel(labelName) { // only call on 'mounted'
    $(`#${labelName}`).focus(() => {
        $(`#${labelName}-floating-label`).addClass('floating-label-focused')
    })
}

export function makeRand(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function paintStatusBar(color, style) {
    try {
        if (isApp()) {
            gonative.statusbar.set({
                'style': style,
                'color': color
            });
        }
    } catch (e) {
        console.log(e)
    }
}

export function modalHandler(elm, action, color, style) {
    let el = $(`${elm}`)
    if (el) {
        switch (action) {
            case 'show':
                el.show()
                break;
            case 'hide':
                el.hide()
                break;
            case 'removeClassOpen':
                el.removeClass('open')
                break
            case 'addClassOpen':
                el.addClass('open')
                break;
        }
    }
    paintStatusBar(color, style)
    window.scrollTo(0, 0);
    let openingModalActions = ["addClassOpen", "show"]
    if (openingModalActions.indexOf(action) > 0) {
        $('body').addClass('stop-scrolling')
    } else {
        $('body').removeClass('stop-scrolling')
    }
}


export function detectUserOs() {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
        return "Android"
    }
    else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return "iOS"

    }
    return "Other"
}

export function processPhoneBook(phoneBook) {
    let finalList = [];
    let uniqueNumbers = [];
    _.forEach(phoneBook, (contact) => {
        let contactName = `${contact.givenName}${contact.familyName ? " " + contact.familyName : ''}`;
        _.forEach(contact.phoneNumbers, (innerPhone) => {
            let phoneNum = innerPhone.phoneNumber.replaceAll("-", "").replaceAll(" ", "").replace("+972", "0")
            if (uniqueNumbers.indexOf(phoneNum) === -1) {
                finalList.push({
                    "name": contactName, "phone": phoneNum
                })
                uniqueNumbers.push(phoneNum)
            }
        })
    })
    return finalList;
}

export function transmitEvent(event) {
    try {
        if (isApp()) {
            gonative.firebaseAnalytics.event.logEvent({ 'event': event.name, 'data': event.data});
        } else {
            gtag('event', event.name, event.data);
        }
    } catch (e) {
        console.log("failed to sent an event " + e)
    }
}

export function isDisplayingBanner() {
    let userClosed = $cookies.get('userClosed') === 'true';
    let userOs = detectUserOs();
    let isApp = navigator.userAgent.indexOf('gonative') > -1
    return !isApp && !userClosed && (userOs === 'iOS' || userOs === 'Android')
}

export function getTimeOfDayString() {
    let hour = new Date().getHours();
    if (hour >= 5 && hour <= 12) {
        return "morning";
    }
    if (hour >= 12 && hour <= 17) {
        return "afternoon";
    }
    if (hour >= 17 && hour <= 21) {
        return "evening";
    }
    return "night";
}

export function getUserFullName(lang) {
    let res = lang === 'il' && localStorage.getItem('userNameHebrew') ? (localStorage.getItem('userNameHebrew') || '') : (localStorage.getItem('userName') || '')
    if (res) {
        try {
            let firstName = res.substring(0, res.indexOf(' '))
            let lastName = res.substring(res.indexOf(' ') + 1)
            return `${capitalizeName(firstName)} ${capitalizeName(lastName)}`
        } catch (e) {
            return res;
        }
    }
    return res
}

export function getUserFirstName() {
    let res = localStorage.getItem('userFirstName') || ''
    if (res) {
        try {
            return capitalizeName(res)
        } catch (e) {
            return res;
        }
    }
    return res
}

function capitalizeName(str) {
    if (str.length < 2) return str
    return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function isMobile() {
    return screen.width < 768;
}

export function parseConstraints(fields) {
    const constraints = {};
    Object.keys(fields['BENEFICIARY']).forEach(key => {
        fields['BENEFICIARY'][key].forEach(field => {
            const rules = { required: true, numeric: field.numeric }
            if (field.minValue && field.minValue === field.maxValue) {
                rules.length = field.minValue
            } else if (field.minValue) {
                rules.min = field.minValue
            }
            if (field.maxValue) {
                rules.max = field.maxValue
            }
            if (field.regExp) {
                rules.regex = field.regExp
            }
            if (field.possibleValues && field.possibleValues.length > 0) {
                rules.included = field.possibleValues;
                if (field.name === 'relationshipWithCustomer') {
                    rules.included.unshift('SELF')
                }
            }
            constraints[field.name] = rules;
        });
    });
    sessionStorage.setItem('beneficiaryFieldsConstraints', JSON.stringify(constraints));
}