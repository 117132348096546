<template>
  <div class="modal-mask modal-mask-transfer-error" @click="closeModal">
    <div class="modal-container modal-transfer" id="modal-error" @click.stop>
      <div class="modal-body">
        <img src="../assets/images/gifs/help.gif" alt="">
        <div class="modal-text mb">
          <p><span v-html="errorMessage"></span></p>
        </div>
        <div v-if="errorCode === 'CUSTOMER_DENIED'">
          <a v-bind:href="'https://gmtonline.co.il/'+ $ml.with('VueJS').get('contactUsLink')"
             class="btn btn-yellow btn-close"
             v-text="this.$ml.with('VueJS').get('contactBtnCaption')" style="min-width:160px;"></a>
        </div>
        <div v-else-if="errorCode === 'WALLET_FRIEND_NOT_REGISTERED'" class="group-btn">
          <button @click="closeModal()" class="btn btn-small btn-border">{{ this.$ml.get('btnReturn') }}</button>
          <button @click="goToFriendsInvitation()" class="btn btn-yellow btn-go-to-friends">
            {{ this.$ml.get('wallet.friends.inviteBnt') }}
          </button>
        </div>
        <div v-else>
          <button @click="closeModal()" class="btn btn-yellow btn-close">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalError',
  computed: {
    isError() {
      return this.$store.getters.getShowModalError
    },
    errorMessage() {
      if (this.$store.getters.getErrorMessage)
        return this.$store.getters.getErrorMessage;
      else {
        let msg = this.$ml.get(this.$store.getters.getErrorCode);
        return msg ? msg : this.$ml.get('API_EXCEPTION');
      }
    },
    errorCode() {
      return this.$store.getters.getErrorCode
    }
  },
  watch: {
    isError(newCount, oldCount) {
      if (newCount) {
        $('.modal-mask').hide();
        if (navigator.userAgent.indexOf('gonative') > -1) {
          gonative.statusbar.set({
            'color': 'ffffff',
            'style': 'dark'
          });
        }
        //to close previous modals
        $('.modal-mask-transfer-error').show();
        if (navigator.userAgent.indexOf('gonative') > -1) {
          gonative.statusbar.set({
            'color': '092086',
            'style': 'light'
          });
        }
        // when importing in this class the css gets fucked
      }
    },
    errorMessage(newCount, oldCount) {
      return newCount;
    }
  },
  methods: {
    goToFriendsInvitation() {
      $('.modal-mask').hide();
      if (navigator.userAgent.indexOf('gonative') > -1) {
        gonative.statusbar.set({
          'color': 'ffffff',
          'style': 'dark'
        });
      }
      if (this.errorCode === 'WALLET_FRIEND_NOT_REGISTERED') {
        this.$router.push('/wallet/invite')
      }
    },
    closeModal() {
      $('.modal-mask').hide();
      if (navigator.userAgent.indexOf('gonative') > -1) {
        gonative.statusbar.set({
          'color': 'ffffff',
          'style': 'dark'
        });
      }
      this.$store.commit('setShowModalError', false);
      this.$store.commit('setErrorCode', '');
      this.$store.commit('setErrorMessage', '');
    },
  }
}
</script>

<style lang="scss" scoped>

.group-btn {
  .btn {
    text-align: center;
    display: inline;
  }

  .btn-small{
    width: 70px;
  }

  .btn-go-to-friends {
    width: 210px;
  }
}

</style>
