<template>
  <div>
    <div v-show="beneficiary" class="wrap">
      <h1 class="title">{{ $ml.with('VueJS').get('transferFundsTo') + ' ' + getTitleAmount + ' ' +
        $ml.with('VueJS').get('to') + getCountryName }}</h1>
      <h2 class="subtitle" v-text="$ml.with('VueJS').get('summaryTransfer')"></h2>
      <div class="summary-transfer">
        <div class="list-transfer">
          <div class="item-transfer">
            <ul class="captions">
              <li id="name1" v-text="$ml.with('VueJS').get('nameBeneficiary')"></li>
              <li v-text="$ml.with('VueJS').get('destination')"></li>
              <li v-text="$ml.with('VueJS').get('transferType')"></li>
              <li v-if="transactionClass === 'CARD_DEPOSIT'" v-text="$ml.with('VueJS').get('cardNumber')"></li>
              <li v-if="transactionClass === 'WALLET_TRANSFER'">{{ correspondentIdentifier === 'THUNES_WECHATPAY' ?
                $ml.with('VueJS').get('beneficiary.phoneNumber') : $ml.with('VueJS').get('toWalletAccount') }}</li>
              <li v-else-if="transactionClass === 'CASH_TRANSFER'" v-text="$ml.with('VueJS').get('correspondentSystem')">
              </li>
              <li v-else-if="transactionClass === 'BANK_TRANSFER'"
                v-text="$ml.with('VueJS').get((beneficiary.accountIdentifier) ? 'beneficiary.accountIdentifier' : 'beneficiary.iban')">
              </li>
              <li v-else-if="transactionClass === 'UPI_BANK_TRANSFER'"
                v-text="$ml.with('VueJS').get(`beneficiary.upi_${destinationCountry}`)">
              </li>
            </ul>
            <ul class="values">
              <li id="name2">{{ beneficiary.firstName + ' ' + beneficiary.lastName }}</li>
              <li>{{ getCountryName }}</li>
              <li>
                {{ $ml.with('VueJS').get(destinationCountry == 'ISR' ? transactionClass + '_ISR' : transactionClass) }}
              </li>
              <li>
                <template v-if="transactionClass == 'CARD_DEPOSIT'"> {{ beneficiary.maskedCardNumber | formatCardNumber
                }}</template>
                <template v-if="transactionClass == 'WALLET_TRANSFER'"> {{ correspondentIdentifier === 'THUNES_WECHATPAY' ?
                  beneficiary.phoneNumber : beneficiary.walletIdentifier }}
                  <template v-if="beneficiary.walletBankName">({{ beneficiary.walletBankName }})</template>
                </template>
                <template v-else-if="transactionClass == 'BANK_TRANSFER'">{{ beneficiary.accountIdentifier ?
                  beneficiary.accountIdentifier : beneficiary.iban }}
                </template>
                <template v-else-if="transactionClass == 'UPI_BANK_TRANSFER'">{{ beneficiary.upiIdentifier}}
                </template>
                <template v-else-if="transactionClass == 'CASH_TRANSFER'">
                  {{ correspondentName }}
                  <template v-if="cashBank">({{ beneficiary.cashBankName }})</template>
                </template>
              </li>
            </ul>

          </div>
          <div class="item-transfer">
            <ul class="captions">
              <li v-text="$ml.with('VueJS').get('amount')"></li>
              <li v-text="$ml.with('VueJS').get('commission')"></li>
              <li v-if="discount" v-text="$ml.with('VueJS').get('discount')"></li>
              <li v-if="useCashBack" v-text="$ml.with('VueJS').get('cashBackCredit')"></li>
              <li v-text="$ml.with('VueJS').get('totalTransfer')"></li>
              <li v-if="!hideAmountReceivedAndRate" v-text="$ml.with('VueJS').get('exchangeRate')"></li>
              <li v-if="!hideAmountReceivedAndRate" v-text="$ml.with('VueJS').get('amountReceived')"></li>
            </ul>
            <ul class="values">
              <li>{{ amount | formatComma }} {{ currency }}</li>
              <li>
                <template v-if="discount">{{ commission + discount }} {{ currency }}</template>
                <template v-else>{{ commission }} {{ currency }}</template>
              </li>
              <li v-if="discount">{{ discount }} {{ currency }}</li>
              <li v-if="useCashBack"><span dir="ltr">{{ 0 - cashBackCredit }}</span> {{ currency }} </li>
              <li>{{ totalAmount | formatComma }} {{ currency }}</li>
              <template v-if="!hideAmountReceivedAndRate">
                <li class="OneLine">
                  <template v-if="exchangeRate">{{ exchangeRate | formatComma }}
                    <template v-if="isEstimaed">~{{ $ml.with('VueJS').get('estimated') }}</template>
                  </template>
                  <template v-else>{{ payoutCurrency }} ~</template>
                </li>
                <li class="OneLine">
                  <template v-if="totalReceiveAmount">{{ totalReceiveAmount | formatComma }} {{ payoutCurrency }}
                    <template v-if="isEstimaed">~{{ $ml.with('VueJS').get('estimated') }}</template>
                  </template>
                  <template v-else>{{ $ml.with('VueJS').get('calculatedAbroad') }}</template>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="currencyMsg">
          <label v-if="transactionClass == 'CARD_DEPOSIT'" v-text="$ml.with('VueJS').get('currencyCardMsg')"></label>
          <label v-else-if="transactionClass == 'BANK_TRANSFER'" v-text="$ml.with('VueJS').get('currencyBankMsg')"></label>
        </div>
        <div class="group-btn">
          <button class="btn wide" v-text="$ml.with('VueJS').get('btnOk')" @click="nextStep()"></button>
        </div>
      </div>
    </div>
    <div class="modal-mask modal-mask-cashBack" @click="closeModal()">
      <div class="modal-container modal-transfer cashBack" id="modal-mask-cashBack" @click.stop>
        <span class="close-modal" @click="cashBackChoice(false)"
          :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
        <div class="modal-body">
          <img src="../../assets/images/gif-cashback-gift.gif" alt="">
          <div class="modal-text mb">
            <strong>
              <p class="msg1" v-text="$ml.with('VueJS').get('cashBackModalMessage')"></p>
              <p class="msg2">{{ $ml.with('VueJS').get('cashBackModalMessage2') }} {{ cashBackSymbol + cashBackCredit }}
                {{ $ml.with('VueJS').get('cashBackModalMessage3') }}
              <p v-text="$ml.with('VueJS').get('cashBackModalMessage4')"></p>
            </strong>
          </div>
          <button class="btn btn-yellow btn-close" v-text="$ml.with('VueJS').get('cashBackModalMessageConfirm')"
            @click="cashBackChoice(true)"></button>
          <a calss="cashBackCancel" v-text="$ml.with('VueJS').get('cashBackModalMessageCancel')"
            @click="cashBackChoice(false)"></a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Terms from "../../components/Terms";
import { getBeneficiary, getCashBackCreditAvailable } from "../../services/api";
import { getCurrencySymbol, modalHandler } from "@/services/utils";

export default {
  name: 'Step3',
  components: { Terms },
  data() {
    return {
      countries: [],
      beneficiary: '',
      cashBackShown: false,
    }
  },
  async created() {
    window.scrollTo(0, 0);
    this.$store.commit('setBeneficiaryUpdated', false);
    this.$store.commit('setUseCashBack', false);
    this.countries = require('../../data/countries');
    this.beneficiary = await getBeneficiary(this.$store.getters.getSelectedBeneficiary);
  },
  computed: {
    exchangeRate() {
      return this.$store.getters.getSelectedExchangeRate;
    },
    amount() {
      return this.$store.getters.getSelectedTransferOption.amount;
    },
    commission() {
      return this.$store.getters.getSelectedTransferOption.commission;
    },
    currency() {
      return this.$store.getters.getSelectedTransferOption.currency;
    },
    totalReceiveAmount() {
      return this.$store.getters.getSelectedTransferOption.totalReceiveAmount;
    },
    payoutCurrency() {
      return this.$store.getters.getSelectedPayoutCurrency;
    },
    transactionClass() {
      return this.$store.getters.getSelectedTransferOption.transactionClass;
    },
    discount() {
      return this.$store.getters.getSelectedTransferOption.discount;
    },
    cashBackCredit() {
      return this.$store.getters.getCashBackCredit;
    },
    cashBackCurrency() {
      return this.$store.getters.getCashBackCurrency;
    },
    useCashBack() {
      return this.$store.getters.getUseCashBack && this.cashBackCredit;
    },
    totalAmount() {
      return this.useCashBack ? this.amount - this.cashBackCredit + this.commission : this.amount + this.commission;
    },
    correspondentName() {
      return this.$store.getters.getSelectedTransferOption.correspondentName;
    },
    correspondentType() {
      return this.$store.getters.getSelectedTransferOption.correspondentType;
    },
    correspondentIdentifier() {
      return this.$store.getters.getSelectedTransferOption.correspondentIdentifier;
    },
    cashBank() {
      return this.transactionClass == 'CASH_TRANSFER' && this.$store.getters.getSelectedTransferOption.correspondentType == 'TRANGLO';
    },
    hideAmountReceivedAndRate() {
      return this.$store.getters.getSelectedTransferOption.isVisaOrMastercard || this.$store.getters.getSelectedTransferOption.transactionClass === 'CARD_DEPOSIT' && this.destinationCountry === 'UKR';
    },
    getTitleAmount() {
      return this.$store.getters.getTitleTransferAmount
    },
    getCountryName() {
      return this.$store.getters.getCountryName;
    },
    destinationCountry() {
      return this.$store.state.transfer.destinationCountry
    },
    isEstimaed() {
      return this.currency != this.payoutCurrency && this.$store.getters.getSelectedTransferOption.correspondentType == 'MONEYGRAM'
    },
    cashBackSymbol() {
      return getCurrencySymbol(this.cashBackCurrency);
    },
    getDir() {
      return this.$store.getters.getDir;
    },
  },
  mounted() {
    if (this.cashBackCredit && this.cashBackCurrency != 'ILS') {
      this.cashBackShown = true;
      modalHandler('.modal-mask-cashBack', 'show', '092086', 'light');
    }
  },
  updated() {
    this.alignRow();
  },
  methods: {
    alignRow() {
      $('#name1').height($('#name2').height());
    },
    prevStep() {
      this.$emit('onNextStep', 1);
    },
    nextStep() {
      this.$validator.validate().then(result => {
        if (result) {
          this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_4')
          if (!this.cashBackShown) {
            getCashBackCreditAvailable({ amount: this.amount, currency: 'ILS' }, (data) => {
              if (data) {
                this.$store.commit('setCashBackCredit', data);
                this.$store.commit('setCashBackCurrency', 'ILS');
                modalHandler('.modal-mask-cashBack', 'show', '092086', 'light');
              } else this.$emit('onNextStep', 3);
            });
          } else this.$emit('onNextStep', 3);
        }
      })

    },
    closeModal() {
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
    },
    cashBackChoice(choice) {
      this.$store.commit('setUseCashBack', choice);
      this.closeModal();
      if (!this.cashBackShown) {
        this.$emit('onNextStep', 3);
      }
    },
  },
}
</script>

<style lang="scss">
.OneLine {
  white-space: nowrap;
  width: 140px;
}

.currencyMsg {
  text-align: center;
  margin-top: 10px;
}

.block-transfer {
  .cashBack {
    .msg1 {
      margin-bottom: 8px;
      font-size: 25px;
    }

    .msg2 {
      margin-bottom: 8px;
      font-size: 23px;
    }

    .btn {
      margin: 30px auto 0 !important;
      width: auto;
      max-width: 88%;
      padding-right: 22px;
      padding-left: 22px;
      height: 38px;
      font-size: 20px;
    }

    a {
      margin-top: 50px;
      margin-bottom: 50px;
      color: #ffffff;
      line-height: 32px;
      cursor: pointer;
    }

    .amount {
      margin-top: 8px;
      font-size: 21px;
    }
  }
}</style>