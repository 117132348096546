<template>
    <div class="grey-bgc">
        <Header class="header-registration"></Header>
        <div class="main-wrapper">
            <div class="content block block-user-profile">
                <!-- <div class="content-top">
                    <div class="breadcrumbs breadcrumbs-main">
                        <ul>
                            <li v-for="(step, index) in steps" :key="step"
                                :class="{ 'active': currentStep === index+1, 'visited': index+1 < currentStep }"
                                @click="changeStep(index, true)">
                                {{$ml.with('VueJS').get(step)}}
                            </li>
                        </ul>
                    </div>
                </div> -->
                <div class="content-user-profile">
                    <div class="wrap text-center">
                        <h1 class="title" v-text="$ml.with('VueJS').get('itemNavUserProfile')"></h1>
                        <div class="body profile-steps">
                            <component :is="currentTabComponent" @onNextStep="changeStep"></component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer v-if="!isMobileDevice"/>
    </div>
</template>

<script>
    import Header from "../components/Header";
    import MainNav from "../components/MainNav";
    import {Validator} from 'vee-validate';
    import Step1 from "./UserProfile/Step1";
    import Step2 from "./UserProfile/Step2";
    import Step3 from "./UserProfile/Step3";
    import DatePicker from 'vue2-datepicker';
    import {isMobile, localesDictionary} from "../services/utils";
    import Footer from "@/components/Footer.vue";

    export default {
        name: 'UserProfile',
        components: {DatePicker, MainNav, Header, Step1, Step2, Step3, Footer},
        data() {
            return {
                isAuthorization: false,
                currentStep: 1,
                currentStepClass: 'step-1',
                typeRegistration: '',
                steps: ['r_step_name', 'r_step_address', 'r_step_doc'],
                isMobileDevice: isMobile()
            }
        },
        computed: {
            getDir() {
                return this.$store.getters.getDir;
            },
            getLang() {
                return this.$store.getters.getLang;
            },
            currentTabComponent: function () {
                return 'Step' + this.currentStep;
            },

        },
        mounted() {
            document.body.setAttribute('dir', this.getDir);
            Validator.localize(this.dictionary());
            Validator.localize(this.getLang);
        },
        methods: {
            showForm(type) {
                this.typeRegistration = type;
            },
            changeStep(index) {
                this.currentStep = index + 1;
                this.currentStepClass = 'step-' + index + 1;
            },
            dictionary(){
                const messages = {
                    required: this.$ml.with('VueJS').get("E_REQUIRED"),
                    numeric: this.$ml.with('VueJS').get("E_NUMERIC"),
                }
                return localesDictionary({ messages: messages });
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    @import "../assets/styles/partials/variables";
    @import "../assets/styles/partials/mixins";

    .block-user-profile {
        padding-top: 0;
        .wrap {
            padding: 0;
            max-width: 739px;
            margin: 0 auto;
        }
        input{
            direction:ltr!important;
        }
        .title {
            line-height: 20px;
            margin-top: 10px;
        }
        .body {
            margin-top: 30px;
        }
        .form-user-profile {
            margin-top: 19px;
            .form-group, .btn {
                display: inline-block;
            }
            .form-group {
                margin-left: 16px;
            }
            label {
                text-align: right;
                color: #4a4a4a;
                padding-right: 10px;
            }
            .form-control {
                width: 260px;
                height: 34px;
                margin-top: 5px;
                // color: #4a4a4a;
                // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                //     color: #4a4a4a;
                // }
                // &::-moz-placeholder { /* Firefox 19+ */
                //     color: #4a4a4a;
                // }
                // &:-ms-input-placeholder { /* IE 10+ */
                //     color: #4a4a4a;
                // }
                // &:-moz-placeholder { /* Firefox 18- */
                //     color: #4a4a4a;
                // }
            }
            .btn {
                height: 34px;
                line-height: 34px;
                width: 180px;
                font-size: 18px;
            }
        }
        .form-step-1 {
            .form-group, .btn-next {
                display: inline-block;
                vertical-align: middle;
            }
            .form-group {
                text-align: right;
                margin-left: 14px;
                label {
                    color: #4a4a4a;
                    padding-right: 10px;
                }
                .form-control {
                    width: 260px;
                    margin-top: 5px;
                    &[aria-invalid=true] {
                        border-color: #ff6a68;
                    }
                }
            }
            .btn-next {
                min-width: 180px;
                width: auto;
                padding: 0 10px;
                font-size: 18px;
                vertical-align: bottom;
                height: 38px;
                line-height: 38px;
            }
        }
        .error {
            width: 100%;
            right: 0;
        }
        .profile-steps {
            text-align: right;
            margin-top: 6px !important;
            label {
                color: #4a4a4a;
            }
            .btn {
                width: 130px;
                display: inline-block;
                vertical-align: bottom;
            }
            .form-group {
                display: inline-block;
                vertical-align: middle;
                margin: 30px 12px 0 12px !important;
            }
            .form-control {
                width: 222px;
                margin-top: 5px;
            }
        }
        .profile-step-1 {
            .icon-calendar {
                position: absolute;
                bottom: 10px;
                left: 10px;
            }
            #dateBirth {
                padding-left: 9px;
            }
        }
        .profile-step-2 {
            .btn-border {
                color: $mainColor;
                border-color: $mainColor;
                margin-left: 18px;
                &:hover {
                    background-color: $mainColor;
                    color: #ffffff;
                }
            }
        }
        .profile-step-3 {
            .btn-next {
                display: block;
                margin: 55px auto 0;
            }
        }
        .profile-step-4 {
            text-align: center;
            margin-top: 55px;
            & > div {
                display: flex;
                margin: 0 -10px;
            }
            .error {
                margin-left: 0;
            }
            .col {
                width: 100%;
                height: 241px;
                border: 1px dashed rgb(218, 218, 218);
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 2px;
                background-color: rgb(255, 255, 255);
                margin: 0 85px;
                position: relative;
            }
            .group-btn {
                display: block;
                margin-top: 40px;
                .btn{
                    margin: 0 10px;
                }
                .btn-border{
                    border-color: #092086;
                    color: #092086;
                    &:hover{
                        background-color: #092086;
                        color: #ffffff;
                    }
                }
            }
        }
        .breadcrumbs ul {
            max-width: 700px;
        }
        .mx-datepicker {
            width: 230px;
            input {
                height: 34px;
                border: 1px solid #dadada;
                border-radius: 2px;
                background-color: white;
                padding: 0 9px;
                font-size: 18px;
                font-weight: 400;
                line-height: 31px;
                width: 100%;
                margin-top: 5px;
                color: #4a4a4a;
                padding-right: 35px;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: #ffffff;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: #ffffff;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: #ffffff;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: #ffffff;
                }
            }
            .mx-input-append {
                display: block;
                width: 17px;
                height: 16px;
                background-image: url(../assets/images/icons/i-calendar.png);
                cursor: pointer;
                bottom: 9px;
                right: 10px;
                top: auto;
                background-clip: border-box;
                svg {
                    display: none;
                }
            }
            .mx-input-wrapper .mx-clear-wrapper {
                display: none;
            }
            .mx-calendar-content .cell.actived,
            .mx-calendar-content .cell:hover {
                background-color: $mainColor;
                color: #ffffff;
            }
        }
    }
</style>
