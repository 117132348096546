<template>
    <div class="wrap">
        <form class="profile-step-4" @submit.prevent="nextStep">
            <div>
                <div class="col col-left">
                    <image-upload-profile doc-type="WEB_CUSTOMER" @onError="onError" :content="docsFile[loadedFileLeft]" :idImg="idDocs[loadedFileLeft]" :isLoadedImg="isLoadedLeftImg"></image-upload-profile>
                </div>
                <div class="col col-right"  v-show="false">
                    <image-upload-profile doc-type="WEB_CUSTOMER" @onError="onError" :content="docsFile[loadedFileRight]" :idImg="idDocs[loadedFileRight]" :isLoadedImg="isLoadedRightImg"></image-upload-profile>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

    import ImageUploadProfile from "../../components/ImageUploadProfile";
    import {getDocuments, getDocumentsUrl, deleteDocuments} from "../../services/api";

    export default {
        components: {ImageUploadProfile},
        name: 'Step4',
        data() {
            return {
                isError: false,
                idDocs: [],
                docsFile: [],
                isLoadedLeftImg: true,
                isLoadedRightImg: true,
                loadedFileLeft: 0,
                loadedFileRight: 1,
            }
        },

        methods: {
            /*nextStep() {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.$router.push('recent_actions');
                    }
                });
            },*/
            onError(data) {
                this.isError = data;
            },
            /*clearImage(){
                if(!this.idDocs[this.loadedFileLeft] && !this.idDocs[this.loadedFileRight]) {
                    return false;
                }

                deleteDocuments(this.idDocs[this.loadedFileLeft], () => {
                    this.idDocs[this.loadedFileLeft] = null;
                });

                let doc = this.idDocs[this.loadedFileRight];
                setTimeout(function () {
                    deleteDocuments(doc, () => {
                        doc = null;
                    });
                },1000);
                this.idDocs[this.loadedFileRight] = null;

                this.isLoadedRightImg = false;
                this.isLoadedLeftImg = false;
                this.$store.commit('setLoaded', false);
                $('.file-upload__image').css('background-image','none');
            },
            loadedLeftImg(doc) {
                this.isLoadedLeftImg = true;
                this.idDocs.push(doc);
                this.loadedFileLeft = this.idDocs.length-1;
            },
            loadedRightImg(doc) {
                this.isLoadedRightImg = true;
                this.idDocs.push(doc);
                this.loadedFileRight = this.idDocs.length-1;

            },
            deleteLeftImg(doc) {
                this.isLoadedLeftImg = false;
                for(let i in this.idDocs) {
                    if (this.idDocs[i] === doc) {
                        this.idDocs[i] = null;
                    }
                }
            },
            deleteRightImg(doc) {
                this.isLoadedRightImg = false;
                for(let i in this.idDocs) {
                    if (this.idDocs[i] === doc) {
                        this.idDocs[i] = null;
                    }
                }
            }*/

        },
        created(){
            getDocuments((data) =>{
                for(let i in data){
                    getDocumentsUrl(data[i].docId, (data) =>{
                        this.docsFile.push(data);
                        this.idDocs.push(data.id);
                    });
                }
            });
        }
    }
</script>

