<template>
  <div>
    <div class="smartAppBanner" id="smartAppBannerElement">
      <div class="icons">
        <span class="close" @click="this.hideBanner">&#10005;</span>
        <img src="../assets/images/gmt-app-logo.png" alt="gmt-app-logo" class="logo">
      </div>
      <div class="text-box">
        <strong>{{ this.$ml.get('smartBannerTitle') }}</strong>
        <sub>{{ this.$ml.get('smartBannerText') }}</sub>
      </div>
      <button class="btn" :class="{ 'btnRtl': this.$store.getters.getDir === 'ltr' }" @click="goDownload">
        {{ this.$ml.get('smartBannerBtnText') }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SmartAppBanner',
  props: {},
  components: {},
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    }
  },
  data() {
    let userOS
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      userOS = "Android"
    } else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      userOS = "iOS"
    } else {
      userOS = "Other"
    }
    return {
      userOs: userOS
    }
  },
  methods: {
    isUserClosed() {
      return $cookies.get('userClosed') === 'true'
    },
    hideBanner() {
      $cookies.set("userClosed", true, new Date(window.moment().add(1, 'months')))
      $("#smartAppBannerElement").hide()
    },
    goDownload() {
      $("#smartAppBannerElement").hide()
      if (this.getLang === "cn") {
        this.$router.push({ path: '/Download?apk=1' });
        return
      }
      //for analytics
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': "downloadAppButton",
        'evntDetails': {}
      });
      // end analytics
      switch (this.userOs) {
        case 'iOS':
          window.open('https://apps.apple.com/app/mygmt-money-transfer-abroad/id1666510532')
          break;
        case 'Android':
          window.open('https://play.google.com/store/apps/details?id=il.co.gmtonline.mygmt')
          break;
      }
    }
  },
  mounted() {
    let userClosed = $cookies.get('userClosed') === 'true';
    let userOs
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      userOs = "Android"
    } else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      userOs = "iOS"
    } else {
      userOs = "Other"
    }
    let isApp = navigator.userAgent.indexOf('gonative') > -1
    if (!(!isApp && !userClosed && (userOs === 'iOS' || userOs === 'Android'))) {
      $("#smartAppBannerElement").hide()
    }
  }
}

</script>
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.smartAppBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  background-color: $mainColor;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  z-index: 8;

  .close {
    font-size: 15px;
    margin-inline-start: 10px;
    font-weight: bold;
    color: white;
  }

  .logo {
    height: 35px;
    margin-inline-start: 10px;
    border-radius: 15%;
  }

  .text-box {
    display: flex;
    flex-direction: column;
    color: white;

    strong {
      font-size: 12px;
    }

    sub {
      font-size: 12px;
    }
  }

  .icons {
    margin-inline-start: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .btn {
    height: 26px;
    font-size: 13px;
    line-height: 20px;
    width: 81px;
    position: relative;
    margin: 0 17px;
    background-color: $secondaryColor;
    color: $mainColor;
    font-weight: bold;
  }
}

hr {
  opacity: 0.5;
  position: relative;
  margin: 0;
}
</style>