<template>
  <div v-if="show">
    <div class="modal-mask modal-mask-message" style="display: block" @click="customClose ? close() : closeModal()">
      <div class="modal-container modal-message" id="modal-message" @click.stop>
        <div>
          <span class="close-modal" @click="customClose ? close() : closeModal()"
            :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
          <div class="modal-body body-dialogue">
            <i v-if="icon" :style="iconStyle" :class="icon"></i>
            <img :src="image" alt="">
            <div class="title">
              <h4 v-text="title"></h4>
            </div>
            <div class="title body-title">
              <div v-if="body">
                <p style="white-space: pre-wrap;" v-text="body"></p>
              </div>
              <div v-else>
                <slot name="customModalBody" />
              </div>
              <slot />
            </div>
            <div v-if="footer" class="title body-title">
              <p v-text="footer"></p>
            </div>
            <div class="group-btn">
              <button v-if="!noCancelBtn" class="btn btn-small btn-border"
                v-text="cancel ? cancel : $ml.get('btnReturn')" @click="customClose ? close() : closeModal()"></button>
              <button type="submit" class="btn btn-small btn-yellow wide" v-text="this.confirm"
                @click="noAction ? closeModal() : submit()"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modalHandler } from "@/services/utils";

export default {
  props: {
    customClose: {
      type: Boolean
    },
    icon: {
      type: String
    },
    image: {
      type: String
    },
    iconStyle: {
      type: String
    },
    title: {
      type: String
    },
    footer: {
      type: String
    },
    body: {
      type: String
    },
    confirm: {
      type: String
    },
    cancel: {
      type: String
    },
    noAction: {
      type: Boolean
    },
    noCancelBtn: {
      type: Boolean
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    openModal() {
      modalHandler(null, 'show', '092086', 'light')
      this.show = true
    },
    closeModal() {
      modalHandler(null, 'hide', 'ffffff', 'dark')
      this.show = false
    },
    submit() {
      this.$emit('action');
    },
    close() {
      this.$emit('closeAction');
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    },
  }
}

</script>