<template>
  <div class="cash-back-card-wrapper">
    <div class="cash-back-card-container pointer" @click="$router.push('/wallet/invite')">
      <div class="img-container" v-if="getValidCashBackBalances.length">
        <img src="../assets/mygmt_v2_images/Present-Cashback.svg" alt="cashback">
        <div class="flex flex-column">
          <a>{{ $ml.get('cashBackBalance1') }}
          </a>
          <a>{{ $ml.get('cashBackBalance2') }}
            <label class="message-inline" v-for="(balance, key) in getValidCashBackBalances" :key="key">{{
      balance.symbol
    }}{{ balance.amount
              }}<span>,</span>&nbsp;&nbsp;</label>
          </a>
        </div>
      </div>
      <div class="img-container" v-else>
        <img src="../assets/mygmt_v2_images/Cashback.svg" alt="cashback">
        <a>{{ $ml.get('wallet.friends.inviteBnt') }} {{ $ml.get('getCashback') }} <span class="add-yellow-label">{{
      $ml.get('cashBack_info') }}</span></a>
      </div>
      <img v-if="getValidCashBackBalances.length" class="cashback-img"
        src="../assets/mygmt_v2_images/CashBack-Balance.svg" alt="cashback">
      <span v-else>
        <img class="arrow-next" src="../assets/mygmt_v2_images/Right-Chevron-blue.svg"
          :class="{ 'arrow-mirror': getLang === 'il' }"  style="fill: blue;"></span>
    </div>
  </div>
</template>
<script>
import { isApp } from "@/services/utils";

export default {
  name: 'CashBackCard',
  components: {},
  props: {},
  data() {
    return {
      isApp: isApp(),
      cashBackCreditData: {}
    }
  },
  created() {
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getValidCashBackBalances() {
      const balances = this.getCashBackBalances()
      const filterRes = balances.filter((balance) => balance.amount > 0)
      return filterRes
    }
  },
  mounted() { },
  methods: {
    getCashBackBalances() {
      return this.$store.getters.getCashBackBalances || []
    }

  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.cashback-img {
  margin-left: 25px;
}

.message-inline {
  font-weight: 700;
}

.message-inline:last-of-type span {
  display: none;
}


.cash-back-card-wrapper {
  padding: 0 15px;

  .cash-back-card-container {
    height: 90px;
    padding: 0 15px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -3px 18px 0px #D2D2D240;

    .img-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      a {
        margin: 0 5px;
        font-size: 16px;
      }

      .add-yellow-label {
        text-decoration: underline #D0C660;
        text-decoration-thickness: 3px;
        font-weight: bold;
        font-size: 16px;
      }

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}

@media only screen and (min-width: $screen-tablet) {
  .cash-back-card-wrapper {
    padding: 0;

    .cash-back-card-container {
      height: 100%;
      margin-top: 0;
    }
  }
}
</style>
