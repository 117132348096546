import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
import {en} from "./data/languages/en";
import {he} from "./data/languages/he";
import {ru} from "./data/languages/ru";
import {th} from "./data/languages/th";
import {cn} from "./data/languages/cn";
import store from './store';

Vue.use(MLInstaller);
export default new MLCreate({
    initial: store.getters.getLang,
    // initial: 'en',
    save: process.env.NODE_ENV === 'production',
    languages: [
        new MLanguage('il').create(he),
        new MLanguage('en').create(en),
        new MLanguage('ru').create(ru),
        new MLanguage('th').create(th),
        new MLanguage('cn').create(cn)
    ]
})