import { render, staticRenderFns } from "./MarketingBanners.vue?vue&type=template&id=315a3970&scoped=true"
import script from "./MarketingBanners.vue?vue&type=script&lang=js"
export * from "./MarketingBanners.vue?vue&type=script&lang=js"
import style0 from "./MarketingBanners.vue?vue&type=style&index=0&id=315a3970&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "315a3970",
  null
  
)

export default component.exports