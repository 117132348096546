<template>
  <div>
    <Header @stepBack="goHome" />
    <div class="main-wrapper">
      <div class="content block block-contacts">
        <div v-if="!loaded" class="loader_page">
          <img src="../../assets/images/loading.gif" />
        </div>
        <div v-else>
          <h1 class="title title-header" v-text="$ml.with('VueJS').get('wallet.friends.contacts')"></h1>
          <div class="list-actions">
            <h2 class="title" style="margin: 5px 10px 5px 10px; visibility: hidden"
              v-text="$ml.get('wallet.friends.title')"></h2>
            <div class="content-top">
              <div class="form-search">
                <input type="text" class="form-control"
                  v-bind:placeholder="$ml.with('VueJS').get('wallet.friends.searchByNameOrPhone')"
                  v-model="searchCondition">
                <i class="icon icon-search"></i>
              </div>
            </div>
            <div class="item-action plus-icon-div" @click="openFriendModal">
              <i class="fas fa-user-plus icon icon-action"></i>
              <div class="friend-send-modal-contacts-list">
                <span> {{ $ml.with('VueJS').get('wallet.friends.addContactByPhone') }} </span>
              </div>
            </div>
            <div v-for="(contact, index) in filteredContacts" :key="index" class="item-action contact-row">
              <div class="friend-send-modal-contacts-list" @click="addFriendAndSend(contact.phone)">
                <div class="icon-and-text">
                  <div class="icon-and-text-inner-div">
                    <i class="fas fa-user icon icon-action" aria-hidden="true"></i>
                    <div class="text">{{ contact.name }}</div>
                  </div>
                  <span> {{ contact.phone }} </span>
                </div>
              </div>
            </div>
          </div>
          <modal ref="friendSendMoneyModal" image="/img/gifs/add-contact.gif" :confirm="$ml.get('bntContinue')"
            @action="modalSubmitHandler" :cancel="$ml.get('btnReturn')">
            <template v-slot:customModalBody>
              <section>
                <div class="form-group friend-send-modal">
                  <h3 v-text="$ml.get('wallet.friends.addFriend')"></h3>
                  <div class="friend-send-modal-input-container">
                    <label for="mobile_phone" class="mobile-number-label"
                      v-text="$ml.get('wallet.friends.mobilePhone')"></label>
                    <input class="form-control" type="tel" id="mobile_phone" name="mobile_phone"
                      placeholder="0599999999" v-model="friendWalletPhone"
                      v-validate="{ required: 'required', regex: [/^05\d([-]{0,1})\d{7}$/] }" />
                    <span class="error">{{ errors.first('mobile_phone') }}</span>
                  </div>
                </div>
              </section>
            </template>
          </modal>
        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />
  </div>
</template>
<script>

import Header from "../../components/Header";
import MainNav from "../../components/MainNav";
import { Validator } from "vee-validate";
import { isApp, paintStatusBar, localesDictionary} from "@/services/utils";
import { addWalletFriend, getCustomerByPhone, walletFriends } from "@/services/api";
import Modal from "@/components/Modal.vue";
import Footer from "@/components/Footer.vue";
import { isMobile } from "../../services/utils";

export default {
  name: 'Contacts',
  components: { Modal, MainNav, Header, Footer },
  data() {
    return {
      loaded: false,
      searchCondition: "",
      friendWalletPhone: "",
      isMobileDevice : isMobile()
    }
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    filteredContacts() {
      return this.$store.getters.getContacts.filter(contact => {
        return contact.name.includes(this.searchCondition) || contact.phone.includes(this.searchCondition)
      })
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
    Validator.localize(this.dictionary());
    Validator.localize(this.getLang);
    if (!isApp()) { this.goHome() }
    this.loaded = true;
  },
  methods: {
    goHome() {
      this.$router.push({ name: localStorage.getItem('homePage') || 'wallet-main' })
    },
    addFriendAndSend(phoneNumber) {
      this.loaded = false;
      getCustomerByPhone(phoneNumber, (response) => {
        if (!response.errorCode) {
          const customer = response.result
          this.friendToAdd = {
            id: customer.id,
            firstName: customer.firstName,
            lastName: customer.lastName,
            phoneNumber: customer.phoneNumber
          }
          addWalletFriend(this.friendToAdd.id, () => {
            walletFriends((result) => {
              this.loaded = true;
              result.friends.forEach(friend => {
                localStorage.setItem('currentWalletBeneficiary', JSON.stringify(friend));
                this.friendToAdd.phoneNumber.replaceAll('-', '') === friend.phoneNumber.replace('+972', '0') ? this.sendToFriend(friend) : null
              })
            });
          });
        } else if (response.errorCode === 'WALLET_FRIEND_NOT_REGISTERED') {
          this.$store.commit('setErrorMessage', this.$ml.with('mobileNumber', phoneNumber).get(response.errorCode));
        }
        this.loaded = true;
        paintStatusBar('092086', 'light')
        $('body').removeClass('stop-scrolling')
      });
    },
    sendToFriend(friend) {
      if (friend.blocked || friend.status === 'DENIED') {
        this.$store.commit('setShowModalError', true);
        this.$store.commit('setErrorCode', 'WALLET_FRIEND_BLOCKED');
      } else {
        localStorage.setItem('currentWalletBeneficiary', JSON.stringify(friend));
        this.$router.push('/wallet/transfer/friend')
      }
    },
    openFriendModal() {
      this.$refs.friendSendMoneyModal.openModal();
    },
    modalSubmitHandler() {
      this.$validator.validate().then(result => {
        if (result) {
          this.addFriendAndSend(this.friendWalletPhone)
        }
      });
    },
    dictionary() {
      const custom = {
        mobile_phone: {
          regex: this.$ml.get("E_REGEX_mobileNumber")
        }
      }
      const messages = {
        required: this.$ml.get("E_REQUIRED"),
        numeric: this.$ml.get("E_NUMERIC"),
        regex: this.$ml.get("E_REGEX"),
      }
      return localesDictionary({ custom: custom, messages: messages });
    }
  },
}

</script>

<style scoped lang="scss">
@import "../../assets/styles/partials/variables";
@import "../../assets/styles/partials/mixins";

.block-contacts {
  background: url(../../assets/images/bg-actions.png) no-repeat;
  background-position: left calc(100% - 30px);
  -webkit-background-size: contain;
  background-size: contain;
  min-height: calc(100vh - 71px);
  padding-top: 0;

  .smallImgs {
    vertical-align: middle;
    display: inline-block;
    height: 21px;
    margin-inline-end: 5px;
  }

  .visaImg {
    padding-bottom: 2px;
    width: 20px;
    padding-top: 2px;
  }

  .groupSmallImg {
    padding-top: 3px;
    width: 18px;
  }

  .paperPlaneImg {
    width: 18px;
    padding-top: 1px;
    padding-bottom: 3px;
  }

  .beneficiarySmallImg {
    width: 14px;
    padding-top: 4px;
    padding-bottom: 5px;
  }

  .content-top {
    height: 69px;
    padding: 10px 0;
    width: 96%;
    margin: auto;
  }

  .wrap {
    padding: 0;
  }

  .form-search {
    max-width: 739px;
    margin: 0 auto;
    position: relative;

    .form-control {
      width: 100%;
      height: 48px;
      font-size: 20px;
      padding: 0 18px 0 18px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 20px;
        color: #808080;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 20px;
        color: #808080;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 20px;
        color: #808080;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 20px;
        color: #808080;
      }
    }

    .icon-search {
      position: absolute;
      top: 17px;
      inset-inline-end: 14px;
    }
  }

  .body {
    max-width: 739px;
    margin: 43px auto 0;
  }

  .nav-tabs {
    margin-top: 22px;

    li {
      display: inline-block;
      vertical-align: middle;

      &+li {
        margin-right: 15px;
      }

      a {
        font-size: 18px;

        &:before {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }

  .list-actions {
    margin-top: 10px;
  }

  .border {
    margin-top: 6px;
    border-top: 0.5px solid rgb(209, 207, 207);
    // border-top: 0.5px solid rgb(6, 26, 136);
    padding-top: 4px;
    min-width: 220px;
  }

  #mobile_phone {
    text-align: center;
    margin: auto;
  }
}


.item-action {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 2px solid #ececec;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  padding: 16px;
  line-height: 22px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 16px;
  @include transition(all .4s ease);

  &:hover {
    background-color: #f2f2f2;
  }

  &.open {
    height: auto;
    overflow: visible;

    .icon-arrow-down-circle {
      background-image: url(../../assets/images/icons/i-arrow-up-circle.png);
    }
  }

  &+.item-action {
    margin-top: 3px;
  }

  .item-title {
    font-size: 20px;
  }

  .text-gray {
    color: #767676;
  }

  .text-blue {

    color: #092086;

  }

  .icon-arrow-down-circle {
    position: absolute;
    top: 10px;
    left: 15px;
  }

  .icon-action {
    width: 30px;
    height: 30px;
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    background-color: #092086;
    border-radius: 50%;
    margin: 8px;
    -webkit-margin-end: 15px;
    margin-inline-end: 15px;
    line-height: 30px;
    cursor: pointer;

    &.bank {
      font-size: 17px;
      line-height: 38px;
    }
  }

  &.action-grey {
    .icon-action {
      background-color: #d8d8d8;
    }
  }
}

.friend-send-modal-contacts-list {
  font-size: large;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  span {
    font-size: large;
  }
}

.contact-row {
  display: flex;
  width: 88%;
  justify-content: space-between;
  margin: auto;
  margin-top: 0 !important;
  border: 0;
  border-bottom: 1px solid #0920863d;
  padding: 0 16px;
  border-radius: 0;
  background-color: #f6f8f900;

  .icon-and-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .text {
      font-size: large;
    }

    .icon-and-text-inner-div {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.plus-icon-div {
  width: 88%;
  margin: auto auto 20px auto;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 0.5px #969696;
  border: 0;

  .fa-user-plus {
    width: 38px !important;
    position: relative;
    inset-inline: -2px;
  }
}

.title-header {
  padding-top: 20px;
  margin-top: 20px;
  font-size: 26px !important;
}

.mobile-number-label {
  width: 240px;
  margin: 10px auto auto auto;
  text-align: start;
}

.title {
  //margin: auto;
}
</style>