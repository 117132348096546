export const he = {
    GMTbranches: '<a href="https://gmtonline.co.il/branches/" style="color:#d4c641;text-decoration: underline;">באחד מסניפי GMT</a>',
    accessibleBranch: 'נגיש',
    accountNumber: 'מספר חשבון המוטב',
    activityTime: 'שעות פעילות:',
    actualRate1: 'שער ה',
    and: 'ו',
    appDownload: 'הורידו את האפליקציה שלנו',
    appDownloadBtn: 'הורדה ישירה לטלפון',
    actualRate2: 'עכשיו באתר הוא',
    addAddress: 'הוסף כתובת',
    additionalPhone: 'מספר טלפון נוסף',
    amount: 'סכום',
    amountIn: 'סכום בש"ח',
    recipientAmount: 'סכום ביעד',
    amountReceived: 'סכום שיתקבל',
    amountTogether: 'סכום ביחד',
    anotherSurname: 'שם משפחה נוסף',
    answerQuestion: ' ובינתיים, אולי התשובה לשאלה שלך נמצאת',
    approvalTransaction: 'נהדר! החיוב בוצע בהצלחה והעברת הכסף אושרה. בדקות הקרובות תישלח אליך הודעת SMS עם אישור העסקה',
    maxCardLoadTransaction: 'מעולה! הכרטיס שלך הוטען בהצלחה ',
    approvedPolicies: 'קראתי ואישרתי את התקנון',
    arrivalBranch: 'מזומן בסניף GMT',
    arrivalBranchMsg1: 'אנו נספק לך עכשיו מספר הזמנה לצורך התשלום, ההזמנה תקפה ל 24 שעות',
    arrivalBranchMsg2: ' והתשלום יהיה בש"ח לפי השער בסניף',
    bankName: 'שם הבנק',
    bank: 'בנק',
    bankAccount: 'חשבון בנק',
    bankCode: 'מספר הבנק',
    cardNumber: 'מספר כרטיס',
    cashioPageTtile: 'הזמנת מט״ח עד אליך במשלוח או איסוף מנתב״ג',
    depositCardNumber: 'מספר כרטיס האשראי של המוטב ',
    beneficiary: {
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        phoneNumber: 'טלפון המוטב',
        country: 'יעד',
        maskedCardNumber: 'מספר כרטיס האשראי של המוטב ',
        maskedCardNumber_unionPay: "מספר כרטיס האשראי של המוטב",
        address: 'כתובת',
        city: "עיר",
        zipCode: "מיקוד",
        province: "מחוז/מדינה",
        bankName: "שם הבנק",
        cashBankName: "נקודת איסוף מזומן",
        walletBankName: "שם הארנק",
        swiftCode: 'קוד הבנק SWIFT/BIC',
        bankCity: 'עיר הבנק',
        bankAddress: 'כתובת הבנק',
        branch: 'מספר סניף',
        iban: 'IBAN',
        accountIdentifier: 'מספר חשבון',
        walletIdentifier: 'מספר ארנק',
        alipayWallet: 'חשבון AliPay',
        abaRoutingNumber: "מספר הבנק ROUTING/ABA",
        ifsc: "IFSC",
        relationshipWithCustomer: "מה הקרבה ביניכם?",
        identification: "מספר זהות",
        upi: "מזהה UPI",
        upi_IND: "מזהה UPI",

    },
    relationshipWithCustomer: {
        SELF: 'לעצמי',
        CHILD: ' בן / בת שלי',
        PARENT: 'הורה שלי',
        SPOUSE: 'בן/בת הזוג שלי',
        SIBLING: 'אח/אחות שלי',
        EX_SPOUSE: 'בן/בת זוג לשעבר שלי'
    },
    beneficiaryRemoveDialogue: 'האם למחוק את {beneficiary} מרשימת המוטבים שלך?',
    beneficiaryRemoveDialogue2: 'המחיקה אינה משפיעה על העסקאות הקיימות',
    billingSuccessful: 'נהדר! החיוב בוצע בהצלחה והעברת הכסף אושרה.',
    billingSuccessfulCardDeposit: 'נהדר! החיוב בוצע בהצלחה והעברת הכסף לכרטיס בחו"ל אושרה.',
    billingSuccessfulBankTransfer: 'נהדר! החיוב בוצע בהצלחה והעברת הכסף לחשבון בחו"ל אושרה.',
    billingSuccessfulBankTransferISR: 'נהדר! החיוב בוצע בהצלחה והעברת הכסף לחשבון אושרה.',
    cardUnBlockModalMessage: {
        1: 'בלחיצה על כפתור "הפעלה" הכרטיס שלך יפתח בחזרה לביצוע עסקאות ומשיכת מזומן',
        2: 'יש לבצע פעולה זו רק אם הכרטיס ברשותך'
    },
    cardBlockModalMessage: {
        1: 'בלחיצה על כפתור "חסימה" אנו נחסום זמנית את הכרטיס לביצוע עסקאות ומשיכת מזומן.',
        2: 'היתרה של הכרטיס שמורה לך!',
        3: 'במידה ומצאת את הכרטיס ניתן להפעילו מחדש או שנוכל לחסום אותו באופן קבוע ולהוציא לך כרטיס חדש'
    },
    blocked: 'חסום',
    blog: ' בלוג ',
    bntClear: 'ביטול',
    bntContinued: 'לשלב הבא',
    bntContinue: 'המשך',
    bntImgDelete: 'צילום מחדש',
    bntSave: 'שמור',
    branchNumber: 'סניף',
    status: 'סטטוס',
    btnAccept: 'אישור',
    checkStatus: 'סטטוס',
    btnCheckStatusCheck: 'מוכנה לתשלום',
    btnCheckStatusNoCheck: 'לא זמין לתשלום',
    btnOk: 'אישור',
    btnDone: 'סיום',
    btnDelete: 'מחיקה',
    btnFinish: 'סיום',
    btnClose: 'סיום',
    btnFinishREgistration: 'לסיום הרשמה',
    btnUploadDocuments: 'הוספת תעודה מזהה',
    btnRegistration: 'יצירת חשבון>',
    btnReturn: 'חזרה',
    btnSend: 'שליחה',
    btnSignIn: 'שלחו לי סיסמה ב SMS',
    btnBiometricSignInFaceId: 'כניסה עם זיהוי פנים',
    btnBiometricSignInTouchId: 'כניסה עם זיהוי ביומטרי',
    btnToMyGmt: 'ל myGMT',
    btnTransfer: 'העברת כסף',
    btnTransferFooter: 'העברת כסף',
    btnEnter: 'כניסה',
    btnSearch: 'חיפוש',
    business: 'העיסוק שלי',
    biometricsHeader: 'הזדהות ביומטרית',
    biometricSectionApprove: 'אישרתי את התנאים',
    biometricSectionSignIn: 'לסיום ההרשמה לזיהוי הביומטרי, בבקשה להתחבר לחשבון',
    biometricToggler: 'הרשמה לזיהוי ביומטרי',
    biometric_modal_text: 'האם ברצונך לבטל את ההתחברות הביומטרית לחשבונך?',
    biometric_legal_clause_1: 'הזיהוי הביומטרי (באמצעות טביעת אצבע ו/או זיהוי פנים) ישמש תחליף להקשת סיסמה בכניסה לכל החשבונות המשויכים למשתמש עמו נכנסת למערכת. ',
    biometric_legal_clause_2: 'החברה אינה שומרת את זיהוי הפנים של המשתמש, ההזדהות מסתמכת על  הזיהוי הביומטרי המוגדר במכשיר הנייד של המשתמש. ',
    biometric_legal_clause_3: 'ההרשמה לשירות הינה בכפוף להצהרת בעל ההרשאה לכניסה לאתר כי הינו המשתמש היחידי במכשיר בעל  הזיהוי הביומטרי. מקום בו מעודכנות במכשיר זיהוי ביומטרי של מספר אנשים אין להירשם לשירות.',
    biometric_legal_clause_4: 'בקשת מידע ו/או ביצוע פעולות שיבוצעו באפליקציה או באתר יחשבו כפעולות שבוצעו ע"י בעל ההרשאה, וזאת לרבות פעולות שבוצעו ע"י צד ג\' תוך הפרת סעיף 3 שלעיל.',
    biometric_legal_clause_5: 'במקרה של אובדן ו/או גניבה של מכשיר הנייד יש לבטל מיידית את הרישום לשירות באתר או בפניה טלפונית לשירות הלקוחות. ',
    biometric_legal_clause_6: 'באפשרותך להתנתק מהשירות בכל עת הן באפליקציה והן באמצעות פניה לשירות הלקוחות. ',
    biometric_login_failed: 'מצטערים, הזיהוי נכשל',
    biometric_public_key_expired:'סליחה על ההפרעה, ביטלנו את האפשרות לכניסה ביומטרית באפליקציה כדי לשמור על חשבונך מאובטח ובטוח. אפשר להרשם ולהפעיל מחדש את הכניסה ביומטרית כעת, תודה!',
    biometric_unregister_modal_title: 'כיבוי זיהוי ביומטרי',
    calculatedBy: 'חישוב לפי {curr}',
    captionAgreement: 'קראתי ואישרתי את ',
    captionAgreementLink: ' התקנון ',
    captionCustomerService1: 'נשמח לעזור לך בשירות לקוחות',
    canceled: 'עסקה מבוטלת',
    chatRepresentative: 'צ\'אט איתנו',
    cashBack: 'myCashBack',
    cashBackReceive: 'לקבל CashBack',
    cashBackLabel: 'הטבת קאשבק',
    cashBackBalance1: 'היי, יש לך קאשבק!',
    cashBackBalance2: 'היתרה שלך היא:',
    cashBackCredit: 'ניצול קאשבק',
    cashBackModalMessage: 'איזה כיף! ',
    cashBackModalMessage2: 'יש לך ',
    cashBackModalMessage3: 'מאיתנו',
    cashBackModalMessage4: 'אפשר להשתמש בהם עכשיו ולשלם פחות',
    cashBackModalMessageConfirm: 'מעולה! אני רוצה לממש כעת',
    cashBackModalMessageCancel: ' אני רוצה להמשיך לצבור',
    cashBackPageTitlePlus: 'יש! צברת כבר',
    cashBackPageTitleZero: 'קבלו כסף חזרה',
    cashBackPageMessagePlus: 'איך ממשיכים לצבור?',
    cashBackPageMessageZero: 'איך מרוויחים?',
    cashBackPageMessage2: 'פשוט שולחים כסף עם GMT ומקבלים כסף בחזרה.',
    cashBackPageMessage3: 'ברגע שהגעת ל',
    cashBackPageMessage4: 'דולר או יורו, ניתן יהיה להשתמש בכסף או להמשיך לצבור',
    cashBackPageFotter: '-הצבירה מתבצעת לפי מטבע העסקה, וניתנת לשימוש באותו מטבע',
    cashBackPageFotter2: '-ניתן להשתמש בכסף באתר או בסניפים (בקרוב)',
    cashBackPageFotter3: ' - אם לא בקרת אותנו מעל לשישה חודשים , חשבון ה CashBack שלך יתרוקן',
    cashBackPageFotter4: 'לתקנון המלא>',
    cardMargketing: {
        title: 'כרטיס אשראי נטען בשיתוף ',
        subtitle: 'שמחים להציע לך את הדרך הנוחה לשליטה בהוצאות ללא צורך בחשבון בנק!',
        info1: {
            title: 'יתרונות הכרטיס:',
            con1: 'רכישות בארץ, בחו"ל ובאינטרנט',
            con2: 'משיכת כסף בארץ ובחו"ל מכספומט'
        },
        info2: {
            title: 'תוכלו תמיד:',
            con1: 'לברר יתרה של הכרטיס',
            con2: 'לעקוב אחר רכישות ועסקאות',
            con3: 'לשחזר קוד סודי',
            con4: 'לחסום כרטיס שאבד/ נגנב'
        },
        footer: {
            1: 'ניתן להנפיק ולטעון את הכרטיס טעינה חוזרת בסניפי GMT',
            2: 'לפרטים נוספים והזמנות'
        },
        btn: 'כתבו לנו בוואצאפ'
    },
    cardActionsModalTitle: 'פעולות לכרטיס',
    cardRoute: 'כרטיס נטען',
    cardHystory: 'עסקאות אחרונות',
    cardBlock: 'חסימת כרטיס',
    cardBlockBtn: 'חסימה',
    cardUnBlockBtn: 'הפעלה',
    cardUnBlock: 'הפעלת כרטיס חסום',
    cardBlockConfirm: {
        1: 'חסמנו עבורך את הכרטיס, אך ניתן להפעילו מחדש שוב באתר.',
        2: 'לכל שאלה אנו זמינים עבורך.',
        3: 'ליצירת קשר'
    },
    walletOrder: {
        deposited: 'הפקדה של',
        verification: 'זהו קוד האימות שלך',
        getVerification: 'לקבלת קוד אימות',
        showTo: 'יש להציג את הקוד בסניף',
        expiry: 'הקוד יפקע בעוד',
        minutes: 'דקות',
        returnHere: 'ניתן תמיד לחזור לעמוד זה',
        mainPage: 'מהעמוד הראשי',
        cancel: 'ביטול ההזמנה הזו',
        hooray: 'היפ היפ הוריי',
        close: 'סגירה',
        depositedToYourWallet: 'הפקדנו ',
        depositedToYourWallet1: 'לחשבון הארנק שלך',
        cancelModalTitle: 'האם ברצונך לבטל הזמנה זו?',
        cancelModalText: 'תמיד ניתן לעשות עוד אחת',
        goToActiveOrder: 'קוד ההפקדה שלך',
        depositCash: 'הפקדת מזומן לארנק',
        btnOk: 'אישור',
        btnReturn: 'חזרה',
        amount: 'סכום',
        summaryOfDetails: 'סיכום פרטי הפקדה',
        transferType: 'סוג'
    },
    wallet: {
        menu: 'הארנק שלי',
        balance: 'הארנק שלי: ',
        payWith: 'אישור ותשלום באמצעות הארנק שלי',
        payment: {
            title: 'הארנק שלי',
            body: 'לאחר לחיצה על כפתור "לתשלום" אנו נבצע את הפעולה עבורך ויתרת חשבונך תתעדכן בהתאם.',
            footer: 'לאחר התשלום, יתרת חשבונך תהיה {currency}{amount}.',
            inActive: {
                footer: "מצטערים, יתרתך נמוכה מסכום העסקה, אפשר לעדכן את סכום  העסקה או לטעון לארנקך סכום נוסף"
            }
        },
        main: {
            title: 'היי {name},',
            card: {
                title: 'יתרת הארנק שלך',
                transactionsLink: 'צפייה בכל העסקאות שלך>'
            },
            depositToWallet: 'הפקדה לארנק באמצעות',
            sendMoney: 'שליחת כסף',
            walletOptions: 'אפשרויות ארנק',
            depositActions: {
                cash: 'הפקדת\nמזומן',
                bank: 'העברה\nבנקאית',
                card: 'כרטיס\nאשראי'
            },
            sendActions: {
                sendHome: 'העברת כסף הביתה ',
                inIsrael: 'העברת כסף בישראל',
            },
            walletActions: {
                loadPrePaid: 'הכרטיס הנטען של myGMT',
                send: ' העברה לחברי myGMT',
                invite: ' להזמין חברים ולקבל CashBack'
            },
            doarInstruction: 'איך מפקידים כסף מהדואר לארנק myGmt'
            // doarInstruction: 'GMT\'s New year big offer!'
        },
        invite: {
            title: 'להזמין חברים ולקבל CashBack',
            body: 'אפשר לקבל אפילו יותר מmyGMT.\nבעזרת הלינק האישי שלך, אפשר להזמין חברים וחברות חדשים לmyGMT.\n אחרי ההעברה הראשונה שלהם, שניכם תקבלו {amount} ש״ח CashBack\n\nניתן לשתף את הלינק האישי שלך בעזרת הכפתור למטה',
            shareActions: {
                copy: 'העתקה',
                copied: 'הועתק',
                whatsapp: 'הזמנה באמצעות WhatsApp',
                wechat: 'הזמנה באמצעות WeChat',
                line: 'הזמנה באמצעות Line',
                general: 'שיתוף'
            },
            inviteMessages: {
                firstLine: 'היי, הצטרפת כבר לmyGMT? עם הלינק האישי שלי המצורף למטה אפשר להרשם',
                secondLine: 'לmyGMT ושנינו נקבל CashBAck! >'
            }
        },
        deposit: {
            cash: {
                title: 'הפקדת מזומן לארנק שלך',
                body: 'ניתן להפקיד מזומן בכל הנקודות של GMT.\nאחרי כמה שניות, הארנק שלך יתעדכן, וניתן יהיה להשתמש ביתרה החדשה שלך ב myGMT',
                locations: 'סניפי GMT>',
                walletOrderExplanation: {
                    depositAnywhere: 'ניתן להפקיד מזומן בכל סניפי GMT',
                    amount: 'בעמוד הבא מקלידים את הסכום להפקדה',
                    verificationCode: 'מקבלים קוד אימות',
                    teller: 'מביאים את המזומן ומציגים את הקוד בסניף.',
                    finishTitle: 'זהו!',
                    finishText: 'יתרת חשבון הארנק  תזוכה במיידית'
                }
            },
            bank: {
                title: 'העברה מחשבון הבנק או הדואר לארנק שלך',
                body: 'ניתן לטעון את הארנק שלך באמצעות העברה ישירה לחשבון חברת GMT. \nגם המעסיק שלך יכול או יכולה להעביר כסף לארנק ישירות מחשבון הבנק שלהם.',
                transferDetails: 'פרטי החשבון:\nג׳י.אם.טי טק אינוביישן בע״מ\nבנק הפועלים 12\nסניף 613\nחשבון 545690',
                uploadDetails: 'ניתן להעלות אישור העברה כאן,\nלא לשכוח לציין את מספר הפספורט והטלפון שלך',
                uploadConfirmationBtn: 'העלאת אישור',
                placeholder: 'הוספת אישור העברה בנקאית',
                upload: {
                    title: 'העלאת אישור העברה בנקאית',
                    loader: 'אנו מעלים את התמונה שהוספת',
                    footer: 'לא לשכוח לציין את מספר הפספורט והטלפון שלך',
                    success: 'נהדר! הצוות שלנו מטפל בזה ויתרת הארנק שלך תתעדכן בהקדם\nאנחנו נשלח לך אישור גם בהודעת טקסט ובמייל'
                }
            }
        },
        friends: {
            title: 'העברת כסף לחברי myGMT',
            addFriend: 'הוספת מספר ארנק',
            newFriend: 'העברה לארנק myGMT',
            addContactByPhone: 'העברה למספר שלא באנשי הקשר',
            searchByNameOrPhone: 'חיפוש לפי שם או מספר',
            header: 'myGMT מאפשר לך להעביר כספים לכל חברייך. \n השרות שלנו מהיר ופשוט!',
            contacts: 'בחירה מתוך אנשי קשר',
            mobilePhone: 'מספר נייד',
            bulletsOne: 'צריך לבחור איש קשר',
            bulletsTwo: 'להקליד את סכום ההעברה',
            bulletsThree: 'לאשר',
            outro: 'זהו! היתרות הארנק שלך ושל חברך יתעדכנו באופן מיידי. \n רוצה להעביר כסף בקלות עכשיו?',
            inviteBnt: 'הזמנת חברים ל myGMT',
            list: {
                title: 'רשימת חברים'
            },
            addModal: {
                title: 'האם ברצונך להוסיף',
                footer: 'לרשימת החברים שלך?',
                saveBtn: 'שמירה',
                closeBtn: 'ביטול'
            },
            friendCard: {
                title: 'ארנק',
                delete: 'מחיקה',
                deleteDialogFirstLine: 'האם ברצונך למחוק את {beneficiary} מרשימת חברה הארנק שלך?',
                deleteDialogSecondLine: 'לפעולה זו לא תהיה השפעה על עסקאות קיימות',
                send: 'שליחה לארנק'
            }
        },
        transfer: {
            amount: {
                title: 'שליחת כסף לארנק myGMT ל\n {name}'
            },
            send: {
                title: 'שליחת {amount} ש״ח לארנק myGMT'
            },
            summary: {
                title: 'שליחת {amount} ש״ח לארנק myGMT'
            },
            pay: {
                title: 'שליחת {amount} ש״ח לארנק myGMT'
            },
        },
        load: {
            amount: {
                title: 'הפקדה לארנק ותשלום באשראי'
            },
            send: {
                title: 'הפקדה לארנק שלך'
            },
            summary: {
                title: 'הפקדה לארנק שלך'
            },
            pay: {
                title: 'הפקדה לארנק שלך'
            },
        },
        card: {
            amount: {
                title: 'טעינת כרטיס ותשלום מהארנק'
            },
            send: {
                title: 'טעינת כרטיס ותשלום מהארנק'
            },
            summary: {
                title: 'טעינת כרטיס myGMT'
            },
            pay: {
                title: 'טעינת כרטיס myGMT'
            }
        },
    },
    pay: 'לתשלום',
    loadCard: 'טעינה ותשלום מהארנק',
    cardUnBlockConfirm: 'הכרטיס שלך עכשיו פעיל',
    cardMerchantAddress: 'כתובת',
    cardMerchantPhone: 'טלפון',
    cardPresented: 'כרטיס הוצג',
    changeAmount: 'רוצה לשנות את הסכום?',
    changePayoutAmount: 'רוצה לשנות את הסכום ביעד?',
    changeAmountLabel: 'סכום חדש ב {amount}',
    changeAmounBtn: 'חישוב',
    city: 'עיר',
    commission: 'עמלה',
    confirmMarketingContent: 'אני רוצה לקבל תכנים שיווקים ועדכונים',
    confirmCreditNumber: 'חשוב מאוד!  לבדוק את מספר הכרטיס. לא ניתן למשוך חזרה כספים שהועברו לכרטיס בחו"ל.',
    confirmBankAccount: 'חשוב מאוד! יש לבדוק את פרטי החשבון. לא ניתן למשוך חזרה כספים שהופקדו לחשבון בחו"ל.',
    confirmBankAccount2: 'חשבון המוטב יזוכה בדר"כ תוך 2-4 ימי עסקים.',
    contactBtnCaption: 'שירות לקוחות >',
    contactMenuCaption1: 'טלגרם',
    contactMenuCaption2: 'מסנג\'ר',
    contactMenuCaption3: 'להתקשר',
    contactMenuCaption4: 'ווצאפ',
    country: 'ארץ המוצא',
    countryBirth: 'ארץ הלידה',
    countryDestination: 'מדינת יעד',
    countryFrom: 'מדינת המוצא',
    correspondentReference: 'קוד',
    cookies: ' באתר זה נעשה שימוש בקבצי Cookies. המשך הגלישה באתר מהווה הסכמה לשימוש זה. למידע נוסף ניתן לעיין <a style="text-decoration:underline;display: inline;" href="https://gmtonline.co.il/privacy/" target="_blank">במדיניות הפרטיות. </a>',
    payingReference: 'מספר הזמנה',
    creditCard: 'כרטיס אשראי',
    crediting: 'זיכוי',
    creditCardOwner: 'שם על הכרטיס',
    creditCardSupport: 'אנו תומכים ב',
    creditCardCommissionMsg: 'יתכן וחברת האשראי שלך תגבה עמלת המרה',
    creditCardSecurityMsg: 'אנו לא שומרים את פרטי הכרטיס שלך, הם רק מועברים לחברת האשראי לבדיקה ואימות',
    cardDepositAccepted: 'השרות נתמך רק בכרטיסים של חברות Visa / Mastercards',
    Contact: 'קונטקט',
    currency: 'מטבע',
    currencyDestination: 'מטבע ביעד',
    currencyILS: 'ש"ח',
    currencyUSD: 'דולר',
    currencyEUR: 'יורו',
    currencyCardMsg: 'הסכום הסופי שיתקבל תלוי בסוג ומטבע הכרטיס של המוטב',
    currencyBankMsg: 'הסכום הסופי שיתקבל תלוי בסוג ומטבע החשבון של המוטב',
    clone: 'העברה נוספת',
    dateBirth: 'תאריך הלידה',
    daysWork: 'א-ה',
    daysWork2: 'א\'-ה\'  08:30-19:00, ו׳ 08:30-14:00',
    daysWork3: 'ו׳',
    destination: 'יעד',
    discount: 'הנחה',
    docsTitle: 'בבקשה לצלם תעודה מזהה בתוקף',
    docsTitleUploaded: 'תודה, כמעט סיימנו!',
    documentsUploading1: 'אנו שומרים את התמונה שצרפת',
    documentsUploading2: 'זה יקח עוד כמה שניות',
    documentsUploading3: 'ואז אפשר להמשיך',
    editUserProfile: 'הפרטים שלי',
    email: 'כתובת מייל',
    errorCode: 'הקוד לא תקין, נסה שוב',
    estimated: 'משוער',
    calculatedAbroad: 'יחושב בחו"ל',
    exchangeRate: 'שער המרה',
    extraDetails: 'פרטים נוספים',
    byExchangeRate: 'לפי שער המרה של',
    expired: 'תוקף הסתיים',
    fax: 'פקס',
    for: 'עבור',
    finished: 'סיים',
    foreignPassport: 'יש לי דרכון זר',
    to: 'ל',
    foreignCurrencyHandlingFee: 'עמלת טיפול במט"ח',
    freeSearch: 'חיפוש חופשי...',
    friday: 'ו׳',
    fullName: 'שם מלא',
    fun: 'איזה כיף. מבטיחים לעדכן רק במה שמעניין',
    gender: 'מין',
    here: 'כאן ',
    hours: 'לתשומת ליבך! יש לבצע תשלום עד 48 שעות',
    houseNumber: 'מספר בית',
    welcome: 'שלום',
    interested: 'ובינתיים, אולי הבלוג שלנו מעניין אותך?',
    interest: 'כולל ריבית {inter}',
    israel: 'ישראל',
    imprtant: 'Important',
    israeliID: 'יש לי תעודת זהות ישראלית',
    israeliResident: 'תושבי ישראל',
    nonIsraeliResident: 'תושב זר',
    installments: 'תשלומים',
    inProcess: 'בתהליך אישור',
    itemNavMain: 'My Wallet',
    itemNav0: 'myCashBack',
    itemNav1: 'העברת כספים',
    itemNav10: 'סניפים',
    itemNav2: 'הפעולות שלי',
    itemNav3: 'שערים ועמלות',
    itemNav4: 'המוטבים שלי',
    itemNav5: 'סניפי GMT ',
    itemNav6: 'שאלות ותשובות',
    itemNav7: 'צרו קשר',
    itemNav8: 'תקנון',
    itemNav9: 'יציאה',
    itemNav13: 'הזמנת מט"ח',
    itemNav11: 'מידע נוסף',
    itemNav12: 'כרטיס נטען',
    itemNavUserProfile: 'הגדרות והפרטים שלי',
    labelEmail: 'כתובת המייל',
    labelMsg: 'מה יש לי לומר?',
    labelName: 'שמי הפרטי באנגלית',
    labelPhone: 'מספר הטלפון',
    labelSurname: 'שם משפחתי באנגלית',
    lblDone: 'העברה נשלחת כמתנה או תמיכה לקרוב משפחה מדרגה ראשונה, והיא אינה מהווה הכנסה אצל המוטב',
    lblDoneWechat: 'יש לשים לב, בהעברה הראשונה, מקבל הכספים יצטרך לאשר את העסקה ב-WeChat. חשוב לידע אותם על הדרישה זו.',
    lblDoneWechat1: 'Please add 86 before your Chinese mobile number linked with WeChat, or 972 before your Israeli mobile number.',
    leaveMsg: 'מה יש לי לומר?',
    linkToLoginPage: 'נרשמת כבר? לעמוד כניסה>',
    listBranches: 'לרשימת הסניפים שלנו',
    locatingBranches: 'חיפוש סניפים',
    locationBtn: 'פתוח',
    locationBtnClose: 'סגור',
    loginLabel1: 'תעודת הזהות / הדרכון',
    loginLabel2: 'מספר הנייד',
    logInConsent: 'עם כניסתי לחשבון, אשרתי את ה',
    mailingAddress: 'כתובת למשלוח דואר',
    mailingRecipient: 'ראול ולנברג 18C תל אביב, 6971915',
    marked: 'מסומן',
    maybe: 'ובינתיים, אולי הבלוג שלנו מעניין אותך?',
    messageCode: ' שלחנו לך עכשיו הודעת טקסט עם קוד. יש להכניס את הקוד בשדה למטה',
    messageSend: 'בדקות הקרובות נשלח אליך גם הודעת SMS ומייל עם אישור העסקה',
    messageNotSent: 'אם לא קבלת את הססמה ב SMS כדאי לבדוק את תיבת ההודעות טקסט ואת תיבת הספאם שלך, נשמח לעזור בכל שאלה',
    messageRegistration1: 'היי, שמחים שחזרת!',
    messageRegistration2: 'נשאר לך רק להעלות תעודה מזהה, לאשר את התקנון וסיימנו',
    messageUploadDocuments: 'נראה שהתעודה המזהה שלך כבר לא בתוקף, אפשר להעלות תעודה חדשה בעמוד הבא',
    middleName: 'שם אמצעי',
    mobilePhone: 'מספר הנייד',
    mobilePhoneNumber: 'מספר נייד ישראלי',
    myCard: 'הכרטיס שלי',
    myDateBirthday: ' (שנה-חודש-יום) ',
    monday: 'ב׳',
    moneyApproved: ' ! העברת הכסף אושרה',
    MoneyGram: 'MoneyGram',
    moreOptions: 'עוד אפשרויות',
    msgError: 'יש להשתמש במספרים בלבד',
    maxAccessionCommission: 'עמלת הצטרפות',
    myId: 'תעודת הזהות שלי',
    nameBeneficiary: 'שם המוטב',
    newBeneficiary: 'מוטב חדש',
    new: 'חדש',
    newCustomer_title: 'העברת כספים לחו"ל ושרותים פיננסיים מתקדמים',
    newCustomer_section: 'הבחירה הנכונה לכסף שלך',
    cashBack_info: 'CashBack',
    quick_guide: 'מדריך קצר עבורך',
    invite_friends: 'להזמין חברים ל myGMT',
    cash_back_desc: 'CashBack',
    send_money_home: 'העברת כסף הביתה',
    send_money_worldwide: 'העברת כסף לחו״ל',
    deposit_to_wallet: 'הפקדה לארנק',
    quickActions: 'פעולות מהירות',
    quickSend: 'שליחה מהירה',
    quickSendActionsParagraph: 'המוטבים האחרונים שלך כאן ומוכנים לשליחה מהירה שוב',
    quickActionsAdd: 'הוספה',
    payAnywhere: 'כרטיס ניטען מבית MAX',
    carousel_slide_one_text: 'הכרטיס הניטען שלנו יעזור לך לשלוט בכסף שלך אפילו טוב יותר',
    carousel_slide_one_button: 'למידע נוסף',
    seeAll: 'הכל',
    cardBalance: 'יתרת כרטיס',
    getCashback: 'ולקבל',
    home: 'בית',
    menu: 'תפריט',
    activity: 'פעילות',
    cards: 'כרטיסים',
    greeting_morning: 'בוקר טוב',
    greeting_afternoon: 'צהריים טובים',
    greeting_evening: 'ערב טוב',
    greeting_night: 'לילה טוב',
    paidBy: 'שולם באמצעות: ',
    incoming: 'קבלת',
    outgoing: 'שילמת',
    CONVERSION: 'המרת מט״ח',
    PAYMENT: 'תשלום',
    CASH_BACK: 'CashBack',
    cardActions: 'פעולות לכרטיס',
    actions: 'פעולות',
    activateCard: 'הפעלת כרטיס חדש',
    activateCardAction: 'הפעלת הכרטיס',
    enterLastDigits: 'בבקשה להקליד 4 ספרות אחרונות של הכרטיס שלך',
    searchTransactions: 'חיפוש עסקאות',
    filterTransactions: 'פילטור עסקאות',
    filterTransactionsButton: 'פילטר',
    openWallet: 'פתיחת חשבון ארנק בחינם',
    pleaseSelect: 'אפשר לבחור אחד או יותר',
    date: 'תאריך',
    from_date: 'מתאריך',
    to_date: 'עד תאריך',
    choose_date: 'בחירת תאריך',
    showResults: 'תצוגת תוצאות',
    clearFilter: 'חזרה לכל התוצאות',
    today: 'היום',
    contact_us_paragraph: 'אנחנו מאמינים בתקשורת טובה, אז הרגישו חופשי לפנות אלינו בכל הדרכים הרשומות.\n' + 'שעות הפעילות שלנו:\n' + 'א׳-ה 08:30-17:00, ו׳ 08:30-14:00',
    call_us: 'שיחה טלפונית',
    contact_us_or: 'או',
    lastTransactions: 'עסקאות אחרונות לכרטיס',
    transactionClasses: {
        BANK_TRANSFER: 'העברה לחשבון בנק',
        CASH_TRANSFER: 'העברה לאיסוף מזומן',
        CARD_DEPOSIT: 'העברה לחשבון כרטיס',
        PAYMENT: 'העברה נכנסת',
        CONVERSION: 'המרת מט״ח',
        CASH_BACK: 'קאשבק',
        WALLET_DEPOSIT: 'הפקדה לחשבון הארנק שלך',
        WALLET_TRANSFER: 'העברה לארנק דיגיטלי',
        WALLET_TO_WALLET: 'העברה לארנק myGMT',
        WALLET_FROM_WALLET: 'העברה נכנסת לארנק myGMT'
    },
    paymentTypes: {
        CASH: 'מזומן',
        CHECK: 'שיק',
        BANK_TRANSFER: 'העברה בנקאית',
        CREDIT_CARD: 'כרטיס',
        CREDITTING: 'אשראי',
        OUTER_CONVERSION: 'מזומן',
        CASHBACK: 'קאשבק',
        CUSTOMER_DEPOSIT: 'יתרה מצד ג׳',
        WALLET: 'חשבון ארנק',
        CHANGE: 'עודף',
        AIRPORT: 'נתב״ג',
    },
    CARD: 'כרטיס',
    CASH: "מזומן",
    AIRPORT: 'נתב״ג',
    CHECK: "שיק",
    CHANGE: 'עודף',
    CREDITTING: "אשראי",
    OUTER_CONVERSION: "מזומן",
    CASHBACK: "קאשבק",
    CUSTOMER_DEPOSIT: "יתרה מצד ג׳",
    WALLET: "חשבון ארנק",
    changeLang: "שינוי שפה",
    nothing_found: 'לא נימצאו עסקאות',
    nothing_found_text: 'ברגע שיהיו לך פעולות חדשות, הן יופיעו כאן',
    max_transaction_description: 'תיאור העסקה',
    creditCardReferenceNumber: 'אסמכתה של חברת האשראי ',
    checkNumber: 'מספר שיק',
    referenceNumber: 'אסמכתה',
    flightDate: 'תאריך טיסה',
    updateBeneficiary: 'פרטי מוטב',
    nextLevel: 'לשלב הבא',
    no: 'לא',
    noBeneficiaries: 'לא הוגדרו מוטבים במערכת',
    noRecentActions: 'ברוכים הבאים לאיזור האישי ב myGMT',
    noRecentActions1: 'כאן תראו את ההיסטוריה שלכם עם GMT',
    noRecentActions2: ' בואו נתחיל!',
    noCardRecentActions: 'לא נמצאו עסקאות בכרטיס',
    notAvailable: 'לא זמין כעת',
    notRequired: 'שדה רשות',
    numberPassport: 'מספר הדרכון',
    numberOfTransaction: 'מספר קבלה',
    openNow: 'פתוח',
    paymentFrom: 'תשלום מ',
    payUsing: 'אני אשלם באמצעות:',
    toPay: 'לתשלום',
    payRate: 'לפי שער {rate}',
    paymentCredit: 'כרטיס אשראי',
    photoID: 'תז, רישיון נהיגה ישראלי או דרכון',
    photoRequired: 'יש להעלות תמונה',
    pinCode: 'צפייה בקוד סודי',
    pinCodeModalMessage: 'רוצה לצפות בקוד הסודי של הכרטיס המסתיים ב {digits}',
    pinCodeModalMessage2: 'שלחנו לך כעת הודעת טקסט לטלפון הנייד עם לינק לצפייה בקוד הסודי',
    placeholderDigit: 'מספר הזהות בן 9 ספרות',
    questionsAnswers: 'שאלות ותשובות',
    questionsAnswersSubtitle: 'ריכזנו בשבילכם שאלות וכמובן תשובות הקשורות להעברות כספים ולשאר השרותים הפיננסים שלנו.לא מוצאים תשובה לשאלתכם? אתם כמובן מוזמנים לפנות אלינו בכל אחת מהדרכים המפורטות בעמוד',
    rate: 'שער',
    reasonTransfer: 'סיבת העברה',
    recentActions: 'הפעולות שלי',
    recentActionsTab1: 'מוטב חדש',
    recentActionsTab2: 'שירות הזמנת מט"ח לנתב"ג',
    recentActionsTab3: 'הטענת כרטיס',
    recentActionsTab4: 'קבלת כסף',
    recentActionsTab5: 'רכישת ביטקויין',
    recentActionsBeneficiaries: 'המוטבים שלי',
    recentActionsTransfer: 'העברת כספים',
    recommended: 'אנו ממליצים!',
    r_step_address: 'כתובת',
    r_step_doc: 'תעודה',
    r_step_name: 'שם ועיסוק',
    registerSystem: 'הרשמה ל myGMT',
    registerSystemAddDocuments: 'העלאת תעודה מזהה',
    registerText1: 'בעוד רגע תוכלו לשלוח כסף בקלות ובמהירות',
    registerText2: 'הצטיידו בתעודה מזהה, אנו נבקש לצלם אותה, בואו נתחיל!',
    registerFooter: 'GMT פועלת תחת רישיון נותן שירותים פיננסיים מורחב ',
    registerFooter2: 'של משרד האוצר - מספר רישוי 57479',
    registerFooter3: 'האתר שלנו מוצפן ומאובטח ואנו לעולם לא נשתף את הפרטים שלך עם אף אחד.',
    registrationSuccess: 'ההרשמה בוצעה בהצלחה!',
    registrationSuccess1: 'תודה! קיבלנו את כל הפרטים',
    registrationSuccess2: 'ב myGMT ניתן לשלוח כסף לחו"ל בקלות ובמהירות',
    requestSuccessfully: 'בקשתך נקלטה בהצלחה ',
    requestSuccessfully1: 'מספר ההזמנה: ',
    requestSuccessfully2: 'מה עכשיו? יש לשלם עבור ההעברה תוך 24 שעות',
    requestSuccessfully3: '<a href="https://gmtonline.co.il/branches/" style="color:#d4c641;text-decoration: underline;">באחד מסניפי GMT</a>',
    requestSuccessfully4: 'ולאחר מכן היא תשלח לחו"ל',
    riskyLimitMsg: 'לסכומים גבוהים יותר, אפשר ליצור איתנו קשר.',
    russianSpeaker: 'שרות ברוסית',
    saturday: 'ש׳',
    searchResult: 'נמצאו X סניפים ב XXX ',
    selectBeneficiary: 'המוטבים שלי',
    selectCity: 'חיפוש לפי עיר',
    selectCountry: 'ארץ מוצא',
    selectProvince: 'בחר מדינה',
    sendMarketingContentLbl: 'שלחו לי הצעות מיוחדות ועדכונים',
    qrCodeDownload: "Download QR",
    scanExplanation: "Scan this QR code find us on WeChat",
    sentOn: 'תאריך',
    signedUp: 'נרשמת!',
    soonPossible: 'נחזור בשעות הפעילות',
    staging: {
        payWith: 'מזומן בסניף GMT',
        body: 'לאחר לחיצה על כפתור "הזמנה", אנו נכין את ההעברה לחו"ל והיא תבוצע לאחר התשלום בסניף לבחירתך.',
        order: 'הזמנה'
    },
    streetName: 'רחוב',
    streetName_HouseNumber: 'שם רחוב ומספר בית',
    subscribeDesc: 'כל מה שחדש ומעניין בתחום הפינטק- ממש כאן בבלוג שלנו ובהרשמה לניוזלטר שלנו',
    subscribeNewsletter: 'הרשמה לניוזלטר הרשמה לניוזלטר - כדאי!',
    subtitleCustomerService: 'נשמח לעזור לך בשירות לקוחות',
    summaryTransfer: 'סיכום פרטי העברה:',
    sunday: 'א׳',
    surtitleFormLang: 'מה השפה המתאימה לך?',
    smartBannerTitle: 'שדרגו לאפליקציית myGMT',
    smartBannerText: 'קל. מהיר. בטוח. בדיוק בשבילך',
    smartBannerBtnText: 'הורדה',
    system: 'באמצעות מערכת',
    correspondentSystem: 'בסניפי',
    termsUse: 'תקנון',
    termsTitle: 'יש לקרוא ולאשר את התקנון ומדיניות הפרטיות',
    tpinWarning: 'To ensure your transaction is successful, please verify that your beneficiary’s TPIN and mobile wallet number are registered under the same ID (NRC or Passport). For TPIN registration or verification, visit a Zambia Revenue Authority office or go to zra.org.zm.',
    thanks: 'תודה שפנית אלינו!',
    thursday: 'ה׳',
    titleFormLang: 'שמחים שבאת',
    titleLoginPage: 'כניסה לחשבון',
    toBankAccount: 'לחשבון',
    toWalletAccount: 'לארנק',
    pickupPoint: 'נקודת איסוף',
    toCreditCard: 'לכרטיס',
    totalNIS: 'סה”כ לתשלום בשקלים',
    totalTransfer: 'סה״כ לתשלום',
    total: ' סה״כ',
    t_step_amount: 'סכום',
    t_step_transfer: 'שליחה',
    t_step_summary: 'סיכום',
    t_step_pay: 'תשלום',
    transferAmount: 'סכום העברה',
    transactionAmount: 'סכום העסקה',
    transactionCharge: 'מיון לפי',
    filterPaymentType: 'סוג התשלום',
    transactionType: 'סוג העסקאות',
    transferFundsTo: 'העברת',
    transferOptions: 'אפשרויות העברה',
    transferType: 'סוג העברה',
    transferTypePick: 'נא לבחור סוג העברה',
    tuesday: 'ג׳',
    waitingPayment: ' הזמנה לתשלום:',
    wednesday: 'ד׳',
    yourCardBalance: 'יתרה בכרטיס',
    zipCode: 'מיקוד',
    loginAgreementRequired: 'יש לאשר את קריאת התקנון',
    customerCommission: 'עמלתך',
    customerCommissionMaxTopup: 'עמלת טעינה',
    customerEmailNotification: {
        title: 'עדכון כתובת המייל שלך',
        body: 'נראה שאין לנו את המייל שלך,\nכדאי בשבילך לעדכן אותו בשדה למטה כך נוכל לשלוח לך העתק של כל פעולה גם למייל\nאנחנו לעולם לא נשלח ספאם',
        cancel: 'חזרה'
    },
    sumAmount: 'סכום כולל',
    terms: 'תקנון',
    error: 'שגיאה',
    generalErrorMsg: 'מצטערים, יצאנו לשדרוג קצר כדי להשתפר, אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    connectionError: 'אנא נסו מאוחר יותר שגיאה',
    locationLink: 'branches/',
    faqLink: 'שאלות-ותשובות/',
    contactUsLink: 'צור-קשר/',
    protocolLink: 'protocol/',
    privacyLink: 'privacy/',
    privacyPolicy: 'מדיניות הפרטיות',
    profileDelete: 'מחיקת הפרופיל שלך',
    profileDeleteModalTitle: "ברצונך למחוק את הפרופיל?",
    profileDeleteModalText: 'בלחיצה על כפתור האישור, \n אנו נקבל הודעה ונחזור אלייך בהקדם',
    aboutLink: 'my-gmt/',
    paymentProcessing: 'בבקשה להמתין עד שנסיים לטפל בבקשה שלך',
    paymentType: 'אופן תשלום',
    paymentTypeCredit: 'תשלום בכרטיס אשראי',
    paymentTypeByCredit: 'שולם באשראי ',
    paymentTypeCash: 'מזומן',
    uploadDocumentMsg1: 'אנו נדרשים לאמת את הזהות שלך, לכן אנו מבקשים להעלות תעודה מזהה.',
    uploadDocumentMsg2: 'הפרטיות שלך חשובה לנו מאוד, האתר שלנו מוצפן ומאובטח ואנו לעולם לא נשתף את הפרטים שלך עם אף אחד.',
    surveyModal: {
        active: true,
        title: 'מה המחשבות שלך: הכנו עבורך סקר אנונימי קצר',
        body: 'היי {name}, נשמח לשמוע ממך ולקבל ממך עזרה כיצד נוכל לשפר את השרותים הפיננסים שלנו בעזרת מענה על סקר קצר ואנונימי. הדיעה שלך חשובה. אפשר להתחיל עכשיו.',
        confirm: 'להתחיל את הסקר עכשיו',
        cancel: 'אח״כ',
        link: 'https://forms.gle/dCH3X8vWdNRUx1y27'
    },
    termsUpdate: {
        title: 'תקנון ומדיניות הפרטיות שלנו',
        body: {
            first: 'שלום, לידיעתך עדכנו את התקנון ומדיניות הפרטיות שלנו.',
            second: 'ניתן לקרוא אותם כאן>'
        }
    },
    version: 'v {num}',
    yes: 'כן',
    zipCodePlaceholder: '7 ספרות',
    zipCodeRequiredFields: 'בבקשה למלא את פרטי הכתובת שלך, כדי שנוכל לחפש את המיקוד המתאים',
    zipCodeNotFound: 'לא מצאנו מיקוד לכתובת שהזנת, כדאי לבדוק שוב את הכתובת או למלא ידנית מיקוד בן 7 ספרות',
    CORRESPONDENT_BLOCKED: 'מצטערים, השרות שבחרת אינו פעיל זמנית, אנחנו ממליצים לבחור שרות אחר או לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    CARD_DEPOSIT: 'זיכוי כרטיס בחו"ל',
    CARD_DEPOSIT_TO: 'הפקדה לכרטיס ב{dest}',
    CARD_DEPOSIT_MAX: 'טעינת כרטיס נטען',
    CASH_TRANSFER: 'איסוף מזומן בחו"ל',
    CASH_TRANSFER_INLAND: 'איסוף מזומן',
    CASH_TRANSFER_ISR: 'איסוף מזומן',
    CASH_TRANSFER_TO: 'איסוף מזומן ב{dest}',
    CREDIT_CARD: 'כרטיס',
    BANK_TRANSFER: 'זיכוי חשבון בנק בחו"ל',
    UPI_BANK_TRANSFER: 'זיכוי חשבון בנק בחו״ל באמצעות מזהה UPI',
    BANK_TRANSFER_ISR: 'זיכוי חשבון בנק',
    BANK_TRANSFER_TO: 'זיכוי חשבון בנק ב{dest}',
    BANK_TRANSFER_PAYMENT: 'העברה בנקאית',
    WALLET_TRANSFER: 'הפקדה לארנק בחו"ל',
    WALLET_TRANSFER_TO: 'הפקדה לארנק ב{dest}',
    WALLET_TO_WALLET: 'העברה לארנק myGMT',
    WALLET_FROM_WALLET: 'קבלת כסף מארנק myGMT',
    WALLET_DEPOSIT: 'הפקדה לארנק myGMT',
    NEW_CUSTOMER: 'היי, ייקח לנו עד 24 שעות להשלים את הרשמתך. בינתיים, האפשרות לשלם באשראי באתר סגורה, נעדכן אותך ברגע שסיימנו',
    CUSTOMER_NOT_AUTHORIZED: 'מצטערים, אפשרות התשלום באשראי סגורה בפרופיל שלך, נשמח לעזור בכל שאלה',
    CUSTOMER_NOT_SIGNED: 'מצטערים, לא סיימת את תהליך ההרשמה ולכן אפשרות התשלום באשראי עדיין סגורה בפרופיל שלך. נשמח לעזור בכל שאלה',
    CREDIT_LIMIT: 'אפשר להעביר החודש עד ',
    CREDIT_LIMIT_MONTHLY: 'אפשר להעביר החודש עד {amount}',
    CREDIT_LIMIT_CREDIT_CARD: 'סכום העסקה עובר את המקסימום החודשי שלנו בכרטיס אשראי. נישאר לך לשימוש עד {amount}',
    CREDIT_LIMIT_WALLET: 'סכום העסקה עובר את המקסימום החודשי שלנו בארנק. נישאר לך לשימוש עד {amount}',
    CREDIT_LIMIT_UNREGISTERED: 'אפשר להעביר בחודש עד ',
    CREDIT_LIMIT_RISKY: 'אפשר להעביר ל{country}‎ עד',
    TRANSACTION_LIMIT: 'מצטערים, העברה זו מוגבלת עד לסכום של {currency}{amount} לפעולה אחת. נשמח לעזור בכל שאלה',
    CREDIT_CARD_ILS_AMOUNT: 'התשלום באשראי יהיה זמין מייד',
    CREDIT_NOT_ACTIVE: 'מצטערים, אפשרות התשלום באשראי סגורה כעת, נחזור בשעות הפעילות בין 06:00-24:00. ',
    CREDIT_BLOCKED: 'מצטערים, אפשרות התשלום באשראי סגורה כעת עקב שדרוג, אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    API_EXCEPTION: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    GRAILS_VALIDATION_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    CAPTCHA_NOT_VERIFIED: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    CUSTOMER_SESSION_NOT_FOUND: 'פרטי הזיהוי שגויים או שעוד לא נרשמת ל myGMT, אפשר להרשם כאן> <a href="https://mygmt.gmtonline.co.il/#/registration" style="color:#d4c641;text-decoration: underline;">mygmt.gmtonline.co.il</a>',
    CUSTOMER_BLOCKED: 'מצטערים, הפרופיל שלך סגור, בבקשה לפנות > <a href="https://gmtonline.co.il/צור-קשר/" style="color:#d4c641;text-decoration: underline;">לשרות לקוחות </a>',
    SMS_WRONG: 'קוד האימות שהזנת שגוי',
    SMS_EXPIRED: 'מצטערים, קוד האימות כבר לא תקף, אפשר לשלוח שוב',
    REGISTRATION_TOKEN_NOT_FOUND: 'קוד האימות שהזנת שגוי',
    REGISTRATION_TOKEN_EXPIRED: 'מצטערים, קוד האימות כבר לא תקף, אפשר לשלוח שוב',
    SESSION_EXPIRED: 'חזרת! חשבנו שהלכת אז יצאנו מהחשבון כדי לשמור עליו. אפשר להתחיל שוב כרגיל',
    CUSTOMER_MOBILE_NOT_MATCH: 'מצטערים, על מנת להרשם לmyGMT עליך לפנות <a href="https://gmtonline.co.il/צור-קשר/" style="color:#d4c641;text-decoration: underline;">לשרות לקוחות </a>',
    CUSTOMER_ALREADY_REGISTERED: 'היי, אנחנו חושבים שכבר נרשמת לmyGMT,  אפשר להכנס כאן> <a href="https://mygmt.gmtonline.co.il" style="color:#d4c641;text-decoration: underline;">mygmt.gmtonline.co.il</a>',
    WALLET_FRIEND_NOT_UNIQUE: 'מצטערים, לא ניתן לצרף מספר זה לרשימת החברים שלך. נשמח לעזור בכל שאלה, ניתן לפנות אלינו באמצעות הכפתור ״צרו קשר״ למעלה',
    CUSTOMER_DENIED: 'היי, שמחים שחזרת! לא סיימת את ההרשמה בפעם הקודמת, נשמח לעזור לך',
    BENEFICIARY_NOT_FOUND: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    BENEFICIARY_NOT_FOUND_FOR_CUSTOMER: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    TRANSACTION_NOT_FOUND_FOR_CUSTOMER: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    DOCUMENT_NOT_FOUND: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    DOCUMENT_NOT_FOUND_FOR_CUSTOMER: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    MONEYGRAM_VALIDATION_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    CREDIT_CARD_SIGN_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    CREDIT_CARD_VERIFY_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    CREDIT_CARD_COMMIT_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    CUSTOMER_MULTIPLE: 'מצטערים, על מנת להרשם לmyGMT עליך לפנות <a href="https://gmtonline.co.il/צור-קשר/" style="color:#d4c641;text-decoration: underline;">לשרות לקוחות </a>',
    MONEYGRAM_EXCEPTION: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    MONEYGRAM_301: 'עברתם את הסכום המקסימלי להעברה',
    MONEYGRAM_304: 'מצטערים, שרות העברת הכספים שלנו סגור כעת, נחזור בשעות הפעילות בימי חול בין 8:00-21:00 ובימי שישי בין 8:00-15:00',
    MONEYGRAM_323: 'מצטערים, אך שם המוטב שציינת ארוך מדי, באפשרותך לשלוח שוב עם שם קצר יותר. נשמח לעזור בכל שאלה.',
    MONEYGRAM_4055: 'מצטערים, אך חברת מניגרם דחתה את העברה לחו"ל כדי להגן עליך. העסקה לא בוצעה. נשמח לעזור בכל שאלה.',
    MONEYGRAM_7003: 'מצטערים, אך הגעת למגבלה החודשית במערכת MoneyGram. העסקה לא בוצעה. נשמח לעזור בכל שאלה.',
    FILE_NOT_ALLOWED: 'קיימת בעיה בקובץ',
    INVALID_SESSION_INFO: 'מצטערים, משהו השתבש. נשמח אם תנסו שוב, זה חשוב לנו',
    KYC_EXCEPTION: 'מתנצלים על אי הנוחות. על מנת להשלים את העסקה, אנו זקוקים לפרטים נוספים. בבקשה ליצור קשר עם שירות הלקוחות לעזרה. תודה.',
    WALLET_FRIEND_ALREADY_ADDED: 'היי, המספר שבקשת כבר קיים ברשימת החברים שלך',
    WALLET_FRIEND_BLOCKED: 'מצטערים, לא ניתן לצרף מספר זה לרשימת החברים שלך. נשמח לעזור בכל שאלה, ניתן לפנות אלינו באמצעות הכפתור ״צרו קשר״ למעלה',
    WALLET_FRIEND_NOT_REGISTERED: 'מצטערים המספר {mobileNumber} עוד לא קיים בmyGMT. כדאי להשתמש בלינק האישי שלך להזמין חברים חדשים ולקבל CashBack',
    marketingMessage: 'עם myGMT ניתן לשלוח כסף לקרובי המשפחה בחו"ל ולאסוף אותו במאות אלפי נקודות התשלום בכל רחבי העולם עם קוד סודי, להפקיד כסף ישירות לכרטיס אשראי בחו"ל או לזכות חשבון בחו"ל.',
    marketingMessage2: 'קל. מהיר. בטוח. בדיוק בשבילך.',
    YAAD_SARIG_3: 'מצטערים, אך חברת האשראי שלך לא אשרה את העסקה. ממליצים לפנות אליה לטיפול בנושא ואז לנסות שוב, נשמח לעזור בכל שאלה.',
    YAAD_SARIG_4: 'מצטערים, אך חברת האשראי שלך לא אשרה את העסקה או את פרטי הכרטיס שהקלדת. ממליצים לנסות שוב ונשמח לעזור בכל שאלה',
    YAAD_SARIG_6: 'מצטערים, אך היתה טעות בפרטי הכרטיס שהקלדת, ממליצים לנסות שוב ונשמח לעזור בכל שאלה',
    YAAD_SARIG_26: 'מצטערים, אך מספר תעודת הזהות לא משוייך לכרטיס שהזנת. אפשר לנסות שוב עם כרטיס הרשום על שמך. נשמח לעזור בכל שאלה',
    YAAD_SARIG_141: 'מצטערים, אך אנו יכולים לקבל כרגע רק כרטיסים מסוג ויזה ומאסטרכארד, נשמח לעזור בכל שאלה',
    YAAD_SARIG_303: 'מצטערים, אך כרטיס האשראי שלך לא תומך בבדיקת האבטחה של חברת האשראי, אפשר לפנות לחברת האשראי בנושא. נשמח לעזור בכל שאלה',
    YAAD_SARIG_447: 'מצטערים, אך מספר הכרטיס שהזנת שגוי, ממליצים לנסות שוב ונשמח לעזור בכל שאלה.',
    YAAD_SARIG_553: 'מצטערים, אך ישנה בעיה מול חברת האשראי, אנחנו ממליצים לפנות אליה לטיפול בבעיה ואז לנסות שוב, נשמח לעזור בכל שאלה.',
    YAAD_SARIG_999: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר, נשמח לעזור בכל שאלה.',
    YAAD_SARIG_3000: 'מצטערים, אך חברת האשראי שלך לא אשרה את העסקה. ממליצים לפנות אליה לטיפול בבעיה ואז לנסות שוב, נשמח לעזור בכל שאלה.',
    YAAD_SARIG_3003: 'מצטערים, נראה שלא אשרת את התשלום לחברת האשראי ולכן העסקה לא בוצעה. ממליצים לנסות שוב. נשמח לעזור בכל שאלה.',
    YAAD_SARIG_9101: 'מצטערים, אך כרטיס האשראי שלך לא תומך בבדיקת האבטחה של חברת האשראי, אפשר לפנות לחברת האשראי בנושא. נשמח לעזור בכל שאלה.',
    YAAD_SARIG_GENERAL: 'מצטערים, משהו השתבש בתשלום. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    INVALID_CARD: 'מצטערים, אך הכרטיס בחו"ל אליו ניסית להעביר אינו נתמך בשרות. ניתן לנסות לפרטי כרטיס אחר. נשמח לעזור בכל שאלה',
    AMOUNT_LIMITED: 'מצטערים, הסכום שניסית להעביר גדול מהמותר לכרטיס של המוטב בחו"ל, ממליצים לנסות סכום נמוך יותר ונשמח לעזור בכל שאלה.',
    CONTACT_GENERAL: 'מצטערים, יצאנו לשדרוג קצר כדי להשתפר, אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    BENEFICIARY_INVALID: 'מצטערים, שם המוטב אינו תואם לפרטי הכרטיס בחו"ל. ממליצים לתקן ואז לשלוח שוב, נשמח לעזור בכל שאלה',
    CONTACT_DENIED: 'מצטערים, אך נאלצנו לדחות את העברה לחו"ל כדי להגן עליך. העסקה לא בוצעה. נשמח לעזור בכל שאלה.',
    CARD_DENIED: 'מצטערים אך לא ניתן לבצע העברה לכרטיס זה. נשמח לעזור בכל שאלה.',
    CARD_WRONG_DETAILS: 'מצטערים, פרטי ההעברה לחו"ל שניסית לבצע שגויים, ממליצים לתקן ולנסות שנית. נשמח לעזור בכל שאלה.',
    REGISTER_INFO_DETAILS_REQUIRED: 'היי, הפרטים והתעודה שלך נדרשים לצורך שליחת כסף לחו"ל',
    REGISTER_INFO_SEARCH_ZIP: 'היי, ניתן לחפש את המיקוד שלך ע"י לחיצה על הזכוכית מגדלת',
    CARD_REJECTED: 'מצטערים, אך לא ניתן לשלוח למוטב בחו"ל שציינת כעת. נשמח לעזור בשרות הלקוחות‎',
    BLACKLIST: 'מצטערים, אך לא ניתן לשלוח למוטב בחו"ל שציינת כעת. נשמח לעזור בשרות הלקוחות‎',
    BLACKLIST_CARD: 'מצטערים, אך לא ניתן לשלוח למוטב בחו"ל שציינת כעת. נשמח לעזור בשרות הלקוחות‎',
    COMPANY_IDENTIFICATION: 'מצטערים, נראה שניסית להרשם עם מספר של לקוח עסקי. ניתן להרשם עם מספר תעודת זהות, נשמח לעזור בכל שאלה',
    CUSTOMER_MONTHLY_LIMIT: 'מצטערים, אך הגעת לסכום החודשי המקסימלי ב GMT.  נשמח לעזור בכל שאלה.',
    CORRESPONDENT_EXCEPTION: 'מצטערים, קבלנו שגיאה בניסיון לשלוח את ההעברה שלך לחו”ל. נשמח לבדוק ולעזור לך בשרות לקוחות',
    TEMPORARY_ERROR: 'מצטערים, קבלנו שגיאה בניסיון לשלוח את ההעברה שלך לחו”ל. נשמח לבדוק ולעזור לך בשרות לקוחות',
    BAD_REQUEST: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    MAX_EXCEPTION: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    SERVER_ERROR: 'מצטערים, המערכת בשדרוג כעת. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
    CUSTOMER_DAILY_LIMIT_EXCEEDED: 'מצטערים, עברת את המספר המקסימלי של העברות להיום. לכל שאלה, נשמח לעזור בשרות לקוחות.‎',
    NEW_CUSTOMER_SESSION_LIMIT_EXCEEDED: 'שמחים שהצטרפת אלינו! עברת את המספר המקסמלי של העברות ללקוחות חדשים בשבוע הראשון. לכל שאלה, נשמח לעזור בשרות לקוחות',
    NEW_CUSTOMER_SESSION_SUM_LIMIT_EXCEEDED: 'שמחים שהצטרפת אלינו! עברת את הסכום המקסמלי ללקוחות חדשים בשבוע הראשון. לכל שאלה, נשמח לעזור בשרות לקוחות',
    NEW_BENEFICIARY_LIMIT_EXCEEDED: 'מצטערים, אך ניתן לבצע עד 2 העברות לכרטיס חדש בחו"ל בשבוע. לכל שאלה, נשמח לעזור בשרות לקוחות.‎‎',
    NEW_BENEFICIARY_AMOUNT_EXCEEDED: 'מצטערים, עברת את הסכום המקסימלי בהעברה לכרטיס חדש בחו״ל. לכל שאלה, נשמח לעזור בשרות לקוחות.',
    INVALID_VERSION: 'אנחנו מצטערים על ההפרעה, יש לרפרש את העמוד בבקשה, כדי שנוכל להציג עבורך את הגרסאה החדשה של myGMT',
    BENEFICIARY_CARD_BLOCKED: 'מספר הכרטיס או הבנק אינם נתמכים כרגע בשרות, ניתן לשלוח לכרטיס אחר',
    ERROR_NEGATIVE: ' מצטערים, לא ניתן לבצע פעולה בסכום שלילי, בבקשה לעדכן את הסכום המבוקש.',

    E_DECIMAL: 'רק מספר עגול בבקשה',
    E_INVALID_zipCode: 'המיקוד לא תקין',
    E_LEMGTH: { 1: '', 2: 'ספרות בלבד' },
    E_MAX_VALUE: 'חרגת מהסכום המקסימלי',
    E_MAX_CHAR: { 1: 'מקסימום', 2: 'תווים' },
    E_MIN_CHAR: { 1: 'מינימום', 2: 'תווים' },
    E_MIN_passport: 'מספר הפספורט צריך להכיל יותר מ 5 תווים',
    E_NUMERIC: 'רק מספרים בבקשה',
    E_REGEX: 'פורמט השדה אינו תקין',
    E_REGEX_email: 'נראה שנפלה לך טעות במייל',
    E_REGEX_english: 'רק באנגלית בבקשה',
    E_REGEX_mobileNumber: 'בבקשה להכניס מספר טלפון ישראלי בן 10 ספרות.',
    E_REGEX_israeliId: 'תעודת זהות לא תקינה',
    E_REGEX_passport: 'אותיות ומספרים בלבד בבקשה',
    E_REGEX_birthDateAge: 'ההרשמה לגילאי 18-85 בלבד',
    E_REGEX_zipCode: 'יש לכתוב מיקוד בעל 7 ספרות',
    E_REQUIRED: 'שדה חובה',
    E_REQUIRED_amount: 'מהו הסכום?',
    E_REQUIRED_currency: 'מהו המטבע?',
    E_REQUIRED_destinationCountry: 'מהו היעד?',
    E_REQUIRED_agreement: 'יש לאשר את קריאת התקנון',

    STATUS_NOT_FOUND: 'לא נמצא ',
    STATUS_CONFIRMED: 'מאושר',
    STATUS_READY_FOR_PAYMENT: 'מוכן לתשלום',
    STATUS_PAID: 'שולם',
    STATUS_SENT_TO_BANK: 'הועבר לבנק',
    STATUS_BEING_CANCEL: 'בתהליך ביטול',
    STATUS_READY_TO_CANCEL: 'מוכן לביטול',
    STATUS_CANCELED: 'בוטל',
    STATUS_PREPARED: 'טיוטה',
    STATUS_FAILED: 'נכשל',
    STATUS_RETURNED: 'הוחזר',
    STATUS_ACCEPTED: 'התקבל',
    STATUS_STAGED: 'STAGED',
    STATUS_INCOMPLETE: 'ביצוע חלקי',
    STATUS_IN_PROCESS: 'בתהליך תשלום',
    STATUS_SWIFT_TRANSMITTED: 'שודר לבנק',
    STATUS_SWIFT_APPROVED: 'אושר לבנק',
    STATUS_REJECTED: 'נדחה',
    STATUS_EXPIRED: 'לא בתוקף',
    STATUS_CLOSED: 'סגור',
    STATUS_SUBMITTED: 'נשלח',

    DESC_CASHBACK: 'זיכוי לחשבון קאשבק',
    DESC_CASHBACK_WELCOME: 'שמחים שהצטרפת CashBack',
    DESC_CASHBACK_BIRTHDAY: 'מזל טוב CashBack',
    DESC_CASHBACK_ONETIME: 'מתנת CashBack',
    DESC_WALLET: 'הפקדה לארנק',
    DESC_MAX_TOPUP: 'טעינת כרטיס נטען',
    DESC_MAX_PURCHASE: 'רכישה וטעינת כרטיס ניטען',
    DESC_MAX_ORDER: 'הזמנת כרטיס נטען',
    DESC_CONVERSION: 'המרה',
    DESC_019MOBILE: '019 mobile',
    DESC_REWIRE: 'Rewire',
    DESC_DEPOSIT_3MONTHS: 'פקדון ל 3 חודשים',
    DESC_DEPOSIT_6MONTHS: 'פקדון ל 6 חודשים',
    DESC_DEPOSIT_WITHDRAW: 'משיכה מפקדון',
    DESC_DEPOSIT_INTEREST_PAYMENT: 'תשלום ריבית מפקדון',

    DEPOSIT_IN: 'הפקדה לחשבון פקדון',
    DEPOSIT_OUT: 'משיכה מחשבון פקדון',
    DEPOSIT_INTEREST: 'תשלום ריבית מפקדון',

    NAME_ALIPAY: 'ALIPAY',
    NAME_UNIONPAY: 'UNIONPAY',
    NAME_WECHATPAY: 'WECHATPAY',
    NAME_UPI: 'UPI',

    validation: {
        mock: '',
        beneficiary: {
            invalid: 'ערך לא חוקי',
            maskedCardNumber: {
                invalidCountry: 'הכרטיס כנראה אינו תואם למדינת היעד',
                tranglo: 'מצטערים, פרטי המוטב כפי שהקלדת שגויים, אפשר לעדכן ולנסות שוב'
            },
            accountIdentifier: {
                invalid: 'ערך לא חוקי',
                tranglo: 'מצטערים, פרטי המוטב כפי שהקלדת שגויים, אפשר לעדכן ולנסות שוב'
            },
            walletIdentifier: {
                invalid: 'ערך לא חוקי',
                tranglo: 'מצטערים, פרטי המוטב כפי שהקלדת שגויים, אפשר לעדכן ולנסות שוב'
            },
            phoneWallet: {
                unavailable: 'לצערנו, החשבון הזה אינו זמין ולא יכול לקבל כספים. בבקשה לבדוק את החשבון ולנסות שנית',
                mismatch: 'לצערנו, פרטי החשבון ושם החשבון אינם תואמים. אפשר לתקן ולנסות שוב',
                invalidAccount: 'לצערנו, החשבון אינו תקין. בבקשה לבדוק לפני ניסיון נוסף',
                barred: 'TPIN not registered. Please register TPIN under the same ID (NRC or Passport).'
            },
            phoneNumber: {
                format: {
                    passParam: 'הפורמט הנכון: {format}'
                }
            },
        }
    }
}