<template>
    <div>
        <Header></Header>
        <div class="main-wrapper">
            <MainNav></MainNav>
            <div class="content block block-card-main">
                <div v-if="!page_loaded" class="loader_page">
                    <img src="../assets/images/loading.gif" />
                </div>

                <body v-show="page_loaded">
                    <div class="wrap">
                        <div class="tab-content" v-if="cards.length">

                            <div class="card-group">
                                <h1 class="title" v-text="$ml.with('VueJS').get('myCard')"></h1>
                                <span>
                                    <ul class="card-list-wrap">
                                        <li class="card-list-item" v-for="(card, index) of cards" :key="index">
                                            <div @click="loadSelectedData(index)">
                                                <img class="card-img" :src="imgSrc(card.currency)" />
                                                <span class="last4digits">{{ card.last4digits }} <span
                                                        class="blocked">{{ cardBlocked(card) }}</span></span>
                                            </div>
                                        </li>
                                    </ul>
                                </span>
                            </div>

                            <div class="card-group balance">
                                <p v-text="$ml.with('VueJS').get('yourCardBalance')"></p>
                                <span v-formatTitleAmount="[balance, getSymbole]" id="card_balance"></span>
                            </div>

                            <div v-show="!blockedPermanently" class="card-btn-group">
                                <button class="btn-action" @click="showPinModal">
                                    <i class="icon icon-card-pass-b"></i>
                                    <span v-text="$ml.with('VueJS').get('pinCode')"></span>
                                </button>
                                <button class="btn-action" @click="showBlockModal">
                                    <i class="icon icon-lock icon-card-block-b"></i>
                                    <span>{{ $ml.with('VueJS').get(blocked ? 'cardUnBlock' : 'cardBlock') }}</span>
                                </button>
                                <button
                                    v-if="this.selected_card.currency === 'ILS' && (this.showCardLoad || this.hasWallet)"
                                    class="btn-action" @click="goToCardDeposit">
                                    <i class="icon icon-load-credit-card" style="margin: 3px auto;"></i>
                                    <span>{{ $ml.get('loadCard') }}</span>
                                </button>
                            </div>

                            <div class="full-page-box box-recent-actions" :class="{ 'blocked': blockedPermanently }">
                                <CardRecentActions ref="recantActionComp"></CardRecentActions>
                            </div>

                            <div v-show="!blockedPermanently">
                                <a class="icon-plus mobile-icon" @click="showActions"></a>
                            </div>
                        </div>

                        <CardMarketing v-else></CardMarketing>
                    </div>

                    <!-- modals -->
                    <!-- modal actions -->
                    <div class="modal-mask modal-message add_action">
                        <div class="modal-container">
                            <button class="fancybox-close-small" title="Close" @click="closeModal"></button>
                            <div class="modal-body">
                                <div class="list-actions">
                                    <strong class="btn-action item-title">
                                        <p>{{ $ml.with('VueJS').get('cardActionsModalTitle') }} {{ selected_card.last4digits }}
                                        </p>
                                    </strong>
                                    <button @click="showPinModal" class="btn-action">
                                        <i class="icon icon-card-pass-fab"></i>
                                        <span v-text="$ml.with('VueJS').get('pinCode')"></span>
                                    </button>
                                    <hr style="height:1px; width:30%; background-color:white" />
                                    <button @click="showBlockModal" class="btn-action">
                                        <i class="icon icon-card-block-fab"></i>
                                        <span>{{ $ml.with('VueJS').get(blocked ? 'cardUnBlock' : 'cardBlock') }}</span>
                                    </button>
                                    <div
                                        v-if="this.selected_card.currency === 'ILS' && (this.showCardLoad || this.hasWallet)">
                                        <hr style="height:1px; background-color:white" />
                                        <button class="btn-action" @click="goToCardDeposit">
                                            <i class="icon icon-load-credit-card-fab" style="margin: 3px auto;"></i>
                                            <span>{{ $ml.get('loadCard') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end modal actions -->

                    <!-- modal BLOCK CARD -->
                    <div class="modal-mask modal-mask-card" id="blockCardModal" @click="closeModal()">
                        <div class="modal-container modal-message" id="modal-mask-block" @click.stop>
                            <span @click="closeModal" class="close-modal"
                                :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
                            <div class="modal-body">
                                <div v-if="!dialogueDone" class="title body-title">
                                    <i class="icon icon-card-block-fab"></i>
                                    <strong>
                                        <p class="header">{{ $ml.with('VueJS').get('cardBlock') + ' ' +
                                            selected_card.last4digits }}</p>
                                        <p v-text="$ml.with('VueJS').get('cardBlockModalMessage.1')"></p>
                                        <p v-text="$ml.with('VueJS').get('cardBlockModalMessage.2')"></p>
                                    </strong>
                                    <p v-text="$ml.with('VueJS').get('cardBlockModalMessage.3')"></p>
                                </div>
                                <div v-else class="title body-title">
                                    <strong>
                                        <p><img src="../assets/images/gifs/card_lock.gif" alt=""></p>
                                        <p>{{ $ml.with('VueJS').get('cardBlockConfirm.1') }} </p>
                                    </strong>
                                    <p>{{ $ml.with('VueJS').get('cardBlockConfirm.2') }}
                                        <a :href="hrefSrc()" target="_blank"
                                            style="color:#d4c641;text-decoration: underline;">{{ $ml.with('VueJS').get('cardBlockConfirm.3') }}</a>
                                    </p>
                                </div>
                                <div class="form-group group-btn">
                                    <div v-if="!data_loaded" class="no-actions">
                                        <p><img src="../assets/images/loading_w.gif" /></p>
                                    </div>
                                    <div v-else>
                                        <button v-if="!dialogueDone" class="btn btn-small btn-border"
                                            v-text="$ml.with('VueJS').get('btnReturn')" @click="closeModal()"></button>
                                        <button type="submit" class="btn btn-small btn-yellow"
                                            v-text="$ml.with('VueJS').get(dialogueDone ? 'btnOk' : 'cardBlockBtn')"
                                            @click=" dialogueDone ? closeModal() : blockUnblock()">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end modal BLOCK CARD -->

                    <!-- modal UNBLOCK CARD -->
                    <div class="modal-mask modal-mask-card" id="unBlockCardModal" @click="closeModal()">
                        <div class="modal-container modal-message" id="modal-mask-block" @click.stop>
                            <span @click="closeModal" class="close-modal"
                                :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
                            <div class="modal-body">
                                <div v-if="!dialogueDone" class="title body-title">
                                    <i class="icon icon-card-block-fab"></i>
                                    <strong>
                                        <p class="header">{{ $ml.with('VueJS').get('cardUnBlock') + ' ' +
                                            selected_card.last4digits }}</p>
                                        <p v-text="$ml.with('VueJS').get('cardUnBlockModalMessage.1')"></p>
                                    </strong>
                                    <p v-text="$ml.with('VueJS').get('cardUnBlockModalMessage.2')"></p>
                                </div>
                                <div v-else class="title body-title">
                                    <strong>
                                        <p><img src="../assets/images/gifs/card_unlock.gif" alt=""></p>
                                        <p>{{ $ml.with('VueJS').get('cardUnBlockConfirm') }} </p>
                                    </strong>
                                </div>
                                <div class="form-group group-btn">
                                    <div v-if="!data_loaded" class="no-actions">
                                        <p><img src="../assets/images/loading_w.gif" /></p>
                                    </div>
                                    <div v-else>
                                        <button v-if="!dialogueDone" class="btn btn-small btn-border"
                                            v-text="$ml.with('VueJS').get('btnReturn')" @click="closeModal()"></button>
                                        <button type="submit" class="btn btn-small btn-yellow"
                                            v-text="$ml.with('VueJS').get(dialogueDone ? 'btnOk' : 'cardUnBlockBtn')"
                                            @click=" dialogueDone ? closeModal() : blockUnblock()">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end modal UNBLOCK CARD -->

                    <!-- modal PIN CARD -->
                    <div class="modal-mask modal-mask-card" id="pinModal" @click="closeModal()">
                        <div class="modal-container modal-message" id="modal-mask-pin" @click.stop>
                            <span @click="closeModal" class="close-modal"
                                :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
                            <div class="modal-body">
                                <div v-if="!dialogueDone" class="title">
                                    <i class="icon icon-card-pass-fab"></i>
                                    <span
                                        v-text="$ml.with('digits', selected_card.last4digits).get('pinCodeModalMessage')"></span>
                                    ?
                                </div>
                                <div v-else class="title">
                                    <p><img src="../assets/images/sms_once2.gif" alt=""></p>
                                    <span v-text="$ml.with('VueJS').get('pinCodeModalMessage2')"></span>
                                </div>
                                <div class="form-group group-btn">
                                    <div v-if="!data_loaded" class="no-actions">
                                        <p><img src="../assets/images/loading_w.gif" /></p>
                                    </div>
                                    <div v-else>
                                        <button v-if="!dialogueDone" class="btn btn-small btn-border"
                                            v-text="$ml.with('VueJS').get('btnReturn')" @click="closeModal()"></button>
                                        <button type="submit" class="btn btn-small btn-yellow"
                                            v-text="$ml.with('VueJS').get('btnOk')"
                                            @click="dialogueDone ? closeModal() : getPinCode()">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end modal PIN CARD -->
                    <!-- end modals -->

                </body>
            </div>
        </div>
    </div>
</template>
<script>
import Header from "../components/Header";
import CardMarketing from "../components/CardMarketing";
import MainNav from "../components/MainNav";
import { getCards, getCardBalance, cardBlockUnblock, getPinCode } from "../services/api";
import CardRecentActions from "../pages/CardRecentActions";
import { getCurrencySymbol, modalHandler } from "@/services/utils";


export default {
    name: 'CardMain',
    components: { MainNav, Header, CardMarketing, CardRecentActions },
    data() {
        return {
            cards: [],
            selected_card: [],
            balance: '',
            page_loaded: false,
            data_loaded: true,
            transactions: [],
            noTransactions: false,
            dialogueDone: false
        }
    },
    computed: {
        getDir() {
            return this.$store.getters.getDir;
        },
        getSymbole() {
            return getCurrencySymbol(this.selected_card.currency);
        },
        blocked() {
            return this.isInCode(this.selected_card, 'BLOCKED');
        },
        blockedPermanently() {
            return this.isInCode(this.selected_card, 'BLOCKED_PERMANENTLY');
        },
        showCardLoad() {
            return localStorage.getItem('showCardLoad') === 'true'
        },
        hasWallet() {
            return this.$store.getters.hasWallet('ILS');
        }
    },
    created() {
        getCards((res) => {
            const filtered = res.filter(item => { return !this.isInCode(item, 'BLOCKED_PERMANENTLY') });

            res.forEach(item => {
                if (this.isInCode(item, 'BLOCKED_PERMANENTLY') && !filtered.some(exist => item.currency === exist.currency)) {
                    filtered.push(item);
                }
            });

            this.cards = _.orderBy(filtered, item => { return [this.isInCode(item, 'BLOCKED_PERMANENTLY'), item.currency != 'ILS', item.currency != 'USD'] });
            if (this.cards.length > 0) {
                this.loadSelectedData(0);
            } else
                this.page_loaded = true;
        });
    },
    mounted() {
        document.body.setAttribute('dir', this.getDir);
    },
    methods: {
        isInCode(item, code) {
            return item.blockCode == code || item.blockCode.name == code
        },
        showActions() {
            modalHandler('.add_action', 'show', '092086', 'light');
        },
        imgSrc(curr) {
            return require(`../assets/images/gmt-card_${curr}.png`)
        },
        hrefSrc() {
            return `https://gmtonline.co.il/${this.$store.state.locale.lang}/contact-us/`
        },
        loadSelectedData(index) {
            this.selected_card = this.cards[index];
            this.focusCard(index);
            //for card transactions
            this.$store.commit('setSelectedCard', this.selected_card);
            getCardBalance({ "cid": this.selected_card.cid }, (data) => {
                this.balance = data;
                $('#card_balance').animate({ 'fontSize': '29px' }, 200);
                this.page_loaded = true;
            });
            if (this.$refs.recantActionComp) this.$refs.recantActionComp.loadCardTrans();
        },
        showBlockModal() {
            this.closeModal();
            modalHandler(this.blocked ? '#unBlockCardModal' : '#blockCardModal', 'show', '092086', 'light');
        },
        showPinModal() {
            this.closeModal();
            modalHandler('#pinModal', 'show', '092086', 'light');
        },
        closeModal() {
            modalHandler('.add_action', 'hide', 'ffffff', 'dark')
            modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
            this.dialogueDone = false;
        },
        goToCardDeposit() {
            this.$router.push('/wallet/transfer/card')
            localStorage.setItem('maxCardNumber', this.selected_card.cid);
        },
        blockUnblock() {
            this.data_loaded = false;
            cardBlockUnblock({ "cid": this.selected_card.cid, "blockCode": this.blocked ? '0' : 'T' }, (data) => {
                // update selected_card block code
                this.selected_card.blockCode = data
                this.dialogueDone = this.data_loaded = true;
            });
        },
        getPinCode() {
            this.data_loaded = false;
            getPinCode({ "cid": this.selected_card.cid }, (data) => {
                this.dialogueDone = this.data_loaded = true;
            });
        },
        cardBlocked(card) {
            if (this.isInCode(card, 'BLOCKED') || this.isInCode(card, 'BLOCKED_PERMANENTLY'))
                return this.$ml.get('blocked')
        },
        focusCard(index) {
            let below3Cards = this.cards.length < 3
            let $card = $(`.card-img:eq(${below3Cards ? index : 1})`);
            if (index != 1 || below3Cards) {
                $('#card_balance').css('fontSize', '0px');
                if ($card.hasClass('active')) {
                    $card.removeClass('active');
                }
                if (!below3Cards) {
                    let temp = this.cards[1];
                    this.$set(this.cards, 1, this.cards[index]);
                    this.$set(this.cards, index, temp);
                }
                setTimeout(() => {
                    $('.card-img').removeClass('active');
                    $(`.card-img:eq(${below3Cards ? index : 1})`).addClass('active');
                }, below3Cards ? 0 : 600); // (6s is parallel to transition .6s of class card-img)
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.block-card-main {
    background: url(../assets/images/bg-actions.png) no-repeat;
    background-position: left calc(100% - 30px);
    -webkit-background-size: contain;
    background-size: contain;
    min-height: calc(100vh - 71px);
    padding-top: 20px;

    .content-top {
        height: 69px;
        padding: 10px 0;
    }

    .wrap {
        padding: 0;
    }

    body {
        max-width: 739px;
        margin: 0px auto;
    }

    .centered-control {
        display: block !important;
        margin: 20px auto 0 !important;
        width: 170px !important;
    }

    .card-btn {
        padding-top: 35px;
    }

    .card-img {
        cursor: pointer;
        height: 50px;
        margin: 8px 15px;
        display: block;
        opacity: 0.4;

        +.last4digits {
            opacity: 0.4;

            .blocked {
                display: none;
                color: red;
            }
        }

        @include transition(all .6s ease);

        &.active {
            height: 160px;
            opacity: 1;

            +.last4digits {
                font-size: 21px;
                opacity: 1;

                .blocked {
                    display: initial;
                }
            }
        }
    }

    .card-list-wrap {
        height: 200px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
    }

    .card-list-item {
        display: inline-block;
    }

    .card-group {
        margin: 0 auto 22px;
        text-align: center;

        .title {
            margin-bottom: 8px;
        }

        &.balance {
            min-height: 58px;

            span {
                font-size: 32px;
                margin-bottom: 15px;
            }
        }
    }

    .card-btn-group {
        // margin: 45px auto; /**/
        text-align: end;

        /**/
        .btn-action {
            margin: 10px 0px;
            cursor: pointer;
            color: $mainColor;
        }

        .icon {
            margin: -2px auto;

            &.icon-lock {
                margin-bottom: 4px;
            }
        }
    }

    .full-page-box {
        padding-top: 10px;
        background: transparent;
        text-align: start;

        &.box-recent-actions {
            margin-top: -97px;
            /**/
        }

        &.blocked {
            margin-top: -30px
                /**/
        }
    }

    .mobile-icon {
        display: none;
        position: fixed;
        left: 15px;
        bottom: 25px;
        width: 53px;
        height: 53px;

        &:before {
            width: 23px;
            height: 23px;
        }
    }

    .fancybox-close-small {
        width: 106px;
        height: 106px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: rgb(213, 199, 65) !important;
        bottom: 30px;
        left: 30px;
        top: auto;
        background-image: none;

        &:before {
            content: '';
            display: block;
            width: 34px;
            height: 33px;
            background-image: url(../assets/images/icons/i-close-b.png);
            -webkit-background-size: cover;
            background-size: cover;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
        }
    }

    button.btn-action {
        min-width: auto;
        margin: 0px;
        background-color: transparent;
        border: none;
        padding: 0 10px;
    }

    .body-title p {
        margin: 15px auto;

        &.header {
            font-weight: 400;
            font-size: 24px;
            margin-bottom: 20px;
        }
    }

    .btn-action p {
        color: #ffffff
    }

    .btn-action.item-title {
        font-size: 20px;
    }
}</style>
