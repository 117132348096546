<template>
  <div>
    <!--<i v-if="isImg" ref="remove" class="icon icon-remove remove-file" @click="removeFile()"></i>-->
    <label class="file-upload" :class="{'file-upload--bordered': isImg}" @dragover.prevent
           @drop.prevent="fileUpload">
      <template>
        <span style="margin-top:40px" class="file-upload__text" v-show="!isImg">
        <img v-if="placeholderCode" height="40px" width="40px" src="../assets/images/icons/i-camera.png" alt="">
        <p>{{ placeholderCode ? $ml.get(placeholderCode) : '' }}</p>
        </span>
        <span class="file-upload__image" v-show="isImg" ref="image" :class="{ 'is-pdf': idPdf }"></span>
      </template>
      <input type="file" v-validate="'required|mimes:image/png,image/gif,image/jpeg,image/jpg,application/pdf,image/bmp'"
             @change="fileUpload" ref="fileRef" accept="image/*">
    </label>
    <span class="errors" v-if="isError">{{ errors.first('photoID') }}</span>
  </div>
</template>

<script>
import {deleteDocuments, uploadFile} from "../services/api";

export default {
  name: "ImageUpload",
  props: {
    content: {},
    idImg: null,
    docType: {
      type: String,
      required: true
    },
    isLoadedImg: {
      type: Boolean,
      default: true
    },
    placeholderCode: {
      type: String
    },
    removePrev: {
      type: Boolean
    }
  },
  data() {
    return {
      idPdf: false,
      isError: false,
      file: {
        contentImg: '',
        contentType: ''
      },
      idFile: '',
      isImg: true
    }
  },
  created() {
    if (this.$store.state.registration.isLoaded) {
      this.isImg = true;
    } else {
      this.isImg = false;
    }
  },
  watch: {
    content: {
      handler: function (value) {
        this.file.contentImg = value.content;
        this.file.contentType = value.contentType;

        this.displayFileBase64();
      },
      deep: true
    },
    idImg(value) {
      if (value) {
        this.isImg = true;
      }
      this.idFile = value;
    },
    isLoadedImg(value) {
      if (value) {
        this.isImg = true;
      }
      this.isImg = value;
    }
  },
  methods: {
    fileUpload(e) {
      const upload = () => {
        this.$emit('startUpload', true);
        let files = e.target.files || e.dataTransfer.files;
        var formData = new FormData();
        formData.append('file', files[0]);
        formData.append('docType', this.docType);
        this.displayFile(files[0]);
        uploadFile(formData, (response) => {
          if (response.data.errorCode) {
            this.$emit('fileNotUpload', true);
          } else {
            this.idFile = response.data.result
            this.$emit('fileUpload', true);
          }
        }, (error) => {
          console.log(error);
        });
      }
      this.$validator.validate().then(result => {
        if (result) {
          if (this.removePrev && this.idFile) {
            this.removeFile(() => {
              upload()
            })
          } else {
            upload()
          }
        }
      });
    },
    displayFile(file) {
      let self = this;
      let reader = new FileReader();
      let fileType = file.name.split('.').pop();

      self.idPdf = false;
      self.$refs.image.style.backgroundImage = '';
      if (fileType === 'pdf') {
        self.idPdf = true;
      } else {
        reader.onloadend = () => {
          self.imageResize(reader.result, 350, 350, (result) => {
            this.$store.commit('setLoaded', true);
            this.isImg = true;
            //this.isLoaded = true;
            self.$refs.image.style.backgroundImage = 'url(' + URL.createObjectURL(result) + ')';
          });
        };
        reader.readAsDataURL(file);
      }
    },
    displayFileBase64() {
      //console.log(this.file.contentImg, this.file.contentType, this.idImg);
      let self = this;
      let reader = new FileReader();
      // let fileType = file.name.split('.').pop();

      self.idPdf = false;
      self.$refs.image.style.backgroundImage = '';
      /*if (fileType === 'pdf') {
          self.idPdf = true;
      } else {*/
      reader.onloadend = () => {
        self.imageResize(reader.result, 350, 350, (result) => {
          this.$store.commit('setLoaded', true);
          this.isImg = true;
          //this.isLoaded = true;
          self.$refs.image.style.backgroundImage = 'url(' + URL.createObjectURL(result) + ')';
        });
      };
      fetch('data:' + this.file.contentType + ';base64,' + this.file.contentImg)
          .then(res => res.blob())
          .then(blob => {
            reader.readAsDataURL(blob);
          });

      //}
    },
    imageResize(srcData, width, height, callback) {
      let tempImg = new Image();
      tempImg.src = srcData;

      tempImg.onload = () => {
        let canvas = document.createElement("canvas"),
            ctx = canvas.getContext('2d'),
            xStart = 0,
            yStart = 0,
            aspectRadio,
            newWidth,
            newHeight;

        canvas.width = width;
        canvas.height = height;

        aspectRadio = tempImg.height / tempImg.width;

        newWidth = width;
        newHeight = aspectRadio * width;
        yStart = -(newHeight - height) / 2;

        ctx.drawImage(tempImg, xStart, yStart, newWidth, newHeight);
        canvas.toBlob(callback, 'image/png');
      };
    },
    removeFile(callback) {
      if (!this.idFile) {
        return false;
      }
      deleteDocuments(this.idFile, () => {
        this.idFile = null;
        this.isImg = false;
        this.$emit('fileDelete', this.idFile);
        this.$store.commit('setLoaded', false);
        if (callback) {
          callback()
        }
      });
    }
  },
}
</script>

<style lang="scss">
.file-upload {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 192px;
  margin: 23px auto 0;
  cursor: pointer;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: {
      repeat: no-repeat;
      position: center;
      size: cover;
    }

    &.is-pdf {
      background: url(../assets/images/icons/i-pdf.png) no-repeat center/contain !important;
    }
  }

  &--bordered {
    //    border: 1px solid rgb(255, 0, 252);
  }

  input[type=file] {
    display: none;
  }
}

.icon-remove {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>