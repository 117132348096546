<template>
  <div class="youtube-card-container">
    <div class="youtube-card-header">
      <h6><strong>{{ $ml.get('quick_guide') }}</strong></h6>
    </div>
    <div @click.stop="openYoutubeModal" class="youtube-card-video-wrapper">
      <div :style="{ backgroundImage: `url(${getThumbnail})` }" class="thumbnail"></div>
      <img class="play-button" src="../assets/mygmt_v2_images/play-button.svg" alt="">
    </div>
    <span class="doar-instructions">{{ $ml.get('wallet.main.doarInstruction') }} </span>
    <span class="doar-instructions">02:06m </span>
    <YoutubeModal ref="youTubeModalRef"></YoutubeModal>
  </div>

</template>
<script>
import { isApp, modalHandler } from "@/services/utils";
import YoutubeModal from "./YoutubeModal.vue";


export default {
  name: 'YoutubeCard',
  components: { YoutubeModal },
  props: {},
  data() {
    return {
      isApp: isApp(),
      isModalOpen: false
    }
  },
  created() {

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getThumbnail() {
      return `https://img.youtube.com/vi/${this.getVideoId}/0.jpg`
    },
    getUrl() {
      return `https://www.youtube.com/embed/${this.getVideoId}?autoplay=0`
    },
    getVideoId() {
      const langUrl = {
        il: 'lU4NnpKP2HM',
        ru: 'i5V2_uwSgxM',
        th: 've4pWm4Jz_k',
        cn: '57t7XQtVNZM',
        en: 'lU4NnpKP2HM',
      }
      return langUrl[this.getLang]
    },
    customThumbnailUrl() {
      return 'http://img.youtube.com/vi/231321/maxresdefault.jpg'
    }
  },
  mounted() {

  },
  methods: {
    openYoutubeModal() {
      this.$refs.youTubeModalRef.toggleModal()
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.youtube-card-container {
  margin-top: 30px;
  background-color: white;
  padding: 15px 5%;
  border-radius: 20px;
  box-shadow: 0px -3px 18px 0px #D2D2D240;

  .youtube-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  h6 {
    color: black;
    font-size: 14px;
    font-weight: normal;
  }

  .youtube-card-video-wrapper {
    padding-top: 20px;
    border-radius: 10px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .thumbnail {
      background-position: center center;

      background-size: 78% 160%;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }

    .play-button {
      cursor: pointer;

      position: absolute;
    }
  }
}

.doar-instructions {
  color: black;
  font-size: 12px;
  margin-top: 10px;

  &:last-of-type {
    color: grey;
  }
}

@media only screen and (min-width: $screen-tablet) {


  .youtube-card-container {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .youtube-card-video-wrapper {
      padding-top: 10px;
      // height: 80%;


      .youtube-iframe {
        height: 100%;
        width: 100%;
        overflow: hidden;
      }

    }
  }
}
</style>
