<template>
  <div>
    <Header :currentStep="2" @stepBack="$router.go(-1)"></Header>
    <div class="main-wrapper">
      <div class="content block">
        <h1 class="title" v-text="$ml.get('wallet.deposit.bank.upload.title')" />
        <form class="uploader">
          <div>
            <div class="col">
              <image-upload doc-type="WALLET_BANK_DEPOSIT_CONFIRMATION" @onError="onError" :isLoadedImg="isLoaded"
                @fileUpload="loadedImg()" @startUpload="startedUpload()" @fileNotUpload="notLoadedImg()"
                :remove-prev="true" placeholder-code="wallet.deposit.bank.placeholder" ref="imageRef" />
            </div>
          </div>
        </form>
        <div class="row">
          <button class="btn btn-border" v-text="$ml.get('btnReturn')" @click="prevStep()"></button>
          <button class="btn submit" :disabled='!this.isLoaded' @click="confirm()">
            {{ $ml.get('btnFinish') }}
          </button>
        </div>
        <div class="loader" v-if="isProcessLoadingImg">
          <div>
            <img class="loader_reg3" src="../../../assets/images/loading.gif" />
          </div>
          <div>
            <p>{{ $ml.get('wallet.deposit.bank.upload.loader') }}</p>
          </div>
        </div>
        <p class="footer" v-show="this.isLoaded" v-text="$ml.get('wallet.deposit.bank.upload.footer')" />
      </div>
    </div>
    <div class="modal-mask success">
      <div class="modal-container modal-transfer" id="modal-transfer" @click.stop>

        <div class="modal-body">
          <img src="../../../assets/images/success_once.gif" alt="">
          <div class="modal-text">
            <p v-text="$ml.get('wallet.deposit.bank.upload.success')" />
          </div>
          <button class="btn btn-yellow btn-close" v-text="$ml.get('btnClose')" @click="closeModal()" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>

import Header from "../../../components/Header";
import MainNav from "../../../components/MainNav";
import Action from "@/components/Action";
import ImageUpload from "@/components/ImageUpload";
import Modal from "@/components/Modal";
import { confirmBankDeposit } from "@/services/api";
import { modalHandler } from "@/services/utils";
import Footer from "@/components/Footer.vue";

export default {
  name: 'WalletBankConfirmation',
  components: { Action, MainNav, Header, ImageUpload, Modal, Footer },
  data() {
    return {
      uploading: false,
      isError: false,
      isLoaded: false,
      isProcessLoadingImg: false,
      isDeletingImgs: false,
    }
  },
  beforeDestroy() {
    this.$refs.imageRef.removeFile()
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
  },
  methods: {
    onError(data) {
      this.isError = data;
    },
    loadedImg() {
      this.isLoaded = true;
      this.isProcessLoadingImg = false;
    },
    startedUpload() {
      this.isLoaded = false
      this.isProcessLoadingImg = true;
    },
    notLoadedImg() {
      this.isLoaded = false;
      this.isProcessLoadingImg = false;
    },
    confirm() {
      confirmBankDeposit(this.$refs.imageRef.idFile)
      this.$analyticsService.transmitSwitchCase('wallet_deposit', 'wallet_deposit_stage_2', {
        page_title: "Wallet Deposit - Success",
        screen_name: "Wallet Deposit - Success",
        event_category: 'wallet_deposit',
        event_lable: 'bank_transfer',
        method: 'bank_transfer',
        event_category: 'wallet_deposit'
      }) 
      this.openModal()
    },
    openModal() {
      modalHandler('.modal-mask.success', 'show', '092086', 'light');
    },
    closeModal() {
      modalHandler('.modal-mask.success', 'hide', 'ffffff', 'dark')
      this.$router.push('/wallet')
    },
    prevStep() {
      this.$refs.imageRef.removeFile()
      this.$router.push('/wallet/deposit/bank')
    },
  },
}

</script>

<style lang="scss" scoped>
@import "../../../assets/styles/partials/variables";
@import "../../../assets/styles/partials/mixins";

.loader {
  margin: 2%;
}

.footer {
  margin-top: 2%;
  white-space: pre-line;
  font-weight: bold;
}

.modal-text {
  white-space: pre-line;
}

.uploader {
  text-align: center;
  margin-top: 20px;

  &>div {
    display: flex;
  }

  .error {
    margin-left: 0;
  }

  .col {
    width: 30%;
    height: 241px;
    border: 1px dashed rgb(218, 218, 218);
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    margin: 0 auto 2% auto;
    position: relative;
  }

  .group-btn {
    margin: 30px 0 0;
    display: block;

    .btn {
      margin: 0 10px;
      display: inline;
    }
  }
}

.block {
  padding-top: 20px;

  text-align: center;

  .upload-details {
    font-weight: bold;
  }

  .submit {
    width: 220px;
  }

  .btn {
    font-size: 18px;
    height: 38px;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;

    &.btn-border {
      border-color: $mainColor;
      color: $mainColor;

      &:hover {
        background-color: $mainColor;
        border-color: $mainColor;
        color: #ffffff;
      }
    }
  }
}

@include max-screen($screen-phones) {

  .loader {
    margin: 4%;
  }

  .footer {
    margin-top: 6%;
  }

  .block {
    .title {
      text-align: center;
      font-size: 26px;
      margin-top: 4%;
    }

    .col {
      width: 86%;
      margin-bottom: 12%;
    }
  }
}
</style>