<template>
  <div v-show="loaded" class="wrap">
    <div v-show="paying" class="loader_page">
      <img src="../../../assets/images/loading.gif"/>
    </div>
    <div v-show="!paying">
      <div v-if="mode === this.$walletTransferType.FRIEND">
        <h1 class="title" v-text="$ml.with('name', name).with('amount', amount).get('wallet.transfer.pay.title')"/>
      </div>
      <div v-else-if="mode === this.$walletTransferType.CARD">
        <h1 v-text="$ml.get('wallet.card.pay.title')" class="title"/>
      </div>
      <div v-else>
        <h1 class="title" v-text="$ml.get('wallet.load.pay.title')"/>
      </div>
      <div class="type-payment">
        <payment-type v-if="mode === this.$walletTransferType.FRIEND || mode === this.$walletTransferType.CARD" @action="showWalletPaymentApprovalModal" div-class="item-type type-wallet"
                      icon="fas fa-wallet" title="wallet.payment.title"
                      :footer="$ml.with('amount', this.remainingWalletBalance).with('currency', this.getSymbol).get('wallet.payment.footer')"
                      :in-active-footer="!walletHasEnoughBalance ? $ml.get('wallet.payment.inActive.footer') : this.getLimitMessage('WALLET')"
                      :active="walletHasEnoughBalance && !this.isWalletLimited"
                      :totalAmount="totalAmount" 
                      :paymentAmount="totalAmount" 
                      :paymentCurrency="getSymbol"
                      :currency="getSymbol"
                      :installmentDetails="null"/>
        <payment-type v-else @action="payByCreditCard" div-class="item-type type-credit-card" icon="fa fa-credit-card" title="creditCard"
                      :footer="$ml.get('creditCardCommissionMsg')"
                      :in-active-footer="this.getLimitMessage(this.isCreditCardLimited ? 'CREDIT_CARD' : 'MONTHLY')"
                      :active="!this.isCreditCardLimited && creditAvailabilityMap.available" 
                      :paymentAmount="this.installmentDetails ? this.installmentDetails.totalAmount : amountToPay"
                      :in-process="isFormSubmitted"
                      :totalAmount="totalAmount" :exchange-needed="false" :currency="getSymbol" paymentCurrency="₪"
                      :installment="true" @installments-action="doInstallments" :installmentDetails="installmentDetails"/>
        <modal noCancelBtn ref="walletModal" icon="fas fa-wallet" icon-style="color: white; font-size: 40px" :title="$ml.get('wallet.payWith')" :confirm="$ml.get('pay')"
               @action="payByWallet()">
          <p style="font-size: 30px">{{ this.getSymbol }}{{ this.totalAmount | formatComma }}</p>
          <hr/>
          <p>{{ $ml.get('wallet.payment.body') }}</p>
        </modal>
      </div>

      <div class="modal-mask modal-mask-transfer-3" @click="goToFriends()">
        <div class="modal-container modal-transfer" id="modal-transfer" @click.stop>
          <div class="modal-body">
            <div v-if="mode === this.$walletTransferType.FRIEND">
              <img src="../../../assets/images/gifs/wallet-transfer.gif" alt="">
            </div>
            <div v-else-if="mode === this.$walletTransferType.CARD">
              <img src="../../../assets/images/gifs/gmt-credit-card.gif" alt="">
            </div>
            <div v-else>
              <img src="../../../assets/images/success_once.gif" alt="">
            </div>
            <div class="modal-text">
              <div v-if="mode === this.$walletTransferType.CARD">
                <strong v-text="$ml.get('maxCardLoadTransaction')"></strong>
                <p v-text="$ml.get('messageSend')"></p>
              </div>
              <div v-else>
                <strong v-text="$ml.get('billingSuccessful')"></strong>
                <p v-text="$ml.get('messageSend')"></p>
              </div>
            </div>
            <button class="btn btn-yellow btn-close" v-text="$ml.get('btnClose')" @click="goToFriends()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PaymentType from "@/components/PaymentType";
import {EventBus} from "@/event-bus";
import Modal from "@/components/Modal";
import {commitTransaction, creditCardSign, getInstallmentsData, paymentAmountInfo, sendValidation} from "@/services/api";
import app from "@/main";
import {formatComma} from "@/filters/AppFilters";
import {isApp, modalHandler, transmitEvent} from "@/services/utils";

export default {
  name: 'WalletPay',
  components: {PaymentType, Modal},
  props: {
    mode: {
      type: String,
      required: false
    },
    skip: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      paying: false,
      isSubmitted: false,
      rate: 1,
      paymentType: '',
      amountToPay: 0,
      creditAvailabilityMap: '',
      installments: 1,
      installmentDetails: null,
      loaded: false,
    }
  },
  computed: {
    name() {
      const beneficiary = JSON.parse(localStorage.getItem('currentWalletBeneficiary'))
      return beneficiary.firstName + ' ' + beneficiary.lastName
    },
    amount() {
      return this.$store.getters.getSelectedTransferOption.amount;
    },
    currency() {
      return this.$store.getters.getSelectedTransferOption.currency;
    },
    cashBackCredit() {
      return this.$store.getters.getCashBackCredit;
    },
    useCashBack() {
      return this.$store.getters.getUseCashBack && this.cashBackCredit;
    },
    totalAmount() {
      return this.useCashBack ? +(this.amount - this.cashBackCredit).toFixed(2) : this.amount;
    },
    getSymbol() {
      return this.$store.getters.getSelectedCurrencySymbol;
    },
    walletHasEnoughBalance() {
      return this.remainingWalletBalance >= 0;
    },
    remainingWalletBalance() {
      return (this.$store.getters.getWalletBalance(this.currency) - this.amountToPay).toFixed(2);
    },
    isFormSubmitted(){
      return this.isSubmitted;
    },
    isCreditCardLimited(){
      return this.isLimitExceeded('CREDIT_CARD')
    },
    isWalletLimited(){
      return this.isLimitExceeded('WALLET')
    },
  },
  created() {
    this.creditAvailabilityMap = this.$store.getters.getCreditAvailabilityMap;
    let lookupDto = this.$store.getters.getSelectedTransferOption.lookupDto;
    paymentAmountInfo({
      "amount": this.$store.getters.getSelectedTransferOption.amount,
      "commission": this.$store.getters.getSelectedTransferOption.commission,
      "currency": this.currency,
      "useCashBack": this.$store.getters.getUseCashBack,
      "cashBackCurrency": this.$store.getters.getCashBackCurrency,
      "transactionRate": this.$store.getters.getSelectedExchangeRate,
      "lookupAmount": lookupDto && lookupDto.lookupAmount,
      "lookupRate": lookupDto && lookupDto.rate,
      "lookupBaseRate": lookupDto && lookupDto.baseRate
    }, (response) => {
      this.amountToPay = response.amountILS;
      this.rate = response.rate;
      this.loaded = true;
    });
  },
  methods: {
    getLimitByPayment(paymentType){
      return (paymentType == 'MONTHLY')
        ? this.creditAvailabilityMap.limit
        : this.creditAvailabilityMap.paymentLimits[paymentType];
    },
    getLimitMessage(paymentType){
      if (this.creditAvailabilityMap.message != 'CREDIT_LIMIT') { // unavailable - other reasons
        return this.$ml.get(this.creditAvailabilityMap.message);
      }
      const max = Math.max(this.getLimitByPayment(paymentType), 0);
      return this.$ml.with('amount', this.$store.getters.getLookupCurrencyByAmountType + formatComma(max)).get('CREDIT_LIMIT_' + paymentType);
    },
    isLimitExceeded(paymentType){
      return !this.creditAvailabilityMap.customerMonthlyLimitPaused && this.amountToPay > this.getLimitByPayment(paymentType)
    },
    showWalletPaymentApprovalModal() {
      this.$refs.walletModal.openModal()
    },
    doInstallments(val) {
      this.installments = val;
      this.installmentDetails = null
      if (val > 1) {
        getInstallmentsData({principal: this.amountToPay, numOfPayments: val}, response => {
          this.installmentDetails = {
            instalment: response.installment,
            interest: (response.totalAmount - this.amountToPay).toFixed(2),
            totalAmount: response.totalAmount
          }
        });
      }
    },
    getTransactionData(paymentType){
      let selectedOption = this.$store.getters.getSelectedTransferOption;
      return {
            "amount": selectedOption.amount,
            "currency": selectedOption.currency,
            "commission": selectedOption.commission,
            "destinationCountry": selectedOption.destinationCountry,
            "payoutCurrency": selectedOption.payoutCurrency,
            "transactionClass": selectedOption.transactionClass,
            "correspondentId": selectedOption.correspondentId,
            "paymentType": paymentType,
            "transferPurpose": "FAMILY_TRANSFER",
            "totalReceiveAmount": selectedOption.totalReceiveAmount || '',
            "transactionRate": this.$store.getters.getSelectedExchangeRate,
            "lookupAmount": selectedOption.lookupDto && selectedOption.lookupDto.lookupAmount,
            "lookupRate": selectedOption.lookupDto && selectedOption.lookupDto.rate,
            "lookupBaseRate": selectedOption.lookupDto && selectedOption.lookupDto.baseRate
          }
    },
    payByCreditCard() {
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        app.$Progress.start();
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        this.paymentType = selectedOption.transactionClass
        sendValidation({
          "transaction": this.getTransactionData(1)
        }, responseValidation => {
          if (!responseValidation.errorCode) {
            localStorage.setItem('mgparams', JSON.stringify(responseValidation.result));
            localStorage.setItem('selectedTransferOption', JSON.stringify(this.$store.getters.getSelectedTransferOption));
            this.$analyticsService.transmitSwitchCase('wallet_deposit', 'wallet_deposit_stage_3')
            let self = this;
            creditCardSign({
              "amountILS": this.amountToPay,
              "amount": this.amount,
              "currency": this.currency,
              "destinationCountry": selectedOption.destinationCountry,
              "numOfPayments": this.installments
            }, (response) => {
              if (!response.errorCode) {
                sessionStorage.setItem('cc_tempInfo', JSON.stringify({
                  url: process.env.VUE_APP_YAAD_URL + response.result.textResponse,
                  p_amount: this.installmentDetails ? this.installmentDetails.totalAmount : this.amountToPay,
                  t_amount: selectedOption.amount,
                  t_currency: selectedOption.currency,
                  t_country: selectedOption.destinationCountry,
                  numOfPayments: this.installments,
                  installments: this.installmentDetails,
                }));
                localStorage.setItem('analyticsEvent', JSON.stringify({group:'wallet_deposit', eventId: 'wallet_deposit_card'}) );
                this.$router.push('/credit_card_payment');
                app.$Progress.finish();
              } else {
                this.$store.commit('setShowModalError', true);
                this.$store.commit('setErrorCode', responseValidation.errorCode);
                self.isSubmitted = false;
                app.$Progress.finish();
                this.$analyticsService.transmitSwitchCase('wallet_deposit','wallet_deposit_error', {errorCode: responseValidation.errorCode}) 
              }
            });
          } else {
            this.$store.commit('setShowModalError', true);
            this.$store.commit('setErrorCode', responseValidation.errorCode);
            self.isSubmitted = false;
            app.$Progress.finish();
          }
        });
      }
    },
    payByWallet() {
      this.$refs.walletModal.closeModal();
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        app.$Progress.start();
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        this.paymentType = selectedOption.transactionClass
        let self = this;
        this.paying = true;
        const payload = {
          "transaction": this.getTransactionData() 
        }
        if (this.mode === this.$walletTransferType.FRIEND) {
          payload.beneficiaryId = JSON.parse(localStorage.getItem('currentWalletBeneficiary')).id
        } else if (this.mode === this.$walletTransferType.CARD) {
          payload.maxCardNumber = localStorage.getItem('maxCardNumber')
        }
        sendValidation(payload, responseValidation => {
          if (!responseValidation.errorCode) {
            commitTransaction({
              "transaction": {
                "id": responseValidation.result.transactionId,
                "paymentType": 'WALLET',
                'maxAction': 'LOAD'
              }
            }, response => {
              if (!response.errorCode) {
                this.paying = false;
                self.isSubmitted = false;
                if (response.result) {
                  this.referenceNumber = response.result;
                  modalHandler('.modal-mask-transfer-3', 'show', '092086', 'light');
                  this.$analyticsService.transmitSwitchCase('card_deposit','card_deposit_stage_3', {referenceNumber}) 
                }
                app.$Progress.finish();
              } else {
                this.paying = false;
                this.$store.commit('setShowModalError', true);
                this.$store.commit('setErrorCode', responseValidation.errorCode);
                self.isSubmitted = false;
                app.$Progress.finish();
                this.$analyticsService.transmitSwitchCase('card_deposit','card_deposit_stage_3_error', {errorCode:  response.errorCode}) 
              }
            });
          } else {
            this.paying = false;
            this.$store.commit('setShowModalError', true);
            this.$store.commit('setErrorCode', responseValidation.errorCode);
            self.isSubmitted = false;
            app.$Progress.finish();
        
          }
        });
        setTimeout(() => this.isSubmitted = false, 10000);
      }
    },
    goToFriends() {
      setTimeout(() => EventBus.$emit('updateWalletBalances'), 5000);
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
      this.$router.push({ name: localStorage.getItem('homePage') || 'recent_actions' })
    },
  }
}

</script>