<template>
<div class="full-page-box box-marketing"> 
    <div class="message">
        <p>{{$ml.with('max', 'MAX').get('cardMargketing.title')}} <img class="imgInfo cashback" src="../assets/images/max_logo.svg"/></p>
        <div class="cashBack img" >
            <div class="img-cashback">
                <img class="imgInfo cashback" src="../assets/images/gifs/gmt-credit-card.gif"/>
            </div>
        </div>
        <p></p>
    </div>
    <div class="message">
        <p class="message-subtitle" v-text="$ml.with('VueJS').get('cardMargketing.subtitle')"></p>
    </div>
    <div class="message">
        <ul class="message-content">
            <strong v-text="$ml.with('VueJS').get('cardMargketing.info1.title')"></strong>
            <p v-text="$ml.with('VueJS').get('cardMargketing.info1.con1')"></p>
            <p v-text="$ml.with('VueJS').get('cardMargketing.info1.con2')"></p>
        </ul> 
    </div>
    <div class="message">
        <ul class="message-content">
            <strong v-text="$ml.with('VueJS').get('cardMargketing.info2.title')"></strong>
            <p v-text="$ml.with('VueJS').get('cardMargketing.info2.con1')"></p>
            <p v-text="$ml.with('VueJS').get('cardMargketing.info2.con2')"></p>
            <p v-text="$ml.with('VueJS').get('cardMargketing.info2.con3')"></p>
            <p v-text="$ml.with('VueJS').get('cardMargketing.info2.con4')"></p>
        </ul> 
    </div>
    <div class="message">
        <p class="message-content font-20">
            <strong v-text="$ml.with('VueJS').get('cardMargketing.footer.1')"></strong>
        </p> 
        <p class="message">
        </p>
        <p class="message-content">
            <strong v-text="$ml.with('VueJS').get('cardMargketing.footer.2')"></strong>
        </p>
        <p class="message">
            <button @click="openWhatsApp()" class="btn">
                <span v-text="$ml.with('VueJS').get('cardMargketing.btn')"></span>
            </button>
        </p>
    </div>
</div>
</template>

<script>
    export default {
        methods: {
            openWhatsApp() {
                window.open('https://wa.me/972547228005');
            },
        }
    }
</script>

<style lang="scss" scoped>
    .full-page-box{
        background: transparent;
        &.box-marketing{
            padding-top:0; 
            .message{
                margin: 5px;
            }
            .btn{
                margin-top: 4px;
                width: auto;
                padding: 0 25px;
                margin: 0 auto;
            }
        }
    }
</style>
