export const en = {
    GMTbranches: '<a href="https://gmtonline.co.il/en/locating-branches/" style="color:#d4c641;text-decoration: underline;">at one of GMT branches</a>',
    accessibleBranch: ' Accessible ',
    myCard: 'My Card',
    myDateBirthday: ' (Day-Month-Year) ',
    accountNumber: 'Beneficiary account number',
    activityTime: 'Activity time:',
    actualRate1: 'The online',
    and: 'and the ',
    appDownload: 'Download our app',
    appDownloadBtn: 'Direct download \n to your Phone',
    actualRate2: 'rate now is',
    addAddress: 'Add Address',
    additionalPhone: 'Additional phone number',
    amount: 'Amount',
    amountIn: 'Amount in ILS',
    recipientAmount: 'Recipient gets',
    amountReceived: 'Amount to be received',
    amountTogether: 'Amount together',
    anotherSurname: 'Additional Last Name',
    answerQuestion: 'In the meantime maybe you can find an answer to your question',
    approvalTransaction: 'Wonderful! The charge was successful, and the money transfer was approved. In the next few minutes, you will be sent an SMS with the transaction confirmation ',
    maxCardLoadTransaction: 'Wonderful! The charge was successful, and your myGMT Card was reloaded with money ',
    approvedPolicies: 'I have read & accepted the T&Cs',
    arrivalBranch: 'Cash at GMT location',
    arrivalBranchMsg1: 'We will give you now an order number to pay with, the number is valid for 24 hours',
    arrivalBranchMsg2: ', and the payment will be in ILS according to the exchange rate in store',
    bankName: 'Bank Name',
    bank: 'Bank',
    bankAccount: 'Bank Account',
    bankCode: 'Bank number',
    canceled: 'Canceled',
    cardNumber: 'Card number',
    cashioPageTtile: 'Foreign currency exchange services including delivery or airport pickup',
    depositCardNumber: 'Beneficiary\'s credit card number',
    beneficiary: {
        firstName: 'First Name',
        lastName: 'Last Name',
        phoneNumber: 'Beneficiary\'s phone number',
        country: 'Destination‏',
        maskedCardNumber: 'Beneficiary\'s credit card number',
        maskedCardNumber_unionPay: "Beneficiary's credit card number",
        address: 'Address',
        city: 'City',
        province: "State/Province",
        zipCode: "Postal code",
        bankName: 'Bank Name',
        cashBankName: 'Pickup point',
        walletBankName: 'Wallet Name',
        swiftCode: 'Bank SWIFT/BIC Code',
        bankCity: 'Bank City',
        bankAddress: 'Bank Address',
        branch: 'Bank Branch',
        iban: 'IBAN',
        accountIdentifier: 'Account Number',
        walletIdentifier: 'Wallet Number',
        alipayWallet: 'AliPay Account',
        abaRoutingNumber: "Bank Routing/ABA Number‏",
        ifsc: "IFSC",
        relationshipWithCustomer: "What is the relationship between yourself and the beneficiary?",
        identification: "Identification Number",
        upi: "UPI ID or number",
        upi_IND: "UPI ID or number",
    },
    relationshipWithCustomer: {
        SELF: 'myself',
        CHILD: 'my child',
        PARENT: 'my parent',
        SPOUSE: 'my partner',
        SIBLING: 'my sibling',
        EX_SPOUSE: 'my Ex-spouse'
    },
    beneficiaryRemoveDialogue: 'Do you want to delete {beneficiary} from your beneficiaries list?',
    beneficiaryRemoveDialogue2: 'This will not effect existing transfers',
    billingSuccessful: 'Wonderful! The charge was successful, and your Money transfer was approved.',
    billingSuccessfulCardDeposit: 'Wonderful! The charge was successful and your money transfer to the card was approved.',
    billingSuccessfulBankTransfer: 'Wonderful! The charge was successful and your money transfer to the account was approved.',
    billingSuccessfulBankTransferISR: 'Wonderful! The charge was successful and your money transfer to the account was approved.',
    cardUnBlockModalMessage: {
        1: 'After you press the "activate" button, your card will be active for new payment transactions and cash withdrawal ',
        2: 'please do so only if you have the card in your possession'
    },
    cardBlockModalMessage: {
        1: 'After you press the "block" button, we will temporarily block your card for new transactions or cash withdrawal.',
        2: 'Your card\'s balance is saved! ',
        3: 'In case you have found the card, you can reactivate it here, or you can ask us to permanently block it and issue a new card for you'
    },
    blocked: 'Blocked',
    blog: ' blog ',
    bntClear: 'Cancel',
    bntContinued: 'Next Step',
    bntContinue: 'Next',
    bntImgDelete: 'Upload again',
    bntSave: 'Save',
    branchNumber: 'Branch',
    checkStatus: 'Status',
    status: 'Status',
    btnAccept: 'Accept',
    btnCheckStatusCheck: 'Ready to pay',
    btnCheckStatusNoCheck: 'Unavailable for payment',
    btnOk: 'Approve',
    btnDone: 'Done',
    btnDelete: 'Delete',
    btnFinish: 'Finish',
    btnClose: 'Close',
    new: 'NEW',
    newCustomer_section: 'The perfect choice for your money',
    quick_guide: 'Quick Guide for you',
    invite_friends: 'Invite friends to myGMT',
    cash_back_desc: 'CashBack',
    send_money_home: 'Send money home',
    send_money_worldwide: 'Send money world wide',
    newCustomer_title: 'Money transfers and \nadvanced financial services',
    quickSend: 'Quick Send',
    quickActions: 'Quick actions',
    quickSendActionsParagraph: "Your most recent beneficiaries are here and ready to be sent again quickly",
    quickActionsAdd: 'Add',
    payAnywhere: 'PrePaid Card',
    carousel_slide_one_text: 'get your prepaid card and control your money even better',
    carousel_slide_one_button: 'Learn more',
    seeAll: 'See all',
    cardBalance: 'Card Balance',
    getCashback: 'and get',
    cashBack_info: 'Cashback',
    home: 'Home',
    menu: 'Menu',
    activity: 'Activity',
    cards: 'Cards',
    greeting_morning: 'Good morning',
    greeting_afternoon: 'Good afternoon',
    greeting_evening: 'Good evening',
    greeting_night: 'Good night',
    paidBy: 'Paid with: ',
    incoming: 'You got',
    outgoing: 'You paid',
    CONVERSION: 'Conversion',
    PAYMENT: 'Payment',
    CASH_BACK: 'Cashback',
    cardActions: "Card's actions",
    actions: 'Actions',
    activateCard: 'Activate your new card',
    activateCardAction: 'Activate Card',
    enterLastDigits: 'Enter Final 4 Digits to Activate',
    searchTransactions: 'Search transactions',
    filterTransactions: 'Transactions Filter',
    filterTransactionsButton: 'Filter',
    openWallet: 'Open wallet account free',
    pleaseSelect: 'Select one or more',
    date: 'Date',
    from_date: 'From date: ',
    to_date: 'To date: ',
    choose_date: 'Choose Date',
    showResults: 'Show Results',
    clearFilter: 'Clear filter',
    today: 'Today',
    contact_us_paragraph: 'We believe in good communication, so feel free to reach out to us through any of the methods listed here. \n' + 'Our operating hours:\n' + 'Sun-Thu: 08:30 AM - 05:00 PM,\n' + 'Fri: 08:30 AM - 02:00 PM.',
    call_us: 'Call us',
    contact_us_or: 'or',
    lastTransactions: `Card's last transactions`,
    transactionClasses: {
        BANK_TRANSFER: 'Bank Deposit Transfer',
        CASH_TRANSFER: 'Cash Pickup Transfer',
        CARD_DEPOSIT: 'Card Load Transfer',
        PAYMENT: 'Incoming Transfer',
        CONVERSION: 'Currency Conversion',
        CASH_BACK: 'CashBack',
        WALLET_DEPOSIT: 'Self Deposit to your Wallet',
        WALLET_TRANSFER: 'E-Wallet Deposit Transfer',
        WALLET_TO_WALLET: 'myGMT Deposit Transfer',
        WALLET_FROM_WALLET: 'Incoming myGMT Transafer',
        UPI_BANK_TRANSFER: 'Upi Bank Transfer'
    },
    paymentTypes: {
        CASH: 'CASH',
        CHECK: 'CHECK',
        BANK_TRANSFER: 'Bank transfer',
        CREDIT_CARD: 'Credit card',
        CREDITTING: 'Card',
        OUTER_CONVERSION: 'Cash',
        CASHBACK: 'CASHBACK',
        CUSTOMER_DEPOSIT: 'Third party balance',
        WALLET: 'Wallet',
        CHANGE: 'CHANGE',
        AIRPORT: 'AIRPORT',
    },
    CARD: 'Card',
    CASH: "CASH",
    AIRPORT: 'AIRPORT',
    CHECK: "CHECK",
    CHANGE: 'CHANGE',
    CREDIT_CARD: "CREDIT_CARD",
    CREDITTING: "Card",
    OUTER_CONVERSION: "Cash",
    CASHBACK: "CASHBACK",
    CUSTOMER_DEPOSIT: "Third party balance",
    WALLET: "Wallet",
    changeLang: "Change Language",
    nothing_found: 'No transactions were found',
    nothing_found_text: "Once you'll have some activity it will be shown here",
    max_transaction_description: 'Description',
    creditCardReferenceNumber: 'Card company reference',
    checkNumber: 'Check number',
    referenceNumber: 'Reference',
    flightDate: 'Flight date',
    deposit_to_wallet: 'Deposit to Wallet',
    btnFinishREgistration: 'To finish registration',
    btnUploadDocuments: 'Add a document',
    btnRegistration: 'Create an account>',
    btnReturn: 'Go back',
    btnSend: 'Send',
    btnSignIn: 'Send me a password by SMS',
    btnBiometricSignInFaceId: 'Login with Face ID',
    btnBiometricSignInTouchId: 'Login with Touch ID',
    btnToMyGmt: 'To myGMT',
    btnTransfer: 'Send Money',
    btnTransferFooter: 'Send Money',
    btnEnter: "Let's Go in",
    btnSearch: 'Search',
    business: 'Occupation',
    biometricsHeader: 'Biometrics Identification',
    biometricSectionApprove: 'I approve the T&C',
    biometricSectionSignIn: 'To complete the registration for the biometric identification, please log in to the account',
    biometricToggler: 'Biometric login ',
    biometric_modal_text: 'Do you want to cancel your fast biometric log in?',
    biometric_legal_clause_1: 'The biometric identification (using a fingerprint and/or facial recognition) will serve as a substitute for entering a password when entering all the accounts associated with the username with which you enter the system.',
    biometric_legal_clause_2: "The company does not store the user's facial recognition, the identification relies on the biometric identification defined on the user's mobile device.",
    biometric_legal_clause_3: 'Registration for the service is subject to the declaration by the person authorized to enter the website/app that they are the only user of the device with the biometric identification. Where the biometric identification of several people is updated on a device, it should not be used to register for the service.',
    biometric_legal_clause_4: 'Requesting information and/or performing actions performed on the website/app will be considered actions performed by the authorization holder, including actions performed by a third party in violation of Section 3 above.',
    biometric_legal_clause_5: 'In the event of loss and/or theft of the mobile device, the registration to the service must be canceled immediately by contacting customer service by telephone.',
    biometric_legal_clause_6: 'You can disconnect from the service at any time both in the application and by contacting customer service.',
    biometric_login_failed: 'Sorry the identification failed',
    biometric_public_key_expired:'Sorry for the inconvenience! We disabled your biometric login to keep your account secure and safe. Please re-register for biometric login again. Thank you!',
    biometric_unregister_modal_title: 'Turn off biometric login ',
    calculatedBy: 'Calculate by {curr}',
    captionAgreement: 'I have read and accepted the ',
    captionAgreementLink: 'T&Cs',
    captionCustomerService1: 'Please contact our customer service; we\'d love to help',
    chatRepresentative: 'Chat with us',
    qrCodeDownload: "Download QR",
    scanExplanation: "Scan this QR code find us on WeChat",
    cashBack: 'myCashBack',
    cashBackReceive: 'Get CashBack',
    cashBackLabel: 'CashBack',
    cashBackBalance1: 'Hey, You got CashBack!',
    cashBackBalance2: 'Your balance is:',
    cashBackCredit: 'Redeem Cashback',
    cashBackModalMessage: 'Surprise!',
    cashBackModalMessage2: 'You have',
    cashBackModalMessage3: 'from us',
    cashBackModalMessage4: 'You can use it now and pay less',
    cashBackModalMessageConfirm: 'Great! I want to use it',
    cashBackModalMessageCancel: 'I will keep collecting',
    cashBackPageTitlePlus: 'Great! You already got',
    cashBackPageTitleZero: 'Get money back',
    cashBackPageMessagePlus: 'How to get more CashBack?',
    cashBackPageMessageZero: 'How to earn CashBack?',
    cashBackPageMessage2: 'Just use GMT services as always and earn money back',
    cashBackPageMessage3: 'Once you\'ve reached',
    cashBackPageMessage4: '$/€ you can start using it or keep on collecting',
    cashBackPageFotter: 'You receice CashBack in the same currency of your transfer',
    cashBackPageFotter2: 'You can use your CashBack in myGMT or at any GMT branch (soon)',
    cashBackPageFotter3: 'If You didn\'t visit us for more than 6 months, your CashBack account will empty',
    cashBackPageFotter4: 'Read the full T&C>',
    cardMargketing: {
        title: 'PrePaid Card in cooperation with ',
        subtitle: 'We are happy to offer you the convenient way to control your expenses without the need for a bank account!',
        info1: {
            title: 'Card benefits:',
            con1: 'Purchases in Israel, abroad and online',
            con2: 'Withdrawing money in Israel and abroad from any ATM'
        },
        info2: {
            title: 'You can always:',
            con1: 'View your card\'s balance',
            con2: 'Track purchases',
            con3: 'Recover your pin code',
            con4: 'Block a lost or stolen card'
        },
        footer: {
            1: 'The new card is available at GMT branches',
            2: 'For more details and to order a card'
        },
        btn: 'Write to us on WhatsApp'
    },
    cardActionsModalTitle: 'Actions for card',
    cardRoute: 'Prepaid Card',
    cardHystory: 'Previous Transactions',
    cardBlock: 'Block card',
    cardBlockBtn: 'Block',
    cardUnBlock: 'Activate Blocked Card',
    cardUnBlockBtn: 'Activate',
    cardBlockConfirm: {
        1: 'We blocked the card for you, but you can reactivate it again on the website',
        2: 'For any question, we are here for you',
        3: 'contact us'
    },
    walletOrder: {
        deposited: 'Deposit',
        verification: 'This is your verification code',
        getVerification: 'Get your code',
        showTo: 'Show it to the teller',
        expiry: 'The code will be expired in ',
        minutes: 'min',
        returnHere: 'You can always return here from the ',
        mainPage: 'main page',
        cancel: 'Cancel this order',
        hooray: 'Hooray',
        close: 'Close',
        depositedToYourWallet: 'We deposited ',
        depositedToYourWallet1: 'to your wallet',
        cancelModalTitle: 'Do you want to cancel this order?',
        cancelModalText: 'you can always make another one',
        goToActiveOrder: 'Your deposit code',
        depositCash: 'Deposit cash to your wallet',
        btnOk: 'Approve',
        btnReturn: 'Go back',
        amount: 'Amount',
        summaryOfDetails: 'Summary of transfer details',
        transferType: 'Transfer Type'
    },
    wallet: {
        menu: 'My Wallet',
        balance: 'my Wallet: ',
        payWith: 'Confirm and pay with my Wallet',
        payment: {
            title: 'my Wallet',
            body: 'After you press the “Place your Order” button we will use your account credit to make the transaction for you, and your balance will be updated accordingly.',
            footer: 'Your balance will be updated to {currency}{amount} after this  payment',
            inActive: {
                footer: "We're sorry, Your wallet balance is low. You can change the amount or deposit more funds to your wallet account"
            }
        },
        main: {
            title: 'Hi {name},',
            card: {
                title: 'Your Wallet Balance',
                transactionsLink: 'View all your transactions>'
            },
            depositToWallet: 'Load your wallet by',
            sendMoney: 'Send Money',
            walletOptions: 'Wallet Options',
            depositActions: {
                cash: 'Cash\nDeposit',
                bank: 'Bank\nTransfer',
                card: 'Card\nPayment'
            },
            sendActions: {
                sendHome: 'Send Money Home',
                sendWorldWide: 'Send Money World Wide',
                inIsrael: 'Send Money in Israel',
            },
            walletActions: {
                loadPrePaid: 'myGMT PrePaid Card',
                send: ' Send to myGMT Friends',
                invite: ' Invite Friends, Get CashBack'
            },
            doarInstruction: 'How to transfer money from Bank Hadoar'
            // doarInstruction: 'GMT\'s New year big offer!'
        },
        invite: {
            title: 'Invite friends and get CashBack',
            body: 'You and your friends can get more from myGMT.\nSend your personal registration link to your friends. After their first transaction on myGMT both of you will get {amount} ILS CashBack!\n\nYou can share your personal registration link using the buttons below',
            shareActions: {
                copy: 'copy to your clipboard',
                copied: 'copied',
                whatsapp: 'Invite via WhatsApp',
                wechat: 'Invite via WeChat',
                line: 'Invite via Line',
                general: 'Share'
            },
            inviteMessages: {
                firstLine: 'Hi, join myGMT! with my personal link below start your registration',
                secondLine: 'to myGMT and we\'ll both get CashBack!>'
            }
        },
        deposit: {
            cash: {
                title: 'Deposit Cash to your wallet',
                body: 'You Can Deposit cash at any GMT location.\nAfter a few seconds your myGMT wallet will be updated and you will be able to use it immediately',
                locations: 'GMT Locations>',
                walletOrderExplanation: {
                    depositAnywhere: 'You Can Deposit cash at any GMT location.',
                    amount: 'Type the amount you want to deposit',
                    verificationCode: 'Get a verification code',
                    teller: 'Bring the funds & show the code to the teller.',
                    finishTitle: "That's it!",
                    finishText: "Your wallet account will be credited immediately"
                }
            },
            bank: {
                title: 'Transfer from your bank or HaDoar account to your wallet',
                body: 'Make a bank transfer to GMT and get it directly to your wallet account.\nYour employer can also transfer money to your wallet from their account.',
                transferDetails: 'Account Details:\nGMT tech innovation LTD\nBank HaPoalim 12\nBranch 613\nAccount 545690',
                uploadDetails: 'Upload your transfer confirmation here, Don\'t forget to mention your passport and mobile numbers',
                uploadConfirmationBtn: 'Upload confirmation',
                placeholder: 'Add bank transfer confirmation',
                upload: {
                    title: 'Upload your transfer confirmation',
                    loader: 'We are uploading the Photo you added',
                    footer: 'Don\'t forget to mention your passport and mobile numbers',
                    success: 'Wonderful! Our staff will get on it ASAP and your wallet balance will be updated soon.\nWe will also send you a confirmation by SMS and email.\n'
                }
            }
        },
        friends: {
            title: 'Send to myGMT Friends',
            addFriend: 'Add a friend\'s wallet',
            newFriend: 'Send to myGMT wallet',
            contacts: 'Choose from my Contacts',
            addContactByPhone: 'transfer to unsaved contact',
            searchByNameOrPhone: 'search by name or number',
            header: 'With our easy-to-use money transfer feature, you can securely send funds to any of your myGMT friends. \n It\'s quick and simple!',
            mobilePhone: 'mobile number',
            bulletsOne: 'Select your friend\'s contact number.',
            bulletsTwo: 'Enter the amount you wish to send.',
            bulletsThree: 'Confirm the transaction.',
            outro: 'That\'s it! Your friend\'s balance will be credited immediately. \n Start transferring money effortlessly today!',
            inviteBnt: 'Invite friends to myGMT',
            list: {
                title: 'Friends List'
            },
            addModal: {
                title: 'Do you want to add',
                footer: 'to your Wallet\'s friends ?',
                saveBtn: 'Save',
                closeBtn: 'Cancel'
            },
            friendCard: {
                title: 'Wallet',
                delete: 'Delete',
                deleteDialogFirstLine: 'Do you want to delete {beneficiary} from your Wallet\'s Friends list?',
                deleteDialogSecondLine: 'This will not effect existing transfers',
                send: 'Send to Wallet'
            }
        },
        transfer: {
            amount: {
                title: 'Send Money to myGMT wallet'
            },
            send: {
                title: 'Send {amount} ILS to myGMT wallet'
            },
            summary: {
                title: 'Send {amount} ILS to myGMT wallet'
            },
            pay: {
                title: 'Send {amount} ILS to myGMT wallet'
            },
        },
        load: {
            amount: {
                title: 'Deposit to your wallet & pay with your Card'
            },
            send: {
                title: 'Deposit to your wallet'
            },
            summary: {
                title: 'Deposit to your wallet'
            },
            pay: {
                title: 'Deposit to your wallet'
            },
        },
        card: {
            amount: {
                title: 'Reload myGMT Card with your Wallet'
            },
            send: {
                title: 'Reload myGMT Card with your Wallet'
            },
            summary: {
                title: 'Reload myGMT Card'
            },
            pay: {
                title: 'Reload myGMT Card'
            }
        }
    },
    loadCard: 'Reload with Your Wallet',
    pay: 'Place Your Order',
    cardUnBlockConfirm: 'Your card is now active',
    cardMerchantAddress: 'Address',
    cardMerchantPhone: 'Phone',
    cardPresented: 'Card Presented',
    changeAmount: 'Want to change the send amount?',
    changePayoutAmount: 'Want to change the received amount?',
    changeDepositAmount: 'Want to change the deposit amount?',
    changeAmountLabel: 'New amount in {amount}',
    changeAmounBtn: 'Calculate',
    city: 'City',
    commission: 'Commission',
    confirmMarketingContent: ' I want to receive marketing content and updates',
    confirmCreditNumber: 'Important! Please carefully review the card number, it is impossible to refund funds that have been deposited into a card abroad',
    confirmBankAccount: 'Important! Please carefully review the account details, it is impossible to refund funds that have been deposited into an account abroad.',
    confirmBankAccount2: 'The Beneficiary account will be credited in about 2-4 working days.',
    contactBtnCaption: 'Service Page >',
    contactMenuCaption1: 'Telegram',
    contactMenuCaption2: 'Messenger',
    contactMenuCaption3: 'Call',
    contactMenuCaption4: 'Whatsapp',
    country: 'Country of Origin',
    countryBirth: 'Country of Birth',
    countryDestination: 'Country of destination',
    countryFrom: 'From Country',
    correspondentReference: 'Reference',
    cookies: 'This website uses Cookie files. If you continue using this website, you give your consent for using cookies. For further details please refer to the <a style="text-decoration:underline;display: inline;" href="https://gmtonline.co.il/en/privacy/" target="_blank">Privacy Policy </a>',
    clone: 'Send again',
    payingReference: 'Order number',
    creditCard: 'Credit Card',
    crediting: 'credit‏',
    creditCardOwner: 'Name on card',
    creditCardSupport: 'We work with',
    creditCardCommissionMsg: 'Your credit card company may take a commission for an exchange transaction',
    creditCardSecurityMsg: 'We don\'t save your card\'s details, we only send it to the credit card company for  approval',
    cardDepositAccepted: 'The service is provided for Visa / Mastercard only',
    Contact: 'Contact',
    currency: 'Currency',
    currencyDestination: 'Recipient Currency',
    currencyILS: 'ILS',
    currencyUSD: 'USD',
    currencyEUR: 'EUR',
    currencyCardMsg: 'The final received amount depends on the type and currency of the beneficiary\'s card',
    currencyBankMsg: 'The final received amount depends on the type and currency of the beneficiary\'s account',
    dateBirth: 'Date of Birth',
    daysWork: 'Sunday to Thursday',
    daysWork2: 'Sunday to Thursday 08:30-19:00, Friday 08:30-14:00',
    daysWork3: 'Friday',
    destination: 'Destination',
    discount: 'Discount',
    docsTitle: 'Please scan your valid Identification',
    docsTitleUploaded: 'Thank you, we\'re almost done!',
    documentsUploading1: 'We\'re saving the photo you added',
    documentsUploading2: 'it will take a few more seconds',
    documentsUploading3: 'then press the next step button',
    editUserProfile: 'My Details',
    email: 'Email Address',
    errorCode: 'Invalid code, please try again',
    estimated: 'Estimated',
    calculatedAbroad: 'will be calculated abroad',
    expired: 'Expired',
    extraDetails: 'More Details',
    exchangeRate: 'Exchange rate',
    byExchangeRate: 'Exchange rate by',
    fax: 'Fax',
    for: 'For',
    finished: 'Finished',
    foreignPassport: 'I have a foreign passport',
    to: 'to ',
    freeSearch: 'Search',
    friday: 'Friday',
    fullName: 'Full Name',
    fun: 'We promise to only update you on the important things.',
    foreignCurrencyHandlingFee: 'Foreign currency handling fee',
    gender: 'Gender',
    here: ' here',
    hours: 'Please note! You must get to a GMT Location in the next 48 Hours to complete the payment',
    houseNumber: 'House number',
    welcome: 'Welcome',
    interest: '{inter} Interest included',
    interested: 'Meanwhile, you can go to our Blog',
    israel: 'Israel',
    israeliID: 'I have an Israeli ID',
    israeliResident: 'Israeli resident',
    nonIsraeliResident: 'Foreign resident',
    installments: 'Payments',
    inProcess: 'in process',
    itemNavMain: 'My Wallet',
    itemNav0: 'myCashBack',
    itemNav1: 'Send Money',
    itemNav10: 'Branches',
    itemNav2: 'My History',
    itemNav3: 'Rates & Fees',
    itemNav4: 'My Beneficiaries ',
    itemNav5: 'GMT Locations',
    itemNav6: 'FAQ',
    itemNav7: 'Contact Us',
    itemNav8: 'Terms & Conditions',
    itemNav9: 'Log Out',
    itemNav11: 'About',
    itemNav12: 'PrePaid Card',
    itemNavUserProfile: 'Personal details and settings',
    itemNav13: 'Foreign Currency Order',
    labelEmail: 'Email address',
    labelMsg: 'What would you like to say?',
    labelName: 'My First Name',
    labelPhone: 'Phone number',
    lblDone: 'The transfer is made as a gift or support to a first-degree relative, and it does not constitute income for the beneficiary',
    lblDoneWechat: 'Note that for the first transaction, your recipient needs to approve the WeChat transaction. Tell them to click on the SMS link.',
    lblDoneWechat1: 'Please add 86 before your Chinese mobile number linked with WeChat, or 972 before your Israeli mobile number.',
    labelSurname: 'My Last Name',
    leaveMsg: 'What would you like to say?',
    linkToLoginPage: 'Already registered? Sign in instead>',
    listBranches: 'Find GMT',
    locatingBranches: 'Locating branches',
    locationBtn: 'Open',
    locationBtnClose: 'close',
    loginLabel1: 'ID / Passport number',
    loginLabel2: 'Mobile number',
    logInConsent: 'By logging in, I agree to our ',
    mailingAddress: 'Address',
    mailingRecipient: 'Raul Wallenberg 18C, Tel-Aviv, 6971915',
    marked: 'Open now',
    maybe: 'Meanwhile, you can go to our Blog',
    messageCode: 'We just sent you an SMS with a Code. Please enter the code in the field below',
    messageSend: 'In the next few minutes, we will also send you this confirmation by SMS and email.',
    messageNotSent: 'Experiencing difficulty receiving your OTP? Please check your SMS inbox and spam folder. We would be happy to assist with any questions.',
    messageRegistration1: "Hi, We're happy you are back!",
    messageRegistration2: "We just need you to upload your ID or Passport, approve our T&C and we're done",
    messageUploadDocuments: 'It seems that your Identification document is not valid anymore, please upload a new one',
    middleName: 'Middle name',
    mobilePhone: 'Mobile number',
    mobilePhoneNumber: 'Israeli mobile number',
    monday: 'Monday',
    moneyApproved: 'Money transfer was approved !',
    MoneyGram: 'MoneyGram',
    moreOptions: 'More Options',
    msgError: 'Use only numbers',
    maxAccessionCommission: 'Joining Commission',
    myId: 'My ID',
    nameBeneficiary: 'Beneficiary Name',
    newBeneficiary: 'New Beneficiary',
    version: 'v {num}',
    updateBeneficiary: 'Beneficiary\'s Details',
    nextLevel: 'Next',
    no: 'No',
    noBeneficiaries: 'No beneficiaries were defined in the system',
    noRecentActions: 'Welcome to myGMT your personal zone',
    noRecentActions1: 'Here you can see all your history with GMT',
    noRecentActions2: 'Now lets send money!',
    noCardRecentActions: 'No Transactions were found',
    notAvailable: 'Unavailable',
    notRequired: 'not mandatory',
    numberPassport: 'Passport number',
    numberOfTransaction: 'Invoice number',
    openNow: 'open',
    paymentFrom: 'Payment from',
    payUsing: 'I will pay by',
    toPay: 'payment',
    payRate: 'rate {rate}',
    paymentCredit: 'Credit Card',
    photoID: 'ID, Drivers license or Passport',
    photoRequired: 'Please add a photo',
    pinCode: 'View Pin Code',
    pinCodeModalMessage: 'Do you want to view the pin code of the card ending with {digits}',
    pinCodeModalMessage2: 'We sent you a text message to your mobile phone to view the pin code',
    placeholderDigit: '9 digit ID number',
    questionsAnswers: 'FAQ',
    questionsAnswersSubtitle: 'Can\'t find an answer to your question? Please contact us in any of the ways listed on the contact page',
    reasonTransfer: 'Reason of Transfer',
    recentActions: 'My History',
    recentActionsTab1: 'New Beneficiary',
    recentActionsTab2: 'Foreign exchange service to Ben Gurion Airport',
    recentActionsTab3: 'Charging Card',
    recentActionsTab4: 'Receiving money',
    recentActionsTab5: 'Buying Bitkoiin',
    recommended: 'Recommended!',
    recentActionsBeneficiaries: 'My Beneficiaries',
    recentActionsTransfer: 'Send Money',
    r_step_address: 'Address',
    r_step_doc: 'Doc',
    r_step_name: 'Name',
    rate: 'rate',
    registerSystem: 'Registration to myGMT',
    registerSystemAddDocuments: 'Upload Identification Document',
    registerText1: 'In just a moment, you will be able to send money',
    registerText2: 'Get your ID/Passport, we will ask you to take a picture of it later, let\'s get started!',
    registerFooter: 'GMT operates under licence of the Ministry of Finance',
    registerFooter2: 'Extended Financial Service Provider - License number 57479',
    registerFooter3: 'Our service is encrypted and secured and we will never share your details.',
    registrationSuccess: 'Registration completed successfully!',
    registrationSuccess1: 'Thanks! We have received your details',
    registrationSuccess2: 'With myGMT you can send money abroad easily and fast.',
    requestSuccessfully: 'Your request was successfully received',
    requestSuccessfully1: 'Your order number is: ',
    requestSuccessfully2: 'What now? You need to pay for this transfer in the next 24 hours',
    requestSuccessfully3: '<a href="https://gmtonline.co.il/en/locating-branches/" style="color:#d4c641;text-decoration: underline;">at one of GMT branches</a>',
    requestSuccessfully4: 'then it will be sent abroad',
    riskyLimitMsg: 'Higher amounts? Contact our support team.',
    russianSpeaker: 'Service in Russian',
    saturday: 'Saturday',
    searchResult: 'Found X branches in XXX ',
    selectBeneficiary: 'My Beneficiaries ',
    selectCity: 'Search by city',
    selectCountry: 'Origin country',
    selectProvince: 'Select Province',
    sendMarketingContentLbl: 'Send me special offers & updates',
    sentOn: 'Date',
    signedUp: ' You signed up!',
    soonPossible: 'We\'ll get back to you during our office hours.',
    staging: {
        payWith: 'Cash at GMT location',
        body: 'you\'ll press the "place your order" button, we will prepare the transfer for you. The transfer will be sent after you pay at one of GMT\'s locations.',
        order: 'Place Your Order'
    },
    streetName: 'Street',
    streetName_HouseNumber: 'Street name and house number',
    subscribeDesc: 'All the latest news in the FinTech world, right here  ',
    subscribeNewsletter: 'Sign up for our newsletter - it is worthwhile!',
    subtitleCustomerService: 'Please contact our customer service; we\'d love to help',
    summaryTransfer: 'Summary of transfer details',
    sunday: 'Sunday',
    surtitleFormLang: 'Choose the right language for you',
    smartBannerTitle: 'Upgrade to myGMT app',
    smartBannerText: 'Easy. Fast. Safe. Just for you.',
    smartBannerBtnText: 'GET',
    system: 'System',
    correspondentSystem: 'Branches of',
    termsUse: 'T&C',
    imprtant: 'Important',
    termsTitle: 'Please read and approve the T&C\'s',
    tpinWarning: 'To ensure your transaction is successful, please verify that your beneficiary’s TPIN and mobile wallet number are registered under the same ID (NRC or Passport). For TPIN registration or verification, visit a Zambia Revenue Authority office or go to zra.org.zm.',
    surveyModal: {
        active: true,
        title: 'Share Your Thoughts: Take Our Quick Anonymous Survey',
        body: 'Hello {name}, we want to hear from you! Help us improve our financial services by answering a short, anonymous survey. Your opinions matter.',
        confirm: 'Start Survey Now',
        cancel: 'later',
        link: 'https://forms.gle/P4Y5u8cbqjpRuGuA6'
    },
    termsUpdate: {
        title: 'T&C and Privacy Policy',
        body: {
            first: 'Hello, we updated our legal documents,',
            second: 'you can read them here>'
        }
    },
    thanks: 'Thanks for reaching out!',
    thursday: 'Thursday',
    titleFormLang: 'Welcome',
    titleLoginPage: 'Sign in to myGMT',
    toBankAccount: 'to Acc',
    toWalletAccount: 'to Wallet',
    pickupPoint: 'pickup point',
    toCreditCard: 'to card',
    totalNIS: 'Total in NIS',
    totalTransfer: 'Total to Pay',
    total: ' Total ',
    t_step_amount: 'Amount',
    t_step_transfer: 'Transfer',
    t_step_summary: 'Summary',
    t_step_pay: 'Pay',
    transferAmount: 'Amount',
    transactionAmount: 'Amount',
    transactionCharge: 'Sort by',
    filterPaymentType: 'Payment type',
    transactionType: 'Transaction type',
    transferFundsTo: 'Send',
    transferOptions: 'Transfer options',
    transferType: 'Transfer Type',
    transferTypePick: 'Please choose your transfer method',
    tuesday: 'Tuesday',
    waitingPayment: ' - Awaiting Payment',
    wednesday: 'Wednesday',
    yourCardBalance: 'Card\'s Balance',
    zipCode: 'Postal code',
    loginAgreementRequired: 'Please read and accept terms of service',
    customerCommission: 'Your Commission',
    customerCommissionMaxTopup: 'Top Up Commission',
    customerEmailNotification: {
        title: 'Update you email',
        body: 'We do not seem to have your email,\nYou can update it in the field below so we can send you a copy of each transaction by email.\nWe will never send spam.',
        cancel: 'Skip'
    },
    sumAmount: 'Sum Amount',
    terms: 'Terms & Conditions',
    error: 'Error',
    generalErrorMsg: 'We\'re sorry, we are updating the website to improve our services. We recommend to try again later. We would be happy to assist with any question',
    connectionError: 'connectionError',
    locationLink: 'en/locating-branches/',
    faqLink: 'en/faq/',
    contactUsLink: 'en/contact-us/',
    protocolLink: 'en/protocol/',
    privacyLink: 'en/privacy',
    privacyPolicy: 'Privacy Policy',
    profileDelete: "Delete your profile",
    profileDeleteModalTitle: "Do you want to cancel your myGMT Profile?",
    profileDeleteModalText: "By clicking the approve button, \n we will receive a message and get back to you ASAP",
    aboutLink: 'en/my-gmt/',
    paymentProcessing: 'Please wait as we process your request.',
    paymentType: 'Payment type',
    paymentTypeCredit: 'Pay with Credit Card',
    paymentTypeByCredit: 'Paid by Card ',
    paymentTypeCash: 'Cash',
    uploadDocumentMsg1: 'We must verify your Identification, so please take a photo of your document.',
    uploadDocumentMsg2: 'Be sure we take your privacy seriously. Our site is encrypted and secured and we will never share your details.',
    yes: 'Yes',
    zipCodePlaceholder: '7 numbers',
    zipCodeRequiredFields: 'Please fill up your address details, so we could lookup your Postal code',
    zipCodeNotFound: 'We could\'nt find the Postal code to that address, please check it again or fill your 7 digit Postal code',
    CORRESPONDENT_BLOCKED: 'We\'re sorry, this service is down for maintenance, We recommend to try again later. We would be happy to assist with any question',
    CARD_DEPOSIT: 'Deposit to a Card abroad',
    CARD_DEPOSIT_MAX: 'Card Top Up',
    CARD_DEPOSIT_TO: 'Deposit to a Card in {dest}',
    CASH_TRANSFER: 'Cash Pick Up abroad',
    CASH_TRANSFER_INLAND: 'Cash Pick Up',
    CASH_TRANSFER_ISR: 'Cash Pick Up',
    CASH_TRANSFER_TO: 'Cash Pick Up in {dest}',
    BANK_TRANSFER: 'Deposit to Account abroad',
    UPI_BANK_TRANSFER: 'Deposit to Account abroad by UPI',
    BANK_TRANSFER_ISR: 'Deposit to Account',
    BANK_TRANSFER_TO: 'Deposit to Account in {dest}',
    BANK_TRANSFER_PAYMENT: 'Bank Transfer',
    WALLET_TRANSFER: 'Deposit to eWallet abroad',
    WALLET_TRANSFER_TO: 'Deposit to eWallet in {dest}',
    WALLET_TO_WALLET: 'Send to myGMT wallet',
    WALLET_FROM_WALLET: 'Receive from myGMT wallet',
    WALLET_DEPOSIT: 'Deposit to myGMT wallet',
    NEW_CUSTOMER: 'Hi, It will take us up to 24 hours to complete your registration. Meanwhile, the credit payment option is not available. We will notify you as soon as it ready.',
    CUSTOMER_NOT_AUTHORIZED: 'We are sorry, the credit card payment option is not available in your profile. We will be happy to assist with any Question',
    CUSTOMER_NOT_SIGNED: 'We are sorry, you didn\'t finish your registration so the credit card payment option is not available yet in your profile.  We would be happy to asist with any questions.',
    CREDIT_LIMIT_UNREGISTERED: 'You can send this month up to ',
    CREDIT_LIMIT: 'This month you can send up to ',
    CREDIT_LIMIT_MONTHLY: 'This month you can send up to {amount}',
    CREDIT_LIMIT_CREDIT_CARD: 'The transaction amount exceeds our monthly limit by credit card. You can use up to {amount}',
    CREDIT_LIMIT_WALLET: 'The transaction amount exceeds our monthly limit by wallet. You can use up to {amount}',
    CREDIT_LIMIT_RISKY: 'You can send to {country} up to',
    TRANSACTION_LIMIT: 'We are sorry, this transfer is limited to {currency}{amount} per transaction. You can update the amount and send it. We will be happy to help with any questions.',
    CREDIT_CARD_ILS_AMOUNT: 'Calculating the sum in ILS, payment will be available immediately',
    CREDIT_NOT_ACTIVE: 'We are sorry, the credit card payment option is not available at the moment. It is open 06:00-24:00 regulary.',
    CREDIT_BLOCKED: 'We\'re sorry, the credit card payment option is not available at the moment as we are updating the website. We recommend to try again later. We would be happy to assist with any question',
    API_EXCEPTION: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    GRAILS_VALIDATION_ERROR: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    CAPTCHA_NOT_VERIFIED: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    CUSTOMER_SESSION_NOT_FOUND: 'The identification details are incorrect or you have\'nt yet registered to myGMT, you can do it here> <a href="https://mygmt.gmtonline.co.il/#/registration" style="color:#d4c641;text-decoration: underline;">mygmt.gmtonline.co.il</a>',
    CUSTOMER_BLOCKED: 'Sorry, your profile is closed, Please contact our <a href="https://gmtonline.co.il/en/contact-us/" style="color:#d4c641;text-decoration: underline;">Customer Service > </a>',
    SMS_WRONG: 'The code you entered is wrong',
    SMS_EXPIRED: 'Sorry, the code is not valid any more, you can get a new one',
    REGISTRATION_TOKEN_NOT_FOUND: 'The code you entered is wrong',
    REGISTRATION_TOKEN_EXPIRED: 'Sorry, the code is not valid any more, you can get a new one',
    SESSION_EXPIRED: 'You\'re Back! We thought you left so we logged out from the page to keep it safe. you can start again',
    CUSTOMER_MOBILE_NOT_MATCH: 'Sorry, to register to myGMT please contact our <a href="https://gmtonline.co.il/en/contact-us/" style="color:#d4c641;text-decoration: underline;">Customer Service > </a>',
    WALLET_FRIEND_NOT_UNIQUE: 'Sorry, we can not add this number to your friends list. We wil be happy to assist, please press on "contact us" to ask any question',
    CUSTOMER_ALREADY_REGISTERED: 'Hi! We think you are already registered to myGMT, you can login here > <a href="https://mygmt.gmtonline.co.il" style="color:#d4c641;text-decoration: underline;">mygmt.gmtonline.co.il</a>',
    CUSTOMER_DENIED: "Hi, We're happy you are back! You didn't finish you registration last time, please contact us to get it done",
    BENEFICIARY_NOT_FOUND: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    BENEFICIARY_NOT_FOUND_FOR_CUSTOMER: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    TRANSACTION_NOT_FOUND_FOR_CUSTOMER: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    DOCUMENT_NOT_FOUND: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    DOCUMENT_NOT_FOUND_FOR_CUSTOMER: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    MONEYGRAM_VALIDATION_ERROR: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    CREDIT_CARD_SIGN_ERROR: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    CREDIT_CARD_VERIFY_ERROR: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    CREDIT_CARD_COMMIT_ERROR: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    CUSTOMER_MULTIPLE: 'Sorry, to register to myGMT please contact our <a href="https://gmtonline.co.il/en/contact-us/" style="color:#d4c641;text-decoration: underline;">Customer Service > </a>',
    MONEYGRAM_EXCEPTION: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    MONEYGRAM_301: 'exceeded maximun transfer amount',
    MONEYGRAM_304: 'We\'re sorry, our Money Transfer service is closed at the moment. We\'ll be back on working days between 8:00-21:00 and Fridays between 8:00-15:00',
    MONEYGRAM_323: 'We\'re sorry, but the beneficiary\'s name you entered is too long. You can try again with a shorter name. We will be happy to assist.',
    MONEYGRAM_4055: 'We\'re sorry, but MoneyGram has rejected this transfer to protect you, and the transfer was not complete. We will be happy to assist.',
    MONEYGRAM_7003: 'We\'re sorry, but you have reached MoneyGram\'s limit, and the transfer was not complete. We will be happy to assist.',
    FILE_NOT_ALLOWED: 'There is a problem with the file',
    INVALID_SESSION_INFO: 'Sorry, something went wrong. Please try again, it\'s important to us',
    KYC_EXCEPTION: 'Apologies for the inconvenience. To complete the transaction, we require additional details. Please contact customer service for assistance. Thank you.',
    WALLET_FRIEND_ALREADY_ADDED: 'Hi, This number is already in your Friends List.',
    WALLET_FRIEND_BLOCKED: 'Sorry, we can not add this number to your friends list. Please contact us for more information',
    WALLET_FRIEND_NOT_REGISTERED: 'Sorry, the mobile number {mobileNumber} is not on myGMT yet. Use your personal registration link to invite your friends and get CashBack!',
    marketingMessage: 'With myGMT you can send money to your family abroad and pick it up in thousands of payment points with a reference number, to deposit directly to a Credit Card abroad, or to deposit directly to any bank account abroad.',
    marketingMessage2: 'Easy. Fast. Safe. Just for you.',
    YAAD_SARIG_3: 'We are sorry, but the credit company didn\'t approve the transfer. We recommend that you contact it for the issue and then try again. We would be happy to help with any questions.',
    YAAD_SARIG_4: 'We are sorry, but the credit company didn\'t approve the transfer or the card details you entered. We recommend you to try again. We would be happy to help with any questions.',
    YAAD_SARIG_6: 'We are sorry, but there was something wrong with the card details you entered. We recommend you to try again. We would be happy to help with any questions.',
    YAAD_SARIG_26: 'We are sorry, but the card is not belong to your ID number. You can try again with a card that belongs to you. We would be happy to help with any questions.',
    YAAD_SARIG_141: 'We are sorry, but we can only accept Visa and MasterCard cards right now. We would be happy to help with any questions.',
    YAAD_SARIG_303: 'We are sorry, but your credit card does not support the credit company security check, you can contact your card company about it. We would be happy to help with any questions.',
    YAAD_SARIG_447: 'We are sorry, but the credit number you entered is wrong. We recommend that you try again. We would be happy to help with any questions.',
    YAAD_SARIG_553: 'We are sorry, we think you didn\'t approve  the payment to your credit card check, so it was canceled. You can try again. We would be happy to help with any questions.',
    YAAD_SARIG_999: 'We are sorry, something went wrong. We recomend to try again later, we would be happy to asist with any questions.',
    YAAD_SARIG_3000: 'We are sorry, but the credit company didn\'t approve the transfer. We recommend that you contact it for the issue and then try again. We would be happy to help with any questions.',
    YAAD_SARIG_3003: 'We are sorry, but the credit company didn\'t approve the transfer. We recommend that you contact it for the issue and then try again. We would be happy to help with any questions.',
    YAAD_SARIG_9101: 'We are sorry, but your credit card does not support the credit company security check, you can contact your card company about it. We would be happy to help with any questions.',
    YAAD_SARIG_GENERAL: 'We are sorry, something went wrong with the payment, we recomend to try again later. We would be happy to asist with any questions.',
    INVALID_CARD: 'We\'re sorry, but the card you tried to send to is not supported,. You can try again to a differend card. We will be happy to assist.',
    INVALID_VERSION: 'We\'re sorry for the interruption, please refresh the page to visit myGMT latest updates.',
    AMOUNT_LIMITED: 'We\'re sorry, but the amount is to high for the beneficiary\'s card limits. you can try again with a smaller amount. We will be happy to assist.',
    CONTACT_GENERAL: 'We\'re sorry, we are updating the website to improve our services. We recommend to try again later. We would be happy to assist with any question',
    BENEFICIARY_INVALID: 'We\'re sorry, but the beneficiary\'s details of this transfer are wrong. You can fix them and try again. We will be happy to assist.',
    CONTACT_DENIED: 'We\'re sorry, but we had to reject this transfer to protect you, and the transfer was not completed. We will be happy to assist.',
    CARD_DENIED: 'We\'re sorry, but we can\'t send money to this card. We will be happy to assist.',
    CARD_WRONG_DETAILS: 'We\'re sorry, but the details of this transfer are wrong. You can fix them and try again. We will be happy to assist.',
    REGISTER_INFO_DETAILS_REQUIRED: 'Hi, We need your details and ID Document to send money abroad',
    REGISTER_INFO_SEARCH_ZIP: 'Hi, You can search your Postal code by pressing on the magnifying Glass',
    CARD_REJECTED: 'We\'re sorry, but it\'s impossible to send money to your beneficiary now, please contact us for help‎',
    BLACKLIST: 'We\'re sorry, but it\'s impossible to send money to your beneficiary now, please contact us for help‎',
    BLACKLIST_CARD: 'We\'re sorry, but it\'s impossible to send money to your beneficiary now, please contact us for help‎',
    COMPANY_IDENTIFICATION: 'We\'re sorry, It looks like you tried to register with a buissness number. You can register with ID number, we will be happy to assist in any question',
    CUSTOMER_MONTHLY_LIMIT: 'We are sorry, but you have reach the monthly limit with GMT. We will be happy to assist in any question',
    CORRESPONDENT_EXCEPTION: 'We are sorry, we got an error while trying to send your transfer abroad. Please contact us and would be happy to check it and assist you',
    TEMPORARY_ERROR: 'We are sorry, we got an error while trying to send your transfer abroad. Please contact us and would be happy to check it and assist you',
    BAD_REQUEST: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    MAX_EXCEPTION: 'We are sorry, something went wrong, we recomend to try again later. We would be happy to asist with any questions.',
    SERVER_ERROR: 'Sorry, the system is currently being updated. We recommend trying again later, we will be happy to help with any questions.',
    CUSTOMER_DAILY_LIMIT_EXCEEDED: 'We\'re sorry, you have passed the maximum number of transfers for today. For any questions, our customer service will be happy to assist you',
    NEW_CUSTOMER_SESSION_LIMIT_EXCEEDED: 'Glad you joined us! You have passed the maximum number of transfers for new customers in the first week. For any questions, our customer service will be happy to help.',
    NEW_CUSTOMER_SESSION_SUM_LIMIT_EXCEEDED: 'Glad you joined us! You have passed the maximum amount for new customers in the first week. For any questions, our customer service will be happy to help.',
    NEW_BENEFICIARY_LIMIT_EXCEEDED: 'We\'re Sorry, but you can send up to 2 transfers to a new overseas card in a week. For any questions, our customer service will be happy to help.',
    NEW_BENEFICIARY_AMOUNT_EXCEEDED: 'We\'re sorry, You have passed the maximum amount for new cards deposit abroad. For any questions, our customer service will be happy to help',
    BENEFICIARY_CARD_BLOCKED: 'This card number or the bank are not supported by the service, you can try to send to a different card',
    ERROR_NEGATIVE: 'We\'re sorry you can not make a transaction in a negative amount, please update the requested amount.',

    E_DECIMAL: 'Only rounded numbers please',
    E_INVALID_zipCode: 'Postal code is incorrect',
    E_LEMGTH: { 1: '', 2: 'Digits only' },
    E_MAX_VALUE: 'You\'ve exceeded the maximum',
    E_MAX_CHAR: { 1: '', 2: 'Characters maximum' },
    E_MIN_CHAR: { 1: '', 2: 'Characters minimum' },
    E_MIN_passport: 'Must be more than 5 characters',
    E_NUMERIC: 'Use only numbers please',
    E_REGEX: 'The field format is invalid',
    E_REGEX_email: 'Invalid email format',
    E_REGEX_english: 'English only please',
    E_REGEX_mobileNumber: 'Please enter a valid Israeli mobile number with 10 digits',
    E_REGEX_israeliId: 'not valid israeli Id',
    E_REGEX_passport: 'Only letters and numbers please',
    E_REGEX_birthDateAge: 'Only for 18-85 yr old',
    E_REGEX_zipCode: 'Postal code must be 7 numbers',
    E_REQUIRED: 'required field',
    E_REQUIRED_amount: 'What is the amount?',
    E_REQUIRED_currency: 'What is the currency?',
    E_REQUIRED_destinationCountry: 'What is the destination?',
    E_REQUIRED_agreement: 'Please read and accept terms of service',

    STATUS_NOT_FOUND: 'Not found',
    STATUS_CONFIRMED: 'Confirmed',
    STATUS_READY_FOR_PAYMENT: 'Ready for Payment',
    STATUS_PAID: 'Paid',
    STATUS_SENT_TO_BANK: 'Sent to Bank',
    STATUS_BEING_CANCEL: 'Being canceled',
    STATUS_READY_TO_CANCEL: 'Ready for Cancelation',
    STATUS_CANCELED: 'Canceled',
    STATUS_PREPARED: 'Prepared',
    STATUS_FAILED: 'Failed',
    STATUS_RETURNED: 'Returned',
    STATUS_ACCEPTED: 'Accepted',
    STATUS_STAGED: 'STAGED',
    STATUS_INCOMPLETE: 'incomplete',
    STATUS_IN_PROCESS: 'Being paid out',
    STATUS_SWIFT_TRANSMITTED: 'Transmitted',
    STATUS_SWIFT_APPROVED: 'Approved',
    STATUS_REJECTED: 'Rejected',
    STATUS_EXPIRED: 'Expired',
    STATUS_CLOSED: 'Closed',
    STATUS_SUBMITTED: 'Submitted',

    DESC_CASHBACK: 'CashBack',
    DESC_CASHBACK_WELCOME: 'Welcome! CashBack',
    DESC_CASHBACK_BIRTHDAY: 'Happy Birthday! CashBack',
    DESC_CASHBACK_ONETIME: 'CashBack Gift',
    DESC_WALLET: 'Deposit to eWallet',
    DESC_MAX_TOPUP: 'Card Top Up',
    DESC_MAX_PURCHASE: 'Card Purchase and Top Up',
    DESC_MAX_ORDER: 'Card reservation',
    DESC_CONVERSION: 'conversion',
    DESC_019MOBILE: '019 mobile',
    DESC_REWIRE: 'Rewire',
    DESC_DEPOSIT_3MONTHS: '3 months deposit plan',
    DESC_DEPOSIT_6MONTHS: '3 months deposit plan',
    DESC_DEPOSIT_WITHDRAW: 'Withdraw from deposit account',
    DESC_DEPOSIT_INTEREST_PAYMENT: 'Credit of accumulated Interest',

    DEPOSIT_IN: 'Transfer to Deposit Account',
    DEPOSIT_OUT: 'Withdrawal from Deposit Account',
    DEPOSIT_INTEREST: 'Credit of accumulated Interest',

    NAME_ALIPAY: 'ALIPAY',
    NAME_UNIONPAY: 'UNIONPAY',
    NAME_WECHATPAY: 'WECHATPAY',
    NAME_UPI: 'UPI',

    validation: {
        mock: '',
        beneficiary: {
            invalid: 'Invalid value',
            maskedCardNumber: {
                invalidCountry: 'The card does may not match the destination country',
                tranglo: 'Sorry, the beneficiary\'s details you entered are incorrect. Please check, fix and try again'
            },
            accountIdentifier: {
                invalid: 'Invalid value',
                tranglo: 'Sorry, the beneficiary\'s details you entered are incorrect. Please check, fix and try again'
            },
            walletIdentifier: {
                invalid: 'Invalid value',
                tranglo: 'Sorry, the beneficiary\'s details you entered are incorrect. Please check, fix and try again'
            },
            phoneWallet: {
                unavailable: 'Unfortunately, this account is unavailable and can not received funds. Please check the account before trying again.',
                mismatch: 'Unfortunately, the beneficiary name and account do not match. You can fix the details and try again.',
                invalidAccount: 'Unfortunately, the account is invalid. Please check the account before trying again.',
                barred: 'TPIN not registered. Please register TPIN under the same ID (NRC or Passport).'
            },
            phoneNumber: {
                format: {
                    passParam: 'use correct format: {format}'
                }
            },
        }
    }
}