<template>
<div :class="[divClass, {'progress': inProcess}, {'card_disabled': !active }]">
  <div @click="active && !inProcess ? $emit('action') : ''" >
    <i class="icon icon-action" :class="icon"></i>
    <div class="item-text">
      <span class="item-title" v-text="$ml.get(title)"></span>
      <span v-if="!active">&nbsp;&nbsp;-&nbsp;&nbsp;{{ $ml.get('notAvailable') }}</span>
      <div v-show="active" class="item-subtitle">
        <span>{{paymentCurrency}}{{paymentAmount | formatComma}}</span>
        <!-- <span v-if="exchangeNeeded || installmentDetails" class="item-subtitle">  = {{currency}}{{totalAmount | formatComma}}</span> -->
        <!-- <span v-if="exchangeNeeded" class="item-subtitle" >{{' ' + $ml.get('rate') + ' '}}{{rate}}</span> -->
        <span v-if="installmentDetails" class="item-subtitle" v-text="' (' + $ml.with('inter', paymentCurrency + installmentDetails.interest).get('interest') + ' )' "></span>
      </div>
    </div>
    <div v-if="active" class="pickPaymentType">
      <span v-if="$store.state.locale.lang!=='il'"> <img class="arrow-next" src="../assets/images/icons/i-arrow-right.png"> </span>
      <span v-if="$store.state.locale.lang==='il'"> <img class="arrow-next" src="../assets/images/icons/i-arrow-left.png"> </span>
    </div>
    <hr>
  </div>
    <div class="payment-type-footer">
      <ul>
        <li v-if="active || !inActiveFooter" class="item-subtitle">
          <span v-text="footer"></span>
        </li>
        <li v-if="!active && inActiveFooter" class="item-subtitle" style="color: #092086">
          <span v-text="inActiveFooter"></span>
        </li>
      </ul>
    </div>
  <div v-if="installment && active" class="installments item-subtitle">
    <div :class="['form-group', {'custom-select': !installmentDetails}]">
      <span for="installments" v-text="$ml.get('installments')" ></span>:
      <select name="installments" id="installments" class="form-control select-installments" v-model="installments"> 
          <option v-for="idx in [1,2,3,4,5,6]" :value="idx" :key="idx" v-text="idx"></option>
      </select>
    </div>
     <div class="installment-details">
      <span v-if="installmentDetails">* ₪{{installmentDetails.instalment | formatComma}}</span>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    action: {
      type: String
    },
    icon: {
      type: String
    },
    divClass: {
      type: String
    },
    title: {
      type: String
    },
    footer: {
      type: String
    },
    inActiveFooter: {
      type: String
    },
    totalAmount: {
      type: Number
    },
    paymentAmount: {
      type: Number
    },
    currency: {
      type: String
    },
    paymentCurrency: {
      type: String
    },
    exchangeNeeded: {
      type: Boolean
    },
    rate: {
      type: Number
    },
    active: {
      type: Boolean,
      default: true
    },
    installment: {
      type: Boolean
    },
    installmentDetails: {
      type: Object, default: () => { return {} }
    },
    inProcess: {
      type: Boolean,
      default: false
    },
  }, 
  data(){
    return { installments: 1 }
  },
  watch: {
      installments() {
          this.$emit('installments-action', this.installments);
      }
  },
}
</script>

<style lang="scss">

  .installments{
    margin-top:1px;
    text-align: start;
    cursor: default;
    margin-inline-start: 13%;
    font-size:14px;

    .select-installments{
      margin-inline-start:-5px;
      height:32px;
      cursor: pointer;
      background-color:inherit;
      width:50px;
      border:none;
    }
  }
  .installment-details{
    display: inline-block;
    margin-inline-start:-25px!important;
  }

</style>
