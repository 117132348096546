<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div class="main-wrapper main-wrapper-logged-out">
      <MainNav></MainNav>
      <div class="content block block-login download-wrapper">
        <h1 class="title" v-text="$ml.get('appDownload')"></h1>
        <img src="../assets/images/apple-white.png" class="apple" @click="open('apple')">
        <img src="../assets/images/google-white.png" class="google" @click="open('android')">
        <a class="apk" v-if="queryParams.apk" @click="downloadApk()">
          <i class="fab fa-android"></i>
          <span>{{ $ml.get('appDownloadBtn') }}</span>
        </a>
        <lottie-player id="homepage" autoplay loop mode="normal" />
        <div style="position:absolute;visibility: hidden">
          <a id="iOS" href="https://apps.apple.com/app/mygmt-money-transfer-abroad/id1666510532" v-text="'iOS'" />
          <a id="Android" target="_blank" href="https://play.google.com/store/apps/details?id=il.co.gmtonline.mygmt"
            v-text="'Android'" />
          <a id="Other" href="/#/" v-text="'Other'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import { isApp, loadLottieJson, detectUserOs } from '../services/utils'
import { Validator } from "vee-validate";

export default {
  name: 'Download',
  components: { MainNav, Header },
  data() {
    return {
      queryParams: {}
    }
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
  },
  methods: {
    downloadApk() {
      window.open("https://gmtonline.co.il/wp-content/uploads/APK%20app%20download/app-release.apk")
    },
    open(platform) {
      switch (platform) {
        case 'apple':
          window.open('https://apps.apple.com/app/mygmt-money-transfer-abroad/id1666510532')
          break;
        case 'android':
          window.open('https://play.google.com/store/apps/details?id=il.co.gmtonline.mygmt')
          break;
      }
    },
    goHome() {
      this.$router.push('/login')
    },
  },

  created() {
  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
    Validator.localize(this.dictionary());
    Validator.localize(this.getLang);
    this.queryParams = this.$route.query
    if (isApp()) {
      this.$router.push('Login')
    }
    if (!this.queryParams.apk) {
      switch (detectUserOs()) {
        case 'iOS':
          $("#iOS")[0].click()
          break;
        case 'Android':
          $("#Android")[0].click()
          break;
      }
    }
    loadLottieJson("homepage", "homepage_v5")
    $(".cookie-consent").css('display', 'none')
  },
}
</script>
<style scoped lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.main-wrapper {
  min-height: auto !important;
}

#homepage {
  display: none !important;
  transform: scale(3.6) translate(5px, 65px) !important;
  position: relative !important;
  max-height: 270px !important;
}

.download-wrapper {
  display: flex;
  justify-content: center;

  .apple {
    position: absolute;
    width: 50%;
    height: 55px;
    max-width: 220px;
    min-width: 220px;
    top: 540px;
    z-index: 2;
  }

  .apk {
    position: absolute;
    width: 50%;
    height: 55px;
    max-width: 220px;
    min-width: 220px;
    top: 620px;
    z-index: 2;
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #575a66;

    span {
      font-weight: 600;
      color: #575a66;
      white-space: break-spaces;
      position: relative;
      inset-inline-end: 11px;
      min-width: 122px;
    }

    i {
      font-size: 30px;
      position: relative;
      inset-inline-end: 22px;
    }
  }


  .google {
    top: 460px;
    position: absolute;
    width: 50%;
    max-width: 220px;
    height: 55px;
    z-index: 2;
    min-width: 220px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1070px;
    /*  -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;*/
    //background-color: $mainColor;
    background-image: url(../assets/images/bg-login-desctop.png);
    background-position: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .title {
    position: absolute;
    top: 50px;
  }
}

@include max-screen($wide-screen) {
  #homepage {
    display: block !important;
  }

  .download-wrapper {
    margin: auto;

    &:before {
      display: none;
    }

    .apk {
      span {
        font-weight: 600;
        color: #575a66;
        white-space: break-spaces;
        inset-inline-end: 11px;
      }

      i {
        font-size: 30px;
        position: relative;
        inset-inline-end: 22px;
      }
    }
  }

}
</style>
