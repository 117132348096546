const state = {
    selectedCard: {},
    cardsList: []
};

const mutations = {

    setSelectedCard(state, data) {
        state.selectedCard = data;
    },
    setCardsList(state, data) {
        state.cardsList = data
    }
};

const getters = {
    getSelectedCard(state) {
        return state.selectedCard;
    },
    getCardsList(state) {
        return state.cardsList
    }
};

const actions = {};

export default {
    state,
    mutations,
    getters,
    actions
}