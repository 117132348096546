<template>
  <div class="grey-bgc">
    <Header @stepBack="$router.go(-1)" :currentStep="2"></Header>
    <div class="main-wrapper">
      <div class="content block block-beneficiaries">
        <div class="wrap">
          <h1 class="title" v-text="$ml.with('VueJS').get(title)"></h1>
          <h1 v-if="isTransferProcess" class="subtitle"
          v-text="$ml.with('dest', $store.getters.getCountryName).get(currentDeliveryOption.transactionClass + '_TO')">
        </h1>
        <div class="body">
            <div v-if="isWalletZambia" class="lblDoneTPIN">
              <span class="lblImportant" v-text="$ml.get('imprtant')" />:
              <span v-text="$ml.get('tpinWarning')" />
            </div>
            <form @submit.prevent="submit">
              <div class="first-row" v-if="isTransferProcess">
                <div class="form-group" v-for="field in getBeneficiaryInfo" :key="field.name">
                  <div v-if="field.individual || !field.company">
                    <label :for="field.name" v-text="$ml.get('beneficiary.' + field.key)">{{ field.name }}</label>
                    <div v-if="possibleValues[field.name]" class="custom-select">
                      <model-select :name="field.name" :id="field.name" :options="possibleValues[field.name]"
                        class="form-control gray" data-vv-validate-on="input" v-model="beneficiary[field.name]"
                        v-validate="constraints[field.name]" />
                    </div>
                    <input v-else type="text" :id="field.name" :name="field.name" class="form-control"
                      v-model.trim="beneficiary[field.name]" v-validate="getConstraints[field.name]">
                    <div class="error">{{ errors.first(field.name) }}</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="form-group">
                  <label for="firstName" v-text="$ml.get('beneficiary.firstName')"></label>
                  <input type="text" id="firstName" name="firstName" class="form-control"
                    v-model.trim="beneficiary.firstName" v-validate="{ required: 'required', regex: /^[a-zA-Z\s,'-]*$/ }">
                  <div class="error">{{ errors.first('firstName') }}</div>
                </div>
                <div class="form-group">
                  <label for="lastName" v-text="$ml.get('beneficiary.lastName')"></label>
                  <input type="text" id="lastName" name="lastName" class="form-control"
                    v-model.trim="beneficiary.lastName" v-validate="{ required: 'required', regex: /^[a-zA-Z\s,'-]*$/ }">
                  <div class="error">{{ errors.first('lastName') }}</div>
                </div>
                <div class="form-group">
                  <label for="countryIso3" v-text="$ml.get('destination')"></label>
                  <div class="custom-select">
                    <div class="custom-select">
                      <model-list-select name="countryIso3" id="countryIso3" class="form-control"
                        data-vv-validate-on="input" :list="countries" option-value="iso"
                        v-model="beneficiary.countryIso3" :option-text="$store.state.locale.lang"
                        v-validate="'required'">
                      </model-list-select>
                      <div class="error">{{ errors.first('countryIso3') }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isTransferProcess">
                <div class="form-group" v-for="field in getTransferInfo" :key="field.name">
                  <div v-if="field.individual || !field.company">
                    <label :for="field.name" v-text="$ml.get('beneficiary.' + field.key)">{{ field.name }}</label>
                    <div v-if="possibleValues[field.name]" class="custom-select">
                      <model-select :name="field.name" :id="field.name" :options="possibleValues[field.name]"
                        class="form-control gray" data-vv-validate-on="input" v-model="beneficiary[field.name]"
                        v-validate="getConstraints[field.name]" />
                    </div>
                    <input v-else type="text" :id="field.name" :name="field.name" class="form-control"
                      v-model.trim="beneficiary[field.name]" v-validate="getConstraints[field.name]">
                    <div class="error">{{ errors.first(field.name) }}</div>
                  </div>
                </div>
                <div class="form-group" v-for="field in getBeneficiaryContacts" :key="field.name">
                  <div v-if="field.individual || !field.company">
                    <label :for="field.name" v-text="$ml.get('beneficiary.' + field.key)">{{ field.name }}</label>
                    <div v-if="possibleValues[field.name]" class="custom-select">
                      <model-select :name="field.name" :id="field.name" :options="possibleValues[field.name]"
                        class="form-control gray" data-vv-validate-on="input" v-model="beneficiary[field.name]"
                        v-validate="getConstraints[field.name]" />
                    </div>
                    <input v-else type="text" :id="field.name" :name="field.name" class="form-control"
                      v-model.trim="beneficiary[field.name]" v-validate="getConstraints[field.name]">
                    <div class="error">{{ errors.first(field.name) }}</div>
                  </div>
                </div>
                <hr v-if="getBankInfo" />
                <div class="form-group" v-for="field in getBankInfo" :key="field.name">
                  <div v-if="field.individual || !field.company">
                    <label :for="field.name" v-text="$ml.get('beneficiary.' + field.key + isUnionPay(field.key))">{{
                      field.name }}</label>
                    <div v-if="possibleValues[field.name]" class="custom-select">
                      <div v-if="field.dynamic || field.refresh">
                        <model-select :name="field.name" :id="field.name" :options="possibleValues[field.name]"
                          class="form-control gray" data-vv-validate-on="input" style="width: 290px"
                          v-model="beneficiary[field.name]" v-validate="getConstraints[field.name]"
                          @input="updateDynamicFields(field.name)" />
                      </div>
                      <div v-else>
                        <model-select :name="field.name" :id="field.name" :options="possibleValues[field.name]"
                          class="form-control gray" style="width: 100%" data-vv-validate-on="input"
                          v-model="beneficiary[field.name]" v-validate="getConstraints[field.name]" />
                      </div>
                    </div>
                    <input v-else type="text" :id="field.name" :name="field.name" :ref="field.name"
                      class="form-control bank-info" :class="field.name" v-model.trim="beneficiary[field.name]"
                      v-validate="field.name === 'maskedCardNumber' ? maskedCardNumberFieldConstraints : getConstraints[field.name]">
                    <div class="error">{{ errors.first(field.name) }}</div>
                  </div>
                </div>
                <div v-if="isWeChat" v-text="$ml.get('lblDoneWechat1')" />
                <div v-if="isWeChat" class="lblDone" v-text="$ml.get('lblDoneWechat')" />
                <div v-if="isNeedCard && isVisaOrMastercard" class="lblDone">
                  <label v-text="$ml.get('cardDepositAccepted')"></label>
                </div>
                <div v-if="isNeedCard" class="lblDone">
                  <label v-text="$ml.get('confirmCreditNumber')"></label>
                </div>
                <div v-if="isBankTransfer" class="lblDone">
                  <label style="white-space: pre-line;" v-text="$ml.get('confirmBankAccount')"></label>
                  <div v-if="byGmt && !inIsrael" class="lblDone"><label v-text="$ml.get('confirmBankAccount2')"></label>
                  </div>
                </div>
              </div>
              <div class="lblDone">
                <label v-text="$ml.get('lblDone')"></label>
              </div>
            </form>
            <div class="group-btn">
              <button class="btn wide" v-text="$ml.get(isTransferProcess ? 'bntContinue' : 'btnDone')"
                :disabled="hasErrors" @click="submit"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />
  </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import { Validator } from 'vee-validate';
import countries from '../data/countries';
import { EventBus } from "../event-bus";
import { getBeneficiary, getBeneficiaryDynamicFields, saveBeneficiary, updateBeneficiary } from "../services/api";
import { isMobile, localesDictionary, parseConstraints } from "../services/utils";
import app from "../main";
import Footer from "@/components/Footer.vue";

export default {
  components: { MainNav, Header, Footer },
  props: {
    id: {
      type: Number
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      countries,
      beneficiaryInfo: [],
      beneficiaryContacts: [],
      transferInfo: [],
      bankInfo: [],
      constraints: [],
      beneficiary: {
        countryIso3: '',
        maskedCardNumber: ''
      },
      requiredFields: {},
      possibleValues: [],
      isSubmitted: false,
      selectedTransfer: '',
      isMobileDevice: isMobile(),
      errorCount: 0
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.action === 'create' || to.params.action === 'update') {
      next();
    } else {
      next(false);
    }
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    title() {
      return this.action === 'create' ? 'newBeneficiary' : 'updateBeneficiary'
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    isNeedCard() {
      const bankFields = JSON.parse(sessionStorage.getItem('requiredFields'))['BENEFICIARY']['BENEFICIARY_BANK_INFO']
      return this.isTransferProcess && bankFields && bankFields.some(field => field.name === 'maskedCardNumber')
    },
    isTransferProcess() {
      return this.$router.currentRoute.name === 'transfer-beneficiary' && this.selectedTransfer;
    },
    isVisaOrMastercard() {
      return this.selectedTransfer && this.selectedTransfer.isVisaOrMastercard;
    },
    isBankTransfer() {
      return this.currentDeliveryOption.transactionClass === 'BANK_TRANSFER'
    },    
    isWalletTransfer() {
      return this.currentDeliveryOption.transactionClass === 'WALLET_TRANSFER'
    },
    currentDeliveryOption() {
      return JSON.parse(sessionStorage.getItem('currentDeliveryOption'))
    },
    getBeneficiaryInfo() {
      return this.beneficiaryInfo;
    },
    getBeneficiaryContacts() {
      return this.beneficiaryContacts;
    },
    getTransferInfo() {
      return this.transferInfo;
    },
    getBankInfo() {
      return this.bankInfo;
    },
    getConstraints() {
      return this.constraints
    },
    byGmt() {
      return this.$store.getters.getSelectedTransferOption.correspondentType == 'GMT'
    },
    inIsrael() {
      return this.$store.getters.getSelectedTransferOption.destinationCountry == 'ISR'
    },
    maskedCardNumberFieldConstraints() {
      if (this.action === 'create' || !this.beneficiary['maskedCardNumber']) {
        return this.constraints['maskedCardNumber']
      }
      return {}
    },
    hasErrors() {
      return this.errors.any()
    },
    isWeChat() {
      return this.selectedTransfer && this.selectedTransfer.correspondentIdentifier === "THUNES_WECHATPAY"
    },
    isWalletZambia() {
      return this.selectedTransfer && this.selectedTransfer.destinationCountry === "ZMB" && this.isWalletTransfer
    },
  },
  async created() {
    this.resetFields();
    if (this.action === 'create') {
      this.beneficiary.countryIso3 = this.$store.state.transfer.destinationCountry;
      this.countries = _.orderBy(this.countries, this.getLang).filter(item => {
        return item.moneygram !== false
      });
    } else if (this.action === 'update') {
      this.beneficiary = await getBeneficiary(this.id);
    }
    this.populateInitialValues()
    this.selectedTransfer = this.$store.getters.getSelectedTransferOption;
    EventBus.$on('changeLang', lang => {
      this.$validator.localize(this.dictionary());
      this.$validator.localize(lang);
    });
  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
    Validator.localize(this.dictionary());
    Validator.localize(this.getLang);

    let fields = this.getFields();

    if (fields) {
      this.requiredFields = Object.values(fields['BENEFICIARY'])
        .flat()
        .reduce(function (map, obj) {
          map[obj.name] = obj;
          return map;
        }, {});
    }
  },
  watch: {
    'beneficiary.maskedCardNumber'(newValue) {
      const maskedCardNumberField = this.$validator.fields.find({ name: "maskedCardNumber" })
      if (maskedCardNumberField) {
        if (maskedCardNumberField.initialValue !== '' && maskedCardNumberField.initialValue === newValue) {
          maskedCardNumberField.update({ rules: {} })
        } else {
          maskedCardNumberField.update({ rules: this.constraints['maskedCardNumber'] })
        }
      }
    },
  },
  methods: {
    getFields() {
      return JSON.parse(sessionStorage.getItem('requiredFields'));
    },
    resetFields() {
      let map = this.getFields();
      if (map) {
        this.beneficiaryInfo = map['BENEFICIARY']['BENEFICIARY_DETAILS'];
        this.beneficiaryContacts = map['BENEFICIARY']['BENEFICIARY_CONTACTS'];
        this.transferInfo = map['BENEFICIARY']['TRANSFER_INFO'];
        this.bankInfo = map['BENEFICIARY']['BENEFICIARY_BANK_INFO'];
        this.constraints = JSON.parse(sessionStorage.getItem('beneficiaryFieldsConstraints'));;
      }
    },
    submit() {
      this[this.action]();
    },
    isUnionPay(fieldName) {
      return (this.beneficiary.countryIso3 === 'CHN' && fieldName === 'maskedCardNumber') ? '_unionPay' : ''
    },
    create() {
      this.$validator.validate().then(result => {
        if (result && !this.isSubmitted) {
          app.$Progress.start();
          this.isSubmitted = true;
          let self = this;
          const data = { beneficiary: this.beneficiary };
          const deliveryOption = this.currentDeliveryOption;
          if (deliveryOption) {
            data['correspondentId'] = deliveryOption.correspondentId;
            data['transaction'] = { amount: deliveryOption.amount, currency: deliveryOption.currency, payoutCurrency: deliveryOption.payoutCurrency, transactionClass: deliveryOption.transactionClass }
          }
          saveBeneficiary(data, this.errors, (response) => {
            self.isSubmitted = false;
            app.$Progress.finish();
            if (!response.errorCode) {
              this.$store.dispatch('loadBeneficiary');
              this.$store.commit('setBeneficiaryValid', true);
              if (this.isTransferProcess) {
                this.$store.commit('setTransferDestinationCountry', this.beneficiary.countryIso3);
                this.$store.commit('setCurrentBeneficiary', response.result);
                this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_3', {error_count: this.errorCount,beneficiary: 'new_beneficiary' })
                this.$router.push({ name: 'transfer' });
              } else {
                this.$router.push({ name: 'beneficiaries' })
              }
            }
          }, this.$ml);
          setTimeout(() => this.isSubmitted = false, 10000);
        } else {
          this.errorCount = this.errorCount + 1
        }  
      });
    },
    update() {
      this.$validator.validate().then(result => {
        if (result) {
          app.$Progress.start();
          this.isSubmitted = true;
          let self = this;
          const maskedCardNumberField = this.$validator.fields.find({ name: "maskedCardNumber" })
          const body = (this.$refs.maskedCardNumber && maskedCardNumberField.initialValue === this.beneficiary.maskedCardNumber) ? Object.assign({}, this.beneficiary, { maskedCardNumber: '' }) : this.beneficiary
          const data = { beneficiary: body };
          const deliveryOption = this.currentDeliveryOption;
          if (deliveryOption) {
            data['correspondentId'] = deliveryOption.correspondentId;
            data['transaction'] = { amount: deliveryOption.amount, currency: deliveryOption.currency, payoutCurrency: deliveryOption.payoutCurrency, transactionClass: deliveryOption.transactionClass }
          }
          updateBeneficiary(data, this.errors, (response) => {
            self.isSubmitted = false;
            app.$Progress.finish();
            if (!response.errorCode) {
              this.$store.dispatch('loadBeneficiary');
              this.$store.commit('setBeneficiaryValid', true);
              if (this.isTransferProcess) {
                this.$store.commit('setCurrentBeneficiary', response.result);
                this.$store.commit('setTransferDestinationCountry', this.beneficiary.countryIso3);
                this.$store.commit('setBeneficiaryUpdated', true);
                this.$router.push({ name: 'transfer' });
              } else {
                this.$router.push({ name: 'beneficiaries' })
              }
            }
          }, this.$ml);
          setTimeout(() => this.isSubmitted = false, 10000);
        }
      });
    },
    populateInitialValues() {
      const beneficiaryFields = [].concat(this.beneficiaryInfo || []).concat(this.beneficiaryContacts || []).concat(this.bankInfo || []).concat(this.transferInfo || [])
      for (const i in beneficiaryFields) {
        const field = beneficiaryFields[i]
        if (!this.beneficiary[field.name] && field.initialValue) {
          this.beneficiary[field.name] = field.initialValue
        }
        if (field.possibleValues) {
          this.possibleValues[field.name] = field.possibleValues.map(element => {
            const text = field['possibleKeys'] ? this.$ml.get(field.name + '.' + element) : element
            return { text: text, value: element }
          });
          if (this.beneficiary[field.name]) {
            this.updateDynamicFields(field.name)
          }
        }
      }
    },
    async updateDynamicFields(name) {
      app.$Progress.start();
      let selectedOption = this.$store.getters.getSelectedTransferOption;
      this.beneficiary.country = { iso3: this.beneficiary.countryIso3 }
      const data = {
        updatedField: { name: name, value: this.beneficiary[name] },
        beneficiary: this.beneficiary,
        transaction: {
          amount: selectedOption.amount,
          currency: selectedOption.currency,
          payoutCurrency: selectedOption.payoutCurrency,
          transactionClass: selectedOption.transactionClass
        },
        correspondent: {
          id: selectedOption.correspondentId
        }
      }
      const res = await getBeneficiaryDynamicFields(data);
      if (this.requiredFields[name]['relatedFields']) {
        this.requiredFields[name]['relatedFields'].forEach(field => this.possibleValues[field].splice(0, this.possibleValues[field].length))
        const updatedFields = Object.values(res.data.result['BENEFICIARY'])
          .flat()
          .reduce(function (map, obj) {
            map[obj.name] = obj;
            return map;
          }, {});
        this.requiredFields[name]['relatedFields']
          .forEach(field => {
            this.possibleValues[field]
              .push(...updatedFields[field].possibleValues.map(element => {
                return { text: element, value: element }
              }))
          }
          )
      } else {
        parseConstraints(res.data.result);
        sessionStorage.setItem('requiredFields', JSON.stringify(res.data.result));
      }
      this.resetFields();
      app.$Progress.finish();
    },
    dictionary() {
      const messages = {
        required: this.$ml.get("E_REQUIRED"),
        numeric: this.$ml.get("E_NUMERIC"),
        regex: this.$ml.get("E_REGEX_english"),
        min: (field, value) => this.$ml.get("E_MIN_CHAR.1") + ` ${value} ` + this.$ml.get("E_MIN_CHAR.2"),
        max: (field, value) => this.$ml.get("E_MAX_CHAR.1") + ` ${value} ` + this.$ml.get("E_MAX_CHAR.2"),
        length: (field, value) => this.$ml.get("E_LEMGTH.1") + ` ${value} ` + this.$ml.get("E_LEMGTH.2")
      }
      return localesDictionary({ messages: messages });
    },
    prevStep() {
      if (this.isTransferProcess)
        this.$router.push('/transfer');
      else this.$router.push('/beneficiaries');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";
@import "../assets/styles/beneficiary_form.less";
</style>
