<template>
  <div class="quick-actions-card-container">
    <div class="quick-actions-card-header">
      <h6><strong>{{ $ml.get('quickActions') }}</strong></h6>
    </div>
    <section class="quick-actions-container">
      <div class="quick-actions-mobile" v-if="isMobileDevice">
        <div class="line">
          <QuickAction action="sendMoneyLocal" descripton="sendMoneyLocally"
            :text="$ml.get('wallet.main.sendActions.inIsrael')" icon="countries/ISR.svg" />
          <QuickAction v-if="countryIso3 !== 'ISR'" action="sendMoneyHome" :additionalData="{ iso: countryIso3 }"
            descripton="sendMoneyGlobally" :text="$ml.get('wallet.main.sendActions.sendHome')"
            :icon="'countries/' + countryIso3 + '.svg'" />
          <QuickAction v-else action="sendMoneyGlobal" descripton="sendMoneyGlobally"
            :text="$ml.get('send_money_worldwide')" :icon="'general.svg'" />
        </div>
        <div class="line">
          <QuickAction action="inviteFriends" descripton="inviteFriends"
            :text="$ml.get('wallet.main.walletActions.invite')" icon="Gift.svg" />
          <QuickAction action="myGmtPrepaid" descripton="myGmtPrepaid" v-if="countryIso3 !== 'ISR'"
            :text="$ml.get('wallet.main.walletActions.loadPrePaid')" icon="Card.svg" />
          <QuickAction action="sendToFriends" descripton="sendToFriends" v-else
            :text="$ml.get('wallet.main.walletActions.send')" icon="Group.svg" />
        </div>
        <div class="line">
          <QuickAction action="sendToFriends" descripton="sendToFriends"
            :text="$ml.get('wallet.main.walletActions.send')" icon="Group.svg" v-if="countryIso3 !== 'ISR'" />
          <QuickAction action="goToCashio" descripton="goToCashio" :text="$ml.get('itemNav13')" icon="Cashio.svg"
            v-if="countryIso3 === 'ISR'" />
          <div class="place-holder-hackz">place holder</div>
        </div>
      </div>
      <div class="quick-actions-desktop-flex" v-else>
        <QuickAction action="sendMoneyLocal" descripton="sendMoneyLocally"
          :text="$ml.get('wallet.main.sendActions.inIsrael')" icon="countries/ISR.svg" />
        <QuickAction v-if="countryIso3 !== 'ISR'" action="sendMoneyHome" :additionalData="{ iso: countryIso3 }"
          descripton="sendMoneyGlobally" :text="$ml.get('wallet.main.sendActions.sendHome')"
          :icon="'countries/' + countryIso3 + '.svg'" />
        <QuickAction v-else action="sendMoneyGlobal" descripton="sendMoneyGlobally"
          :text="$ml.get('send_money_worldwide')" :icon="'general.svg'" />
        <QuickAction action="inviteFriends" descripton="inviteFriends"
          :text="$ml.get('wallet.main.walletActions.invite')" icon="Gift.svg" />
        <QuickAction action="myGmtPrepaid" descripton="myGmtPrepaid" v-if="countryIso3 !== 'ISR'"
          :text="$ml.get('wallet.main.walletActions.loadPrePaid')" icon="Card.svg" />
        <QuickAction action="sendToFriends" descripton="sendToFriends" :text="$ml.get('wallet.main.walletActions.send')"
          icon="Group.svg" />
        <QuickAction v-if="countryIso3 === 'ISR'" action="goToCashio" descripton="goToCashio"
          :text="$ml.get('itemNav13')" icon="Cashio.svg" />
      </div>
    </section>
  </div>
</template>
<script>
import { isApp, isMobile } from "@/services/utils";
import QuickAction from "@/components/QuickAction.vue";

export default {
  name: 'QuickActionsCard',
  components: { QuickAction },
  props: {},
  data() {
    return {
      isApp: isApp(),
      isMobileDevice: isMobile(),
      countryIso3: localStorage.getItem('country')
    }
  },
  created() {

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {
  },
  methods: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.place-holder-hackz {
  width: 50%;
}

.quick-actions-card-container {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px -3px 18px 0px #D2D2D240;

  .quick-actions-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 5% 0px 5%;
  }

  h6 {
    color: black;
    font-size: 14px;
    font-weight: normal;
  }

  .quick-actions-container {
    display: flex;
    justify-content: center;
    height: 100%;

    .quick-actions-mobile {
      width: 100%;
    }

    .line {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .quick-action-box {
        justify-content: center;
      }
    }
  }
}

@media only screen and (min-width: $screen-tablet) {
  .quick-actions-card-container {
    padding: 16px 5px 0px 16px;
    margin-top: 0;

    h6 {
      color: #000;
      font-family: Assistant;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .quick-actions-card-header {
      padding: 0 9px;
      padding-top: 10px;
    }

    .quick-actions-container {
      overflow-x: auto;

      .quick-actions-desktop-flex {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .quick-action-box {
          min-width: 140px
        }
      }
    }
  }

  .place-holder-hackz {
    display: none !important;
  }
}
</style>
