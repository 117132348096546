<template>
  <div>
    <Header></Header>
    <div class="main-wrapper">
      <div class="content block block-calculator">
        <div v-show="loading" class="loader_page">
          <img src="../assets/images/loading.gif" />
        </div>
        <div v-show="!loading" class="wrap">
          <h1 class="title" v-text="$ml.with('VueJS').get('itemNav3')"></h1>
          <div class="body">
            <lookup ref="lookup" :next-btn="$ml.get('transferOptions')" :logged-in="true" @ready="ready"
              @execute="execute" @next-step="nextStep" @back-step="backStep">
            </lookup>

            <div v-if="showWallet || !isShekel || !creditCardAllow || cashBackBalanceAvailable" class="message-box shield"
              style="margin-bottom:1px;">
              <div class="iconInfo">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                  <circle cx="8" cy="4.5" r="1" />
                </svg>
              </div>
              <div v-if="showWallet">
                <span v-text="$ml.get('wallet.balance')"></span>
                <span v-format-title-amount="[walletBalance, '₪']" />
              </div>
              <div v-if="cashBackBalanceAvailable">
                <span v-text="$ml.get('cashBackBalance')"></span>
                <span v-for="(balance, index) in cashBackBalance">
                  <span v-format-title-amount="[balance.amount, balance.symbol]" />
                  <span v-if="cashBackBalance.length > 1 && index < cashBackBalance.length - 1">,&nbsp;</span>
                </span>
              </div>
              <div v-if="!isShekel || dummyShekel" class="message">
                <span> {{ this.$ml.get("actualRate1") }} {{ getRateSymbole }} {{
                  this.$ml.get("actualRate2")
                }}: {{ getRate }}</span>
              </div>
              <div v-if="!showWallet && !creditCardAllow" class="message">
                <span v-text="$ml.get(this.creditLimit.message)"></span>
              </div>
              <div v-else-if="this.creditLimit.risky">
                <span>
                  {{ this.$ml.with('country', countryName).get('CREDIT_LIMIT_RISKY') }}
                  {{ getSymbole }}{{ this.getCurrencyLimit | formatComma }}
                </span>
                <div>
                  {{ this.$ml.get('riskyLimitMsg') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import Lookup from '../components/Lookup.vue';
import Footer from "@/components/Footer.vue";

export default {
  name: 'Calculator',
  components: { MainNav, Header, Lookup, Footer },
  data() {
    return { loading: true }
  },
  methods: {
    ready() {
      this.loading = false;
    },
    execute() {
      this.$refs.lookup.doLookup();
    },
    nextStep() {
      this.$router.push('/transfer');
    },
    backStep() {
      this.$router.push('/transfer');
    }
  }, computed: {
    getSymbole() {
      return this.$store.getters.getLookupCurrencySymbol;
    },
    getRateSymbole() {
      return this.isShekel && this.dummyShekel ? this.$store.getters.getLookupDummyIlsSymbol : this.getSymbole;
    },
    getRate() {
      return this.isShekel && this.dummyShekel ? this.$store.getters.getDummyIls.rate : this.$store.getters.getLookupCurrencyRate;
    },
    getCurrencyLimit() {
      return this.$store.getters.getTransfetLimit;
    },
    creditLimit() {
      return this.$store.getters.getCreditLimit;
    },
    creditCardAllow() {
      return this.creditLimit && this.creditLimit.message === 'CREDIT_LIMIT'
    },
    countryName() {
      return this.$store.getters.getLookupCountryName;
    },
    hasWallet() {
      return this.$store.getters.hasWallet('ILS');
    },
    walletBalance() {
      return this.$store.getters.getWalletBalance('ILS');
    },
    cashBackBalance() {
      return (this.$store.getters.getCashBackBalances || []).filter(element => (element.currency === 'ILS' || element.currency === this.getCurrency) && element.amount > 0)
    },
    cashBackBalanceAvailable() {
      return this.cashBackBalance.length > 0;
    },
    showWallet() {
      return this.hasWallet && this.walletBalance > 0
    },
    getCurrency() {
      return this.$store.getters.getLookupCurrency;
    },
    isShekel() {
      return this.getCurrency === 'ILS'
    },
    dummyShekel() {
      return this.$store.getters.getDummyIls;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.block-calculator {
  max-width: 757px;
  margin: 0 auto;
  text-align: center;

  .body {
    margin-top: 22px;
  }

  &.block-calculator-unregistered {
    hr {
      margin: 6px 0px;
    }
  }
}
</style>
