const state = {
    amount: null,
    currency: null,
    destinationCountry: null,
    transactionClass: null,
    correspondentName: null,
    correspondentType: null,
    cashBackCredit: null,
    cashBackBalances: null,
    useCashBack: false,
    cashBackCurrency: null,
    usePaymentCashBack: false,
    amountType: null,
    payoutAmount: null,
    payoutCurrency: null,

    options: [],
    currentOption: null,
    fields: {},
    creditAvailabilityMap: [],
};

const mutations = {
    setTransferStep1(state, data) {
        state.amount = data.amount;
        state.currency = data.currency;
        state.destinationCountry = data.destinationCountry;
        state.transactionClass = data.transactionClass;
        state.correspondentName = data.correspondentName;
        state.correspondentType = data.correspondentType;
        state.amountType = data.amountType;
        state.currentOption = null; // reset currentOption before next step
    },
    setTransferStep1Response(state, data) {
        state.options = data;
    },
    setCurrentOption(state, data) {
        state.currentOption = data;
    },
    setTransferAmount(state, data) {
        state.amount = data;
    },
    setTransferCurrency(state, data) {
        state.currency = data;
    },
    setTransferDestinationCountry(state, data) {
        state.destinationCountry = data;
    },
    setFields(state, data) {
        state.fields = data;
    },
    setCreditAvailabilityMap(state, data) {
        state.creditAvailabilityMap = data;
    },
    setCashBackCredit(state, data) {
        state.cashBackCredit = data;
    },
    setCashBackBalances(state, data) {
        state.cashBackBalances = data;
    },
    setUseCashBack(state, data) {
        state.useCashBack = data;
    },
    setCashBackCurrency(state, data) {
        state.cashBackCurrency = data;
    },
    setTransferPayoutAmount(state, data) {
        state.payoutAmount = data;
    },
    setTransferPayoutCurrency(state, data) {
        state.payoutCurrency = data;
    },
    resetCashBack(state, data) {
        state.useCashBack = false;
        state.cashBackCredit = null;
    },
};

const getters = {
    getTransferStep1Response(state) {
        return state.options;
    },
    getSelectedTransferOption(state) {
        return state.options[state.currentOption];
    },
    getFields(state) {
        return state.fields;
    },
    getCreditAvailabilityMap(state) {
        return state.creditAvailabilityMap;
    },
    getCashBackCredit(state) {
        return state.cashBackCredit;
    },
    getCashBackBalances(state) {
        return state.cashBackBalances;
    },
    hasCashBack: (state) => (currency) => {
        return !!state.cashBackBalances && state.cashBackBalances.find(balance => balance.currency === currency);
    },
    getCashBackBalance: (state) => (currency) => {
        return (state.cashBackBalances.find(balance => balance.currency === currency) || {}).amount;
    },
    getUseCashBack(state) {
        return state.useCashBack;
    },
    getUsePaymentCashBack(state) {
        return state.usePaymentCashBack;
    },
    getCashBackCurrency(state) {
        return state.cashBackCurrency;
    },
    getSelectedExchangeRate(state) {
        let selected = state.options[state.currentOption]
        return selected.validCurrencyIndicator ? selected.conversionRate : selected.estimatedExchangeRate;
    },
    getSelectedPayoutCurrency(state) {
        let selected = state.options[state.currentOption]
        return selected.validCurrencyIndicator ? selected.payoutCurrency : selected.estimatedReceiveCurrency;
    },
    getTransferAmount(state) {
        return state.amount;
    },
    getAmountType(state) {
        return state.amountType;
    },
    getCountryName(state) {
        return countryByIso(state.destinationCountry);
    },
    getSelectedCurrencySymbol(state, getters) {
        return getCurrencySymbol(getters.getSelectedTransferOption.currency);
    },
    getTransferLookupSymbol() {
        return getCurrencySymbol(state.amountType === LookupType.RECEIVER ? state.payoutCurrency : lookup.state.lookupCurrency)
    },
    getTitleLookupAmount(state) {
        switch (state.amountType) {
            case LookupType.SENDER: return concat(state.currency, state.amount);
            case LookupType.SENDER_FEE_INCLUDED: {
                const option = state.options ? state.options[state.currentOption || 0] : {};
                const paymentAmount = option.lookupDto && option.lookupDto.paymentAmount || option.paymentAmount && option.paymentAmount;
                return concat(lookup.state.lookupCurrency, paymentAmount || lookup.state.lookupAmount);
            }
            case LookupType.RECEIVER: return concat(state.payoutCurrency, state.payoutAmount);
        }
    },
    getTitleTransferAmount(state) {
        return (state.amountType === LookupType.RECEIVER) ? concat(state.payoutCurrency, state.payoutAmount) : concat(state.currency, state.amount);
    },
};

const concat = function (curr, sum) {
    return getCurrencySymbol(curr) + formatComma(sum)
}

const actions = {};
import { getCurrencySymbol, countryByIso } from "../../services/utils"
import { formatComma } from "../../filters/AppFilters"
import LookupType from "../../enums/lookupType";
import lookup from './../modules/lookup';

export default {
    state,
    mutations,
    getters,
    actions
}