<template>
  <div @click="$emit('action')" class="action-card" :class="{ 'action-card-border-notification' : newNotification }">
    <div v-if="newNotification" class="action-card-attention">{{ $ml.get('new') }}</div>
    <i class="icon icon-action" :class="icon"></i>
    <p class="action-title" style="white-space: pre-line">{{ this.title }}</p>
    <slot v-if="!icon && !title" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    },
    newNotification: {
      type: Boolean,
      required: false
    }
  }
}

</script>
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.action-card {
  text-align: center;
  border-radius: 8px;
  box-shadow: 5px 3px 5px 1px #969696;
  background-color: #ffffff;
  @include transition(all .4s ease);

  &:hover {
    background-color: #f2f2f2;
  }
}

.icon {
  font-size: 20px;
}

.action-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
}

.action-card-border-notification {
  border: 2px #d4c54b solid;
}

.action-card-attention{
  position: absolute;
  background-color: #d4c54b;
  font-size: 10px;
  width: 30px;
  height: 19px;
  line-height: 19px;
  color: #092086;
  font-style: italic;
  font-weight: bold;
  margin-top: -20px;
}
</style>