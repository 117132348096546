<template>
  <div @click.stop="onActionClick('body')" class="banner-container" :style="{backgroundImage: `url(${require(`../assets/mygmt_v2_images/banners/${isMobileDevice ? banner.mobileSrc : banner.desktopSrc}`)})`, cursor: !isButton && isLink? 'pointer' : 'default'}" >
    <div class="text-container">
<div :class="banner.primaryColor" class="title">{{banner.title}}</div><div :class="{[banner.primaryColor]:true,'no-button': !isButton || !isLink}" class="subtitle">{{banner.subtitle }}
      </div>
    </div>
    <div>
      <div @click.stop="onActionClick('button')" v-if="isButton && isLink" :class="banner.primaryColor !== 'white' ?  'bg-blue': 'bg-white'" class="btn">{{banner.btnTxt}}</div>
    </div>
  </div>
</template>
<script>
import { isApp, isMobile } from "@/services/utils";
import YoutubeModal from "./YoutubeModal.vue";

export default {
  name: 'MarketingBanner',
  components:{YoutubeModal},
  props: {
    banner: {
      title: {
        type: String
      },
      subtitle: {
        type: String
      },
      btnTxt: {
        type: String
      },
      imgSrc: {
        type: String
      },
      primaryColor: {
        type: String
      },   actionLink: {
        type: String
      }
    }
  },
  data() {
    return {
      isApp: isApp(),
      isMobileDevice: isMobile(),
    }
  },
  created() {
  },

  computed: {
    isButton(){
      return this.banner.btnTxt
    },
    isLink(){
      return this.banner.internalLink || this.banner.externalLink
    }

  },
  async mounted() {
  },
  methods: {
    onActionClick(clickTarget) {
      if (!this.isLink || (clickTarget === 'body' && this.isButton && !this.isMobileDevice)) return
      if (this.banner.externalLink) {
        if (this.banner.externalLink.includes('youtube.com')) {
          this.$emit('openYoutubeModal', this.banner.externalLink.replace('watch?v=','embed/'))
        } else {
          window.open(this.banner.externalLink, '_blank');
        }
      } else {
        this.$router.push(this.banner.internalLink);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.white {
  color: #FFF;
}

.blue {
  color: $text-color;
}
.bg-white {
  background-color: #FFF;
  @extend .blue
}

.bg-blue {
  background-color: $text-color;
  @extend .white

}

.banner-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 0 26px 10px 25px;
  background-size: cover;
  background-position: right;
    background-repeat: no-repeat;

   .title {
    font-family: Assistant;
    font-size: 24px;
    font-style: normal;
    font-weight: 700; 
    margin-top: 20px;
   } 

   .subtitle {
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    margin-top: 10px;
   }
   .btn {
    font-size: 16px;
    font-style: normal;
    font-weight: 600; 
    padding: 0 10px;
    margin-bottom: 22px;
   }
}

.no-button {
    margin-bottom: 30px;
    }
</style>
