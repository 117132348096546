<template>
  <div class="main-card-container">
    <div v-if="getBalance ===undefined" class="mygmt2-loader">
      <img src="../assets/mygmt_v2_images/mygmt2-loader.gif" />
    </div>
    <div class="main-card-container" v-else>
      <div class="card-wrapper" :class="{ 'card-wrapper-uncover': this.unwrapCards  , 'card-wrapper-grey': isBlocked || isCanceled }"
        v-if="this.status === 'ACTIVATED'">
        <div class="card-container" :class="{ 'background-none': this.unwrapCards,'justify-center': !this.unwrapCards }"
          :id="`card-container-${this.last4digits}`">
          
          <div class="card-content" :style="{height: !this.unwrapCards ? (isMobileDevice ? '85%' : '88%') : '100%'}" :class="{'mg-left-15':this.unwrapCards}">
            <Ribbon :isShown="isBlocked || isCanceled" :title="setRibbonTitle" :color="setRibbonColor"></Ribbon>
            <section class="card-top-flex" :class="{ 'reverse-row-flex': getDir === 'rtl' , 'card-short': this.unwrapCards}">
              <div class="card-left-flex" :class="{ 'card-left-flex-visible': this.unwrapCards }"
                :id="`card-left-flex-${this.last4digits}`">
                <!-- <h6 class="align-left-text">{{ this.cardBlocked }}</h6> -->
              </div>
              <div class="card-right-flex" :class="{'align-start': getDir === 'rtl'}">
                <img style="position: relative;" :class="{'grey-img': isBlocked || isCanceled}" :src="isBlocked || isCanceled ? require('../assets/mygmt_v2_images/Visa-grey.svg'): require('../assets/mygmt_v2_images/Visa.svg')">
                <h6 :class="{'grey-text': isBlocked || isCanceled}" >{{ $ml.get('cardBalance') }}</h6>
                <h1 :class="{'grey-text': isBlocked || isCanceled}"><span :class="{'grey-text': isBlocked || isCanceled}" v-formatDecimal="[getBalance, getSymbole]"></span></h1>
              </div>
            </section>
            <div :class="{'grey-text': isBlocked || isCanceled}" style="position: relative;" class="asterisks"> <span  :id="`extra-asterisks-${this.last4digits}`"
                :class="{ 'invisible': !this.unwrapCards,'grey-text': isBlocked || isCanceled}" style="opacity: 1;" class="asterisks">****
                ****</span>
              **** {{ this.last4digits }} </div>
            <div v-if="!isCanceled" class="card-bottom-line">
              <button class="btn btn-white" @click="cardActions()"> {{ $ml.get('actions') }} </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-wrapper card-wrapper-customer" v-else>
        <div class="card-container" :id="`card-container-${this.last4digits}`">
          <div class="mygmt2-line">
            <span class="plus-sign pointer" @click="cardActions()">+</span>
          </div>
          <div class="activate-a-card">{{ $ml.get('activateCard') }}</div>
        </div>
      </div>
      <Drawer ref="cardActions" :title="$ml.get('cardActions')" :drawerId="id" class="drawer-wrapper-card"
        @beforeClose="closeCardActions()" inLineDrawer>
        <template v-slot:content>
          <div class="card-action-slot-container">
            <CardActions :card="{ ...$props }" inlineCardActions />
          </div>
        </template>
      </Drawer>
    </div>

  </div>
</template>
<script>
import { isApp, isMobile , getCurrencySymbol} from "@/services/utils";
import { getCardData } from "@/services/api";
import Drawer from "@/components/Drawer.vue";
import CardActions from "@/components/CardActions.vue";
import Ribbon from "@/components/Ribbon.vue";
import { formatDecimalAndStyleTitle } from '../directives/AppDirectives'

export default {
  name: 'Card',
  components: { CardActions, Drawer, Ribbon },
  directives: {
    'formatDecimal': formatDecimalAndStyleTitle
  },
  props: {
    cid: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    currency: {
      type: String,
      required: true
    },
    last4digits: {
      type: String,
    },
    unwrapCards: {
      type: Boolean,
      required: false
    },
    status: {
      type: String,
      required: false
    },
    blockCode: {
      type: String,
      required: false
    },
     balance: {
      required: false,
      // default: 0
    }
  },
  data() {
    return {
      isApp: isApp(),
      isMobileDevice: isMobile(),
      loading: true,
    }
  },
  created() {
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    getBalance() {
      return this.balance
    },
    isBlocked() {
     return this.blockCode == 'BLOCKED'
    },
    isCanceled() {
      return  this.blockCode == 'BLOCKED_PERMANENTLY'
    },
    setRibbonTitle() {
    return this.blockCode == 'BLOCKED' ? this.$ml.get('blocked') : this.$ml.get('STATUS_CANCELED') 
    },
    setRibbonColor() {
    return this.blockCode == 'BLOCKED' ? 'yellow' : 'red'
    },
    getSymbole() {
      return getCurrencySymbol(this.currency);
    },
  },
  async mounted() {
  },
  methods: {
    cardActions() {
      this.setActiveCard()
      if (this.isMobileDevice) {
        this.$emit('onActionClick')
      } else {
        this.uncoverCard();
        this.$refs.cardActions.openDrawer();
      }
    },

    setActiveCard(){
      const card = {
        balance: this.balance,
        blockCode:this.blockCode,
        cid:this.cid,
        currency: this.currency,
        last4digits: this.last4digits,
        status: this.status
      }
      this.$store.commit('setSelectedCard', card);
      localStorage.setItem('maxCardNumber', card.cid);
    },
    uncoverCard() {
      $(`#card-container-${this.last4digits}`).addClass('background-none')
      $(`#card-left-flex-${this.last4digits}`).addClass('card-left-flex-visible')
      $(`#extra-asterisks-${this.last4digits}`).removeClass('invisible')


    },
    closeCardActions() {
      $(`#card-container-${this.last4digits}`).removeClass('background-none')
      $(`#card-left-flex-${this.last4digits}`).removeClass('card-left-flex-visible')
      $(`#extra-asterisks-${this.last4digits}`).addClass('invisible')

    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.grey-img {
  position: relative;
}
.grey-text {
  color: rgb(126, 119, 133) !important; 
}

.grey-text * {
  color: inherit !important; 
}


.card-wrapper-customer {
  background: linear-gradient(223.24deg, #2538A9 6.89%, #0E1F81 82.79%), url("../assets/mygmt_v2_images/Subtract_Card.png") no-repeat -3% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  .card-container {
    background: url("../assets/mygmt_v2_images/wallet_decorations.png") no-repeat left, url("../assets/mygmt_v2_images/Subtract_Card.png") no-repeat -3%;
    background-size: contain, contain;
    position: relative;
    overflow: hidden;
    justify-content: center;

    .activate-a-card {
      position: absolute;
      bottom: 20px;
      left: 10px;
      white-space: pre-wrap;
      font-weight: bold;

    }

    :dir(rtl).activate-a-card {
      right: 10px;
    }

    .mygmt2-line {
      height: auto;
      width: auto;

      .plus-sign {
        background: linear-gradient(0deg, #112A86, #112A86), linear-gradient(299.66deg, #2538A9 18.21%, #0E1F81 79.56%);
        color: white;
        border-radius: 40px;
        height: auto;
        font-size: 40px;
        width: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #112A86;
        box-shadow: 2px 4px 4px 0 #13245140 inset;
        position: relative;
        inset-inline-end: 5px;
      }
    }

  }
}

.mygmt2-loader {
  height: 220px;
  width: 300px;
}


.main-card-container {
  height: 94%;
  width: 30%;
}

.mg-left-15 {
  margin-left: 15px !important;
}

.card-wrapper {
  width: 300px;
  height: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
  background: url("../assets/mygmt_v2_images/VisaCardGMT.png") no-repeat center, linear-gradient(223.24deg, #2538A9 6.89%, #0E1F81 82.79%);
  background-size: calc(100% - 40px), 100%;
  position: relative;

  .card-image {
    position: absolute;
    height: 84%;
    width: 86%;
    z-index: 0;
  }
}

.card-wrapper-grey {
  background: url("../assets/mygmt_v2_images/VisaCardGMT-grey.png") no-repeat center, linear-gradient(223.24deg, #2538A9 6.89%, #0E1F81 82.79%);
  background-size: 260px
}

.card-container {
  height: 100%;
  width: 100%;
  margin: auto;
  background: url("../assets/mygmt_v2_images/wallet_decorations.png") no-repeat left, url("../assets/mygmt_v2_images/Subtract_Card.png") no-repeat -3%;
  background-size: contain, contain;
  border-radius: 21.5px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    font-size: 13px;
    font-weight: 400;
  }

  h1 {
    font-size: 36px;
    font-weight: 600;
  }
.align-start {
  align-items: start !important;
}
  .card-content {
    //z-index: -1;
    width: 85%;
    //margin: 0 auto;
    margin-left: 5px;
    height: 90%;
    
    color: black;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    .card-top-flex {
      z-index: 1;
      padding-top: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .card-right-flex {
        z-index: 1;
        padding-right: 10px;
        height: 100%;
        position: relative;
        bottom: 20px;
        display: flex;
        align-items: end;
        flex-direction: column;

        img {
          width: 40px;
        }

        h6 {
          padding-top: 3px;
          font-size: 13px;
          font-weight: 300;
          opacity: .7;
        }

        h1 {
          font-size: 22px;
          font-weight: 600;
        }
      }

      .card-left-flex {
        z-index: 2;
        visibility: hidden;
        font-size: 1rem;

        .align-left-text {
          text-align: end;
          direction: rtl;
        }

        .asterisks {
          position: relative;
          top: 58px;
          right: 13px;
          width: 130px;
        }
      }
    }



    .asterisks {
      display: flex;
      align-items: center;
      justify-content: right;
      letter-spacing: 5px;
      font-size: 1rem;
      opacity: .8;
      direction: ltr;
      padding-right: 7px;
      margin-top: 5px;
    }

    .btn-white {
      box-shadow: 0 3px 18px 0 #86868640;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-weight: 400;
      color: black;
    }

    .card-bottom-line {
      display: flex;
      flex-direction: row-reverse;
      padding: 0 3px;
      direction: ltr;
      margin-top: 5px;
    }
  }

  .card-left-flex-visible {
    visibility: visible !important;
  }
}

@media only screen and (min-width: $screen-tablet) {

  .main-card-container {
    height: 100%;
    max-height: 220px;
  }

  .wallet-container {
    .btn-white {
      width: 180px;
      height: 33px;
    }
  }

  .card-action-slot-container {
    height: 100%;
  }

  .card-container {
    
      .card-content {
        height: 88% !important;
      }

  }

  .drawer-wrapper-card {
    left: 0;
    border-radius: 20px;
    width: fit-content;
    padding: 15px;
    position: absolute;
    inset-inline-start: 30px !important;
    inset-block-start: 150px;
  }
}
</style>
