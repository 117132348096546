const state = {
    name: '',
    firstName: '',
    country: '',
    loggedIn: false,
    notSigned: false,
    oldCustomer: false,
    walletBalances: [],
    areWalletBalancesUpdated: false,
    walletBalancesInterval: undefined,
    walletFriendLink: undefined,
    order: {},
    biometricData: { hasTouchId: false, hasSecret: false, biometryType: "touchId" },
    contacts: []
};

const mutations = {
    setUserFirstName(state, firstName) {
        state.firstName = firstName;
    },
    setUserName(state, name) {
        state.name = name;
    },
    setCountry(state, country) {
        state.country = country;
    },
    setLoggedIn(state, loggedIn) {
        state.loggedIn = loggedIn;
    },
    setNotSigned(state, notSigned) {
        state.notSigned = notSigned;
    },
    setOldCustomer(state, oldCustomer) {
        state.oldCustomer = oldCustomer;
    },
    setWalletBalances(state, walletBalances) {
        state.walletBalances = walletBalances;
    },
    setWalletBalancesAreUpdated(state, updated) {
        state.areWalletBalancesUpdated = updated;
    },
    setWalletBalancesInterval(state, interval) {
        state.walletBalancesInterval = interval;
    },
    setWalletFriendLink(state, walletFriendLink) {
        state.walletFriendLink = walletFriendLink;
    },
    setOrderStatus(state, data) {
        state.order.status = data.status;
    },
    setOrderData(state, data) {
        state.order = data;
    },
    setBiometricData(state, data) {
        state.biometricData = data;
    },
    setContacts(state, data) {
        state.contacts = data;
    }
};

const getters = {
    getUserFirstName(state) {
        return state.firstName;
    },
    getUserName(state) {
        return state.name;
    },
    getCountry(state) {
        return state.country;
    },
    getLoggedIn(state) {
        return state.loggedIn;
    },
    getNotSigned(state) {
        return state.notSigned;
    },
    getOldCustomerd(state) {
        return state.oldCustomer;
    },
    getWalletBalance: (state) => (currency) => {
        return (state.walletBalances.find(balance => balance.currency === currency) || {}).amount;
    },
    getWalletBalances(state) {
        return state.walletBalances;
    },
    areWalletBalancesUpdated(state) {
        return state.areWalletBalancesUpdated;
    },
    getWalletBalancesInterval(state) {
        return state.walletBalancesInterval;
    },
    hasWallet: (state) => (currency) => {
        return !!state.walletBalances && state.walletBalances.find(balance => balance.currency === currency);
    },
    getWalletFriendLink(state) {
        return state.walletFriendLink;
    },
    isOrderActive(state) {
        return state.order && state.order.status === 'OPEN'
    },
    getOrderStatus(state) {
        return state.order && state.order.status
    },
    getOrderId(state) {
        return state.order && state.order.id
    },
    getOrderData(state) {
        return state.order
    },
    getBiometricData(state) {
        return state.biometricData
    },
    isFaceId(state) {
        return state.biometricData.biometryType == 'faceId'
    },
    getContacts(state) {
        return state.contacts;
    }
};

export default {
    state,
    mutations,
    getters
}