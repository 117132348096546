<template>
  <div>
    <Header :currentStep="2" @stepBack="$router.go(-1)"></Header>
    <div class="main-wrapper">
      <div class="block block-payment">
        <div class="wrap">
          <div v-if="loading" class="loader_page">
            <img src="../assets/images/loading.gif" />
          </div>
          <div v-show="!loading">
            <h1 class="title" v-text="$ml.with('VueJS').get('paymentTypeCredit')"></h1>
            <div class="before-iframe-box">
              <div class="text-center">
                <div>
                  <p class="sum">
                    <span v-formatTitleAmount="[paymentData.p_amount, '₪']" class="p_amount"></span>
                  </p>
                  <div v-if="paymentData.installments">
                    <label>₪{{ paymentData.installments.instalment }} * {{ paymentData.numOfPayments }}
                      {{ $ml.get('installments') }}</label>
                  </div>
                  <label v-if="paymentData.t_countryName">{{ this.$ml.with('VueJS').get('for') }}
                    {{ paymentData.t_titleAmount }}
                    {{ this.$ml.with('VueJS').get('to') }}{{ paymentData.t_countryName }}</label>
                </div>
              </div>
              <div class="iframe-box">
                <iframe @load="loaded()" :src="url" id="yaad-iframe"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />
  </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import Footer from "@/components/Footer.vue";
import { isMobile } from "../services/utils";

export default {
  name: "CreditCardPayment",
  components: { MainNav, Header, Footer },
  data() {
    return {
      loading: true,
      paymentData: {},
      isMobileDevice: isMobile()
    };
  },
  beforeRouteLeave(to, from, next) {
    $('.select-lang').show();
    sessionStorage.removeItem("cc_tempInfo");
    next();
  },
  created() {
    this.loading = true;
    this.paymentData = JSON.parse(sessionStorage.getItem("cc_tempInfo"));
    if (!this.paymentData) {
      this.$router.push('transfer');
    }
  },
  mounted() {
    $('.select-lang').hide();
  },
  methods: {
    loaded() {
      this.loading = false;
      $('#yaad-iframe').height(document.getElementById('yaad-iframe').ownerDocument.body.scrollHeight);
    }
  },
  computed: {
    url() {
      return this.paymentData.url;
    },
  }
};
</script>

<style lang="scss" scoped>
.block-payment {
  padding-top: 14px;
  max-width: 600px;
  margin: 0 auto;

  .sum {
    margin-top: -20px;
    margin-bottom: -7px;
  }
}

iframe {
  border: none;
  width: 100%;
}

.iframe-box {
  position: relative;
  margin-bottom: 10px;
}

.before-iframe-box {
  margin: 0px auto 12px;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-top: 10px;
}

.p_amount {
  font-size: 40px;
}

.cc_symbol {
  font-size: 24px;
}
</style>