<template>
  <div class="payment-balances-card-wrapper ">
    <Drawer ref="actionDrawer" :title="$ml.get('quickActions')" drawerId="actionDrawer"
      @beforeClose="!hideWallets ? coverCard() : ''">
      <template v-slot:content>
        <div v-if="getActionPicked === 'wallet'" class="drawer-action-lines">
          <WalletActions />
        </div>
        <div v-if="getActionPicked === 'card'" class="drawer-action-lines">
          <CardActions :card="selectedCard" />
        </div>
      </template>
    </Drawer>
    <div class="payment-balances-card-container">
      <div class="custom-select cards-custom-select" v-show="!this.loading && asList">
        <select @change="selectCard()" class="form-control mygmt2-select" id="selectedCard" v-model="selectedCard">
          <option :value="card" v-for="card in cards" :key="card.cid">
            Card {{ card.last4digits }}({{ card.currency }})
          </option>
        </select>
      </div>
      <section class="splide" aria-label="Payment Balances Carousel" id="paymentBalancesCarousel" v-show="!asList">
        <div class="splide__track splide-cards-slides">
          <ul class="splide__list ">
            <li class="splide__slide slide-content" v-if="!hideWallets">
              <Wallet :id="'wallet'" currency="ILS" @onActionClick="standOut('wallet')" />
            </li>
            <li class="splide__slide slide-content" v-for="(card, index) in cards" :id="index" :key="card.last4digits">
              <Card :status="card.status" :cid="card.cid" :currency="card.currency" :last4digits="card.last4digits"
                :blockCode="card.blockCode" :balance="card.balance" @onActionClick="standOut('card', card)"
                :unwrapCards="hideWallets" :id="index + 'card'" />
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { isApp, isMobile } from "@/services/utils";
import Wallet from "@/components/Wallet.vue";
import Card from "@/components/Card.vue";
import { getCardsAsync } from "@/services/api";
import Drawer from "@/components/Drawer.vue";
import WalletActions from "@/components/WalletActions.vue";
import CardActions from "@/components/CardActions.vue";
import { getCardData } from "@/services/api";


export default {
  name: 'PaymentBalancesCard',
  components: { CardActions, WalletActions, Wallet, Card, Drawer },
  props: {
    hideWallets: {
      type: Boolean,
      required: false,
    },
    asList: {
      type: Boolean,
      required: false
    },
    filterBy: {
      type: Array,
      required: false,
      default: []
    },



  },
  data() {
    return {
      isApp: isApp(),
      cards: [],
      selectedCard: {},
      loading: true,
      actionPicked: '',
      isMobileDevice: isMobile(),
    }
  },
  async created() {
    await this.loadCards()
    if (!this.asList) {
      this.loadCarousel()
    }
    this.loading = false;
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getActionPicked() {
      return this.actionPicked || 'wallet'
    },
  },
  mounted() {
  },
  methods: {
    selectCard() {
      this.$store.commit('setSelectedCard', this.selectedCard)
      this.$emit('loadTransactions')
    },
    getBlockedText(card) {
    return card.blockCode === 'BLOCKED' ? this.$ml.get('blocked') : (card.blockCode === 'BLOCKED_PERMANENTLY'? this.$ml.get('STATUS_CANCELED')  : '' )
    },
    
    async loadCards() {
      if (this.$store.getters.getCardsList.length > 0 && this.$store.getters.getSelectedCard) {
        this.selectedCard = this.$store.getters.getSelectedCard;
        this.cards = this.filterResults(this.$store.getters.getCardsList);
        if (!this.isMobileDevice) this.$emit('loadTransactions')

      } else {
        let result = await getCardsAsync();
        if (result && result.length) {
          const ordered = _.orderBy(result, [
            (item) => {
              if (item.status === 'ACTIVATED' && item.blockCode === 'ENABLED') return 1;
              if (item.blockCode === 'BLOCKED') return 2;
              if (item.blockCode === 'BLOCKED_PERMANENTLY') return 3;
              if (item.status === 'CUSTOMER') return 4;
              return 5;
            },
            (item) => {
              if (item.currency === 'ILS') return 1;
              if (item.currency === 'EUR') return 2;
              if (item.currency === 'USD') return 3;
              return 4;
            },
          ]);

          const filtered = this.filterResults(ordered);
          this.cards = _.cloneDeep(filtered)
          const promises = ordered.map(async (card, index) => {
            try {
              if (card.status === 'ACTIVATED') {
                const cardData = await getCardData({ "cid": card.cid });
                ordered[index]['balance'] = cardData.balance;
              } else throw ''
            } catch {
              ordered[index]['balance'] = null;
            }
          });
          Promise.all(promises).then(() => {
            this.$store.commit('setCardsList', ordered);
            this.$store.commit('setSelectedCard', ordered[0]);
            localStorage.setItem('maxCardNumber', ordered[0].cid);
            this.selectedCard = ordered[0];
            this.setLocalCardsBalances(ordered)
            if (!this.isMobileDevice) this.$emit('loadTransactions')
          });
        }
      }
    },

    setLocalCardsBalances(orderedCards) {
      for (let i = 0; i < this.cards.length; i++) {
        const currCard = this.cards[i]
        const idx = orderedCards.findIndex((card) => currCard.cid === card.cid)
        if (idx > -1) {
          currCard['balance'] = orderedCards[idx].balance
        }
      }
    },


    filterResults(data) {
      const filterBy = this.filterBy || [];
      return data.filter(item => {
        return filterBy.every(filter => {
          const [key, value] = Object.entries(filter)[0];
          if (value.charAt(0) === '!') {
            return item[key] !== value.substring(1)
          } else return item[key] === value;
        });
      });
    },

    loadCarousel() {
      let isElementOnDom = $("#paymentBalancesCarousel").length;
      if (!isElementOnDom) {
        return
      }
      this.loading = false;
      let isMobileDevice = isMobile();
      let splideOptions = {
        arrows: false,
        pagination: true,
        isNavigation: false,
        direction: this.getLang === 'il' ? 'rtl' : 'ltr',
        type: 'slide',

        gap: '25px',
        perPage: 3,
        breakpoints: {
          1700: {
            perPage: 2,
          },
          1250: {
            perPage: 2,
          },

          1200: {
            perPage: 1
          },
          930: {
            perPage: 1,
            padding: { left: 0, right: 0 },

            gap: '0px'
          },
          768: {
            perPage: 1,

            padding: { left: this.calculatePaddingForCentering(300), right: this.calculatePaddingForCentering(300) },
            gap: '25px'

          },

        },
        trimSpace: false,
        classes: {
          arrows: 'splide__arrows payment-balances-arrows',
          pagination: 'splide__pagination carousel-slide-pagination hide-if-one carousel-slide-pagination-hide-for-desktop',
          page: 'splide__pagination__page carousel-slide-pagination-page gridddd',
          track: 'gridddd'
        },
      };
      var splide = new Splide('#paymentBalancesCarousel', splideOptions);
      splide.on('mounted', function () {
        let numOfSlides = $("#paymentBalancesCarousel-list").children().length
        if (numOfSlides < 2) {
          $(".hide-if-one").css('visibility', 'hidden')
        }
        let arrowsElement = $(".payment-balances-arrows");
        if (isMobileDevice) {
          arrowsElement.hide()
        } else {
          if (numOfSlides < 4) {
            arrowsElement.hide()
          }
        }
      });
      splide.on('resize', function () {
        try {
          let numOfSlides = $("#paymentBalancesCarousel-list").children().length
          let arrowsElement = $(".payment-balances-arrows");
          if (numOfSlides < 4) {
            arrowsElement.hide()
          }
        } catch (e) {
          console.log("failed to hide arrows but it's ok")
        }
      });
      let self = this;
      splide.on('active', function (e) {
      
      });

      splide.on('moved', function (e) {
        const elIndex = e
        self.$store.commit('setSelectedCard', self.cards[elIndex]);
        self.$emit('loadTransactions')
      });
      splide.mount();
        if (self.$route.path !=='/Home') {
          const selectedCard = self.$store.getters.getSelectedCard
          const idx = self.cards.findIndex((card)=> card.cid == selectedCard.cid)
          if (idx > -1) {
            splide.go(idx)
          } 
        }
    },
    standOut(type, card) {
      this.actionPicked = type;
      if (type === 'card') {
        this.selectedCard = card
        this.uncoverCard()
      }
      this.openDrawer();
    },
    uncoverCard() {
      $(`#card-container-${this.selectedCard.last4digits}`).addClass('background-none')
      $(`#card-left-flex-${this.selectedCard.last4digits}`).addClass('card-left-flex-visible')
      $(`#extra-asterisks-${this.selectedCard.last4digits}`).removeClass('invisible')
    },
    coverCard() {
      $(`#card-container-${this.selectedCard.last4digits}`).removeClass('background-none')
      $(`#card-left-flex-${this.selectedCard.last4digits}`).removeClass('card-left-flex-visible')
      $(`#extra-asterisks-${this.selectedCard.last4digits}`).addClass('invisible')
    },
    openDrawer() {
      this.$refs.actionDrawer.openDrawer();
    },
    calculatePaddingForCentering(elWidth) {
      const screenWidth = window.innerWidth;
      const totalPadding = screenWidth - elWidth;
      const sidePadding = totalPadding / 2;
      const padding = Math.max(sidePadding, 0);
      return padding;
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.mygmt2-select {
  width: 220px;
}

.splide {
  width: 100%;
  height: 100%;

}

.slide-content {
  width: auto;
  height: auto;
  min-height: 100px;

}

.splide__track {
  width: 100%;
  height: 100%;
z-index: 999;

}

.payment-balances-card-container {
  margin-top: 20px;
  height: 250px;

}

.payment-balances-card-desktop {
  .payment-balances-card-container {
    height: 225px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-content,
li {
  min-height: 100px;
}

@media only screen and (min-width: $screen-tablet) {
  .slide-content {
    margin-left: 0px;
    margin-right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 300px !important;
  }

  .splide__track {

z-index: 1;

}

  .payment-balances-card-wrapper {
    display: block;

    .payment-balances-card-container {
      margin-top: 0;
      background: transparent;
      height: 100%;
    }
  }
}
</style>
