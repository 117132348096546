<template>
  <div v-show="loaded" class="wrap">
    <div v-show="paying" class="loader_page">
      <img src="../../assets/images/loading.gif" />
      <div class="loader_page_msg" v-text="$ml.get('paymentProcessing')"></div>
    </div>
    <div v-show="!paying">
      <h1 class="title">
        {{ $ml.get('transferFundsTo') + ' ' + getTitleAmount + ' ' + $ml.get('to') + getCountryName }}</h1>
      <h2 v-if="exchange || totalAmount != transactionAmount" class="subtitle">
        {{ $ml.with('rate', rate).get('toPay') }} ₪{{ (useCashBack && ilsCashBack ? amountToPay + cashBackCredit :
          amountToPay) | formatComma }}
        <span v-show="exchange"> = {{ getSymbol }}{{ totalAmount | formatComma }} {{ $ml.with('rate', rate).with('rate',
          rate).get('payRate') }}</span>
      </h2>
      <h2 v-if="useCashBack && ilsCashBack" class="subtitle">
        <span style="display:inline-block;"><i class="fa fas fa-gift"></i></span>
        &nbsp;{{ $ml.get('cashBackLabel') }} {{ getCashBackSymbol }}{{ cashBackCredit }}
      </h2>
      <h2 class="subtitle" style="margin:10px 8px -4px 8px;text-align:start;" v-text="$ml.get('payUsing')"></h2>

      <div class="type-payment">
        <payment-type @action="showWalletPaymentApprovalModal" div-class="item-type type-wallet" icon="fas fa-wallet"
          title="wallet.payment.title"
          :footer="$ml.with('amount', this.remainingWalletBalance).with('currency', getWalletSymbol).get('wallet.payment.footer')"
          :in-active-footer="!walletHasEnoughBalance ? $ml.get('wallet.payment.inActive.footer') : this.getLimitMessage('WALLET')"
          :active="walletHasEnoughBalance && !this.isWalletLimited" :totalAmount="totalAmount" :currency="getSymbol"
          :paymentAmount="amountToPay" :paymentCurrency="getWalletSymbol" :installmentDetails="null"
          :hidden="!this.hasWallet">
        </payment-type>
        <payment-type @action="payByCreditCard" div-class="item-type type-credit-card" icon="fa fa-credit-card"
          title="creditCard" :footer="$ml.get('creditCardCommissionMsg')"
          :in-active-footer="this.getLimitMessage(this.isCreditCardLimited ? 'CREDIT_CARD' : 'MONTHLY')"
          :active="!this.isCreditCardLimited && creditAvailabilityMap.available" :totalAmount="totalAmount"
          :currency="getSymbol"
          :paymentAmount="this.installmentDetails ? this.installmentDetails.totalAmount : amountToPay" paymentCurrency="₪"
          :in-process="isFormSubmitted" :exchange-needed="exchange" :rate="rate" :installment="true"
          @installments-action="doInstallments" :installmentDetails="installmentDetails">
        </payment-type>
        <payment-type @action="showStagingPaymentApprovalModal" div-class="item-type type-branch" icon="fas fa-store bank"
          title="arrivalBranch"
          :footer="exchange ? $ml.get('arrivalBranchMsg1') + $ml.get('arrivalBranchMsg2') : $ml.get('arrivalBranchMsg1')"
          :active="true" :totalAmount="totalAmount" :currency="getSymbol" :paymentAmount="totalAmount"
          :paymentCurrency="getSymbol" :installmentDetails="null"
          :hidden="true || this.$store.getters.getSelectedTransferOption.correspondentType !== 'MONEYGRAM' || this.hasWallet">
          <!-- currenctly hidden -->
        </payment-type>
        <modal ref="walletModal" noCancelBtn icon="fas fa-wallet" icon-style="color: white; font-size: 40px"
          :title="$ml.get('wallet.payWith')" :confirm="$ml.get('pay')" @action="payByWallet">
          <p style="font-size: 30px">{{ this.getWalletSymbol }}{{ this.amountToPay | formatComma }}</p>
          <hr />
          <p>{{ $ml.get('wallet.payment.body') }}</p>
        </modal>
        <modal ref="stagingModal" icon="fas fa-store bank" icon-style="color: white; font-size: 35px"
          :title="$ml.get('staging.payWith')" :confirm="$ml.get('staging.order')" @action="payAtBranch">
          <p style="font-size: 26px">{{ $ml.get('amount') }} {{ this.getSymbol }}{{ this.totalAmount | formatComma }}</p>
          <hr />
          <p>{{ $ml.get('staging.body') }}</p>
        </modal>
      </div>

      <div class="modal-mask modal-mask-transfer-2" @click="recentActions('staging')">
        <div class="modal-container modal-transfer" id="modal-transfer-2" @click.stop>
          <div class="modal-body">
            <img src="../../assets/images/success_cash.png" alt="">
            <div class="modal-text mb">
              <strong v-text="$ml.get('requestSuccessfully')"></strong>
              <p><span v-text="$ml.get('requestSuccessfully1')"></span>{{ referenceNumber }}</p>
              <div v-if="this.paymentType === 'BRANCH'">
                <p v-text="$ml.get('requestSuccessfully2')"></p>
                <p v-html="$ml.get('requestSuccessfully3')"></p>
                <p v-text="$ml.get('requestSuccessfully4')"></p>
              </div>
            </div>
            <button class="btn btn-yellow btn-close" v-text="$ml.get('btnClose')"
              @click="recentActions('staging')"></button>
          </div>
        </div>
      </div>
      <div class="modal-mask modal-mask-transfer-3" @click="recentActions('wallet')">
        <div class="modal-container modal-transfer" id="modal-transfer" @click.stop>
          <div class="modal-body">
            <img src="../../assets/images/success_once.gif" alt="">
            <div class="modal-text">
              <strong v-if="cardDeposit" v-text="$ml.get('billingSuccessfulCardDeposit')"></strong>
              <strong v-else-if="bankTransfer" v-text="$ml.get('billingSuccessfulBankTransferISR')"></strong>
              <strong v-else v-text="$ml.get('billingSuccessful')"></strong>
              <p v-text="$ml.get('messageSend')"></p>
            </div>
            <button class="btn btn-yellow btn-close" v-text="$ml.get('btnClose')"
              @click="recentActions('wallet')"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { commitTransaction, creditCardSign, paymentAmountInfo, sendValidation, getInstallmentsData } from "../../services/api";
import app from '../../main';
import MainNav from "@/components/MainNav";
import Header from "@/components/Header";
import PaymentType from "@/components/PaymentType";
import Modal from "@/components/Modal";
import { EventBus } from "@/event-bus";
import { getCurrencySymbol, isApp, modalHandler, transmitEvent } from "@/services/utils"
import { formatComma } from "@/filters/AppFilters";

export default {
  name: 'Step4',
  components: { Modal, PaymentType, MainNav, Header },

  data() {
    return {
      showModal: true,
      registrationData: {},
      rate: 1,
      amountToPay: 0,
      referenceNumber: '',
      creditAvailabilityMap: '',
      isSubmitted: false,
      loaded: false,
      paymentType: '',
      paying: false,
      installments: 1,
      installmentDetails: null
    }
  },
  computed: {
    amount() {
      return this.$store.getters.getSelectedTransferOption.amount + this.$store.getters.getSelectedTransferOption.commission;
    },
    transactionAmount() {
      return this.$store.getters.getSelectedTransferOption.amount;
    },
    currency() {
      return this.$store.getters.getSelectedTransferOption.currency;
    },
    cashBackCredit() {
      return this.$store.getters.getCashBackCredit;
    },
    useCashBack() {
      return this.$store.getters.getUseCashBack && this.cashBackCredit;
    },
    cashBackCurrency() {
      return this.$store.getters.getCashBackCurrency;
    },
    ilsCashBack() {
      return this.cashBackCurrency === 'ILS';
    },
    totalAmount() {
      return this.useCashBack && !this.ilsCashBack ? +(this.amount - this.cashBackCredit).toFixed(2) : this.amount;
    },
    hasWallet() {
      return this.$store.getters.hasWallet(this.walletCurrency) || localStorage.getItem('showWallet') === 'true';
    },
    walletHasEnoughBalance() {
      return this.remainingWalletBalance >= 0;
    },
    remainingWalletBalance() {
      return (this.$store.getters.getWalletBalance(this.walletCurrency) - this.amountToPay).toFixed(2);
    },
    getSymbol() {
      return this.$store.getters.getSelectedCurrencySymbol;
    },
    getWalletSymbol() {
      return getCurrencySymbol(this.walletCurrency);
    },
    getCashBackSymbol() {
      return getCurrencySymbol(this.cashBackCurrency);
    },
    exchange() {
      return this.currency !== 'ILS'
    },
    getCountryName() {
      return this.$store.getters.getCountryName;
    },
    cardDeposit() {
      return this.$store.getters.getSelectedTransferOption.transactionClass === 'CARD_DEPOSIT'
    },
    bankTransfer() {
      return this.$store.getters.getSelectedTransferOption.transactionClass === 'BANK_TRANSFER'
    },
    getTitleAmount() {
      return this.$store.getters.getTitleTransferAmount
    },
    isFormSubmitted() {
      return this.isSubmitted;
    },
    walletCurrency() {
      return 'ILS'; // currently wallet is by ILS only
    },
    isCreditCardLimited() {
      return this.isLimitExceeded('CREDIT_CARD')
    },
    isWalletLimited() {
      return this.isLimitExceeded('WALLET')
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
    this.creditAvailabilityMap = this.$store.getters.getCreditAvailabilityMap;
    let lookupDto = this.$store.getters.getSelectedTransferOption.lookupDto;
    paymentAmountInfo({
      "amount": this.$store.getters.getSelectedTransferOption.amount,
      "commission": this.$store.getters.getSelectedTransferOption.commission,
      "currency": this.currency,
      "useCashBack": this.$store.getters.getUseCashBack,
      "cashBackCurrency": this.$store.getters.getCashBackCurrency,
      "transactionRate": this.$store.getters.getSelectedExchangeRate,
      "lookupAmount": lookupDto && lookupDto.lookupAmount,
      "lookupRate": lookupDto && lookupDto.rate,
      "lookupBaseRate": lookupDto && lookupDto.baseRate
    }, (response) => {
      this.amountToPay = response.amountILS;
      this.rate = response.rate;
      this.loaded = true;
    });
  },
  updated() {
    const creditCardDiv = $('.type-credit-card')
    const branchDiv = $('.type-branch')
    const walletDiv = $('.type-wallet')
    const height = Math.max(creditCardDiv.height() || 0, branchDiv.height() || 0, walletDiv.height() || 0) 
    creditCardDiv.height(height);
    branchDiv.height(height);
    walletDiv.height(height);
  },
  methods: {
    transmitSelectedPaymentType(selectedType){
      this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_5', {selectedType, amountToPay: this.amountToPay})
    },
    getLimitByPayment(paymentType) {
      return (paymentType == 'MONTHLY')
        ? this.creditAvailabilityMap.limit
        : this.creditAvailabilityMap.paymentLimits[paymentType];
    },
    getLimitMessage(paymentType) {
      if (this.creditAvailabilityMap.message != 'CREDIT_LIMIT') { // unavailable - other reasons
        return this.$ml.get(this.creditAvailabilityMap.message);
      }
      const max = Math.max(this.getLimitByPayment(paymentType), 0);
      return this.$ml.with('amount', this.getWalletSymbol + formatComma(max)).get('CREDIT_LIMIT_' + paymentType);
    },
    isLimitExceeded(paymentType) {
      return !this.creditAvailabilityMap.customerMonthlyLimitPaused && this.amountToPay > this.getLimitByPayment(paymentType)
    },
    getLang() {
      return this.$store.getters.getLang;
    },
    getTransactionData(paymentType) {
      let selectedOption = this.$store.getters.getSelectedTransferOption;
      return {
        "amount": selectedOption.amount,
        "currency": selectedOption.currency,
        "commission": selectedOption.commission,
        "destinationCountry": selectedOption.destinationCountry,
        "sessionId": selectedOption.sessionId,
        "payoutCurrency": selectedOption.payoutCurrency,
        "transactionClass": selectedOption.transactionClass,
        "correspondentId": selectedOption.correspondentId,
        "paymentType": paymentType,
        "transferPurpose": "FAMILY_TRANSFER",
        "totalReceiveAmount": selectedOption.totalReceiveAmount || '',
        "transactionRate": this.$store.getters.getSelectedExchangeRate,
        "lookupAmount": selectedOption.lookupDto && selectedOption.lookupDto.lookupAmount,
        "lookupRate": selectedOption.lookupDto && selectedOption.lookupDto.rate,
        "lookupBaseRate": selectedOption.lookupDto && selectedOption.lookupDto.baseRate
      }
    },
    payByCreditCard() {
      this.transmitSelectedPaymentType('credit_card')
      this.paymentType = 'CREDIT_CARD'
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        app.$Progress.start();
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        sendValidation({
          "beneficiaryId": this.$store.getters.getSelectedBeneficiary,
          "CORRESPONDENT_COMMISSION": selectedOption.correspondentCommission,
          "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
          "transaction": this.getTransactionData(1)
        }, responseValidation => {
          if (!responseValidation.errorCode) {
            localStorage.setItem('mgparams', JSON.stringify(responseValidation.result));
            localStorage.setItem('selectedTransferOption', JSON.stringify(this.$store.getters.getSelectedTransferOption));
            localStorage.setItem('selectedBeneficiary', JSON.stringify(this.$store.getters.getSelectedBeneficiary));
            localStorage.setItem('customerFields', sessionStorage.getItem('requiredFields')['CUSTOMER']);
            localStorage.setItem('beneficiaryFields', sessionStorage.getItem('requiredFields')['BENEFICIARY']);
            let self = this;
            this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_6')
            creditCardSign({
              "amountILS": this.amountToPay,
              "amount": this.amount,
              "currency": this.currency,
              "destinationCountry": selectedOption.destinationCountry,
              "numOfPayments": this.installments
            }, (response) => {
              if (!response.errorCode) {
                /* yaad page*/
                //    if(response.result.masofType=='MASOF_3DS')
                //         window.location = process.env.VUE_APP_3DS_URL + response.result.textResponse;
                //     else if(response.result.masofType=='MASOF_3DS_NEW')
                //         window.location = process.env.VUE_APP_3DS_NEW_URL + response.result.textResponse;
                //     else
                //         window.location = 'https://icom.yaad.net/p/?action=pay&'+response.result.textResponse;
                /* iframe page */
                sessionStorage.setItem('cc_tempInfo', JSON.stringify({
                  url: process.env.VUE_APP_YAAD_URL + response.result.textResponse,
                  p_amount: this.installmentDetails ? this.installmentDetails.totalAmount : this.amountToPay,
                  t_amount: selectedOption.amount,
                  t_currency: selectedOption.currency,
                  t_country: selectedOption.destinationCountry,
                  t_countryName: this.getCountryName,
                  t_titleAmount: this.getTitleAmount,
                  numOfPayments: this.installments,
                  installments: this.installmentDetails,
                }));
                localStorage.setItem('analyticsEvent', JSON.stringify({group:'transaction', eventId: 'transaction_credit_card'}) );
                this.$router.push('credit_card_payment');
                app.$Progress.finish();
              } else {
                this.$store.commit('setShowModalError', true);
                this.$store.commit('setErrorCode', responseValidation.errorCode);
                self.isSubmitted = false;
                app.$Progress.finish();
                this.$analyticsService.transmitSwitchCase('transaction','transaction_error', {errorCode:  response.errorCode}) 
              }
            });
          } else {
            this.$store.commit('setShowModalError', true);
            this.$store.commit('setErrorCode', responseValidation.errorCode);
            self.isSubmitted = false;
            app.$Progress.finish();
            if (responseValidation.errorCode === 'INVALID_SESSION_INFO') {
              this.$emit('onNextStep', 0);
            }
          }
        });
      }
    },
    payAtBranch() {
      this.paymentType = 'BRANCH'
      this.$refs.stagingModal.closeModal();
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        let self = this;
        this.paying = true;
        sendValidation({
          "beneficiaryId": this.$store.getters.getSelectedBeneficiary,
          "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
          "transaction": this.getTransactionData(2)
        }, responseValidation => {
          if (!responseValidation.errorCode) {
            this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_6')
            commitTransaction({
              "transaction": {
                "id": responseValidation.result.transactionId,
                "sessionId": selectedOption.sessionId,
                "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
                "paymentType": 'BRANCH',
              }
            }, response => {
              if (!response.errorCode) {
                this.paying = false;
                self.isSubmitted = false;
                if (response.result) {
                  this.referenceNumber = response.result;
                  modalHandler('.modal-mask-transfer-2', 'show', '092086', 'light');
                  this.$analyticsService.transmitSwitchCase('transaction','transaction_branch') 
                }
              } else {
                this.paying = false;
                this.$store.commit('setShowModalError', true);
                this.$store.commit('setErrorCode', responseValidation.errorCode);
                self.isSubmitted = false;
                this.$analyticsService.transmitSwitchCase('transaction','transaction_error', {errorCode:  responseValidation.errorCode}) 
              }
            });
          } else {
            this.paying = false;
            this.$store.commit('setShowModalError', true);
            this.$store.commit('setErrorCode', responseValidation.errorCode);
            self.isSubmitted = false;
          }
        });
        setTimeout(() => this.isSubmitted = false, 10000);

      }
    },
    showWalletPaymentApprovalModal() {
      this.transmitSelectedPaymentType('my_wallet') 
      if (this.walletHasEnoughBalance) {
        this.$refs.walletModal.openModal()
      }
    },
    showStagingPaymentApprovalModal() {
      this.$refs.stagingModal.openModal()
    },
    payByWallet() {
      this.paymentType = 'WALLET'
      this.$refs.walletModal.closeModal();
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        let self = this;
        this.paying = true;
        sendValidation({
          "beneficiaryId": this.$store.getters.getSelectedBeneficiary,
          "CORRESPONDENT_COMMISSION": selectedOption.correspondentCommission,
          "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
          "transaction": this.getTransactionData()
        }, responseValidation => {
          if (!responseValidation.errorCode) {
            this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_6')
            commitTransaction({
              "transaction": {
                "id": responseValidation.result.transactionId,
                "sessionId": selectedOption.sessionId,
                "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
                "paymentType": 'WALLET',
              }
            }, response => {
              if (!response.errorCode) {
                this.paying = false;
                self.isSubmitted = false;
                if (response.result) {
                  this.referenceNumber = response.result;
                  modalHandler('.modal-mask-transfer-3', 'show', '092086', 'light');
                  this.$analyticsService.transmitSwitchCase('transaction', 'transaction_wallet')
                }
              } else {
                this.paying = false;
                this.$store.commit('setShowModalError', true);
                this.$store.commit('setErrorCode', responseValidation.errorCode);
                self.isSubmitted = false;
                this.$analyticsService.transmitSwitchCase('transaction','transaction_error', {errorCode:  responseValidation.errorCode}) 
              }
            });
          } else {
            this.paying = false;
            this.$store.commit('setShowModalError', true);
            this.$store.commit('setErrorCode', responseValidation.errorCode);
            self.isSubmitted = false;
          }
        });
        setTimeout(() => this.isSubmitted = false, 10000);
      }
    },
    recentActions(paymentType) {
      if (paymentType == 'wallet') {
        setTimeout(() => EventBus.$emit('updateWalletBalances'), 5000);
      }
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
      this.$router.push({ name: localStorage.getItem('homePage') || 'recent_actions' })
    },
    doInstallments(val) {
      this.installments = val;
      this.installmentDetails = null
      if (val > 1) {
        getInstallmentsData({ principal: this.amountToPay, numOfPayments: val }, response => {
          this.installmentDetails = { instalment: response.installment, interest: (response.totalAmount - this.amountToPay).toFixed(2), totalAmount: response.totalAmount }
        });
      }
    }
  }
}
</script>
