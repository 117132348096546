<template>
    <div>
        <!--<i v-if="isImg" ref="remove" class="icon icon-remove remove-file" @click="removeFile()"></i>-->
        <label class="file-upload" :class="{'file-upload--bordered': isImg}" @dragover.prevent  @drop.prevent="fileUpload">
            <template>
                <span class="file-upload__text" v-show="!isImg" v-text="$ml.with('VueJS').get('PhotoID')" style="cursor:default !important"></span>
                <span class="file-upload__image" v-show="isImg" ref="image" :class="{ 'is-pdf': idPdf }" style="cursor:default !important"></span>
            </template>
            <!--<input type="file" v-validate="'required|mimes:image/png,image/gif,image/jpeg,image/jpg,application/pdf'" @change="fileUpload" accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf">-->
        </label>
        <span class="errors" v-if="isError">{{ errors.first('photoID') }}</span>
    </div>
</template>

<script>
import {deleteDocuments, uploadFile} from "../services/api";

export default {
        name: "ImageUploadProfile",
        props: {
            content: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            idImg: null,
            docType: {
              type: String,
              required: true
            },
            isLoadedImg: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                idPdf: false,
                isError: false,
                file: {
                    contentImg: '',
                    contentType: ''
                },
                idFile: '',
                isImg: true
               // isLoaded: false
            }
        },
        // props: ['content','idImg'],
        created(){
          if(this.$store.state.registration.isLoaded){
              this.isImg = true;
          } else{
              this.isImg = false;
          }
        },
        watch: {
            content:{
                handler: function (value) {
                    if(value.content === undefined && value.contentType === undefined) {
                        return false;
                    }

                    this.file.contentImg = value.content;
                    this.file.contentType = value.contentType;

                    this.displayFileBase64();
               },
                deep: true
            },
            idImg(value){
                if(value){
                    this.isImg = true;
                }
                this.idFile = value;
            },
            isLoadedImg(value){
                if(value){
                    this.isImg = true;
                }
                this.isImg = value;
            }
        },
        methods: {
            fileUpload(e) {
                this.$validator.validate().then(result => {
                    if (result) {
                        let files = e.target.files || e.dataTransfer.files;

                        this.displayFile(files[0]);

                        var formData = new FormData();
                        formData.append('file', files[0]);
                        formData.append('docType', this.docType);
                        uploadFile(formData, (response) => {
                            if (response.data['errorCode'] != 0 && response.data['errorMessage'] !== '') {
                                this.isError = false;
                                return true;
                            }
                            this.isError = true;
                            this.$emit('fileUpload', response.data['result']);
                        }, (error) => {
                            console.log(error);
                        });

                    }
                });
            },

            displayFile(file) {
                let self = this;
                let reader = new FileReader();
                let fileType = file.name.split('.').pop();

                self.idPdf = false;
                self.$refs.image.style.backgroundImage = '';
                if (fileType === 'pdf') {
                    self.idPdf = true;
                } else {
                    reader.onloadend = () => {
                        self.imageResize(reader.result, 350, 350, (result) => {
                            this.$store.commit('setLoaded', true);
                            this.isImg = true;
                            //this.isLoaded = true;
                            self.$refs.image.style.backgroundImage = 'url(' + URL.createObjectURL(result) + ')';
                        });
                    };
                    reader.readAsDataURL(file);
                }
            },
            displayFileBase64() {
                //console.log(this.file.contentImg, this.file.contentType, this.idImg);
                let self = this;
                let reader = new FileReader();
                // let fileType = file.name.split('.').pop();

                self.idPdf = false;
                self.$refs.image.style.backgroundImage = '';
                /*if (fileType === 'pdf') {
                    self.idPdf = true;
                } else {*/
                    reader.onloadend = () => {
                        self.imageResize(reader.result, 350, 350, (result) => {
                            this.$store.commit('setLoaded', true);
                            this.isImg = true;
                            //this.isLoaded = true;
                            self.$refs.image.style.backgroundImage = 'url(' + URL.createObjectURL(result) + ')';
                        });
                    };
                    fetch('data:' + this.file.contentType + ';base64,' + this.file.contentImg)
                        .then(res => res.blob())
                        .then(blob => {
                            reader.readAsDataURL(blob);
                        });

                //}
            },
            imageResize(srcData, width, height, callback) {
                let tempImg = new Image();
                tempImg.src = srcData;

                tempImg.onload = () => {
                    let canvas = document.createElement("canvas"),
                        ctx = canvas.getContext('2d'),
                        xStart = 0,
                        yStart = 0,
                        aspectRadio,
                        newWidth,
                        newHeight;

                    canvas.width = width;
                    canvas.height = height;

                    aspectRadio = tempImg.height / tempImg.width;

                    newWidth = width;
                    newHeight = aspectRadio * width;
                    yStart = -(newHeight - height) / 2;

                    ctx.drawImage(tempImg, xStart, yStart, newWidth, newHeight);
                    canvas.toBlob(callback, 'image/png');
                };
            },
            removeFile(){
                if(!this.idFile) {
                    return false;
                }
                deleteDocuments(this.idFile, () => {
                    this.idFile = null;
                });
                this.$refs.image.style.backgroundImage = '';
                this.$refs.remove.style.display = 'none';
                this.isImg = false;
                this.$emit('fileDelete', this.idFile);
                this.$store.commit('setLoaded', false);
            }
        },
    }
</script>

<style lang="scss">
    .file-upload {
        position: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        height: 192px;
        margin: 23px auto 0;
        cursor: pointer;
        &__image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: {
                repeat: no-repeat;
                position: center;
                size: cover;
            }
            &.is-pdf {
                background: url(../assets/images/icons/i-pdf.png) no-repeat center/contain !important;
            }
        }
        &--bordered {
        //    border: 1px solid rgb(255, 0, 252);
        }
        input[type=file] {
            display: none;
        }
    }
    .icon-remove{
        position: absolute;
        top: 5px;
        right: 5px;
    }
</style>