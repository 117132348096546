<template>
  <div class="friend-body" @click="showDetails()">
    <div class="friend-card-content">
      <div class="title-row">
        <h1 class="title">{{ name }}</h1>
        <i :class="{icon: true, 'icon-arrow-up-circle': this.opened, 'icon-arrow-down-circle': !this.opened}"></i>
      </div>
      <p> {{ $ml.get('wallet.friends.friendCard.title') + ' ' + mobileNumber }}</p>
      <div v-if="opened" class="row">
        <button class="btn btn-border delete-action" v-text="$ml.get('wallet.friends.friendCard.delete')" @click="$emit('deleteFriend')" @click.stop></button>
        <button class="btn send-action" v-text="$ml.get('wallet.friends.friendCard.send')" :disabled="disabled" @click="$emit('sendToFriend')" @click.stop></button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    mobileNumber: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
    }
  },
  data() {
    return {
      opened: false
    }
  },
  methods: {
    showDetails() {
      this.opened = !this.opened
    }
  }
}

</script>
<style lang="scss" scoped>
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.friend-body {
  border: 2px solid #ececec;
  background-color: #fff;;
  text-align: right;
  padding: 10px 0;
  position: relative;
  .icon {
    left: 15px;
    position: absolute;
  }
}

.friend-card-content {
  margin: auto 4% auto 4%;
}

.title-row {
  display: flex;
  flex-direction: row;

  .title {
    font-size: 20px;
  }
}

.icon-arrow-up-circle {
  background-image: url(../assets/images/icons/i-arrow-up-circle.png);
}

.btn-border {
  border-color: $mainColor;
  color: $mainColor;
}

.row {
  display: flex;
  flex-direction: row;
  margin-top: 2%;

  .delete-action {
    width: 30%;
    margin-right: 2%;
    margin-left: 2%;
  }

  .send-action {
    width: 60%;
  }
}

@include max-screen($screen-phones) {

  .friend-body {
    padding: 10px 0;
  }

  .title-row {
    .icon {
      margin: 0 1% 0 auto;
    }
  }

  .row {
    display: flex;
    flex-direction: row;

    .delete-action {
      width: 30%;
    }

    .send-action {
      width: 60%;
    }
  }
}

</style>