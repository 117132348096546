<template>
    <div>
        <Header></Header>
        <div class="main-wrapper">
            <MainNav></MainNav>
            <div class="content block block-transfer block-unregistered">
                <div class="wrap">
                    <h1 class="title">{{ $ml.with('VueJS').get('itemNav1') }} - {{ country }}</h1>
                    <h2 class="subtitle" v-text="$ml.with('VueJS').get('transferTypePick') + ':'"></h2>
                    <div class="payment-type">
                        <div class="wrapper" v-for="(item, index) in transferOptions" @click="selectOption(item, index)"
                            :class="[{ 'disabled': item.amountLimited || !item.canSend }]">
                            <div class="item-title">
                                <i :class="iconClass(item)"></i>
                                &nbsp;&nbsp;
                                <strong>{{ $ml.with('VueJS').get(item.destinationCountry === 'ISR' ? item.transactionClass +
                                    '_ISR' : item.transactionClass) }}</strong>
                            </div>
                            <span :set="conversionRate = getOptionConvertionRate(item)"></span>
                            <span :set="payoutCurrency = getOptionPayoutCurrency(item)"></span>
                            <span :set="isEstimaed = isOptionEstimaed(item, payoutCurrency)"></span>
                            <div class="item-type">
                                <div class="col">
                                    <ul class="captions">
                                        <li v-text="$ml.with('VueJS').get('transferAmount')"></li>
                                        <li v-text="$ml.with('VueJS').get('commission')"></li>
                                        <li v-if="item.discount" v-text="$ml.with('VueJS').get('discount')"></li>
                                        <li v-text="$ml.with('VueJS').get('exchangeRate')"></li>
                                        <li v-text="$ml.with('VueJS').get('amountReceived')"></li>
                                    </ul>
                                    <ul class="values">
                                        <li>{{ item.amount }} {{ item.currency }}</li>
                                        <li>
                                            <template v-if="item.discount">{{ item.commission + item.discount }}
                                                {{ item.currency }}</template>
                                            <template v-else>{{ item.commission }} {{ item.currency }}</template>
                                        </li>
                                        <li v-if="item.discount">{{ item.discount }} {{ item.currency }}</li>
                                        <li>
                                            <template v-if="conversionRate">{{ conversionRate }} <template
                                                    v-if="isEstimaed">~{{ $ml.with('VueJS').get('estimated') }}</template></template>
                                            <template v-else>{{ payoutCurrency }} ~</template>
                                        </li>
                                        <li><template v-if="item.totalReceiveAmount"><strong>{{ item.totalReceiveAmount }}
                                                    {{ payoutCurrency }} <template v-if="isEstimaed">~{{
                                                        $ml.with('VueJS').get('estimated') }}</template></strong></template>
                                            <template v-else><template>{{ $ml.with('VueJS').get('calculatedAbroad')
                                            }}</template></template>
                                        </li>
                                    </ul>
                                    <div class="pickTransferType" v-show="!item.amountLimited && item.canSend">
                                        <span v-if="$store.state.locale.lang != 'il'"> <img style="height:26px;width:22px;"
                                                src="../assets/images/icons/i-arrow-right.png"> </span>
                                        <span v-if="$store.state.locale.lang == 'il'"> <img style="height:26px;width:22px;"
                                                src="../assets/images/icons/i-arrow-left.png"> </span>
                                    </div>
                                    <div class="pickPaymentType" v-show="item.amountLimited || !item.canSend">
                                        <img src="../assets/images/icons/info.png" alt="">
                                    </div>
                                </div>
                                <div class="col"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import { countryByIso, getCurrencySymbol } from "../services/utils"

export default {
    name: 'CalcUnregisteredOptions',
    components: { MainNav, Header },
    computed: {
        transferOptions() {
            return this.$store.getters.getTransferStep1Response.filter(element => element.transactionClass !== 'WALLET_DEPOSIT' && element.transactionClass !== 'WALLET_TO_WALLET' && element.transactionClass !== 'WALLET_FROM_WALLET' && (element.transactionClass !== 'CARD_DEPOSIT' || element.destinationCountry !== 'ISR'));
        },
        country() {
            return countryByIso(this.$store.state.transfer.destinationCountry);
        },
    },
    methods: {
        selectOption(item, index) {
            if (!item.canSend) {
                this.$store.commit('setShowModalError', true);
                this.$store.commit('setErrorCode', 'CORRESPONDENT_BLOCKED');
                this.$store.commit('setErrorMessage', this.$ml.with('VueJS').get("CORRESPONDENT_BLOCKED"));
                return;
            }
            if (item.amountLimited) {
                this.$store.commit('setShowModalError', true);
                var message = this.$ml.with('amount', item.amountLimit).with('currency', getCurrencySymbol(item.currency)).get("TRANSACTION_LIMIT");
                this.$store.commit('setErrorMessage', message);
                return;
            }
            this.$store.commit('setCurrentOption', index);
            localStorage.setItem('fromUnregisteredCalc', true);
            this.$router.push('registration');
        },
        iconClass(item) {
            return item.transactionClass == 'CARD_DEPOSIT' ? 'fa fa-credit-card' : item.transactionClass == 'BANK_TRANSFER' ? 'fa fa-university' : 'fa fa-money'
        },
        getOptionPayoutCurrency(opt) {
            return opt.validCurrencyIndicator ? opt.payoutCurrency : opt.estimatedReceiveCurrency ? opt.estimatedReceiveCurrency : '~'
        },
        getOptionConvertionRate(opt) {
            return opt.validCurrencyIndicator ? opt.conversionRate : opt.estimatedExchangeRate
        },
        isOptionEstimaed(opt, curr) {
            return opt.correspondentType == 'MONEYGRAM' && opt.currency !== curr
        }
    },
}
</script>

<style  lang="scss" scoped>.block-unregistered {
    .title {
        padding-top: 10px;
    }
}</style>