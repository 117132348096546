<template>
    <div>
        <email-update ref="emailUpdateModal"></email-update>
        <terms-update ref="termsUpdateModal"></terms-update>
        <survey ref="surveyModal"></survey>
    </div>
</template>
<script>
import EmailUpdate from './EmailUpdate.vue'
import TermsUpdate from './TermsUpdate.vue'
import Survey from './Survey.vue'

export default {
    name: 'Wellcome',
    components: {EmailUpdate, TermsUpdate, Survey},
    mounted(){
        if(this.$refs.termsUpdateModal.checkModalTerms()){
            return
        }
        if(this.$refs.emailUpdateModal.checkModalEmail()){
            return
        }
        // if(this.$refs.surveyModal.checkModalSurvey()){
        //     return
        // }
    },
}
</script>

