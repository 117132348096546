<template>
    <div>
        <Header></Header>
        <div class="main-wrapper main-wrapper-logged-out">
            <div class="content block block-calculator block-calculator-unregistered">
                <div class="wrap">
                    <h1 class="title" v-text="$ml.with('VueJS').get('itemNav3')"></h1>
                    <div class="body">

                        <lookup 
                            ref="lookup"
                            :next-btn="$ml.get('transferOptions')"
                            :logged-in="false" 
                            @ready="ready"
                            @execute="execute" 
                            @next-step="nextStep" 
                            @back-step="backStep"
                            :recaptchaToken="getRecaptchaToken"
                        ></lookup>

                        <div class="message-box shield">
                            <div class="iconInfo">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
                                    <circle cx="8" cy="4.5" r="1"/>
                                    </svg>
                                </div>
                            <div v-if="this.getCurrency !=='ILS'" class="message">
                                <span> {{this.$ml.with('VueJS').get("actualRate1")}} {{this.getSymbole}} {{this.$ml.with('VueJS').get("actualRate2")}}: ₪{{this.getRate}}</span>
                                <hr>
                            </div>
                                <div class="message">
                                <p>{{this.$ml.with('VueJS').get("marketingMessage")}}</p>
                                <p>{{this.$ml.with('VueJS').get("marketingMessage2")}}</p>
                            </div>
                        </div>

                        <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" size="invisible" :sitekey="sitekey"></vue-recaptcha>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import VueRecaptcha from 'vue-recaptcha';
import Lookup from '../components/Lookup.vue';

export default {
        name: 'CalculatorUnregistered',
        components: {MainNav, Header,VueRecaptcha, Lookup},
        data() {
            return {
                recaptchaToken: null,
                sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            }
        },
        created(){

            if(!localStorage.getItem("lang") && this.$route.query.lang) {
                var lang = this.$route.query.lang;
                this.$ml.change(lang);
                this.$store.commit('changeLang', {'lang': lang,'dir': ''});
                this.$store.commit('setLangTitle', lang);
            }
        },
        computed: {
            getDir() {
                return this.$store.getters.getDir;
            },
            getSymbole(){
                return this.$store.getters.getLookupCurrencySymbol;
            },
            getRate(){
                return this.$store.getters.getLookupCurrencyRate;
            },
            getCurrency(){
                return this.$store.getters.getLookupCurrency;
            },
            getRecaptchaToken(){
                return this.recaptchaToken;
            }
        },
        mounted() {
            document.body.setAttribute('dir', this.getDir);
        },
        methods: {
            ready(){
                if(this.$route.query && this.$route.query.currency){
                      this.$refs.lookup.setCurency(this.$route.query.currency);
                      this.$route.query.currency = null;
                }
            },
            nextStep(){
                this.$router.push('calc-unregistered-options');
            },
            backStep(){
                this.$router.push('/calculator-unregistered')
            },
            execute(){
                this.$refs.recaptcha.execute();
            },
            onVerify: function (recaptchaToken) {
                this.recaptchaToken = recaptchaToken;
                setTimeout(() => this.$refs.lookup.doLookup(), 300);
            },
            onExpired: function () {
                this.$refs.recaptcha.reset();
            },
        }
    }
</script>

