<template>
  <div class="grey-bgc">
    <Header @stepBack="changeStep(currentStep - 2, true)" :currentStep="currentStep"></Header>
    <div class="main-wrapper ">
      <div class="content block block-transfer" :class="currentStepClass">
        <div class="content-top">
          <div class="breadcrumbs breadcrumbs-main">
            <ul>
              <li v-for="(step, index) in steps"
                :class="{ 'active': currentStep === index + 1, 'visited': index + 1 < currentStep }"
                @click="changeStep(index, true)">
                {{ $ml.with('VueJS').get(step) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="body">
          <component :is="currentTabComponent" @onNextStep="changeStep"></component>
        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />
  </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import Step1 from "./Transfer/Step1";
import Step2 from "./Transfer/Step2";
import Step3 from "./Transfer/Step3";
import Step4 from "./Transfer/Step4";
import { EventBus } from "../event-bus";
import Footer from "@/components/Footer.vue";
import { isMobile } from "../services/utils";

export default {
  name: 'Transfer',
  components: { MainNav, Header, Step1, Step2, Step3, Step4, Footer },
  data() {
    return {
      currentStep: 1,
      currentStepClass: 'step-1',
      steps: ['t_step_amount', 't_step_transfer', 't_step_summary', 't_step_pay'],
      isMobileDevice: isMobile()
    }
  },
  computed: {
    currentTabComponent() {
      return 'Step' + this.currentStep;
    },
    dir() {
      return this.$store.getters.getDir;
    },
  },
  methods: {
    changeStep(index, isClicked) {
      if (index < 0) {
        this.$router.push({ name: localStorage.getItem('homePage') || 'wallet-main' })
        return
      }
      if (index === 0 && this.currentStep > (index + 1)) {
        this.clearBeneficiary();
      }

      if (isClicked && (index + 1) > this.currentStep) {
        return false;
      }

      this.currentStep = index + 1;
      this.currentStepClass = 'step-' + index + 1;
    },
    clearTransferStep1Data() {
      this.$store.commit('setTransferStep1', {});
    },
    clearTransferOptions() {
      this.$store.commit('setCurrentOption', null);
      this.$store.commit('setLookupAmount', null); // reset lookupAmount state
      this.$store.commit('setTransferStep1Response', null);
      this.$store.commit('setCreditAvailabilityMap', null);
    },
    clearBeneficiary() {
      this.$store.commit('setCurrentBeneficiary', null);
      this.$store.commit('setBeneficiaryValid', false);
    },
  },
  created() {
    EventBus.$on('clearTransferState', () => {
      this.clearTransferStep1Data();
      this.clearTransferOptions();
      this.clearBeneficiary();
      this.changeStep(0);
    });

  },
  mounted() {
    document.body.setAttribute('dir', this.dir);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //clear specific data in specific cases
      const amountSelected = ['recent_actions', 'beneficiaries', 'TransactionsHistory', 'calculator', 'calc-unregistered-options', 'beneficiary', 'transfer-beneficiary', 'Login', 'registration_steps'];
      const optionSelected = ['calculator', 'calc-unregistered-options', 'beneficiary', 'transfer-beneficiary', 'Login', 'registration_steps'];
      const beneficiarySelected = ['Home', 'recent_actions', 'TransactionsHistory', 'beneficiaries', 'transfer-beneficiary', 'beneficiary'];
      window.scrollTo(0, 0);
      if (!amountSelected.includes(from.name)) {//already selected amount and destination - do not delete
        vm.clearTransferStep1Data();
      }
      if (!optionSelected.includes(from.name)) { //already selected option - do not delete
        vm.clearTransferOptions();
      }
      if (!beneficiarySelected.includes(from.name)) {//already selected beneficiary - do not delete
        vm.clearBeneficiary();
      }

      //which transfer step
      //also check if coming from menu link (there should be no amount) then should go to step 1 even though previous page was one of these:

      if (vm.$store.state.transfer.amount && (from.name === 'calculator' || from.name === 'calc-unregistered-options' || from.name === 'Login' || from.name === 'registration_steps')) {
        vm.currentStep = 2;
        vm.currentStepClass = 'step-' + 2;
      }
      if (vm.$store.state.transfer.amount && from.name === 'transfer-beneficiary') {
        vm.currentStep = 2;
        vm.currentStepClass = 'step-' + 2;
        if (vm.$store.getters.isBeneficiaryValid) {
          vm.currentStep = 3;
          vm.currentStepClass = 'step-' + 3;
        }
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setBeneficiaryValid', false);
    this.$store.commit('resetCashBack');
    next();
  },
}
</script>

<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";


.block-transfer {
  padding-top: 0;

  .wrap {
    padding: 0;
    max-width: 739px;
    margin: 0 auto;
  }

  & .step-1 {
    text-align: center;

    label {
      text-align: start;
    }
  }

  .form-group,
  .btn {
    display: inline-block;
    vertical-align: middle;
  }

  .error {
    display: block;
  }

  .form-group {
    margin-left: 14px;

    label {
      color: #4a4a4a;
      margin-right: 10px;
    }

    .form-control {
      margin-top: 5px;
    }

    select.form-control {
      margin-top: 0;
    }

    .custom-select {
      margin-top: 5px;
    }
  }

  .btn {
    font-size: 18px;
    padding: 0 20px;
    vertical-align: bottom;
    height: 38px;
    line-height: 38px;

    &.btn-small {
      min-width: fit-content;
    }
  }

  form {
    margin-top: 22px;
  }

  .body {
    margin-top: 33px;
  }

  .payment-type {
    margin-top: 35px;

    .item-title {
      margin: 0px 5px 5px;
    }

    .wrapper {
      width: 100%;
      height: auto;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      box-shadow: 1px 1px 3px 0.5px #969696;
      background-color: #ffffff;
      padding: 10px 12px;
      margin-bottom: 5px;
      cursor: pointer;

      .item-type {
        display: flex;
        justify-content: space-between;
        position: relative;
      }

     

      &+.wrapper {
        margin-top: 10px;
      }

      .captions {
        margin-left: 40px;
      }
    }

    .col:first-child {
      display: flex;
    }

    .btn {
      display: block;
      margin: 30px auto 0;
    }

    .disabled {
      color: #00000085;
      box-shadow: none;
      background-color: #f2f2f2;

      svg {
        color: $mainColor;
      }
    }
  }

  .summary-transfer {
    margin-top: 30px;

    .list-transfer {
      border: 2px solid #ececec;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      background-color: #ffffff;
      padding: 0 15px;
    }

    .item-transfer {
      display: flex;
      font-size: 18px;
      padding: 10px 0;

      .captions {
        max-width: 210px;
        width: 100%;
      }

      .values {
        font-weight: 700;
      }

      &+.item-transfer {
        margin-top: 0px;
        border-top: 1px solid rgb(6, 26, 136);
      }
    }
  }

  .group-btn {
    margin-top: 30px;
    text-align: center;

    .btn {
      width: 130px;
      display: inline-block;

      &.wide {
        width: 200px;
      }
    }

    .btn-border {
      border-color: $mainColor;
      color: $mainColor;
      margin-left: 15px;
      line-height: 34px;

      &:hover {
        background-color: $mainColor;
        border-color: $mainColor;
        color: #ffffff;
      }
    }
  }

  .terms-transfer {
    margin-top: 25px;

    textarea.form-control {
      width: 100%;
      height: 429px;
      border: 2px solid rgb(236, 236, 236);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background-color: #ffffff;
      padding: 13px 14px;
      line-height: 21px;

      &::-webkit-scrollbar-button {
        background-image: url('');
        background-repeat: no-repeat;
        width: 5px;
        height: 0px
      }

      &::-webkit-scrollbar-track {
        background-color: #ecedee
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0px;
        border-radius: 0px;
        background-color: $mainColor;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: $mainColor;
      }

      &::-webkit-resizer {
        background-image: url('');
        background-repeat: no-repeat;
        width: 4px;
        height: 0px
      }

      &::-webkit-scrollbar {
        width: 4px;
      }
    }

    .form-group {
      width: 100%;
    }

    .group-btn {
      margin-top: 35px;
    }
  }

  .checkbox {
    margin-top: 10px;
    margin-right: 5px;
    position: relative;

    input[type=checkbox] {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;

      &:checked+label {
        &:before {
          background-color: $mainColor;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    label {
      color: $text-color;
      position: relative;
    }

    label:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 13px;
      height: 13px;
      border: 1px solid rgb(9, 32, 134);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      margin-left: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }

    label:after {
      content: '';
      display: block;
      width: 9px;
      height: 8px;
      background-image: url(../assets/images/icons/i-check.png);
      position: absolute;
      top: 3px;
      bottom: 0;
      right: 3px;
      margin: auto;
      opacity: 0;
    }
  }

  .type-payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .item-type {
      position: relative;
      width: 361px;
      height: auto;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      box-shadow: 1px 1px 3px 0.5px #969696;
      background-color: #ffffff;
      padding: 13px 10px;
      cursor: pointer;
      @include transition(all .4s ease);

      &:hover {
        background-color: #f2f2f2;
      }
    }

    .payment-type-footer {
      margin-top: 8px;
      width: 90%;
    }

    .payment-type-footer ul {
      margin-inline-start: 15%;
    }

    hr {
      margin: 12px auto 6px auto;
      width: 70%;
    }

    .item-text {
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      width: 78%;
    }

    .item-title {
      font-size: 20px;
      line-height: 24px;
    }

    .item-subtitle {
      font-size: 16px;
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      width: 42px;
      height: 42px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      position: relative;
      text-align: center;

    }

    .icon-action {
      font-size: 23px;
      line-height: 36px;
    }

    .card_disabled {
      box-shadow: none;
      background-color: #f2f2f2;
      border-color: #f2f2f2;

      &:hover {
        cursor: not-allowed;
      }
    }

    .progress {
      &:hover {
        cursor: progress;
      }
    }
  }

  .recommended {
    position: absolute;
    left: 38px;
    top: 16px;
    font-size: 18px;
  }

  .pickTransferType {
    position: absolute;
    left: 0;
    top: 20%;
  }

  .pickPaymentType {
    position: absolute;
    left: 12px;
    top: 15%;
  }
}

.modal-transfer {
  width: 582px;
  height: auto;
  text-align: center;
  padding-top: 37px;

  img {
    max-width: 123px;
  }

  .modal-text {
    font-size: 18px;
    line-height: 22px;
    color: #fdffff;
    max-width: 275px;
    margin: 48px auto 0;

    span {
      color: #d4c54b;
    }

    &.mb {
      margin: 30px auto 0;
    }
  }

  .fancybox-close-small {
    display: none;
  }

  .btn-yellow {
    width: 105px;
    height: 35px;
    line-height: 35px;
    margin: 50px auto 20px;
    font-size: 18px;
  }

  .btn-border {
    width: 185px;
    height: 34px;
    line-height: 32px;
    margin: 24px auto 20px;
    border-color: #d4c54b;
    color: #d4c54b;

    &+.btn {
      margin: 33px 15px 20px;
    }
  }
}

#modal-transfer-error {
  .btn-border {
    width: 105px;
    height: 34px;
    line-height: 32px;
  }

  .btn-yellow {
    margin-top: 28px;
  }
}

.modal-success {
  text-align: center;
  height: auto;
  padding: 70px 24px;

  .title {
    font-size: 22px;
    color: #ffffff;
  }
}


@media only screen and (min-width: $screen-tablet) {
    .wrapper:hover {
         @include transition(all .4s ease);
         background-color: #f2f2f2 !important;
  
         .icon-recommended {
           background-color: #ffffff;
         }
       }
    

}
</style>
