<template>
    <div class="form-group">
       <textarea name="rules" id="rules" class="form-control" disabled dir="rtl" v-if="this.$store.state.locale.lang == 'il'">
תנאי שימוש לאתר ולשירותים

ברוך הבא לאתר שלנו!
ג'י.אם.טי טק אינוביישן בע"מ ("החברה" או "ג'י.אם.טי") הינה חברה פרטית הפועלת בתחום השירותים
הפיננסיים ומתמחה, בין היתר, בהעברות כספים לחו"ל, המרות מט"ח, טעינת כרטיסים וכיוצ"ב.
השימוש באתר ו/או בשירותי החברה כפוף לתנאי שימוש אלה (להלן: "תנאי השימוש"), בין אם תשתמש
בשירותי החברה באמצעות האתר ו/או בכל אמצעי אחר שתעמיד לרשותך החברה (לרבות נותני שירות מטעמה,
כהגדרתם להלן). לחברה שמורה הזכות לעדכן תנאי שימוש אלה מעת לעת.
הגלישה באתר החברה ו/או השימוש בשירותיה, בכל אמצעי שהוא, עשויים להיות כרוכים במסירת מידע לחברה
ו/או באיסוף מידע על המשתמש (לרבות מידע אישי), לרבות מידע הנאסף ע"י צדדים שלישיים עבור החברה
ויראו את האמור בתנאי שימוש זה חל גם באשר למידע זה. על המידע שישמר במאגרי החברה תחול מדיניות
הפרטיות הזמינה לצפייה בכתובת gmtonline.co.il .תנאי השימוש ומדיניות הפרטיות גם יחד מהווים את הבסיס
המשפטי בקשר עם השימוש שלך באתר ו/או בשירותי החברה.
תנאי השימוש חלים בנוסף לכל תנאי ו/או הוראה נוספת שתתפרסם באתר או במסמך עסקה אחר, והשימוש
בחלקים מסוימים באתר עשוי להיות כפוף לכללים נוספים אשר יופיעו באותם חלקים.
גלישה באתר ו/או שימוש בשירותי החברה, ייחשב להסכמה מפורשת ומודעת מצדך לכל התנאים, ההתניות
וההודעות הנכללים בתנאי השימוש ומדיניות הפרטיות. במידה שאינך מסכים לתנאי מדיניות הפרטיות ו/או תנאי
השימוש, כולם או מקצתם, אל תשתמש באתר ו/או בשירותי החברה.

תנאי השימוש נכתבו לצורכי נוחות בלשון זכר, אך הם מכוונים לגברים ונשים באופן שווה.

1 .הגדרות
1.1. "אתה" או "משתמש" - כל אדם הגולש באתר באמצעות מחשב ו/או מכשיר קצה אחר, לכל מטרה
שהיא, לרבות לקוח ("שירותי החברה" ו"לקוח", כהגדרתם להלן).
1.2. "אתר האינטרנט" או "האתר" - אתר האינטרנט של ג'י.אם.טי טק אינוביישן, המתנהל בכתובת
gmtonline.co.il (או כל כתובת אחרת כפי שתהיה מעת לעת), המאפשר למשתמש, בין היתר, לבצע
פעולות בהתאם לשירותי החברה.
1.3. "תוכן" או "תכנים" - כל מידע מכל מין וסוג, לרבות כל תוכן מילולי, חזותי, קולי, אור-קולי או כל שילוב
שלהם וכן עיצובם או עיבודם או הצגתם בכל אופן שהוא, לרבות כל תמונה, איור, הנפשה, צילום,
תרשים, דגם, דמות, הדמיה, סרטון, קובץ קולי, קובץ מוסיקלי, תוכנה, קוד מחשב, יישום, פרוטוקול,
מאמר, מאגר נתונים, ממשק וכל תו, סימן, סמל וצלמית (icon (המופיע ו/או משולב באתר ע"י החברה,
והכל בין אם הם בבעלות החברה ובין אם בבעלות צד ג' המקנה לחברה זכות שימוש בהם.
1.4. "מידע" – כל מידע שתמסור לחברה באתר ו/או בכל אמצעי אחר, לרבות מידע אישי.
1.5. "מידע אישי", "מידע אנונימי" – כהגדרתם במדיניות הפרטיות של החברה, הניתנת לצפייה בכתובת
.gmtonline.co.il
1.6. "השירותים" או "שירותי החברה" – כל השירותים המוענקים ללקוח באמצעות החברה ו/או נותני
שירות מטעמה, לרבות העברות כספיות, המרות מט"ח, מכירה וטעינה של כרטיסי אשראי, אשראי
בתשלומים וכיוצ"ב.
1.7. "לקוח" – כל אדם המשתמש בשירותי החברה, בכל אמצעי שהוא, לרבות אתר האינטרנט ו/או באופן
פרונטלי באמצעות נותני שירות החברה.
1.8. "נותן שירות"– נציגים מורשים מטעם החברה, הפרוסים ברחבי הארץ, אשר הוסמכו על ידי החברה
וקיבלו את כלל האישורים הנדרשים לפעול מטעמה, לרבות נותני שירות בעלי רישיון לפי חוק הפיקוח
על שירותים פיננסיים (שירותים פיננסיים מוסדרים), תשע"ו – 2016 ו/או אישור המשך עיסוק בתוקף
שניתן על ידי רשות שוק ההון.
1.9. "יום עסקים" - יום עסקים של החברה לעניין שירותי תשלום שמבוצעים ללקוחות החברה הוא כל יום,
למעט יום שבת, ימי שבתון, שני ימי ראש השנה, ערב יום כיפור ויום כיפור, ראשון של סוכות ושמיני
עצרת, פורים, ראשון ושביעי של פסח, יום העצמאות, חג השבועות ותשעה באב, או יום אחר אשר
ייקבע על ידי המפקח על הבנקים בבנק-ישראל או אשר ייקבע על פי כל דין כיום שאינו יום עסקים
כאמור.
"יום עסקים במט"ח" - יום עסקים במט"ח הוא יום שבו בנק-ישראל מפרסם שערים יציגים חדשים של
מטבע הפיקדון, ובהעדר פרסום כאמור יחול השער האחרון המקובל בבנק-ישראל.

2 .שעות פעילות החברה
2.1. החברה תספק את שירותיה באמצעות נותני השירות מטעמה בימי עסקים שונים ובמטה החברה בימי
עסקים משעה 08:30 עד 18:30 בימי שישי, ערבי חג ובכל יום אחר שאינו יום עסקים, החברה תספק
את שירותיה במטה החברה בין השעות 08:30 – 13:30 (להלן: "שעות הפעילות"). מובהר כי מועדי
הפעילות לענין ביצוע הפעולות יהיו בהתאם לימי העסקים בהם פועל מטה החברה, ולגבי פעולות
במט"ח בהתאם ליום העסקים במט"ח ולשעות הפעילות של המטה כאמור לעיל.
2.2. החברה רשאית לעדכן את שעות הפעילות, בהתאם לשירותים שונים ובהתאם לדרכי התקשרות שונות,
סוגי או ערוצי שירות שונים. שעות הפעילות יפורסמו באתר האינטרנט של החברה ויעודכנו מעת לעת
בהתאם לשיקול דעת החברה.
2.3. למרות האמור לעיל ובכפוף לכל דין, החברה רשאית לקבוע מעת לעת מה יהיו ימי העסקים אשר יחולו
לעניין שירותים שונים, מועדים ושעות פתיחה וסיום לעניין קנייה או מכירה או ביצוע פעולות במטבע
חוץ, בשקלים, בצמודי מטבע, אשראי, בימים מסוימים בלבד, או בשעות מוגבלות ומסוימות בלבד
במהלך יום עסקים כלשהו – והכל ביחס לכל יום או ימים או תקופות ומועדים.
4.2. הוראת תשלום (כהגדרתה בחוק שירותי תשלום, תשע"ט – 2019 (או הוראה לביצוע פעולה שתתקבל
לאחר מועד סיום יום העסקים, לפי המוקדם, יראו אותה כאילו התקבלה ביום העסקים הבא.

3 .זכות השימוש באתר
3.1. בכפוף לעמידתך בתנאי השימוש, החברה מעניקה לך בזאת רישיון מוגבל, אישי, שאינו בלעדי, בלתי
עביר ובלתי ניתן להמחאה ו/או להענקת רישיונות משנה, בלתי סחיר וניתן לביטול, לעשות שימוש
באתר (לרבות בכל תוכן או שירות המוצע בו) לשימוש אישי ופרטי בלבד שלך, ולא לכל שימוש מסחרי
מכל סוג שהוא, ולמטרות חוקיות בלבד. הינך מנוע מלעשות שימוש באתר או בכל חלק ממנו למטרה
בלתי חוקית כלשהיא.
3.2. בעצם השימוש באתר או בכל חלק ממנו, הינך מצהיר ומתחייב כי לא תבצע באתר ו/או בכל חלק ממנו
איזה מהפעולות הבאות (בין בעצמך ובין על ידי מי מטעמך): (א) להשתמש באתר לכל מטרה בלתי
חוקית ו/או בניגוד לתנאי השימוש או לעשות באתר או בכל חלק ממנו שימוש לרעה; (ב) להעתיק,
לשכפל, ליצור הנדסה חוזרת או נגזרות, לשנות, לתרגם את האתר (לרבות כל תוכן או שירות המוצע בו);
(ג) לעקוף חסמים טכניים באתר, להסתיר הודעות לעניין שמירה על זכויות קניין רוחני של החברה (ו/או
מי מטעמה) באתר; (ד) לבצע פעולה מכל סוג שהוא אשר תפריע או עשויה להפריע לפעולה התקינה
של האתר ו/או למנוע גישה לתכנים ו/או לשירותים ע"י משתמשים אחרים באתר; (ה) אין להפעיל או
לאפשר להפעיל כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות מסוג Robots,Crawlers וכדומה,
לשם חיפוש, סריקה, העתקה או אחזור אוטומטי של תכנים מתוך האתר, ובכלל זה אין ליצור מאגר
מידע על ידי הורדה ו/או אחסון של תכנים מתוך האתר; (ו) לשתול או להחדיר לאתר כל וירוס, "תולעת"
סוס טרויאני, באג, קוד, יישום או תוכנה שעשויים או נועדו להזיק לפעילות האתר; (ז) להפר אי אילו
מתנאי השימוש.
3.3. לשם נוחות המשתמשים באתר, האתר עשוי להציע קישורים/הפניות לאתרי אינטרנט של צד
ג'. במקרה שתשתמש בקישורים/הפניות אלה, תצא מהאתר. החברה אינה מתחייבת כי
הפניות ו/או קישורים כאמור יובילו לאתרים פעילים, לא בדקה את כל אתרי צד ג' אלה, אין לה
שליטה עליהם ואין היא אחראית לאתרים אלה או לתוכנם. לפיכך החברה אינה יוצרת או
מוסרת מצג כלשהו לגביהם או לגבי כל מידע, שירותים או תכנים הנמצאים בהם, או לגבי כל
תוצאה העשויה לנבוע מן השימוש בהם, לרבות מהשירותים המוצעים על ידם. ההחלטה
לגשת לאתר צד ג' כלשהו המקושר לאתר היא באחריותך בלבד. כאשר אתה עובר לקישורים
עליך לעיין בתנאי השימוש וביתר התנאים וההנחיות באתר המקושר ולנהוג על פי הכתוב
בהם.

4 .זכויות קניין רוחני
4.1. החברה היא הבעלים היחיד של האתר, תוכנו, עיצובו, תכנונו, מבנה הקבצים שלו, הלוגו וסימני המסחר
המופיעים בו.
4.2. עיצוב ותצוגת האתר, לרבות התכנים המצויים בו מוגנים ו/או עשויים להיות מוגנים בזכויות יוצרים ו/או
סימני מסחר, פטנטים או זכויות קניין רוחני אחרות של החברה ו/או של צדדים שלישיים, וחל עליך
איסור להעתיקם, כולם או חלקם, או לשכפל, להפיץ, למכור, להרשות שימוש, להציג בפומבי, ליצור
נגזרות, לבצע, לפרסם, או לנצל למטרה מסחרית כלשהי את האתר או כל חלק ממנו. בנוסף, אינך רשאי
ליצור מסגרות (Frame) לאתר או שימוש ב-Meta Tags או כל טקסט חבוי אחר תוך שימוש בשמה של
החברה או בסימני המסחר שלה ו/או של מי מטעמה. כל שימוש בלתי מורשה יגרור, בין היתר, ביטול
תנאי השימוש והפסקת זכותך להשתמש באתר.
4.3. למען הסר ספק, למעט זכות השימוש שהוקנתה לך במסגרת תנאי השימוש, החברה אינה מעניקה לך
זכות כלשהיא באתר ו/או בחלק ממנו, לרבות בקניין הרוחני של החברה או של צדדים שלישיים.

5 .שימוש בשירותי החברה
5.1. השימוש בשירותים המוצעים ע"י החברה יהיה בהתאם לדין ויעשה בתום לב ובדרך מקובלת. לא יעשה
בהם כל שימוש לרעה או כל שימוש בניגוד לדין או לתקנת הציבור או כל שימוש לא סביר או למטרות
אחרות מאלו שלשמן נועד השירות, לרבות ביצוע העברות כספים בלתי חוקיות או אסורות או העברות
לשם עקיפת המגבלות הנוגעות לדיווחים בקשר עם איסור הלבנת הון ומימון טרור או התחזות לכל אדם
או ישות משפטית אחרת. מבלי לגרוע מזכויות החברה, כל העובר על איסור כאמור, ישפה את החברה
על כל נזק שיגרם לו כתוצאה מכך.
5.2. מקור הכספים המשמשים לביצוע השירותים הוא לגיטימי, חוקי ואינו קשור לעבירה כלשהי על חוקי
מדינת ישראל ו/או כל מדינה אחרת. האחריות בגין מהות הפעולה חלה על המשתמש בלבד
וחלה עליו החובה לבדוק טרם ביצוע העסקה את משמעותה ואת הוראות הדין הנוגעות אליה.
5.3. 
5.4. החברה ופעילותה כפופות להוראות דין שונות ולפיהן מחויבת במניעת מעילות והונאות
בהתאם להוראות הדין הבינלאומי החלות עליה מכח הסכמים עם צדדים שלישיים. המקרים
המתוארים להלן עשויים להעיד על דבר הונאה ומעלים חשש ביחס לאמיתות העסקה, לפיכך הנך
מתבקש להימנע מביצוע עסקאות בנסיבות דומות:
 5.4.1. אם בעסקה מעורב גורם שאינך מכיר ו/או אינך יכול לאמת את זהותו;
 5.4.2. אם נתבקשת, על ידי צד שלישי, שאינו ידוע או מוכר, לספק פרטים חלקיים או מלאים
 אודות פעולותיך;
 5.4.3. אם מוצעים לך שירותים ו/או מוצרים במחירים נמוכים משמעותית ממחירי השוק המקובלים;
 5.4.4. אם הוצעו לך הטבות ו/או מבצעים ו/או השתתפות בהגרלות ו/או נמסרה לך הודעה על זכייה
 ע"י גורם שאינו מוכר לך;
 רשימת המקרים המנויה לעיל הינה רשימה חלקית שאינה ממצה, המבוססת על ניסיונה של החברה.
 החברה וכל מי מטעמה, לא יישאו באחריות כלשהי אם תבחר להתעלם מהאזהרות דלעיל
 ולבצע עסקאות על אף החשש העולה מהן.
5.5. מבלי לגרוע מהאמור לעיל, אין לעשות כל שימוש בשירותים לצורך ביצוע עסקאות או תשלום עבור
שירותים/מוצרים כמפורט להלן:
 5.5.1. עסקאות במטבעות וירטואליים/קריפטוגרפיים.
 5.5.2. עסקאות הלוואות או אשראי או מתן שירותים פיננסיים מכל סוג, לרבות פריטת כסף או המרת
 כספים כדרך עיסוק.
 5.5.3. תוכן ושירותים למבוגרים.
 5.5.4. הימורים.
 5.5.5. סמים.
 5.5.6. סחר ביהלומים.
 5.5.7. סחר בנשק.
 5.5.8. עסקאות בני"ע, אופציות בינאריות ופורקס.
 5.5.9. שירותי מין בתשלום וסחר בבני אדם.
 5.5.10. כל פעילות אסורה על פי דין, לרבות הלבנת הון ומימון טרור ולרבות כל פעילות המחייבת
 רישיונות או היתרים – ככל שאין בידי הצדדים הרלבנטיים את כל הרישיונות וההיתרים
 הדרושים או שהם אינם תקפים.
5.6. בכל מקרה של שימוש בניגוד לאמור בסעיף 2.4 לעיל ובניגוד לאמור ביתר הוראות תנאי השימוש, לרבות
במקרה שפעילות המשתמש נחסמה/הוגבלה, או במקרה שאחד או יותר מאמצעי התשלום הרשומים
בחשבון האישי של המשתמש אינו תקין, נחסם או הוגבל לשימוש בדרך כלשהי, החברה לא תישא בכל
אחריות כלפי כל משתמש או צד ג' או בכל נזק ישיר או עקיף שנגרם להם בקשר לכך. למען הסר ספק,
מובהר כי החברה לא תאפשר ביצוע או השלמת עסקאות ותשלום עבור שירותים כמפורט בסעיף זה
(לרבות במקרה של חשד של החברה בקשר לכך), ו/או שימוש כלשהו בכספים, לרבות משיכה או
העברה בכל דרך של כספים שמקורם בעסקאות ושירותים כאמור.
5.7. החברה רשאית לפנות אל כל משתמש בכל עת על מנת לברר כל פרט הנוגע לשימושים בשירות. בכל
מקרה כאמור, המשתמש מתחייב לשתף פעולה ולספק לחברה את המידע שיידרש.
5.8. החברה רשאית להגביל ו/או לשנות ו/או לבטל את מתן השירותים או חלק מהם מעת לעת
(לרבות הגבלת גובה סכומי העסקאות, ביצוע שינויים ברשימת מדינות היעד וכיוצ"ב). מבלי
לגרוע מהאמור לעיל מובהר כי מאחר והפעולות המבוצעות ע"י החברה כפופות להוראות חוק
ישראליים ובינלאומיים, וצווים והנחיות שהותקנו מכוחם, המתייחסים בין היתר להוראות בנוגע לאיסור
ומניעת הלבנת הון וטרור ולהוראות הנוגעות למיסוי הפעולות המבוצעות על-ידי החברה. לאור זאת
יתכנו מצבים בהם פעולות לא יבוצעו ו/או ישונו ו/או ידווחו לגורמים הרלבנטים בהתאם לחוק עוד בטרם
ביצועם.
5.9. החברה רשאית להתנות את השימוש בשירותים או למנוע את שירותי החברה ללקוח, בין באופן כללי
ובין ביחס למשתמשים מסוימים, בשימוש באמצעי זיהוי מסוימים, או ביחס לעסקה מסויימת. כן רשאית
החברה לחסום את פעילות הלקוח באתר או בכל מקום אחר עד להשלמת פרטי מידע כללים הנוגעים
ללקוח או בקשר לעסקה מסויימת.
5.10. חלק מהשירותים אינם ניתנים לביטול, לרבות במקרה בו בקשת הביטול מקורה בטעות
כגון מסירת פרטים שגויים, טעות סופר של המשתמש או של החברה וכיוצ"ב. המשתמש
מצהיר כי טרם מתן אישור לפעולה הוא שב ובדק את כל הפרטים המבוקשים לצורך אספקת
השירותים, וכי החברה לא תישא באחריות לטעויות שמקורן באי דיוק ו/או שגיאות במסירת
הפרטים המבוקשים ע"י המשתמש.
5.11. מועד ביצוע הפעולות יהיה תוך שני ימי עסקים שלאחר יום העסקים בו ניתנה ההוראה ע"י הלקוח,
ולגבי עסקאות במט"ח שני ימי עסקים במט"ח לאחר יום העסקים במט"ח בו ניתנה ההוראה ע"י הלקוח.
5.12. חלק מהשירותים ניתנים באמצעות באמצעות מערכות חברת MoneyGram International Ltd.
להלן: ("MG") ,ידוע למשתמש כי ביצוע הפעולות באמצעות החברה יהיו כפופות בין היתר להוראות
חברת MG ולתקנונה לרבות הוראותיה בנוגע להגנת הפרטיות אותם ניתן למצוא בקישור:
https://start.moneygram.com . בכל מקרה של סתירה בין תקנון זה לבין תקנון MG מובהר כי
ההוראות המחמירות יותר תגברנה.

6 .מיסים ודיווחים
6.1. המשתמש הוא האחראי לכל תשלום מס הנובע מכל תשלום או עסקה שביצע או קיבל
באמצעותה, ולכל דיווח הנדרש על פי כל דין בקשר לעסקה, והחברה אינה אחראית לכל אלה.
6.2. ידוע למשתמש והוא מסכים מראש לכך כי בכפוף לדין, החברה תהא רשאית למסור פרטים
ודיווחים שונים בגין פעולותיו לרשויות בישראל ובחו"ל (לרבות לרשות המיסים), ככל שהחברה
תידרש לכך.
6.3. ידוע למשתמש כי החברה רשאית לעכב או לבטל ביצוע עסקה בגין אי מסירת פרטים בנוגע לתשלומי
מיסים הרלבנטים לפעולה.
6.4. ידוע למשתמש כי החברה הינה מוסד כספי לענין מע"מ.

7 .עמלות ותשלומים נוספים
7.1. החברה תגבה תשלומים ו/או עמלות בגין השימוש בשירות, בשיעורים ובמועדים הקבועים
במחירון החברה המפורט באתר, אשר עשוי להתעדכן מעת לעת בהתאם לשיקול דעת
החברה, הכל בכפוף לדין.
7.2. על המשתמש לבדוק טרם ביצוע העסקה את כל העלויות והעמלות הכרוכות בה, ואת עלויות
השוק האלטרנטיביות לביצוע הפעולה, ולא תהיה למשתמש כל טענה כלפי החברה בענין זה.
7.3. במקרה בו ביצוע התשלום יחול במועד מאוחר יותר ממועד הזרמת העסקה, יתכן ויחולו
שינויים במחיר העסקה עקב שינוי בשער החליפין והמשתמש ישא בתשלום בהתאם לשער
העדכני.
7.4.  במקרה בו תגלה החברה כי נפלה טעות בחישוב שער החליפין ו/או בגובה העמלה ששולמה לחברה,
בהתאם לשיקול דעתה הבלעדי, החברה תיידע את הלקוח אודות הטעות (להלן: "הודעת התיקון")
והלקוח יהיה רשאי לבטל את העסקה ללא עלות נוספת או לחלופין, לאשרר את העסקה בהתאם
לתנאים שהוצגו לו בהודעת התיקון. המשתמש מצהיר כי ידוע לו שהשירותים עשויים להיות
כרוכים בתשלומים ו/או עמלות נוספות, אשר ייגבו על ידי צדדים שלישיים שאינם תלויים
בחברה, כגון חברות כרטיסי אשראי, קורספונדנטים וכיוצ"ב.
7.5. תשלום באמצעות מזומן או העברה בנקאית:
 7.5.1. האפשרות לשלם במזומן ו/או בהעברה בנקאית זמינה עבור חלק מהשירותים בלבד. אספקת
 השירותים המבוקשים על ידי החברה מותנה בקבלת התשלום מהלקוח במועדים שנקבעו על-ידי
 החברה. באפשרותך להעביר את התשלום בגין השירותים ישירות לחברה, באמצעות העברה
 בנקאית מחשבון המשתמש בבנק ישראלי בלבד או בתשלום במזומן למי מנותני השירות של
 החברה,. החברה רשאית לגבות עמלה נוספת בגין טיפול במזומנים בין בש"ח ובין במט"ח..
 7.5.2. למשתמש לא תהיה כל טענה כלפי החברה במקרה של ביטול ו/או עיכוב באספקת השירותים
 מצד החברה בכל מקרה בו התשלום לחברה לא בוצע ע"י המשתמש במועד שהוגדר מראש ע"י
 החברה ו/או מתעכב בנסיבות שאינן תלויות בחברה.
7.6. לא ניתן יהיה לבטל תשלום באמצעות העברה בנקאית. כספים אשר יתקבלו בחשבון החברה
בהעברה בנקאית לצורך אספקת השירותים לא יושבו למשתמש.
7.7. תשלום באמצעות כרטיס אשראי:
 7.7.1. המשתמש מצהיר כי ידוע לו שאישור חברת האשראי הינו תנאי לביצוע השירות המבוקש על ידו
 ולא תהיה לו כל טענה כלפי החברה במקרה בו העסקה לא תבוצע כתוצאה מסירוב חברת
 האשראי לביצוע העסקה.
 7.7.2. ידוע למשתמש כי החברה רשאית להפעיל מנגנונים למניעה וצמצום של מקרי הונאה, הכחשת
 עסקאות שלא בתום לב, שימוש לרעה בכרטיסי אשראי על סוגיהם השונים וכיוצ"ב, כגון שליחת
 מסרונים למשתמש לצורך אישור עסקאות אשראי.
 7.7.3. מלבד עמלת השירות שתיגבה ע"י החברה ייתכן ותחויב בעמלות נוספות הנגבות בהתאם
 להסכם שבינך ובין חברת כרטיסי האשראי או כל צד שלישי אחר איתו התקשרת בקשר עם
 כרטיס האשראי שברשותך, וכן ייתכן ותחוייב על ידי חברת כרטיסי האשראי בעמלה מיוחדת
 כגון "עמלת רכישת מטבע חוץ מחלפן". למען הסר ספק, לחברה אין קשר ו/או השפעה על
 העמלות שנגבות ע"י צדדים שלישיים אלה והן ישולמו ע"י המשתמש בהתאם להסכם בינו לבין
 אותם צדדים שלישיים.
7.7.4. תשלום באמצעות כרטיס אשראי בעסקת תשלומים:
    7.7.4.1. המשתמש רשאי לשלם עבור השירותים באמצעות פריסת תשלומים באשראי בכפוף
    לתשלום ריבית שתתווסף למחיר (להלן: "ריבית תשלומים").
    7.7.4.2. במתן ההסכמה לתקנון השימוש, מאשר הלקוח את תנאי האשראי הקבועים בתקנון כתנאי
    אשראי משלימים לתנאים הספציפיים החלים על העסקה.
    7.7.4.3. טרם ביצוע העסקה, יוצגו לך פרטי עסקת התשלומים כנדרש על פי חוק, לרבות פרטים
    הנוגעים לריבית, עלות ממשית, שיעור ריבית הפיגורים, לוח התשלומים וכיוצ"ב. עלייך
    לאשר את פרטי העסקה בכתב באמצעות חתימה פרונטלית ו/או במסרון (להלן: "עסקת
    התשלומים").
    7.7.4.4. ידוע ללקוח כי הריבית בגין האשראי בתשלומים, נגבית בנפרד לחלוטין משיעור העמלה
    בגין המרת המט"ח הנקבעת בנפרד על-ידי הסוכן מבצע ההמרה, ואינה חלק מעלות
    האשראי.
    7.7.4.5. במקרה בו חל חוק אשראי הוגן התשנ"ג – 1993 (להלן: "חוק אשראי הוגן"), שיעור העלות
    הממשית של האשראי בעסקה לא יעלה על שיעור העלות המרבית של האשראי,
    כהגדרתה בחוק אשראי הוגן, בעת ביצוע העסקה הספציפית וכפי שמפורט בפרטי העסקה.
    בנוסף, שיעור ריבית הפיגורים הידוע במועד העסקה לא יעלה על שיעור ריבית הפיגורים
    המרבי כהגדרתו בחוק אשראי הוגן הידוע באותו מועד וכפי שמפורט במסמך העסקה.
    7.7.4.6. הלקוח מצהיר ומתחייב בזאת שלא יגרום במישרין או בעקיפין לביטול כרטיס האשראי
    נשוא העסקה ולא יבטל את העסקה, כולה או חלקה, כל עוד לא נפרעה במלואה.
    7.7.4.7. למען הסר ספק, אי פירעון תשלום מהתשלומים בכרטיס האשראי להם התחייב הלקוח
    יהווה הפרה יסודית של ההסכם בינך ובין החברה אשר תנקוט בכל האמצעים העומדים
    לרשותה במקרה של הפרת ההסכם מצידך.
    7.7.4.8. פירעון בפועל ביום הנקוב לפירעון התשלום ייחשב כתשלום בפועל.
    7.7.4.9. תשלום אשר לא יפרע במועדו, יישא בתשלום ריבית פיגורים בשיעור הקבוע בעסקה
    בחישוב ממועד תאריך פירעון התשלום ועד לפירעון בפועל (להלן: "ריבית פיגורים")
    ובתוספת הוצאות גביה לרבות הוצאות משפט ושכ"ט עורך דין, ככל שיחולו (להלן:
    "הוצאות הגביה"). כמו כן מתחייב הלקוח לשלם את הסכום שלא נפרע תוך 3 ימי עסקים
    מיום הדרישה הראשונה.
    7.7.4.10. הלקוח רשאי להקדים את פירעון התשלומים, כולם או חלקם, בהודעה מוקדמת
    בת 3 ימי עסקים לכל הפחות. במקרה כאמור, החברה תשיב ללקוח את גובה ריבית
    האשראי שנגבתה מראש בניכוי עמלת פירעון מוקדם, כפי שנקבעה בעת ביצוע העסקה.
    במקרה בו הלקוח יבקש להקדים את פירעון התשלומים בהודעה מוקדמת הפחותה מ-3 ימי
    עסקים, החברה תהא רשאית לבצע את הפירעון המוקדם כנגד גביית עמלה נוספת בגין אי
    ההודעה המוקדמת כפי שנקבעה בעת ביצוע עסקת התשלומים.
    7.7.4.11. הלקוח מתחייב, תוך 21 יום מדרישה ראשונה, לשלם לחברה באמצעות הפקדה
    ישירה לחשבון הבנק של החברה, או בתשלום במזומן אצל אחד מסוכני החברה, את הסכום
    הנקוב בכל אחד מהתשלומים, גם אם זמן פרעונם טרם הגיע (בצירוף ריבית הפיגורים,
    הפרשי הצמדה והוצאות גביה), וזאת בהתקיים מקרה מהמקרים הבאים: במקרה ובו אחד
    מהתשלומים לא נפרעו על אף דרישת החברה; אם יתברר כי מצגי הלקוח ו/או מי מטעמו
    בהסכם זה או במסמכי העסקה, כולם או חלקם, אינם נכונים; אם הסיכון לאי-פירעון
    התשלומים גדל בשיעור ניכר, אם היתה הפרה יסודית אחרת של הסכם זה או אם התקיימו
    תנאים אחרים המחייבים ביצוע מיידי של פעולה כאמור לגבי פרעון יתרת התשלומים; אם
    מונה ללקוח ו/או למי מבעלי מניותיו או לרכושם או לחלק ממנו, או הוגשה בקשה למינוי
    כונס נכסים ו/או מנהל ו/או מפרק ו/או מפרק זמני או נאמן או כל מינוי רשמי אחר והבקשה
    לא הוסרה תוך 14 ימים, או הוגשה נגד מי מהם בקשת פירוק או הקפאת הליכים או פשיטת
    רגל, או ניתן נגדו צו כינוס, צו פירוק או הוא הוכרז פושט רגל; אם הוטל עיקול על נכסי
    הלקוח, והעיקול לא יוסר תוך 21 יום מיום הטלתו, או אם בעלי שעבודים על נכסי הלקוח
    או חלק מהותי מנכסי הלקוח יממשו את השעבודים, או אם תבוצע פעולה של הוצאה
    לפועל כנגד כל נכסי הלקוח או חלק מהותי מנכסי הלקוח, והפעולה לא תבוטל תוך 21 יום
    מיום ביצועה; אם יהיה חשש סביר, לדעת החברה, כי מאורע או נסיבות כאמור לעיל
    צפויים לקרות, וזאת בין אם קרות האירוע או היווצרות הנסיבות כאמור תלויים בלקוח ובין
    אם לאו, ובין היתר היעדרות מעבודה ו/או יציאה מהארץ לתקופה העולה על 14 יום;
    7.7.4.12. החברה תהיה רשאית למכור ו/או להעביר ו/או להסב ו/או להמחות ו/או לנכות
    ו/או לאגח את התשלומים, כולם או חלקם, לכל גורם שהוא, בתנאים כפי שתמצא לנכון,
    והלקוח נותן לכך את הסכמתו מראש. הלקוח אינו רשאי להמחות ו/או להעביר את חובותיו
    ו/או זכויותיו על פי תקנון זה, אלא אם ניתנה הסכמת המלווה לכך מראש ובכתב.

8 .תנאי שימוש לסוגי שירותים שונים
השימוש בשירותי החברה כפופים בין היתר, ומבלי לגרוע מהאמור בתנאי השימוש הכלליים, לתנאים
המפורטים בנספח א' לתנאי שימוש זה (המופיע ברצף בסיום מסמך זה). באישור תנאי השימוש הינך מאשר
גם את התנאים לשירותים אלו.

9 .אחריות
9.1. האתר, השירותים והתכנים המופיעים בו, ניתנים לשימוש כמות שהם (AS IS/ AS AVAILABLE) וללא כל
התחייבות או מצג מצד החברה בדבר התאמתם לצרכי המשתמש, למטרה מסוימת, לעדכניות התכנים
ו/או לנכונותם ו/או להיעדר הפרה של זכויות יוצרים. השימוש באתר ובשירותים המוצעים נעשה על
אחריות המשתמש בלבד והוא מודע לכך שאינו חייב להשתמש בשירותים אלו. החברה לא תישא בכל
אחריות כלפי המשתמשים וכלפי צדדים שלישיים בכל הנוגע לכל עסקה אשר במסגרתה נעשה שימוש
בשירותי החברה לרבות בכל הנוגע למטרות העסקה.
9.2. החברה אינה מתחייבת כי האתר או איזה מחלקיו יפעל בצורה רציפה, תקינה וכי הגישה אליו תהיה
זמינה ו/או ללא תקלות ו/או לא תופרע (לרבות עקב תחזוקה שוטפת של האתר או לצורך טיפול
תקלות). בהתאם, לא תישמע טענה על היעדר יכולות ביצוע פעולות באתר במועדן (כגון איחור בביצוע
העברה או כל פעולה אחרת שיש לה תאריך יעד לביצוע) בשל תקלה באתר, ואתה פוטר את החברה
מכל תביעה ו/או טענה ו/או דרישה שתהיה לך בקשר עם האמור.
9.3. החברה אינה אחראית בכל אופן שהוא לשיבוש מידע משתמש שנמסר באתר ו/או לאי קבלת פניה
באתר, אי היענות לפניה, לכל עיכוב במענה לפניה ו/או אי קבלת מידע מספיק באתר. אנו נעשה כל
מאמץ להשיב לפנייתך אלינו בהקדם האפשרי.
9.4. אין להסתמך על התכנים ו/או השירותים ו/או על כל ייעוץ, המלצה או חוות דעת שהתקבלו באמצעות
האתר (אם וככל שהתקבלו) לצורך קבלת החלטות כלשהן, לרבות בנושאים כלכליים, מקצועיים,
מסחריים, אישיים, כספיים ואחרים.
9.5. מבלי לגרוע מהאמור לעיל, בשום מקרה לא תחול על החברה (לרבות עובדיה, מנהליה, וכל מי מטעמה)
אחריות בגין נזקים כלשהם (לרבות, אך לא רק, ישירים, עקיפים או תוצאתיים, לרבות נזקים הנובעים
מאובדן רווחים, מאובדן נתונים או מהפרעה למהלך העסקים, פגיעה במוניטין וכיוצ"ב בקשר ו/או
כתוצאה משימוש בשירותי החברה, מאי יכולת להשתמש בשירותים או מתוצאות השימוש בשירותים
(לרבות באיזה מהתכנים ו/או השירותים המוצעים בו), ו/או שימוש או אי יכולת שימוש בכל אתר אחר
המקושר לאתר, או בתכנים הכלולים באתר ובכל אחד מאתרים המקושרים לאתר (לרבות הסתמכות על
תכנים כאמור), ו/או כתוצאה מחשיפה לפגעי מחשב (לרבות וירוסים) ו/או עקב פגיעה בזכיות קניין
רוחני מכל סוג שהוא (בין רשומות ובין לאו), והכל, גם אם החברה כבר קיבלה מידע על האפשרות
להתרחשות נזקים אלה, תהא אשר תהא עילת התביעה או סיבת הנזק, בין חוזית, בין נזיקית ובין אחרת.
9.6. לחברה תהיה זכות קיזוז ועיכבון על כל הכספים המגיעים או שיגיעו למשתמש אשר יוחזקו בידי החברה
מעת לעת, והוא יהיה רשאי לקזז מכל סכום המגיע למשתמש כל חוב או התחייבות שלו כלפי החברה
כתוצאה משימוש בשירותים וכן תהיה החברה רשאית לעכב ביצועה של כל העברת כספים לזכות
המשתמש, עד לקיום כל התחייבויותיו כלפיו, כאמור, לרבות במקרה שבו טרם הגיע מועד קיומן. יובהר
כי רישום הקיזוז/ ניכוי בחשבון באזור האישי יחשב להודעת קיזוז על פי דין, כך שלא תידרש הודעה
נוספת בעניין; וכן, כי לא תעמוד למשתמש זכות עכבון/ קיזוז כנגד החברה.

10 .שירות לקוחות ויצירת קשר
10.1. הנך מוזמן לפנות לחברה באמצעות הקישור לתמיכה (Support) באתר בכתובת הדואר האלקטרוני
gmt-info@gmtonline.co.il או באמצעות מספר הטלפון 03-3722800 בכל שאלה הנוגעת לשירות.
10.2. לתשומת לבך כי ייתכן ששיחות טלפוניות עם שירות הלקוחות יוקלטו, ישמרו במאגר מידע של
החברה או מי מטעמה וישמשו לצורך תיעוד, בירור, שיפור השירות, בקרה וניהול סיכונים.

11 .שונות
11.1. האתר ושירותי החברה מיועדים לשימוש עבור בגיר (מעל גיל 18) בתחום הריבונות של מדינת
ישראל, ואינו מיועד לקטינים. באם הינך קטין (מתחת לגיל 18) ,עצם שימושך באתר יהווה ראיה לכך
שקיבלת אישור מהוריך או אפוטרופוס חוקי שמונה לך לעשות שימוש זה.
11.2. תנאי השימוש כפופים לדין הישראלי בלבד. לבית המשפט המוסמך במחוז תל אביב תהא סמכות
שיפוט בלעדית לדון בכל מחלוקת ו/או סכסוך לעניין הסכם זה, אכיפתו ופרשנותו ולא תהא סמכות
שיפוט לאף בית משפט אחר.
11.3. עליך לבקר באתר מפעם לפעם על מנת לבדוק את תנאי השימוש המעודכנים, שכן הם המחייבים
ממועד העדכון ואילך (מועד העדכון האחרון מופיע בראש תנאי השימוש). הודעות ו/או תנאים
משפטיים ייעודיים המצויים בדפים מסוימים של האתר עשויים לגבור על התניות מסוימות הכלולות
בתנאי השימוש.
11.4. ויתור על זכויות בכל מקרה שהחברה לא תשתמש באחת או יותר מזכויותיה בהתאם להסכם זה, לא
ייחשב הדבר כוויתור או השלמה מצד החברה ולא יהא בכך משום מניעות נגדה.
11.5. אם ייקבע כי חלק כלשהו של תנאי השימוש אינו תקף או שאינו ניתן לאכיפה על פי דין, לרבות אך
ללא הגבלה, סעיפי הגבלת האחריות הנזכרים לעיל, אזי הסעיפים שנשלל תוקפם או שאינם ניתנים
לאכיפה ייחשבו כאילו הוחלפו בסעיפים תקפים וניתנים לאכיפה שתוכנם תואם במידת הקירוב
הגדולה ביותר את כוונת הסעיפים המקוריים, ואילו יתר סעיפי תנאי השימוש יישארו בתוקפם.
11.6. החברה רשאית להמחות, להסב או להעביר את זכויותיה או התחייבויותיה, כולן או חלקן, לכל צד
שלישי, אולם לא יהיה באמור כדי לפגוע בזכויות משתמש בקשר עם כספים המוחזקים לרשותו, הכול
כמפורט בתנאי השימוש.
11.7. המשתמש אינו רשאי להמחות, להעביר, להסב או לשעבד את זכויותיו והתחייבויותיו או חלק מהן,
מכוח תנאי שימוש אלה או כל הסכם אחר, לכל צד שלישי שהוא.

נספח א' –

תנאי שימוש לשירותים שונים
1 .העברות כספיות
1.1. המשתמש (להלן: "מבצע ההעברה") ישא באחריות מלאה למילוי פרטים נכונים ומדויקים
ויוודא כי הנמען שבחר לביצוע ההעברה הוא הנמען הנכון (להלן: "המקבל" ו/או "המוטב").
במקרה של טעות בהקלדת פרטי המוטב או פרטי המשתמש המעביר, לא תחול על החברה
שום אחריות, לרבות לביטול ההעברה ולהשבת הכספים.
1.2. בסיום ביצוע הוראת ההעברה (להלן לצורך סעיף זה: "עסקה" ו/או "בקשה להעברה") יוצג
בפנייך סיכום עסקה ובו יוצגו פרטי העברה המבוקשת על ידיך, לרבות סכום התקבול שצפוי
להתקבל אצל המוטב וקוד סודי (להלן: "הקוד הסודי").
1.3. תשומת לבך כי ייתכן שסכום התקבול שיוצג בפנייך הינו סכום משוער בלבד אשר עשוי
להשתנות בעקבות גורמים שונים כגון שערי המרה במדינת היעד, עמלת קורספונדנטים,
עמלה הנגבית ע"י צדדים שלישים ביחסים שבינם לבין מקבל ההעברה וכיוצ"ב. החברה
תבצע העברה כספית בגובה הסכום אליו התחייבה בסיכום העסקה, אולם היא לא תשא
באחריות לשינוי בסכום שיתקבל בפועל אצל המקבל הנובעים מגורמים שאינם בשליטה
כאמור לעיל.
1.4. העברות כספים שנמשכים במזומן. ככל שתבחר לבצע העברה כספית שתאפשר למוטב למשוך את
הכספים במזומן, יהיה עלייך להעביר את הקוד הסודי אל המוטב. המוטב יוכל למשוך את הכספים
באמצעות קורספונדנט, שותף עסקי ו/או בית עסק בחו"ל בכפוף להצגת הקוד הסודי. האחריות לשמור
את הקוד הסודי ולהעבירו למוטב הנכון חלה על המשתמש בלבד, ולא תהיה לך כל טענה כלפי החברה
בכל מקרה של אובדן ו/או גניבה ו/או שימוש בקוד הסודי על ידי מי שאינו המוטב, בכפוף למגבלות
האחריות הקבועות בסעיפים 27-24 לחוק שירותי תשלום – התשע"ט - 2019 .במקרה של אובדן, גניבה
ו/או חשש לשימוש לרעה בקוד הסודי, עלייך לפנות לשירות הלקוחות של החברה מיד עם היוודע לך
על כך. שירות הלקוחות יטפל בפנייתך בהתאם לנסיבות המקרה הספציפי. הטיפול על ידי שירות
הלקוחות עשוי להיות כרוך בתשלום עמלות נוספות.
1.5. ביטול עסקה. בכפוף לדין, כל העברת כספים ניתנת לביטול רק עד למועד העברת הכספים לידי נותן
שירותי התשלום למוטב (אשר יתכן ותבוצע מיידית לאחר בקשת ההעברה). על מנת לבטל בקשה
להעברה, יש לפנות לשירות הלקוחות של החברה. מובהר כי ככל וביצעת את הפעולה באמצעות נותן
שירותים, עליך לוודא כי נותן השירותים פנה לחברה על-מנת לבטל את הפעולה. הליך הביטול עשוי
להיות כרוך בעמלה. כספך יושב אלייך באמצעות זיכוי לכרטיס האשראי באמצעותו בוצעה הבקשה
להעברה ו/או במזומן, בהתאם לאמצעי התשלום שבחרת בעת ביצוע העסקה. אישור החברה בסיום
הליך הביטול מהווה אסמכתה לביטול הבקשה להעברה.
1.6. החברה רשאית לקבוע מגבלות ביחס לסכומי העברה, אשר עשויים להשתנות מעת לעת, בהתאם
לשיקול דעתה הבלעדי של החברה.
1.7. העברות כספים באמצעות כרטיסים נטענים:
    1.7.1. החברה לא אחראית לטיבו ואיכותו של הכרטיס הנטען שברשותך, למידע שקיבלת
    לגביו בעת רכישתו, ליכולת שלך לבצע בו שימוש ו/או לשירות הלקוחות בקשר עם
    הכרטיס.
    1.7.2. העברת הכספים באמצעות כרטיס נטען, כפופה לאישור חברת הסליקה וליתרה
    הצבורה בכרטיסך. בכל מקרה, הנך מתחייב לא לנסות לבצע העברות כספיות
    בסכומים שערכם עולה על יתרת הסכום הצבורה בכרטיסך במועד ביצוע ההעברה.
    1.7.3. לא ניתן לשלם על העברה כספית באמצעות כרטיס נטען בפריסת תשלומים.

2 .המרת מט"ח
2.1. שערי ההמרה משתנים מעת לעת ונקבעים על ידי החברה על פי לשיקול דעתה הבלעדי, בהתאם
לגורמים שונים במסחר הבינלאומי.
2.2. בהמרת מטבע חוץ אצל זכייני החברה, שערי המטבע עשויים להשתנות מזכיין לזכיין בהתאם
לשיקול דעת הזכיין אשר כפוף להנחיות ומגבלות מטעם החברה.
2.3. בעת ביצוע פעולה של רכישת מט"ח במזומן באמצעות נותן שירותים לחברה, עליך לבדוק את תקינות
השטרות ואת סכומן מול נותן השירותים, לפני עזיבת הדלפק. לחברה לא תהיה אחריות כלפי תקינות
השטרות.

3 .כרטיסים נטענים (PrePaid)
החברה, בשיתוף פעולה עם חברת מקס איט פיננסים בע"מ (להלן: "מקס"), מאפשרת לך לרכוש ולטעון
משירות כרטיס נטען (PrePaid) בינלאומי.
3.1. רכישת כרטיס נטען והשימוש בו כפופים לתנאים המופיעות במסמכי העסקה עליהם תידרש לחתום
בעת רכישת הכרטיס, המהווים חלק בלתי נפרד מתנאי שימוש אלה (להלן ביחד: "תנאי שימוש
לכרטיסים נטענים"). החברה אינה נושאת באחריות לסליקה השוטפת של הכרטיס.
3.2. האפשרות לרכוש כרטיס נטען הינה באמצעות הגעה פיזית אל אחד מסוכני החברה או בכל דרך
אחרת שתפרסם החברה מעת לעת.
3.3. זמן אספקה של כרטיס נטען בעל שם מוטבע יארך 5-6 ימי עסקים.

4. Cash Back ("כסף בחזרה")
4.1. שירות "כסף בחזרה" מאפשר לך לצבור יתרה כספית באזור האישי שלך (להלן: "יתרה צבורה") בגין
שירותי העברות כספיות שתבצע באמצעות האתר ו/או באמצעות סוכני החברה (להלן: "עסקה
מזכה"). החברה רשאית להפעיל שירות זה בגין שירותים נוספים המסופקים על ידה, כפי שתקבע
מעת לעת בהתאם לשיקול דעתה הבלעדי ותנאים אלו יחולו בשינויים המחוייבים.
4.2. לא כל העברה כספית תהווה עסקה מזכה. ההחלטה אילו העברות כספיות יהוו עסקאות מזכות נתונה
לשיקול דעתה הבלעדי של החברה. בסיום כל בקשה להעברת כספים, החברה תעדכן אותך האם
ההעברה מהווה עסקה מזכה.
4.3. באפשרותך להתעדכן ביתרה הצבורה העומדת לרשותך בכל עת, באמצעות הצגת תעודה מזהה אצל
אחד מסוכני החברה ו/או באזור האישי שלך באתר האינטרנט של החברה.
4.4. היתרה הצבורה היא אישית ולא ניתנת להעברה ממשתמש אחד למשתמש אחר.
4.5. צבירת הכספים תעשה בהתאם למטבע בו בוצעה העסקה המזכה (דולר, שקל או אירו).
4.6. היתרה הכספית ניתנת למימוש בהעברות כספיות בלבד בהתאם למטבע שבה נצברה, לא ניתן להמיר
את הכספים הצבורים ממטבע אחד למטבע אחר.
4.7. סך הכספים המינימלי הניתן למימוש בכל עסקה הינו 4 דולר . מימוש הכספים בעסקה ספציפית לא
יעלה על %50 מסך העסקה.
4.8. היתרה הכספית תעמוד לרשותך לתקופה קצובה אשר עשויה להשתנות מעת לעת, בהתאם לשיקול
דעת החברה. במשך 3 חודשים ממועד הפעולה האחרונה שביצעת בחשבונך. במידה ולא תנצל את
יתרת הכספים שצברת תוך 3 חודשים ממועד המימוש האחרון, תמחק היתרה הצבורה העומדת
לרשותך.
4.9. במקרה של ביטול עסקה, יתרת הכספים הצבורים שמימשת בעסקה שבוטלה תשוב לחשבונך באזור
האישי.

5 .שירות העברה לחו"ל באמצעות הרשאה לחיוב חשבון
5.1. המשתמש רשאי להצטרף לשירות העברה לחו"ל באמצעות הרשאה לחיוב חשבון בתנאי שחתם על
טופס הוראה ונתן לחברה הרשאה לחיוב חשבונו.
5.2. הקמת הרשאה לחיוב חשבון הלקוח תהיה כפופה לתנאים המקובלים בבנק בו מתנהל חשבון הלקוח.
5.3. החברה תפעל לחיוב חשבון הלקוח, במועד, בסכום ובהתאם לפרטים נוספים שימסור הלקוח לחברה
בטופס החתום בחתימת ידו.
5.4. הוראת ההעברה תבוצע בתנאי שהחיוב כובד במלואו, שלא התקבל החזר חיוב מכל סיבה שהיא,
והתמורה בגין ההעברה התקבלה בפועל בחשבון החברה, בהתאם להוראה שהעבירה החברה.
5.5. בכל מקרה בו תבוצע ההעברה לחו"ל אך התמורה שהתקבלה בגינו בוטלה או שאינה מספיקה, תפנה
החברה ללקוח לצורך השלמת התמורה. החברה תפעל לקבל את התמורה בהתאם לאמצעים
העומדים לרשותה ובהתאם לכל דין.
5.6. סכום החיוב יחושב בהתאם לסכום שביקש הלקוח להעביר + עמלת העברה בגין פעולה זו, מוכפל
בשער המרה שיקבע ע"י החברה.
5.7. פירוט החיוב ומרכיביו ישלחו בכל חודש למשתמש, בהתאם לפרטי המייל שמסר לחברה במועד מתן
ההוראה.
5.8. אישור על ביצוע ההעברה ישלח ללקוח גם בהודעת סמס למספר הנייד שמסר לחברה בטופס מתן
ההוראה.
5.9. החברה שומרת לעצמה את הזכות שלא לצרף משתמשים לשירות העברות בהרשאה לחיוב חשבון
ו/או להפסיק את השירות בכל עת באופן כללי או ללקוח ספציפי.
5.10. סכום ההעברה הכספי המקסימלי החודשי, בשירות העברה בהוראה לחיוב חשבון יקבע ע"י
החברה, בכפוף לנהלי החברה והוראות החוק. החברה שומרת לעצמה את הזכות לשנות את סכום
המקסימלי מעת לעת, ללא הודעה מוקדמת.
5.11. מבלי לגרוע מהאמור לעיל, העברה באמצעות הרשאה לחיוב חשבון כפופה לכל הסעיפים
הכללים המפורטים ביתר תקנון החברה.

מדיניות פרטיות

1 .כללי
1.1 אתר אינטרנט זה, המתנהל בכתובת gmtonline.co.il (להלן: "האתר" או "אתר האינטרנט"),
מופעל ומנוהל על ידי ג'י.אם.טי. טק אינוביישן בע"מ (להלן: "GMT "או "החברה").
1.2 מטרת מדיניות פרטיות זו הנה לפרט את האופן בו החברה אוספת מידע על ו/או אודות המשתמשים
בשירותיה, כהגדרתם להלן, וכן כיצד ולאיזה מטרות החברה אוספת מידע כאמור. המסמך כתוב
בלשון זכר מטעמי נוחות בלבד, אך מתייחס לשני המינים כאחד.
1.3 תנאי מדיניות הפרטיות המפורטים להלן מהווים חלק בלתי נפרד מתנאי השימוש. החברה רשאית
לשנות מעת לעת את מדיניות הפרטיות ואת תנאי השימוש, יחד או לחוד, כך שישקפו שינויים
טכנולוגיים, עסקיים, משפטיים או רגולטוריים. עדכונים ביחס למדיניות הפרטיות כאמור יפורסמו
במסמך זה, אשר ימצא במתכונתו המעודכנת באתר האינטרנט.
1.4 בטרם השימוש בשירותי החברה, אנא וודא שקראת את תנאי מדיניות הפרטיות בעיון וכי אתה מבין
אותם. השימוש שלך בשירותיי החברה, לרבות גלישה באתר ייחשב להסכמה מפורשת ומדעת מצדך
לכל התנאים, ההתניות וההודעות הנכללים במדיניות הפרטיות. במידה ואינך מסכים לתנאי מדיניות
הפרטיות, כולם או מקצתם, אל תעשה בשירותי החברה, לרבות גלישה באתר ו/או באפליקציה.

2 .הגדרות
2.1 "החוק" – חוק הגנת הפרטיות, התשמ"א-1981.
2.2 "השירותים" או "שירותי החברה" – כל השירותים המוענקים למשתמש באמצעות החברה ו/או
סוכניה, הן שירותים הכרוכים במסירת מידע והן שירותים שאינם כרוכים במסירת מידע, לרבות
גלישה באתר.
2.3 "הסוכנים" או "סוכני החברה" – נציגים מורשים מטעם החברה, הפרוסים ברחבי הארץ, אשר
הוסמכו על ידי החברה וקיבלו את כלל האישורים הנדרשים לפעול מטעמה, לרבות "זכיינים" בעלי
רישיון לפי חוק הפיקוח על שירותים פיננסיים (שירותים פיננסיים מוסדרים), תשע"ו – 2016 ו/או
אישור המשך עיסוק בתוקף שניתן על ידי רשות שוק ההון.
2.4 "מידע אישי" – משמעו כל נתון או מידע שתמסור לחברה באופן יזום באתר בהתאם למטרת הפניה
שלך לחברה, אשר מזהה או מאפשר לזהות אותך באופן אישי (כדוגמת שמך, כתובת דוא"ל שלך,
מספר ת.ז. שלך, אמצעי התשלום שלך וכו'), וכן כל מידע המהווה "מידע" ו/או "מידע רגיש"
כהגדרתם בחוק.
2.5 "מידע אנונימי" – משמעו מידע סטטיסטי, שאינו מזהה או מאפשר לזהות את המשתמש באופן
אישי, אשר מקושר למכשיר הקצה בו המשתמש עושה שימוש באתר (כגון אופן השימוש באתר,
סוג הדפדפן של המשתמש, התאריך והשעה בו נכנס המשתמש לאתר וכו').
2.6 "אתה" או "משתמש" – כל אדם שעושה שימוש בשירותי החברה (כהגדרתם לעיל), לרבות
באמצעות מחשב ו/או מכשיר קצה אחר לכל מטרה שהיא.

3 .איסוף מידע אישי
במסגרת השימוש בשירותים, אתה עשוי להידרש למסור והחברה עשויה לאסוף מידע אישי שלך או
אודותיך באמצעים טכנולוגיים שונים. סוג המידע האישי שתמסור ו/או ייאסף על ידנו עשוי להשתנות,
בהתאם לסיבה ו/או המטרה שלשמה אתה פונה אלינו.
מובהר כי אינך מחויב למסור פרטים אלו על פי דין, ומסירתם תלויה אך ברצונך ובהסכמתך החופשיים,
על מנת לקבל את שירותי החברה המבוקשים. עם זאת, מסירת חלקם מחויב על פי הוראות דין (כמפורט
בסעיף 4.1.4) ,וכן כדי לספק לך את השירותים המבוקשים על ידך, חלקם או כולם.
בעצם השימוש ומסירת מידע לחברה, הינך מצהיר ומתחייב בזאת, כדלקמן: (א) המידע האישי שתמסור
באתר נכון, מדויק ושייך לך. ידוע לך כי מסירת מידע שגוי או כוזב ביודעין עשויה לעלות כדי עבירה
פלילית; (ב) הינך מקנה בזאת לחברה זכות שימוש בלתי חוזרת, לא בלעדית, לא מוגבלת בזמן, ללא
תמורה או כל זכות לקבלת תמלוגים, ניתנת להעברה, לעשות כל שימוש במידע כמפורט בתנאי השימוש
ובמדיניות הפרטיות, בכפוף לכל דין ועל פי שיקול דעתה של החברה, מבלי לקבל לכך הרשאה נוספת
ומבלי שתהיה לך כל טענה ו/או דרישה כספית ו/או אחרת ו/או כל תביעה בקשר עם זכות השימוש
הנ"ל.
3.1 מידע אישי במסגרת השירותים
    3.1.1 מידע שאתה מוסר לצורך ביצוע פעולות באתר ו/או באפליקציה: עליך לבצע תחילה רישום
    לאתר (ככל שאינך רשום בו) וליצור חשבון משתמש.. במצב זה תידרש למסור מידע אישי
    מסוגים שונים, כדוגמת: מספר תעודת זהות/דרכון; מס' טלפון, תאריך לידה, עיסוק, כתובת,
    כתובת דואר אלקטרוני
    3.1.2 מידע אישי שאתה מוסר במסגרת הגשת בקשות שונות לשימוש בשירותי החברה (לרבות
    באמצעות מילוי טפסים): המידע האישי שתידרש למסור עשוי להשתנות בהתאם לאופי
    הפעולה שתבקש לבצע באמצעות החברה. כך למשל, פעולות הקשורות בהעברת כספים
    יחייבו אותך, בין היתר, למסור פירוט אודות שם המוטב אליו מבוקש להעביר כסף, מדינת
    יעד להעברה כספים וכ' ככל שהינך מיפה כוחו/ה של אדם אחר לפעול בשמך, תידרש
    למסור את פרטיך ופרטיו של מיופה הכוח; בקשות הקשורות בביטוחים יחייבו אותך, בין
    היתר, למסור פרטים אודות מצבך הרפואי וכיוצ"ב.
    3.1.3 מידע נוסף אודותיך עשוי להיאסף ולהישמר במסגרת השימוש בשירותים, למשל:
    התכתבויות עם נציגים ו/או עם נציגים אוטומטיים, תוכן אישי (כגון: נושאי העברת
    התשלום למוטב), צ'אט בתוך האפליקציה, מספר חשבון הבנק שלך וכן כל מידע הקשור
    בשימושך בשירותים, לצורך הפעלתם ובהתאם לבקשתך.
3.2 מידע אישי שאתה מוסר על אדם אחר. ככל שבמסגרת השירותים הינך נדרש למסור מידע על
אדם אחר (למשל, בעת ביצוע העברות כספיות הינך נדרש למסור פרטים על המוטב), הינך מצהיר
ומתחייב כי הינך מורשה ובעל ההסכמות הנדרשות עפ"י כל דין למסור לחברה מידע אישי שלו
לצורך השימושים שהחברה עושה במידע כאמור לפי מדיניות פרטיות זו.
3.3 שימוש במידע אישי
בהתאם לחוק, ידוע לך ואתה נותן הסכמתך מדעת לכך שהחברה מקבלת ואוספת המידע האישי
אודותיך למטרות ולשימושים הבאים (כפי שעשויים להתעדכן מעת לעת):
    3.3.1 שימוש במידע לשם שיפור, ניהול ותפעול השירותים המוצעים לך, יצירת הקשר עמך,
    קבלת החלטות ביחס אליך או אחרים, לרבות בנושאים של מניעת הונאות, גילוי תרמיות
    ו/או פעילות בלתי חוקית אחרת, אבטחת השירותים, מטרות תפעוליות, הערכה וניהול
    סיכונים.
    3.3.2 ככל שניסית להירשם באתר על מנת להשתמש בשירותים שלנו וניסיון זה כשל, החברה
    תהא רשאית לפנות אלייך על מנת לסייע לפתור כל בעיה בה נתקלת בתהליך הרישום.
    3.3.3 לצרכי דיוור ישיר: החברה תוכל לעשות שימוש, לעבד, לטייב ולהעשיר את המידע הנ"ל,
    לרבות באמצעות ספק מידע, למטרות ניהול, ייעול, התאמה ושיווק של שירותי ומוצרי
    החברה, בין היתר על ידי יצירת אפיון אודותיך, לרבות על ידי הצעת הצעות שיווקיות
    המותאמות אישית עבורך, וכן לעשות שימוש בנתונים סטטיסטיים, כמפורט בסעיף 5
    להלן.
    3.3.4 החברה עשויה לשלוח אליך הודעות תפעוליות ו/או שירותיות והתראות בדרכים שונות ובין
    היתר באמצעות דואר אלקטרוני או באמצעות מסרונים, כגון מסרון המכיל קוד הזדהות
    לשם גישה לאתר, ושימושים שונים בשירותים.
    3.3.5 החברה תהיה רשאית לפנות אליך, לרבות בדרך של משלוח דיוור פרסומי, ולהציע לך הצעות,
    הטבות ומבצעים, בקשר עם מוצריו ושירותיו, באמצעות דואר אלקטרוני, מסרונים, טלפון או
    אמצעים אחרים. תוכל לבקש להסיר את פרטיך מתפוצת פניות שיווקיות בכלל באמצעות פנייה
    שכותרתה "בקשת הסרה", לכתובת הדוא"ל gmt-info@gmtonline.co.il.

4 .העברת מידע לצדדי ג'
החברה עשויה להעביר מידע אודותייך לצדדים שלישיים, מעת לעת, במספר מקרים, כפי שיפורט להלן,
ו/או בכל מקרה אחר בו תידרש העברה, לרבות העברת מידע אל מחוץ לגבולות ישראל:
4.1 במסגרת פעילות מול צדדים שלישיים על מנת להשלים את הפעולות המבוקשות על ידך ו/או כאשר
נדרש לשם תפעול השירותים, לרבות ספק שירותי ענן; במסגרת זו צפויה להידרש מסירת מידע גם
לכל צד אחר המעורב בשירותי תשלום, לרבות בנקים, חברות כרטיסי אשראי או מנפיקי אמצעי
תשלום אחרים, סולקים, מאגדים וספקי שירותי תשלום, או מוטבים של תשלומים.
4.2 במסגרת הוצאת פעילות של החברה למיקור חוץ ו/או קבלת שירותים מספקים שונים, לרבות
שירותי מחשוב ותוכנה, עיבוד נתונים וענן וכיוצ"ב.
4.3 במסגרת פעילותו מול חברות קשורות עסקית, למען מטרות השימוש המוצהרות ומותרות לפי כל דין,
לשם אספקת השירותים.
4.4 החברה תהא רשאית להעביר מידע אישי לרשויות מוסמכות בישראל או מחוץ לישראל ו/או עפ"י
צו בית משפט ו/או ככל שהדבר נדרש לצורך הגנה על זכויות החברה ונכסיה ו/או ככל שמסירת
מידע כאמור מחויבת עפ"י כל דין, כדוגמת הפרטים הנדרשים מכוח דיני הלבנת הון או העברת
דיווחים (שוטפים ו/או מיוחדים) שהחברה נדרשת לבצע על פעולותיה ופעולות המשתמשים
בשירותיה, וזאת אף מבלי שתהיה חייבת ליידע אותך לפני ביצוע הדיווח ו/או לאחר מכן, לפי שיקול
דעתה.
4.5 מבלי לגרוע מכל האמור, החברה תהא רשאית להעביר מידע אנונימי לצדדי ג' כמפורט בסעיף 4.7
להלן, ללא מגבלה. מאחר ומדובר במידע שאינו מזהה אותך באופן אישי, אתה פוטר את החברה
מכל תביעה ו/או דרישה ו/או טענה כלפי החברה בשל כך.
4.6 ידוע לך שמידע עשוי להיות מועבר לצדדים שלישיים שנמצאים מחוץ לישראל, לרבות למדינות
המעניקות הגנה פחותה יותר לפרטיות מאשר ההגנה המוענקת על פי דיני מדינת ישראל. בעצם
השימוש בשירותי החברה בו אתה נותן הסכמתך מדעת לכך.
4.7 החברה מתחייבת כי העברת מידע לצדדים שלישיים תהיה מוגבלת אך ורק למטרות המנויות
במדיניות זו ורק בהיקף ובמידה הנדרשים לפי שיקול דעתה של החברה. החברה תנקוט מאמצים
נאותים על מנת להבטיח שמקבלי המידע יהיו כפופים לחובת סודיות ואבטחת מידע ביחס למידע,
לפי כל דין.

5 .איסוף מידע אנונימי
5.1 כאשר אתה עושה שימוש באתר ו/או באפליקציה, החברה אוספת מידע הנשלח אליה על ידי
המחשב, טלפון נייד או מכשיר אחר בו אתה משתמש לצורך השימוש באתר ו/או באפליקציה,
שהינו מידע אנונימי, היינו - שאינו מזהה אותך באופן אישי ואינו ניתן לשיוך אליך.
5.2 המידע האנונימי הנשלח אלינו עשוי לכלול, בין היתר: נתונים על הדפים בהם משתמשים ביקרו, סוג
מכשיר, פרטי מחשב וחיבור, פרטי רשת למכשירים ניידים, נתונים סטטיסטיים על צפיות בדפים,
שפת הממשק, תעבורה אל האתר וממנו, כתובת URL ממנה הגולש הגיע לאתר וכו'.
5.3 החברה מנתחת ועושה שימוש בעצמה או באמצעות מי מטעמה במידע אנונימי למטרות המפורטות
להלן (כפי וככל שישתנו מעת לעת): (א) הערכת האופן שבו המשתמשים משתמשים באתר לצורך
ניהול, שיפור וקידום האתר; (ב) תיקון תקלות; (ג) שיפור חווית השימוש באתר; (ד) ניתוח ופילוח
דפוסי גלישה באתר; (ה) התאמת מודעות פרסומת בהתאם להרגלי גלישה; (ו) כל מטרה חוקית
אחרת כפי שנמצא לנכון.
5.4 החברה תהיה רשאית להעביר מידע אנונימי לצדדי ג' הפועלים מטעמה לצורך מימוש המטרות
דלעיל, ולכל מטרה חוקית אחרת כפי שתמצא לנכון. מאחר ומדובר במידע שאינו מזהה את
המשתמש באופן אישי, המשתמש מוותר בזאת על כל תביעה ו/או טענה ו/או דרישה כלפי החברה
בקשר עם האמור.

6. עוגיות (Cookies)
6.1 עוגיות הן קבצי טקסט אשר הדפדפן של המשתמש יוצר לפי פקודה ממחשבי החברה. חלק
מהעוגיות יפקעו כאשר תסגור את הדפדפן או את היישום הרלבנטי, ואחרות נשמרות על גבי זיכרון
המכשיר או המחשב שלך.
6.2 לידיעתך, לצורך אספקת חלק מהשירותים החברה משתמשת בתוכנות צוברות מידע, כגון עוגיות,
לדוגמא- tags, pixels (להלן יחדיו יכונו בשם הגנרי "Cookies "ו/או "עוגיות") לצורך תפעולם
השוטף והתקין, לשם אבטחת הנתונים בהם והמידע הנמסר, ובכלל זה כדי לאסוף נתונים
סטטיסטיים אודות השימוש בשירותים, לשם קבלת החלטות בנוגע לשירותים ו/או פעילותך, לשם
אימות פרטים וזיהוי, מניעת הונאות ותרמיות, לשם שיפור חווית הגלישה, תוך התאמת התכנים
והשירותים להעדפותיך האישיות, לשם אפיון המוצרים המתאימים לך, להתאים עבורך פרסומות
הרלבנטיות אליך, אף בגלישתך באתרים אחרים, הכל למטרות סטטיסטיות, מחקריות, שיווקיות
ומסחריות, וכמובן לצרכי אבטחת מידע.
6.3 העוגיות מכילות ואוספות מידע מגוון כדוגמת הדפים שבהם ביקרת, משך הזמן ששהית באתר ו/או
באפליקציה, מהיכן הגעת אל האתר ו/או הדף, כתובת ה-IP שלך, מאפייני המכשיר שלך, ומידע
שתבקש לראות בעת הכניסה לאתר ועוד. כמו כן יכול והעוגיות ישמרו אף מידע בדבר הרגלי הגלישה
שלך באתרים אחרים, לרבות האתרים בהם גלשת, העמודים באתרים וכל פעולה אחרת בהם.
המידע בעוגיות מוצפן, והחברה נוקטת צעדי זהירות כדי להבטיח שרק מחשביה או מי מטעמה יוכלו
לקרוא ולהבין את המידע האגור בהם.
6.4 השירותים עשויים לעשות שימוש אף בעוגיות צד ג' – למשל עוגיות של חברת Google כגון אלו
המוטמעות תוך שימוש ב- Google Analytics ,העוזרות לנו להתאים עבורך את חווית הגלישה או
השימוש, ולהסיק תובנות סטטיסטיות בנוגע לכלל השימושים בשירותים, למידע נוסף על עוגיות אלו
תוכל למצוא באתר של גוגל בכתובת – https://www.google.com/intl/en/policies/technologies/ads
וכן במדיניות העוגיות של פייסבוק בכתובת - https://www.facebook.com/policies/cookies

7 .אבטחת מידע
7.1 לצורך יצירת אזור אישי באתר, עליך להזין, בין היתר, סיסמא בהתאם להנחיות המופיעות באתר
ו/או באפליקציה. הכנסה לאתר הינה באמצעות פרטים אישיים וקוד חד פעמי (OTP) שישלח
למספר הנייד כפי שמופיע במערכות החברה. הנך נדרש לדווח לחברה על כל שימוש לא מורשה
בפרטיך.
7.2 לתשומת ליבך, כל כניסה לאתר שתיעשה באמצעות פרטיך המזהים (למשל – מספר ת.ז. ו/או
הסיסמא שלך (לרבות כל מידע אישי שיימסר תוך שימוש בהם) תיחשב ככניסה שבוצעה על ידך,
ואתה פוטר את החברה וכל מי מטעמה מכל חבות או אחריות לשימוש בלתי מורשה שיעשה בשם
בפרטיך, ולתוצאות השימוש כאמור.
7.3 החברה משתמשת בטכנולוגיות אבטחת מידע מקובלות, ותעשה מאמץ סביר על מנת להבטיח כי
האתר יהיה חופשי ונקי מוירוסים, תולעים או סוסים טרויאניים. החברה תפעיל ותיישם נהלים
מקובלים בשוק לאבטחת המידע האישי שלך מפני גישה, שימוש או גילוי בלתי מורשים. עם זאת,
ידוע לכך למרות האמור לעיל אין בכך כדי להעניק ביטחון מוחלט ביחס למידע שתמסור לחברה
(בין אם נשמר בפועל במערכות המידע של החברה ובין אם נשמר ומאוחסן אצל ספק/י ענן).
7.4 לפיכך, באחריותך לנקוט אמצעי הגנה בסיסיים על מחשבך או מכשיר הקצה ממנו אתה עושה
שימוש (לרבות התקנת תוכנת אנטי וירוס, נעילת מחשב, החלפת סיסמאות וכו'), על מנת להגן על
מידע אישי שלך.

8 .שמירת מידע, זכות העיון, תיקון ומחיקת מידע
8.1 ככלל, מידע אישי שלך או אודותיך ישמר במאגר/י המידע של החברה לפרק הזמן הדרוש לחברה
לצורך מתן השירותים המבוקשים על ידך, לפי העניין, וכל עוד יש לחברה צורך לגיטימי או חובה
חוקית או אחרת לשמור אותו עפ"י כל דין. מידע אנונימי יישמר במערכות המידע של החברה ללא
הגבלת זמן (כל עוד הדבר אינו אסור עפ"י כל דין).
8.2 הנך זכאי לעיין במידע האישי המוחזק אודותיך במאגר/י המידע של החברה, ככל שנשמר, לבקש
לתקן את המידע או מחיקתו, באמצעות משלוח פניה לחברה בדוא"ל gmt-info@gmtonline.co.il
אנחנו נפעל למילוי בקשתך בהתאם למידע המוטל עלינו עפ"י החוק. ואולם, מידע אישי הדרוש
לחברה לצורך המשך ניהול עסקיה ו/או הדרוש לצורך מטרה לגיטימית כדוגמת התגוננות מפני
תביעות (והכל, ככל שדרוש) ימשיך להישמר ע"י החברה למשך הזמן הדרוש לה עפ"י חוק.

9 .צור קשר. הנך מוזמן ליצור עמנו קשר בכל שאלה, בקשה או בעיה הקשורה במדיניות הפרטיות
באמצעות פניה אלינו בgmt-info@gmtonline.co.il ואנו נעשה כל מאמץ לחזור אליך בקשר לפנייתך
בהקדם האפשרי.

        </textarea>


       <textarea name="rules" id="rules" class="form-control" disabled dir="ltr" v-if="this.$store.state.locale.lang == 'ru'">

Условия пользования сайтом и услугами

«Джи. Эм. Ти. Тек Иновейшен Лтд» (далее: «Компания» или «Джи. Эм. Ти») – это частная
компания, деятельность которой осуществляется в сфере финансовых услуг. Среди прочего
Компания специализируется на переводе денег за рубеж, на обмене валют, пополнении
депозитных карт и т.п.

Использование сайта и / или услуг Компании осуществляется в соответствии с данными
Условиями пользования (далее: «Условия пользования»), независимо от того, будете ли вы
пользоваться услугами Компании через сайт и / или при помощи других средств, которые вам
предоставит Компания (включая Поставщиков услуг по их определению ниже). Компания
оставляет за собой право периодически вносить изменения в данные Условия пользования.

Пользование сайтом Компании и / или ее услугами при помощи любых средств может быть
сопряжено с предоставлением Компании информации и / или со сбором данных о пользователе
(включая личные данные), включая информацию, которую собирают третьи стороны для
Компании, и поэтому сказанное в данных Условиях пользования распространяется также и на
эту информацию. На информацию, которая будет храниться в базе данных Компании, будет
распространяться Политика защиты личных данных, с которой вы можете ознакомиться по
адресу gmtonline.co.il. Условия пользования и Политика защита личных данных вместе
составляют юридическую базу для вашего пользования сайтом и / или услугами Компании.

Условия пользования дополняют любые условия и / или дополнительные распоряжения, которые
будут опубликованы на сайте или в другом официальном письме, и использование любых частей
сайта может быть обусловлено дополнительными требованиями, которые будут размещены на
этих участках сайта.

Пользование сайтом и / или услугами Компании будет считаться однозначным и осознанным
согласием с вашей стороны со всеми условиями, оговорками и уведомлениями, которые
включены в Условия пользования и Политику защиты личных данных. Если вы не согласны с
условиями Политики защиты личных данных и / или с данными Условиями пользования, будь то
всеми или с некоторыми из них, мы просим вас не пользоваться сайтом и / или услугами нашей
Компании.

Для удобства чтения данные Условия пользования составлены в мужском роде, однако они
касаются как мужчин, так и женщин в равной степени.

1. Определения
1.1.«Вы» или «Пользователь» - любой человек, который заходит на сайт через компьютер
и / или другой конечный аппарат для любой цели, включая клиента («Услуги
Компании» и «Клиент» по их определению ниже).
1.2.«Интернет сайт» или «Сайт» - интернет сайт «Джи. Эм. Ти. Тек Иновейшен», который
размещен по адресу gmtonline.co.il (или по любому другому адресу, который будет
действительным в любой момент), и который позволяет Пользователю, среди прочего,
совершать действия в соответствии с Услугами Компании.
1.3.«Контент» - это информация любого рода и любого типа, включая вербальный,
визуальный, звуковой, свето-звуковой контент или любое их сочетание, а также их
оформление или обработку или представление любым способом, включая любую
фотографию, рисунок, анимацию, снимок, чертеж, макет, образ, изображение,
видеофайл, аудиофайл, музыкальный файл, программное обеспечение, компьютерный
код, приложение, протокол, статью, базу данных, интерфейс и любой символ, знак,
значок и иконку (icon), которые появляются и / или интегрируются на Сайте
Компанией, независимо от того, являются ли они собственностью Компании или же
принадлежат третьей стороне, которая предоставляет Компании право на их
использование.
1.4.«Информация» - любая информация, которую Вы предоставите Компании на Сайте и /
или при помощи любого другого средства, включая личные данные.
1.5.«Личные данные», «Анонимные данные» - по их определению в Политике защиты
личных данных Компании, с которой можно ознакомиться по адресу gmtonline.co.il.
1.6.«Услуги» или «Услуги Компании» - все услуги, которые предоставляются Клиенту
через Компанию и / или ее Поставщиками услуг, включая денежные переводы, обмен
валют, продажу и заполнение кредитных карт, рассроченный кредит и т.п.
1.7.«Клиент» - любой человек, который пользуется Услугами Компании при помощи
любых средств, включая Интернет сайт и / или фронтально через Поставщиков услуг
Компании.
1.8.«Поставщики услуг» - уполномоченные представители Компании, которые находятся
по всей стране, и которые были обучены Компанией и получили все разрешения,
которые требуются для того, чтобы действовать от имени Компании, включая
поставщиков услуг, обладающих лицензией по Закону о надзоре над финансовыми
услугами (официальные финансовые услуги), от 2016 года, и / или действительным
разрешением продолжать заниматься деятельностью в данной сфере, выданным
Управлением финансовых рынков.
1.9.«Рабочий день» - рабочий день Компании с точки зрения Услуг осуществления
платежей для Клиентов Компании – это любой день, за исключением субботы,
праздников, двух дней в Рош Хашана, кануна Судного Дня и Судного Дня, первого дня
праздника Суккот и Шмини Ацерет, Пурима, первого и седьмого дня Пейсаха, Дня
Независимости, праздника Шавуот и Тиша Беав, или другого дня, который определит
инспектор по делам банков в Банке Израиля, или который будет определён любым
законом как нерабочий день.
«Рабочий день с точки зрения валют» – это день, в который Банк Израиля публикует
новые курсы той валюты, в которой осуществляется депозит, а при отсутствии такой
публикации будет действовать последний курс, который был опубликован Банком
Израиля.

2. Часы работы Компании
2.1.Компания будет предоставлять свои Услуги через Поставщиков услуг в разные Рабочие
дни, а также в офисе Компании в Рабочие дни с 08:30 до 18:30, в пятницу, в канун
праздников и во все другие дни, которые не являются Рабочими днями, Компания будет
предоставлять свои услуги в своем офисе с 08:30 до 13:30 (далее: «Рабочие часы»).
Настоящим разъясняется, что дни работы с точки зрения осуществления операций,
будут соответствовать Рабочим дням офиса Компании, а в отношении валютных
операций – в соответствии с Рабочими днями с точки зрения валютных операций и
Рабочими часами офиса, как указано выше.
2.2.Компания вправе изменять Рабочие часы в соответствии с различными Услугами и в
соответствии с различными способами связи, типами или каналами различных Услуг.
Рабочие часы будут публиковаться на Сайте Компании, и будут периодически
уточняться исключительно по усмотрению Компании.
2.3.Несмотря на вышесказанное, и в соответствии с законом, Компания вправе
периодически определять Рабочие дни в отношении различных Услуг, дни и часы
открытия и закрытия в отношении покупки или продажи или совершения операций в
иностранной валюте, в шекелях, прикрепленных к валюте, в отношении кредита,
ограничив их только до определённых дней или ограниченных и определённых часов в
течение Рабочего дня – и все это в отношении любого дня или дней или периодов и дат.
2.4.Платежное распоряжение (по его определению в Законе об услугах оплаты, от 2019
года) или распоряжение о совершении любой операции, которое получено после
окончания Рабочего дня, в зависимости от того, какая дата наступит ранее, будет
считаться полученным в следующий Рабочий день.

3. Право пользования Сайтом
3.1.При условии соблюдения вами Условий пользования Компания предоставляет вам
личное, не эксклюзивное, не подлежащее передаче и / или предоставлению
суб-лицензий, не подлежащее продаже и подлежащее отмене разрешение пользоваться
Сайтом (включая любой Контент или Услуги, которые на нем предоставляются),
исключительно для вашего личного пользования, и не для коммерческого
использования любого рода, и исключительно для законных целей. Вы не вправе
использовать Сайт или любую его часть для какой-либо противозаконной деятельности.
3.2.Самим фактом использования Сайта или любой его части вы заявляете и гарантируете,
что не будете осуществлять на Сайте и / или в любой его части какие-либо из
следующих действий (будь то самостоятельно или через других лиц, которые
представляют ваши интересы): (а) использовать Сайт для любой незаконной цели и /
или в нарушение Условий пользования, или злоупотреблять Сайтом или любой его
частью; (б) копировать, размножать, восстанавливать или переделывать, изменять,
переводить Сайт (включая любой Контент или Услуги, которые на нем предлагаются);
(в) обходить технические преграды на Сайте, скрывать сообщения о соблюдении
авторских прав Компании (и / или любого ее представителя) на Сайте; (г) совершать
любые действия, которые помешают или могут помешать нормальной работе Сайта и /
или предотвратить доступ к его Контенту и / или Услугам для других пользователей
Сайта; (д) нельзя задействовать или позволять задействовать любую аппликацию или
любое другое средство, включая программы типа Crawlers, Robots и др., для поиска,
сканирования, копирования или автоматического воспроизведения Контента Сайта, и в
том числе нельзя создавать базы данных путём скачивания и / или хранения Контента,
содержащегося на Сайте; (е) устанавливать или внедрять на Сайт любой вирус,
«троянского червя», ошибку, код, приложение или программное обеспечение, которые
могут нанести вред или предназначены для нанесения вреда нормальной работе Сайта;
(ж) нарушать любое из Условий пользования.
3.3.Для удобства пользователей Сайта на нем могут предлагаться ссылки на сайты других
лиц. В случае если вы воспользуетесь этими ссылками, вы выйдете с Сайта. Компания
не гарантирует, что эти ссылки переведут вас на действующие сайты, она не проверила
все сайты третьей стороны, не контролирует их и не несет ответственность за эти сайты
или за их контент. Поэтому Компания не создаёт и не предоставляет каких-либо
обещаний по поводу этих сайтов или по поводу любой информации, услуг или контента,
которые на них размещены, или по поводу любых результатов, которые могут
возникнуть в результате их использования, включая профессиональные услуги,
которые они предоставляют. Решение перейти на сайт третьей стороны, который связан
с Сайтом, принимается вами исключительно под вашу ответственность. При переходе
по этим ссылкам вам необходимо ознакомиться с правилами пользования и остальными
условиями и указаниями этих сайтов и действовать в соответствии с тем, что там
сказано.

4. Интеллектуальная собственность
4.1.Компания является единственным владельцем Сайта, его Контента, графического
оформления, планирования, структуры его файлов, логотипа и торговых знаков,
которые на нем размещены.
4.2.Оформление и экспозиция Сайта, включая находящийся на нем Контент, защищены
и / или могут быть защищены авторскими правами и / или торговыми знаками,
патентами или правами интеллектуальной собственности Компании и / или третьей
стороны, и вам категорически запрещено копировать их, будь то целиком или частично,
или дублировать, распространять, продавать, разрешать использование,
демонстрировать публично, производить копии, публиковать или эксплуатировать его
для каких-либо коммерческих целей, - как весь Сайт, так и любую его часть. Кроме
того, вам не разрешается создавать рамки (Frame) для Сайта или использовать Meta
Tags или любой скрытый текст с применением названия Компании или ее торговых
знаков и / или названия и торговых знаков любых имеющих к ней отношение лиц.
Любое несанкционированное использование повлечёт среди прочего отмену Условий
пользования и прекращение вашего использования Сайта.
4.3.Во избежание сомнений настоящим разъясняется, что за исключением права
пользования, которое вам предоставлено в рамках данных Условий пользования,
Компания не предоставляет вам каких-либо прав на Сайт и / или на его часть, включая
интеллектуальное право Компании или третьей стороны.

5. Использование Услуг Компании
5.1.Использование Услуг, которые предлагает Компания, производится в соответствии с
законом и только честно и общепринятым образом. Нельзя злоупотреблять ими или
использовать их как-либо в нарушение закона или общественного блага или каким-либо
ненадлежащим образом, или не для тех целей, для которых они предназначены, включая
совершение незаконных или запрещенных денежных переводов или переводов, целью
которых является обход ограничений, которые касаются донесений в связи с запретом
отмывания капитала и финансирования террористической деятельности, или в целях
выдачи себя за другого человека или другое юридическое лицо. Не нарушая прав
Компании, настоящим разъясняется, что человек, который нарушит данный запрет,
должен будет компенсировать Компании любой ущерб, который будет ей причинен в
результате этого нарушения.
5.2.Источник денег, которые используются для осуществления Услуг, является
легитимным, законным, и не связан с каким-либо нарушением законов Государства
Израиль и / или какого либо другого государства. Всю ответственность за суть
операции несёт Пользователь, и он обязан ещё до осуществления операции проверить её
значение и касающиеся нее положения закона.
5.3.
5.4.На Компанию и её деятельность распространяются различные положения
законодательства, на основании которых она обязана предотвращать хищение и
мошенничество в соответствии с применимыми к ним положениями международного
права на основании международных соглашений с третьими сторонами. Описанные
ниже случаи могут свидетельствовать о мошенничестве и хищении и вызывают
подозрение в отношении легитимности сделки, поэтому мы просим вас избегать
совершения сделок при подобных обстоятельствах:
    5.4.1. если в сделке участвует незнакомое вам лицо и / или если вы не можете его
    идентифицировать;
    5.4.2. если третья сторона, которая вам неизвестна или не знакома, попросила вас
    предоставить частичные или неполные данные о ваших действиях;
    5.4.3. если вам предложили услуги и / или товары по существенно более низким
    ценам, чем цены, принятые на рынке;
    5.4.4. если вам предложены льготы и / или скидки и / или участие в лотереях и /
    или вам сообщили о том, что вы выиграли в лотерее, и при этом сообщение
    пришло от лица, которое вам не знакомо;
Список перечисленных выше случаев неполный и не исчерпывающий, и он основан
лишь на опыте Компании. Компания и любые связанные с ней лица не будут нести ту
или иную ответственность, если вы проигнорируете приведённое выше предупреждение
и примете решение совершить такие сделки, несмотря на то, что они являются
подозрительными.
5.5.Несмотря на вышесказанное, нельзя тем или иным образом использовать Услуги для
совершения следующих сделок или оплачивать следующие услуги / товары:
    5.5.1. Операции с виртуальными / криптографическими валютами.
    5.5.2. Ссуды или кредит или предоставление финансовых услуг любого рода,
    включая размен денег или обмен валют в качестве профессиональной
    деятельности.
    5.5.3. Контент и услуги для взрослых.
    5.5.4. Азартные игры.
    5.5.5. Наркотики.
    5.5.6. Торговля бриллиантами.
    5.5.7. Торговля оружием.
    5.5.8. Сделки с ценными бумагами, бинарными опциями и Форекс.
    5.5.9. Платные сексуальные услуги и торговля людьми.
    5.5.10. Любые запрещённые законом действия, включая отмывание капитала и
    финансирование террористической деятельности, и включая любые действия,
    которые требуют лицензий и разрешений, - если у релевантных сторон нет
    соответствующих лицензий и разрешений, или если эти лицензии и
    разрешения недействительны.
5.6.В случае использования Сайта в нарушение сказанного в пункте 4.2 выше, и в
нарушение остальных положений Условий пользования, в том числе в случае если
деятельность Пользователя прекращена / ограничена, или в случае если одно или более
платежных средств, которые зарегистрированы на личном счету Пользователя,
недействительны, заморожены или ограничены для пользования определенным
способом, Компания не будет нести ту или иную ответственность перед Пользователем
или третьей стороной, или за любой прямой или косвенный ущерб, который будет им
причинен в связи с этим. Во избежание сомнений настоящим разъясняется, что
Компания не позволит совершить или завершить операции и оплату услуг, как описано
в данном пункте (в том числе - в случае если у Компании возникнут в связи с ними
какие-либо подозрения), и / или не позволит использовать деньги, включая изъятие или
перевод любым способом денег, источником которых являются указанные выше сделки
и услуги.
5.7.Компания вправе в любой момент обратиться к любому пользователю, чтобы
проверить любые данные, связанные с использованием Услуг. В этом случае
Пользователь обязуется сотрудничать с Компанией и предоставить ей любую
требуемую информацию.
5.8.Компания вправе в любой момент ограничить и / или изменить и / или отменить
предоставление Услуг или часть из них (включая ограничение сумм операций,
изменение списка разрешённых стран и т.п.). Не нарушая общего характера сказанного
выше, так как на совершаемые Компанией действия распространяются израильские и
международные законы, а также указы и инструкции, которые приняты в силу этих
законов, которые среди прочего касаются запрета и предотвращения отмывания
капитала и финансирования террористической деятельности, а также касаются
налогообложения действий, которые совершает Компания, существует вероятность
того, что какие-либо операции не будут осуществлены и / или будут изменены, и / или
еще до их осуществления о них будет сообщено релевантным органам, в соответствии с
требованиями закона.
5.9.Компания вправе обусловить использование Услуг или не предоставить свои Услуги
Клиенту, будь то в целом или только определённые Услуги, при использовании
определенных идентификационных данных или в отношении определённой сделки.
Кроме того, Компания вправе заблокировать деятельность Клиента на Сайте или в
любом другом месте до восполнения общих данных, которые касаются Клиента или
определенной сделки.
5.10. Часть Услуг не подлежит отмене, в том числе в случае, когда просьба об отмене
основывается на ошибке, как например предоставление ошибочных данных, опечатка
Пользователя или Компании и т.п. Пользователь заявляет, что до подтверждения им
операции он повторно проверил все требуемые данные для предоставления Услуг, и что
Компания не будет нести ответственность за ошибки, вытекающие из неточности и /
или ошибок в предоставлении требуемых данных со стороны Пользователя.
5.11. День осуществления операций наступит в течение двух Рабочих дней после того
Рабочего дня, когда было оформлено распоряжение Клиента, а в отношении
валютных операций в течение двух Рабочих дней с точки зрения валютных операций
после дня оформления валютной операции, в который Клиент оформил свое
распоряжение.
5.12. Часть Услуг предоставляется через систему компании MoneyGram International Ltd
(далее: «MG»). Пользователю известно о том, что на осуществление операций через
Компанию будет распространяются среди прочего инструкции компании MG и ее
устав, включая ее инструкции по поводу защиты личных данных. С этими
инструкциями можно ознакомиться по ссылке: https://start.moneygram.com.
Настоящим разъясняется, что в случае разночтений между данным Уставом и
уставом MG превалировать будут более строгие правила.

6. Налоги и уведомления
6.1.Пользователь несёт ответственность за уплату всех налогов, которые вытекают из всех
платежей или операций, которые он осуществил, или которые он получил через
Компанию, а также за предоставление всех уведомлений, которые требуются по
любому закону в связи с операцией, и Компания не будет нести за это какую-либо
ответственность.
6.2.Пользователю известно и он заранее соглашается с тем, что в соответствии с законом,
Компания будет иметь право передать данные и различные донесения в связи с его
действиями в органы управления, как в Израиле, так и за рубежом (в том числе в
налоговые органы), если от Компании этого потребуют.
6.3.Пользователю известно о том, что Компания имеет право задержать или отменить
совершение операции по причине непередачи данных в отношении уплаты релевантных
налогов за операцию.
6.4.Пользователю известно о том, что Компания является финансовым учреждением с
точки зрения налога на добавленную стоимость.

7. Комиссионные и другие платежи
7.1.Компания будет взимать плату и / или комиссионные за использование Услуг в
установленном размере и в установленные сроки по ценнику Компании, который
размещён на Сайте. Этот ценник может периодически уточняться исключительно по
усмотрению Компании, в соответствии с требованиями закона.
7.2.До осуществления операции Пользователь должен проверить стоимость и
комиссионные, которые с ней связаны, а также альтернативные рыночные цены на
такую операцию, и Пользователь не будет вправе предъявлять Компании какие-либо
претензии по этому поводу.
7.3.Если совершение платежа произойдет позднее того дня, в который будет оформлена
операция, могут возникнуть изменения в стоимости операции в связи с изменением
курса валют, и Пользователь должен будет оплатить эту стоимость по новой цене.
7.4.Если Компания обнаружит, что произошла ошибка в расчете обменного курса и / или в
размере комиссионных, которые уплачены Компании, исключительно по усмотрению
Компании, она сообщит Клиенту об ошибке (далее: «Сообщение об исправлении
ошибки»), и Клиент будет иметь право отменить операцию без дополнительной платы,
либо подтвердить операцию в соответствии с условиями, представленными ему в
Сообщении об исправлении ошибки. Пользователь заявляет, что ему известно о том,
что услуги могут быть сопряжены с дополнительными платежами и / или
комиссионными, которые взимают третьи стороны, которые не зависят от Компании,
такие как кредитные компании, банки корреспонденты и т.д.
7.5.Оплата наличными деньгами или банковский перевод:
    7.5.1. Возможность платить наличными и / или банковским переводом
    предоставляется только для некоторой части Услуг. Предоставление
    Компанией требуемых услуг обусловлено платежами Клиента в
    установленные Компанией сроки. Вы можете перевести платеж за Услуги
    напрямую Компании путём банковского перевода со счета Пользователя
    только в израильском банке, или наличными одному из Поставщиков Услуг
    Компании. Компания вправе взимать дополнительные комиссионные за
    оплату наличными в шекелях либо в иностранной валюте.
    7.5.2. Пользователь не будет предъявлять Компании какие-либо претензии в случае
    отмены и / или задержки Услуг со стороны Компании в том случае, если
    Пользователь не оплатил Услуги Компании в заранее установленный
    Компанией срок и / или если задержка в предоставлении Услуг происходит
    при обстоятельствах, неподконтрольных Компании.
7.6 Не будет возможности отменить оплату путём банковского перевода. Деньги,
которые будут получены на счет Компании путём банковского перевода для
предоставления Услуг, не будут возвращены Пользователю.
7.7 Оплата кредитной картой
7.7.1. Пользователь заявляет, что ему известно о том, что разрешение кредитной
компании является условием осуществления требуемых им Услуг, и он не
будет предъявлять Компании претензии в случае, если сделка не будет
осуществлена в результате отказа кредитной компании её осуществить.
7.7.2. Пользователю известно о том, что Компания вправе задействовать различные
механизмы, направленные на предотвращение мошенничества,
недобросовестного отказа от сделок, злоупотребления кредитными картами
различных типов и т.п. Таким механизмом может быть отправка текстовых
сообщений для подтверждения операции по кредитной карте.
7.7.3. Существует вероятность того, что кроме комиссионных за Услугу, которые
взимает Компания, вы должны будете уплатить дополнительные
комиссионные, которые взимаются в соответствии с вашим договором с
кредитной компанией или с любой третьей стороной, с которой вы заключили
договор в связи с кредитной картой, которой вы владеете. Существует также
вероятность того, что вы должны будет уплатить кредитной компании
дополнительные комиссионные, такие как «комиссионные за покупку валюты
в обменном пункте». Во избежание сомнений настоящим разъясняется, что
Компания не имеет никакого отношения и / или не влияет на взимаемые этой
третьей стороной комиссионные, и Пользователь должен их оплачивать в
соответствии с его договором с этой третьей стороной.
7.7.4. Оплата кредитной картой в рассрочку
    7.7.4.1. Пользователь вправе оплатить Услуги кредитной картой в рассрочку
    с уплатой процентов, которые будут начислены на сумму платежа
    (далее: «Проценты по рассрочке»).
    7.7.4.2. Соглашаясь с Уставом пользования, Клиент подтверждает условия
    кредита, которые установлены в Уставе в качестве условий кредита,
    и которые дополняют конкретные условия, которые определены для
    операции.
    7.7.4.3. Перед осуществлением операции вам будут представлены данные по
    рассроченным платежам, как этого требует закон, включая данные,
    которые касаются процентов, реальной стоимости, пени за опоздание,
    сроков платежей и т.п. Вы должны будете подтвердить эти данные в
    письменной форме, подписавшись либо фронтально, либо путём
    отправки текстового сообщения (далее: «Операция с
    рассроченными платежами»).
    7.7.4.4. Клиенту известно, что Проценты по рассрочке взимаются абсолютно
    отдельно от комиссионных за обмен валюты, которые назначаются
    агентом, осуществляющим обмен, и которые не входят в стоимость
    кредита.
    7.7.4.5. В случае если на сделку распространяются положения Закона о
    справедливом кредите, от 1993 года (далее: «Закон о справедливом
    кредите»), реальная стоимость кредита в операции не должна
    превышать максимальной стоимости кредита по ее определению в
    Законе о справедливом кредите на момент осуществления конкретной

    операции, как указано в данных операции. Кроме того, размер пени за
    опоздание, известный на день осуществления операции, не должен
    превышать максимальный размер пени за опоздание по его
    определению в Законе о справедливом кредите, известный на тот
    день, как указано в документе операции.
    7.7.4.6. Клиент заявляет и настоящим гарантирует, что он ни прямо, ни
    косвенно не приведет к отмене кредитной карты, по которой
    осуществляется операция, и не отменит операцию, не полностью, ни
    частично, до тех пор, пока она не будет полностью оплачена.
    7.7.4.7. Во избежание сомнений настоящим разъясняется, что
    неосуществление какого-либо из отсроченных платежей по
    кредитной карте, которые обязался осуществить Клиент, является
    кардинальным нарушением договора между Вами и Компанией,
    которая в свою очередь предпримет все меры, правом на которые она
    располагает в случае нарушения Вами договора.
    7.7.4.8. Практическая выплата в день, установленный для осуществления
    платежа, будет считаться осуществленным платежом.
    7.7.4.9. Если платёж не будет осуществлен своевременно, на него будет
    начислена пеня за опоздание в том размере, который определен в
    сделке, начиная со дня, установленного для платежа, и до дня
    фактически осуществленного платежа (далее: «Пеня за опоздание»),
    с прибавкой расходов на взыскание, которые включают судебные
    издержки и гонорар адвоката, если таковые будут иметь место (далее:
    «Расходы на взыскание»). Кроме того, Клиент обязуется уплатить
    неуплаченную сумму в течение 3 Рабочих дней со дня первого
    требования.
    7.7.4.10. Клиент имеет право ускорить выплату рассроченных платежей, - всех
    или некоторых из них, предоставив предварительное уведомление не
    позднее, чем за 3 Рабочих дня. В этом случае Компания возвратит
    Клиенту Проценты за кредит, которые она взыскала заранее, за
    вычетом комиссионных за ускоренную выплату, в соответствии с
    тем, что определено при заключении сделки. В случае если Клиент
    пожелает ускорить выплату, предоставив уведомление за 3 Рабочих
    дня, Компания будет вправе осуществить ускоренную выплату,
    взыскав дополнительные комиссионные в связи с предварительным
    уведомлением, в соответствии с тем, что определено при оформлении
    операции по рассроченным платежам.
    7.7.4.11. Клиент обязуется в течение 21 дня после первого требования
    уплатить Компании путём внесения депозита на банковский счет
    Компании или путём наличного платежа у одного из агентов
    Компании сумму, указанную в каждом из отсроченных платежей,
    даже если время для их оплаты еще не наступило (с прибавкой Пени
    за опоздание, разницы индексов цен и Расходов на взыскание), при
    следующих обстоятельствах: в случае, если один из платежей не был
    оплачен, несмотря на требование Компании; если выяснится, что
    сведения Клиента и / или его представителя, включённые в данный
    Договор или в документацию сделки, будь то полностью или
    частично, неверны; если риск невозврата платежей достаточно велик
    или если имело место другое кардинальное нарушение данного
    Договора, или если выполнены другие условия, по которым требуется
    немедленно совершить указанное действие в отношении выплаты
    остатка платежей; если Клиенту и / или кому-либо из его акционеров
    или покупателю назначен или подана просьба о назначении для них
    распорядителя имущества и / или управляющего и / или ликвидатора
    и / или временного ликвидатора и / или попечителя или имело место
    любое другое официальное назначение, которое не было
    ликвидировано в течение 14 дней, или если подана против кого-либо
    из них просьба о ликвидации или о прекращении процесса или о
    банкротстве, или если против него вынесен ордер о распределении
    или о ликвидации, или если он объявлен банкротом; если наложен
    арест на имущество Клиента и этот арест не будет ликвидирован в
    течение 21 дня с момента его наложения, или если владельцы залогов
    на имущество Клиента или на его значительную часть реализуют
    свои залоги, или если будет открыт процесс судебного исполнения в
    отношении всего или части имущества Клиента, и этот процесс не
    будет отменен в течение 21 дня с момента его открытия; если, по
    мнению Компании, возникнет серьезное опасение, что указанные
    выше события должны произойти, независимо от того, зависят ли эти
    события или обстоятельства от Клиента; или, среди прочего, Клиент
    будет отсутствовать на работе и / или покинет страну на период,
    превышающий 14 дней.
    7.7.4.12. Компания будет иметь право продать и / или передать и / или отдать и
    / или уступить и / или вычесть и / или передать платежи в качестве
    облигаций, будь то полностью или частично, любому другому лицу на
    тех условиях, которые она посчитает правильными, и Клиент заранее
    дает на это свое согласие. Клиент не будет иметь право передать и /
    или отдать свои обязанности и / или свои права по данному Уставу, за
    исключением случаев, когда ссудодатель дал на это предварительное
    согласие в письменной форме.

8. Условия пользования для различных типов Услуг
Не нарушая общего характера сказанного в общих Условиях пользования, на использование
Услуг Компании распространяются среди прочего условия, описанные в Приложении А к
данным Условиям пользования.

9. Ответственность
9.7.Сайт, Услуги и Контент, размещенный на Сайте, могут использоваться только в том
виде, в котором они существуют (as available / as is), без какого-либо обязательства или
обещания со стороны Компании в отношении их соответствия нуждам Пользователя
или определенным целям, и без обязательства или обещания в отношении точности
Контента и / или его правдивости и / или отсутствия нарушения авторских прав.
Использование Сайта и предлагаемых на нем Услуг осуществляется под собственную
ответственность Пользователя, и он сознает, что он не обязан использовать эти Услуги.
Компания не будет нести ту или иную ответственность перед Пользователями и перед
третьими сторонами во всём, что касается любой операции, в рамках которой
применяются Услуги Компании, в том числе в том, что касается целей операции.
9.8.Компания не гарантирует, что Сайт или любая его часть будет действовать непрерывно
и исправно, и что доступ к Сайту будет доступен и / или будет бесперебойным и / или
будет без помех (в том числе в результате регулярного технического обслуживания
Сайта или устранения неисправностей). Соответственно, Пользователь не сможет
предъявлять претензии по поводу невозможности своевременно совершить операцию на
Сайте (как например: опоздание в осуществлении перевода или любого другого
действия, для которого существует крайний срок) по причине сбоя на Сайте, и Вы
освобождаете Компанию от любых исков и / или претензий и / или требований в связи с
вышесказанным.
9.9.Компания не несет какую-либо ответственность за ошибку в данных Пользователя,
которые он сообщил на Сайте и / или за неполучение обращения через Сайт, за
отсутствие ответа на обращение, за любую задержку в ответе на общение и / или за
получение достаточной информации на Сайте. Мы сделаем все возможное для того,
чтобы ответить на ваше обращение к нам в кратчайший срок.
9.10. Не следует полагаться на Контент и / или на Услуги и / или на любую
рекомендацию, совет или мнение, полученное через Сайт (если таковое будет
получено), при принятии каких-либо решений, в том числе по экономическим,
профессиональным, коммерческим, личным, финансовым и иным вопросам.
9.11. Не нарушая общего характера сказанного выше, ни в коем случае на Компанию
(включая ее работников, руководителей и любых представителей) не будет возложена
ответственность за какой-либо ущерб (включая среди прочего прямой, косвенный или
результативный, и включая ущерб, вытекающий из неполучения доходов, утери данных
или помех в процессе осуществления действий, нанесение вреда репутации и т.п. в связи
и / или в результате использования Услуг Компании, невозможности использовать
Услуги или от результата использования Услуг (включая какой-либо Контент и / или
Услуги, которые на нем предлагаются), и / или в результате невозможности
использовать другой сайт, который связан с Сайтом, или какой-либо Контент, который
включен в Сайт или в любой из сайтов, которые связаны с Сайтом (включая ссылку на
указанный контент), и / или в результате подверженности компьютерным уязвимостям
(включая вирусы), и / или в результате нарушения прав интеллектуальной
собственности любого рода (будь то зарегистрированных или нет), - даже если
Компания уже получила информацию о возможности возникновения этих ущербов,
каким бы ни был повод для иска, и какой бы ни была причина ущерба, - контрактной,
материальной или иной.
9.12. Компания будет иметь право компенсировать и задерживать любые денежные
средства, которые поступают или поступят Пользователю, и которые будут время от
времени находиться в распоряжении компании, и она будет иметь право вычесть из
любой суммы, причитающейся Пользователю, любой долг или обязательство перед
Компанией в результате использования Услуг. Компания будет также иметь право
задержать осуществление перевода денег Пользователю до выполнения им его
обязательств по отношению к Компании, в том числе в случае, если срок для их
выполнения еще не наступил. Настоящим разъясняется, что регистрация вычета /
списания со счёта на личной странице будет считаться уведомлением о списании по
определению закона, таким образом, что не потребуется дополнительное уведомление
об этом, и Пользователь не будет обладать правом на задержку или списание в
отношении Компании.

10. Обслуживание Клиентов и связь
10.7. 10Вы можете обращаться в Компанию через ссылку на техническую поддержку
(Support), размещенную на Сайте, по электронной почте gmt-info@gmtonline.co.il или
по телефону 03-3722800 по любому вопросу, связанному с Услугами.
10.8. Обращаем ваше внимание на то, что существует вероятность того, что телефонные
разговоры с отделом обслуживания клиентов будут записываться и храниться в базе
данных Компании или ее представителей, и будут использоваться для регистрации,
проверки, улучшения обслуживания, контроля и предотвращения рисков.

11. Прочее
11.7. Сайт и Услуги Компании предназначены для совершеннолетних людей (старше 18 лет)
в пределах Государства Израиль, и они не предназначены для несовершеннолетних.
Если вы являетесь несовершеннолетним (вы младше 18 лет), сам факт вашего
использования Сайта будет служить доказательством того, что вы получили
разрешение ваших родителей или назначенного вам законного опекуна на такое
использование.
11.8. На данные Условия пользования распространяется только израильское
законодательство. Компетентный суд тель-авивского округа будет обладать
исключительной юрисдикцией для рассмотрения любых споров и / или конфликтов в
отношении данного Договора, его применения и толкования, и никакой другой суд не
будет обладать юрисдикцией для рассмотрения таких споров.
11.9. Вам следует время от времени посещать Сайт, чтобы проверить уточненные Условия
пользования, так как эти Условия будут обязывающими с момента внесения в них
уточнения (дата последнего уточнения указана в Преамбуле Условий пользования).
Уведомления и / или целевые юридические условия, которые приведены на
определенных страницах Сайта, могут превалировать над определёнными условиями,
включенными в Условия пользования.
11.10. Отказ от прав в случае, если Компания не применит их в отношении одного или
более своих прав по данному Договору, не будет считаться отказом, и не будет
считаться, что Компания приняла этот факт, и не лишает ее этого права.
11.11. Если будет установлено, что какая-либо часть Условий пользования
недействительна или неприменима по закону, включая без ограничений пункты
ограничения ответственности, указанные выше, то признанные недействительными
пункты или те пункты, которые признаны неприменимыми, будут считаться
заменёнными на действительные и применимые пункты, содержание которых будет по
возможности самым близким к намерениям изначальных пунктов, и при этом
остальные Условия пользования останутся действительными.
11.12. Компания имеет право уступить, перевести или передать свои права или
обязательства, будь то полностью или частично, любой третьей стороне, однако
данный факт не нарушит прав Пользователя в связи с денежными средствами, которые
находятся в его распоряжении, в соответствии с Условиями пользования.
11.13. Пользователь не имеет права уступить, перевести или передать свои права или
обязательства, будь то полностью или частично, в силу данный Условий пользования
или любого другого договора любой третьей стороне.

ПРИЛОЖЕНИЕ А
Условия пользования различными Услугами

1. Денежные переводы
1.1.Пользователь (далее: «Человек, осуществляющий перевод») будет нести всю
ответственность за заполнение правильных и точных данных, и он должен убедиться
в том, что адресат, которого он выбрал для осуществления перевода, является
правильным адресатом (далее: «Получатель» и / или «Бенефициар»). В случае
ошибки в наборе данных Бенефициара или данных Пользователя, осуществляющего
перевод, Компания не будет нести какую-либо ответственность, включая
ответственность за отмену перевода и возврат денег.
1.2.По окончании оформления распоряжения о переводе (далее в целях данного пункта:
«Операция» и / или «Распоряжение о переводе») вам будет представлено резюме
Операции, в котором будут указаны данные перевода, о котором вы распорядились,
включая сумму зачета, которую получит бенефициар, и секретный код (далее:
«Секретный код»).
1.3.Обратите внимание на то, что, возможно, сумма зачета, которая будет вам
представлена, является лишь предположительной суммой, и она может измениться
из-за различных факторов, таких как обменный курс валют в стране, в которую
осуществляется перевод, комиссионные банка-корреспондента, комиссионные,
которые взимает третья сторона, которая имеет отношение к получателю перевода, и
т.п. Компания осуществит денежный перевод на ту сумму, которую она обязалась
перевести в резюме перевода, однако она не будет нести ответственность за
изменение фактической суммы зачета у получателя, которое вытекает из факторов,
не подконтрольных Компании, как указано выше.
1.4.Переводы денег, которые снимаются наличными. Если вы пожелаете осуществить
денежный перевод, который позволит получателю снять деньги наличными, вы
должны будете передать ему Секретный код. Получатель сможет снять деньги через
банк-корреспондент и / или компанию за границей с предъявлением Секретного кода.
Ответственность за сохранность Секретного кода и правильную его передачу
получателю несёт только Пользователь, и он не сможет предъявлять какие-либо
претензии Компании в случае утери и / или хищения и / или использования
Секретного кода человеком, который не является Получателем, с учетом
ограничений ответственности по пунктам 24-27 Закона об услугах оплаты, от 2019
года. В случае утери, хищения и / или подозрения о злоупотреблении Секретным
кодом, вам необходимо обратиться в отдел обслуживания клиентов Компании сразу,
как только вам станет об этом известно. Отдел обслуживания клиентов рассмотрит
ваше обращение по существу конкретного случая. Рассмотрение обращения отделом
обслуживания клиентов может быть сопряжено с дополнительными комиссионными.
1.5.Отмена операции. В соответствии с законом, любой денежный перевод может быть
отменён только до дня перевода денег поставщиком услуг перевода получателю
(который, возможно произойдет сразу же после размещения Распоряжения о
переводе). Для того чтобы отменить Распоряжение о переводе необходимо
обратиться в отдел обслуживания клиентов Компании. Настоящим разъясняется, что
если вы произвели Операцию через поставщика услуг, вам необходимо убедиться в
том, что этот поставщик услуг обратился к Компании и попросил отменить
Операцию. Процедура отмены может быть сопряжена с комиссионными. Ваши
деньги будут вам возвращены путём зачёта денег на кредитной карте, с которой
производился денежный перевод, и / или наличными, в соответствии со средством
платежа, которое вы выбрали для осуществления Операции. Подтверждение
Компании об окончании процедуры отмены является документальным
доказательством отмены Распоряжения о переводе.
1.6.Компания имеет право установить ограничения в отношении сумм перевода. Эти
суммы могу время от времени изменяться, исключительно по усмотрению Компании.
1.7.Денежные переводы посредством пополняемых предоплаченных карт:
    1.7.1. Компания не несет ответственность за свойства и качество находящейся в
    вашем распоряжении пополняемой карты, за информацию, которую вы о ней
    получили при покупке, за вашу возможность использовать карту и / или
    обслуживание клиентов в связи с картой.
    1.7.2. Денежный перевод при помощи пополняемой предоплаченной карты
    обусловлен разрешением клиринговой компании и имеющимися на карте
    средствами. В любом случае вы обязуетесь не пытаться производить
    денежные переводы на суммы, которые превышают остаток на вашей карте в
    момент совершения перевода.
    1.7.3. Невозможно уплатить за денежный перевод при помощи пополняемой
    предоплаченной карты в рассрочку.

2. Обмен валют
2.1.Курсы валют периодически изменяются, и они определяются Компанией
исключительно по ее усмотрению, в соответствии с различными факторами
международной торговли.
2.2.При обмене иностранной валюты у франчайзи Компании обменные курсы могут
варьироваться исключительно по усмотрению франчайзи, на которого
распространяются руководящие принципы и ограничения Компании.
2.3.При покупке валюты за наличные через поставщика услуг Компании вы должны
проверить действительность банкнот и сумму еще до того, как вы покинете пункт
обмена. Компания не несет ответственность за действительность банкнот.
3. Пополняемые предоплаченные карты (PrePaid)
Компания сотрудничает с компанией Макс Ит Финансим Лтд (далее: «Макс»), позволяя
вам купить и пополнить международную пополняемую предоплаченную карту (PrePaid).
3.1.Покупка пополняемой предоплаченной карты и ее использование производится на
условиях, которые указаны в документации сделки, которую вам потребуется
подписать при покупке карты, и которая является неотъемлемой частью данных
Условий пользования (далее: «Условия пользования пополняемыми
предоплаченными картами»). Компания не несет ответственность за текущий
клиринг с этой карты.
3.2.У вас есть возможность приобрести пополняемую предоплаченную карту, явившись
к одному из агентов Компании или любым другим способом, который Компания
периодически публикует.
3.3.Время поставки пополняемой предоплаченной карты с выбитым на ней именем – от 5
до 6 Рабочих дней.
4. Вывод денег обратно (Cash Back)
4.1.Услуга «Вывод денег обратно» позволяет вам накопить остаток на вашей личной
странице (далее: «Накопленный остаток») в связи с Услугами денежных переводов,
которые вы осуществите через Сайт и / или через агентов Компании (далее:
«Зачетная операция»). Компания вправе задействовать эту Услугу в связи с
дополнительными услугами, которые она обеспечивает, как будет определено ею
исключительно по своему усмотрению, и эти Условия пользования будут на неё
распространяться с необходимыми изменениями.
4.2.Не каждая Операция будет считаться Зачетной операцией. Решение, какие именно
денежные переводы будут считаться Зачетной операцией, будет приниматься
исключительно по усмотрению Компании. В завершение оформления каждого
распоряжения о денежном переводе Компания сообщит вам, является ли данный
перевод Зачетной операцией.
4.3.Вы можете время от времени проверять Накопленный остаток, предъявив
удостоверение личности одному из агентов Компании и / или на вашей личной
странице на Сайте Компании.
4.4.Накопленный остаток является личным, и он не может быть передан от одного
пользователя другому пользователю.
4.5.Накопление денег осуществляется в соответствии с той валютой, в которой
производилась Зачетная операция (доллары, шекели или евро).
4.6.Накопленный остаток может быть реализован только путём денежного перевода в
той валюте, в которой он накоплен. Невозможно обменять накопленные деньги в
другую валюту.
4.7.Минимальная сумма денег, которая может быть реализована при каждой Операции,
составляет 4 долларов. Реализация денег в конкретной Операции не может превысить
50% от суммы Операции.
4.8.Остаток денег будет находиться в вашем распоряжении в течение определенного
срока, и он может время от времени изменяться исключительно по усмотрению
Компании в течение 3 месяцев со дня последней Операции, которую вы осуществили
на вашем счету. Если вы не реализуете накопленный вами остаток денег в течение 3
месяцев со дня последней реализации, накопленный остаток, которым вы
располагаете, будет ликвидирован.
4.9.В случае отмены Операции Накопленный остаток, который вы реализовали в
отмененной Операции, будет возвращен на ваш счет на вашей личной странице.
5. Услуга перевода за рубеж путём распоряжения о биллинге счета
5.1.Пользователь имеет право присоединиться к услугам денежных переводов за границу
путём распоряжения о биллинге счета при условии, что он подписал форму
распоряжения и дал Компании разрешение снять деньги с его счета.
5.2.Оформление разрешения на биллинг счета Клиента будет осуществлено на условиях,
принятых в банке, в котором находится счет Клиента.
5.3.Компания постарается произвести биллинг счета Клиента в тот день, в той сумме и в
соответствии с теми данными, которые Клиент передаст Компании, заполнив и
подписав форму распоряжения.
5.4.Распоряжение о денежном переводе будет реализовано при условии, что банк принял
распоряжение полностью, что распоряжение не было отклонено по той или иной
причине, и стоимость перевода получена на счет Компании, в соответствии с
распоряжением, которое передала Компания.
5.5.В случае если будет осуществлен денежный перевод за границу, но полученное по
нему вознаграждение отменено или его сумма недостаточна, Компания обратится к
Клиенту и попросит его восполнить недостающую сумму. Компания постарается
получить всю сумму в соответствии с имеющимися в ее распоряжении средствами и
в соответствии с законом.
5.6.Сумма платежа будет рассчитана в соответствии с той суммой, которую Клиент
попросил перевести, + комиссионные за перевод в связи с данной Операцией,
помноженные на обменный курс валюты, который установит Компания.
5.7.Описание платежа и его компонентов будет отправляться один раз в месяц на адрес
электронной почты, который Клиент предоставил Компании при оформлении
платежного распоряжения.
5.8.Подтверждение осуществления перевода будет отправлено Клиенту также в виде
текстового сообщения на номер его мобильного телефона, который он сообщил
Компании в форме платёжного распоряжения.
5.9.Компания оставляет за собой право не присоединять Клиента к Услуге переводов по
распоряжению произвести биллинг счета и / или прекратить эту Услугу в любой
момент в принципе, либо в отношении конкретного Клиента.
5.10. Сумма максимально разрешённого денежного перевода в месяц для Услуги переводов
по распоряжению произвести биллинг счёта Клиента определяется Компанией в
соответствии с уставом Компании и требованиями законодательства. Компания
оставляет за собой право изменять максимальную сумму в любой момент без
предварительного уведомления.
5.11. Не нарушая общего характера сказанного выше, на перевод по распоряжению
произвести биллинг счета Клиента распространяются все общие пункты Устава
Компании.

Политика защиты личных данных
Последнее уточнение – 25 февраля 2020 года

1. Общие положения
1.1.Данный веб-сайт, расположенный по адресу https://gmtonline.co.il/ (далее: «Сайт»
или «Веб-сайт»), принадлежит и поддерживается компанией Джи Эм Ти Тек
Иновейшен Лтд (далее: «GMT» или «Компания»).
1.2.Цель данной Политики защиты личных данных – описать порядок сбора Компанией
информации о Пользователях её Услуг по их определению ниже, а также описать,
каким образом и для каких целей Компания собирает указанную информацию.
Данный документ составлен в мужском роде исключительно для удобства
формулировки, однако всё сказанное в нём касается обоих полов.
1.3.Условия Политики защиты личных данных, которые описаны ниже, являются
неотъемлемой частью Условий пользования. Компания вправе время от времени
изменять как Политику защиты личных данных, так и Условия пользования, - вместе
или раздельно, - с тем, чтобы они отражали технологические, коммерческие,
юридические или регуляторные изменения. Изменения, внесённые в Политику
защиты личных данных, будут публиковаться в этом документе, новая формулировка
которого будет размещаться на веб-сайте.
1.4.Прежде, чем начать пользоваться Услугами Компании, мы просим вас внимательно
прочитать Политику защиты личных данных, и убедиться в том, что она вам понятна.
Ваше использование Услуг Компании, включая пользование Сайтом, будет считаться
вашим однозначным и осознанным согласием со всеми условиями, оговорками и
уведомлениями, которые включены в Политику защиты личных данных. Если вы не
согласны с условиями Политики защиты личных данных, - будь то со всеми, или же
только с некоторыми из них, - мы просим вас не пользоваться Услугами Компании, и
в том числе не пользоваться Сайтом и / или мобильным приложением.

2. Определения
2.1.«Закон» - Закон о защите личных данных, от 1981 года.
2.2.«Услуги» или «Услуги Компании» - все услуги, которые предоставляются
Пользователю через Компанию и / или её агентов, как услуги, которые связаны с
передачей информации, так и те, которые не связаны с передачей информации,
включая пользование Сайтом.
2.3.«Агенты» или «Агенты Компании» - лицензированные представители Компании,
которые находятся по всей стране, и которые были обучены Компанией и получили
все разрешения, которые требуются для того, чтобы действовать от имени Компании,
включая поставщиков услуг, обладающих лицензией по Закону о надзоре над
финансовыми услугами (официальные финансовые услуги), от 2016 года, и / или
действительным разрешением продолжать заниматься деятельностью в данной
сфере, выданным Управлением финансовых рынков.
2.4.«Личные данные» - это любая информация или данные, которые вы по собственной
инициативе сообщите Компании на ее Сайте, в соответствии с целью вашего
обращения в Компанию, и которые вас идентифицируют или позволяют установить
вашу личность (например: ваше имя, адрес электронной почты, номер удостоверения
личности, платежное средство и т.д.), а также любая информация, которая является
«информацией» и / или «конфиденциальной информацией» по их определению в
Законе.
2.5.«Анонимная информация» - это статистические данные, которые не
идентифицируют или не позволяют установить личность Пользователя, и которые
связаны с конечным средством связи Пользователя, с которого он входит на Сайт
(например: порядок использования Сайта, тип браузера Пользователя, дата и время
вхождения Пользователя на Сайт, и т.д.)
2.6.«Вы» или «Пользователь» - любой человек, который пользуется Услугами
Компании (по их определению выше), в том числе при помощи компьютера и / или
другого конечного средства связи для любой цели.

3. Сбор Личных данных
В рамках использования Услуг от Вас может потребоваться предоставить Компании, а
Компания может получить Ваши Личные данные или данные о Вас различными
техническими средствами. Тип Личных данных, которые вы сообщите и / или которые
будут нами получены, может изменяться, в соответствии с причиной и / или целью
Вашего обращения к нам.
Настоящим разъясняется, что по Закону Вы не обязаны предоставлять нам эти
данные, и их предоставление осуществляется исключительно по Вашему
собственному желанию и с Вашего добровольного согласия для того, чтобы
получить необходимые Вам Услуги Компании. Вместе с тем, предоставление
некоторых из них требуется по закону (как указано в пункте 4.1.4), и необходимо для
того, чтобы предоставить Вам Услуги, о которых Вы нас просите, - всех или некоторых
из них.
Самим фактом использования и предоставления Компании Информации, Вы настоящим
заявляете и гарантируете следующее: (а) Личные данные, которые Вы сообщите на
веб-сайте, являются точными и принадлежат Вам. Вам известно о том, что сознательное
предоставление ошибочных или ложных данных может быть признано уголовным
преступлением; (б) настоящим Вы предоставляете Компании безотзывное, не
эксклюзивное, не ограниченное во времени, безвозмездное или не обеспечивающее право
на получение вознаграждения, подлежащее передаче право любым способом
использовать Информацию, как указано в Условиях пользования и в Политике защиты
личных данных, в соответствии с любыми законами, и исключительно по усмотрению
Компании, не получая от Вас дополнительное разрешение, и Вы не сможете предъявлять
Компании какие-либо претензии и / или денежные и / или иные требования и / или иски в
связи с указанным правом пользования.
3.1.Личные данные в рамках Услуг
    3.1.1. Информация, которую Вы предоставляете для осуществления операций на
    веб-сайте и / или через мобильное приложение: вначале Вам необходимо
    зарегистрироваться на веб-сайте (если Вы на нём ещё не зарегистрированы) и
    создать аккаунт Пользователя. В это время Вам потребуется сообщить о себе
    Личные данные разных типов, как например: номер удостоверения личности /
    заграничного паспорта; номер телефона, дату рождения, профессию,
    почтовый адрес, адрес электронной почты.
    3.1.2. Личные данные, которые Вы сообщите в рамках подачи различных просьб об
    использовании Услуг Компании (в том числе путем заполнения бланков):
    Личные данные, которые Вам будет необходимо предоставить, могут
    варьировать в зависимости от характера действия, которое Вы хотите
    совершить через Компанию. Так, например, для осуществления операций,
    связанных с денежными переводами, Вам потребуется сообщить такие
    данные, как имя бенефициара, которому Вы желаете перевести деньги,
    страна, в которую переводятся деньги и т.д. Если вы поручаете другому
    человеку действовать от Вашего имени, Вам потребуется предоставить Ваши
    данные и данные Вашего доверенного лица. В просьбах, связанных со
    страховкой, Вам потребуется, среди прочего, предоставить данные о
    состоянии Вашего здоровья, и т.д.
    3.1.3. Мы можем собирать и сохранять также дополнительную Информацию о Вас,
    как например: переписка с представителями и / или с автоматическими
    представителями, личный контент (например: темы денежных переводов
    получателю), чат в мобильном приложении, номер Вашего банковского счета,
    а также любая информация, которая связана с использованием Услуг. Эта
    Информация сохраняется для осуществления Услуг и в соответствии с
    Вашей просьбой.
3.2.Личные данные, которые Вы сообщаете о другом человеке. Если в рамках Услуг
Вам требуется предоставить данные о другом человеке (например, во время
осуществления денежных переводов Вам необходимо указать данные получателя),
Вы заявляете и гарантируете, что Вы имеете право и получили все необходимые
разрешения, которые требуются по любому закону, для того, чтобы сообщить
Компании Личные данные этого человека для их использования Компанией в
соответствии с данной Политикой защиты личных данных.
3.3.Использование Личных данных
В соответствии с Законом, Вам известно и Вы даёте осознанное согласие на то,
чтобы Компания получила и сохранила Ваши Личные данные для следующих целей и
видов использования (которые могут время от времени изменяться):
    3.3.1. Использование Информации для улучшения, управления и оперирования
    предлагаемыми Вам Услугами, связь с Вами, принятие решений по поводу
    Вас и других лиц, в том числе по вопросам предотвращения махинаций,
    выявления мошеннических схем и / или другой противозаконной
    деятельности, обеспечение безопасности Услуг, оперативные цели, оценка и
    контроль рисков.
    3.3.2. Если Вы попытались зарегистрироваться на нашем веб-сайте для того, чтобы
    воспользоваться нашими Услугами, а Ваша попытка не удалась, Компания
    будет вправе обратиться к Вам, чтобы помочь разрешить проблему, с которой
    Вы столкнулись во время регистрации.
    3.3.3. В целях рассылки рекламы потенциальным клиентам: Компания сможет
    использовать, обрабатывать, улучшать и обогащать указанную Информацию,
    в том числе при помощи поставщика Информации, для целей управления,
    рационализации, преобразования и распространения Услуг и товаров
    Компании, среди прочего путем создания Вашей характеристики, в том числе
    отправки Вам рекламных предложений, которые соответствуют Вашим
    характеристикам, а также использовать статистические данные, как указано в
    пункте 5 ниже.
    3.3.4. Компания может отправлять Вам оперативные сообщения и / или сообщения
    об Услугах, а также предупреждения – различными способами, среди прочего
    по электронной почте или в виде текстовых сообщений, например сообщений,
    в которых содержится идентификационный код для доступа к веб-сайту, и
    различные способы использования Услуг.
    3.3.5. Компания будет вправе обратиться к Вам, в том числе путем рассылки
    рекламных сообщений, и предлагать вам рекламные акции, скидки и льготы в
    связи со своими продуктами и Услугами по электронной почте, в виде
    текстовых сообщений и другими способами. Вы можете попросить, чтобы
    Ваши данные были удалены из рассылки маркетинговых обращений,
    отправив сообщение с заголовком «Просьба об удалении» по адресу:
    gmt-info@gmtonline.co.il

4. Передача Информации третьей стороне
Компания может время от времени передавать Информацию о Вас третьей стороне в
некоторых случаях, которые будут указаны ниже, и / или в любом другом случае, когда
потребуется такая передача, в том числе передача Информации за пределы Государства
Израиль:
4.1.В рамках сотрудничества с третьей стороной для завершения действий, о которых вы
попросили, и / или когда это требуется для предоставления Услуг, включая
поставщика услуг Облака. В этом случае может потребоваться передача
Информации также любой другой третьей стороне, которая участвует в Услугах
оплаты, включая банки, кредитные компании или производителей других платежных
средств, клиринговые компании и поставщиков платежных услуг или получателей
платежей.
4.2.В рамках аутсорсинга деятельности Компании и / или получения услуг от различных
поставщиков, включая компьютерные и программные услуги, обработку данных и
облако и т. д.
4.3.В рамках ее деятельности во взаимодействии со связанными с ней компаниями,
направленной на достижение целей использования, которые заявлены и разрешены
законом, для обеспечения Услуг.
4.4.Компания будет иметь право передать Личную информацию компетентным органам
Израиля или за его пределами и / или на основании судебного ордера и / или если это
потребуется для защиты прав и имущества Компании, и / или если такая передача
Информации требуется по любому закону, например данные, которые требуются в
силу законов о запрете отмывания капитала или о передаче донесений (регулярных и
/ или особых), которые Компания обязана подавать о своей деятельности и о
действиях пользователей ее Услуг, и при этом Компания не будет обязана
предупреждать Вас до того, как она подаст такое донесение и / или после этого, и это
исключительно по ее усмотрению.
4.5.Не нарушая общего характера сказанного выше, Компания будет вправе передать
Анонимную информацию третьей стороне, как описано в пункте 7.4 ниже, без
каких-либо ограничений. Так как речь идет о не идентифицирующей информации, по
которой Вас невозможно опознать, Вы освобождаете Компанию от любых исков и /
или требований и / или претензий по этому поводу.
4.6.Вам известно о том, что Информация может быть передана третьей стороне, которая
находится за пределами Израиля, в том числе в страны, которые обеспечивают
меньшую защиту личных данных, чем та защита, которая обеспечена по
израильскому законодательству. Используя Услуги Компании, Вы даете на это свое
сознательное согласие.
4.7.Компания гарантирует, что передача Информации третьей стороне будет ограничена
только до тех целей, которые перечислены в данной Политике защиты личных
данных, и только в том объеме и в той степени, которые, по мнению Компании,
требуются. Компания предпримет надлежащие меры для того, чтобы получатели
этой Информации были обязаны соблюдать конфиденциальность и обеспечивать
безопасность Информации, в соответствии с требованиями законодательства.

5. Сбор Анонимной информации
5.1.В то время, когда Вы используете веб-сайт и / или мобильное приложение, Компания
собирает Информацию, которую ей отправляет ваш компьютер, мобильный телефон
или другое средство связи, при помощи которого Вы пользуетесь Сайтом и / или
мобильным приложением, и это Анонимная информация, такая, которая Вас не
идентифицирует, и по которой Вас невозможно опознать.
5.2.Анонимная информация, которая нам отправляется, может, среди прочего, включать:
данные о страницах, которые посещали пользователи, тип устройства, данные
компьютера и его подключения, данные сети мобильной связи, статистические
данные о посещениях страниц, язык интерфейса, движение на Сайте и с него, адрес
URL, с которого Пользователь зашел на Сайт и т.д.
5.3.Компания изучает и использует Анонимную информацию, как самостоятельно, так и
через своих представителей, для указанных ниже целей (которые могут время от
времени изменяться): (а) оценка характера использования Сайта пользователями для
управления, улучшения и продвижения Сайта; (б) исправление недостатков; (в)
улучшение качества использования Сайта; (г) анализ и сегментация шаблонов
просмотра на Сайте; (д) подбор рекламы в соответствии с привычками просмотра; (е)
любая другая законная цель по усмотрению Компании.
5.4.Компания будет вправе передать Анонимную информацию третьей стороне, которая
действует от ее имени, для достижения указанных выше целей, а также для любой
другой законной цели по усмотрению Компании. Так как речь идёт о не
идентифицирующей информации, по которой Вас невозможно опознать,
Пользователь освобождает Компанию от любых исков и / или требований и / или
претензий в связи с вышесказанным.

6. Cookie-файлы
6.1.Cookie-файлы – это текстовые файлы, которые браузер Пользователя создает по
команде, поступающей с компьютеров Компании. Срок действия некоторых
cookie-файлов истекает, когда вы закрываете браузер или соответствующее
приложение, а другие сохраняются в памяти вашего устройства или компьютера.
6.2.К Вашему сведению, для предоставления некоторых Услуг Компании она использует
программы, собирающие информацию, такие как Cookie-файлы, например: pixels,
tags (далее вместе: «Cookies» и / или «Cookie-файлы») для текущей и бесперебойной
работы, для обеспечения безопасности содержащейся в них информации, и в том
числе для сбора статистических данных об использовании Услуг, для принятия
решений в отношении Услуг и / или вашей деятельности, для проверки данных и
идентификации, предотвращения мошенничества и жульничества, для повышения
качества использования Сайта, подбирая подходящий для Вас контент и услуги,
соответствующие Вашим личным предпочтениям, для подбора подходящих для Вас
продуктов, релевантной для Вас рекламы, даже в то время как Вы пользуетесь
другими сайтами, и вся эта деятельность осуществляется исключительно в
статистических, исследовательских, маркетинговых и коммерческих целях, и,
естественно, в целях обеспечения безопасности информации.
6.3.Cookie-файлы содержат и собирают различную информацию, такую как страницы,
которые Вы посетили, продолжительность Вашего нахождения на веб-сайте и / или в
мобильном приложении, с какого адреса Вы зашли на веб-сайт и / или на страницу,
ваш IP адрес, характеристики Вашего устройства, информация, которую Вы
запросили в момент посещения веб-сайта и др. Кроме того, существует вероятность
того, что Cookie-файлы сохранят информацию о Ваших привычках посещения других
сайтов, включая сайты, которые Вы уже посетили, страницы на сайтах и все другие
Ваши действия на них. Информация, которая сохраняется в Cookie-файлах,
закодирована, и Компания принимает меры предосторожности, чтобы только ее
компьютеры и компьютеры ее представителей могли прочитать и понять
накопленную в них информацию.
6.4.Услуги могут также использовать Cookie-файлы третьей стороны – например,
Cookie-файлы Google, такие как те, что устанавливаются при использовании Google
Analytics, и которые помогают нам подобрать для Вас формат пользования и сделать
статистические выводы в отношении всех пользователей Услуг. Дополнительную
информацию об этих Cookie-файлах Вы можете найти на сайте Google по адресу
https://www.google.com/intl/en/policies/technologies/ads/, а также в Политике
Cookie-файлов сети Facebook по адресу https://www.facebook.com/policies/cookies/.

7. Обеспечение безопасности информации
7.1.Для создания Вашей личной страницы на Сайте, Вам необходимо среди прочего
ввести пароль, который Вы выберете в соответствии с инструкциями, которые
фигурируют на Сайте и / или в мобильном приложении. Вход на Сайт
осуществляется с указанием личных данных и одноразового кода (OTP(, который
будет отправлен на Ваш мобильный телефон, номер которого зарегистрирован в
системе. Вы обязаны сообщить Компании о любом несанкционированном
использовании Ваших данных.
7.2.К Вашему сведению, любой вход на Сайт производится с указанием Ваших личных
данных (например – номера удостоверения личности и / или Вашего пароля, включая
любые личные данные, которые будут сообщены во время их использования) будет
считаться входом, который совершили Вы сами, и Вы освобождаете Компанию и
всех ее представителей от любых обязанностей или ответственности за
несанкционированное использование веб-сайта с употреблением Ваших личных
данных и за последствия такого использования.
7.3.Компания использует стандартные технологии защиты данных, и она примет все
необходимые меры для того, чтобы обеспечить безопасность Сайта, и чтобы об был
свободен и чист от вирусов и троянских «червей». Компания внедряет принятые на
рынке практики защиты Вашей личной информации от несанкционированного
доступа, использования и разглашения. Вместе с тем, Вы понимаете, что несмотря на
сказанное выше, невозможно предоставить абсолютную гарантию в отношении
Личной информации, которую Вы предоставите Компании (как той, что практически
хранится в базе данных Компании, так и той, которая хранится у поставщиков
Облака).
7.4.Поэтому Вы обязаны принять элементарные меры защиты на Вашем компьютере или
в Вашем мобильном телефоне, с которого Вы используете веб-сайт (включая
программу антивирус, блокирование компьютера, замену паролей и т.п.), чтобы
защитить свою Личную информацию.

8. Сохранение информации, право ознакомления, исправление и удаление данных
8.1.Как правило, Ваша Личная информация или информация, которая Вас касается,
будет храниться в базе данных Компании на протяжении периода времени, который
требуется Компании для обеспечения Вам Услуг, которые Вам требуются, - по
обстоятельствам, - в течение всего времени, пока у Компании есть легитимная
необходимость или законная или иная обязанность хранить ее в соответствии с
требованиями закона. Анонимная информация будет храниться в базе данных
Компании без ограничения времени (при условии, что это не запрещено законом).
8.2.Вы имеете право ознакомиться с Вашей Личной информацией, которая хранится в
базе данных Компании, - если она там хранится, - просить исправить данные или
удалить их, отправив в Компанию обращение на адрес gmt-info@gmtonline.co.il. Мы
постараемся выполнить Вашу просьбу в соответствии с нашими обязанностями,
которые возлагает на нас Закон. Однако та Личная информация, которая необходима
Компании для ее деятельности и / или которая требуется для легитимных целей,
таких как защита от исков (по необходимости), будет и впредь храниться в Компании
в течение того срока, который для этого определен в Законе.

9. Свяжитесь с нами. Вы можете связаться с нами по любому вопросу, с любой просьбой
или проблемой, связанной с Политикой защиты личных данных, написав обращение на
адрес gmt-info@gmtonline.co.il, и мы сделаем все возможное, чтобы ответить Вам в
кратчайший срок.

       </textarea>


       <textarea name="rules" id="rules" class="form-control" disabled dir="ltr" v-if="this.$store.state.locale.lang == 'en' || this.$store.state.locale.lang == 'th' || this.$store.state.locale.lang == 'cn'">
Terms of Use for the Site and the Services

GMT Tech Innovation Ltd. (the "Company" or "GMT") is a private company engaging in
financial services and specializing, inter alia, in foreign money transfers, F/X conversions,
card charging, and so forth.

The use of the Site and/or of the Company's Services is subject to these terms of use
(hereinafter: the "Terms of Use"), whether you use the Company's Services through the Site
and/or using any other means provided to you by the Company (including Service Providers
on its behalf, as defined below). The Company reserves the right to update these Terms of
Use from time to time.

Browsing the Company's Site and/or using its Services, through any means whatsoever, may
involve the provision of Information to the Company and/or the collection of Information
about the User (including Personal Information), including Information collected by third
parties for the Company, and the provisions hereof will be deemed to apply to such
Information as well. The Information saved on the Company's databases will be subject to
the privacy policy available for review at gmtonline.co.il. Both the Terms of Use and the
privacy policy constitute the legal basis in connection with your user of the Site and/or of the
Company's Services.

The Terms of Use apply in addition to any additional term and/or provision which will be
published on the Site or in another transaction document, and the use of certain parts of the
Site may be subject to additional rules which will be listed in such parts.
Browsing the Site and/or using the Company's Services will be deemed to be express and
informed consent on your part to all the terms, stipulations and notices included in the
Terms of Use and the privacy policy. If you do not consent to the terms of the privacy policy
and/or the Terms of Use, all or part thereof, do not use the Site and/or the Company's
Services.

The Terms of Use were written in the male gender for purposes of convenience, but they
refer to men and women equally.

1. Definitions
1.1. "You" or "User" – Any person who browses the Site using a computer and/or
other end device, for any purpose whatsoever, including a Client ("Company's
Services" and "Client", as defined below).
1.2. "Website" or "Site" – GMT Tech Innovation's website, maintained at the URL
gmtonline.co.il (or at any other URL as may be from time to time), which allows
the User, inter alia, to perform actions in accordance with the Company's
Services.
1.3. "Content" or "Contents" – Any Information of any kind and sort, including any
verbal, visual, audio, audiovisual content or any combination thereof, and the
design or processing or display thereof in any manner whatsoever, including
any image, drawing, animation, photograph, schematic, model, figure,
simulation, video, audio file, music file, software, computer code, application,
protocol, article, database, interface and any character, sign, symbol and icon
appearing and/or integrated on the Site by the Company, all whether they are
owned by the Company or by a third party who grants the Company the right of
use thereof.
1.4. "Information" – Any information which you provide to the Company on the Site
and/or by any other means, including Personal Information.
1.5. "Personal Information", "Anonymous Information" – As defined in the
Company's privacy policy, available for review at gmtonline.co.il.
1.6. "Services" or "Company's Services" – All the Services provided to the Client
through the Company and/or Service Providers on its behalf, including money
transfers, F/X conversions, sale and charging of credit cards, installment credit,
and so forth.
1.7. "Client" – Any person who uses the Company's Services, through any means
whatsoever, including the Website and/or frontally through the Company's
Service Providers.
1.8. "Service Provider" – Authorized representatives on the Company's behalf, who
are deployed across the country and who have been authorized by the
Company and have obtained all the certificates required in order to act on its
behalf, including service providers licensed under the Supervision of Financial
Services (Regulated Financial Services) Law, 5776-2016 and/or an effective
Continued Practice Certificate issued by the Capital Market Authority.
1.9. "Business Day" – A business day of the Company, as regards payment services
provided to the Company's Clients, is every day, other than Saturday, sabbatical
days, the two days of Rosh HaShana, Yom Kippur Eve and Yom Kippur, the First
Day of Sukkot and Shemini Atzeret, Purim, the First and Seventh Day of
Passover, Independence Day, the Feast of Shavuot and Tisha B'Av, or another
day determined by the Supervisor of Banks at the Bank of Israel or determined
under any law as a day which is not a business day as aforesaid.
"F/X Business Day" – An F/X business day is a day on which the Bank of Israel
publishes new representative rates of the currency of deposit, and in the
absence of such publication, the last rate customary at the Bank of Israel will
apply.

2. Company's operating hours
2.1. The Company will provide its Services through the Service Providers on its
behalf on various Business Days and at the Company's headquarters on
Business Days from 08:30 to 18:30. On Fridays, holiday eves and on any other
day which is not a Business Day, the Company will provide its Services at the
Company's headquarters between the hours 08:30 – 13:30 (hereinafter: the
"Operating Hours"). It is clarified that the times of operation regarding the
performance of the actions will be in accordance with the Business Days on
which the Company's headquarters is operating, and regarding F/X actions – in
accordance with the F/X Business Day and the headquarters' Operating Hours
as aforesaid.
2.2. The Company may update the Operating Hours, in accordance with various
Services and in accordance with various contact methods, types or various
service channels. The Operating Hours will be published on the Company's
Website and will be updated from time to time at the Company's discretion.
2.3. Notwithstanding the above and subject to any law, the Company may
determine from time to time what Business Days will apply regarding various
Services, dates and opening and closing hours regarding buying or selling or
performing actions in foreign currency, in shekels, in currency-linked, credit, on
certain days only, or at limited and certain hours only during any Business Day –
all with respect to any day or days or periods and dates.
2.4. A payment order (as defined in the Payment Services Law, 5779-2019) or an
order to perform an action which is received after the time on which the
Business Day ends, whichever is earlier, will be deemed to have been received
on the next following Business Day.

3. Right of use of the Site
3.1. Subject to your compliance with the Terms of Use, the Company hereby grants
you a limited, personal, nonexclusive, nontransferable and unassignable and/or
non-sublicensable, nonnegotiable and revocable license to use the Site
(including any Content or Service offered thereon) for your own personal and
private use alone, and not for any commercial use of any kind whatsoever, and
for legal purposes alone. You are prevented from using the Site or any part
thereof for any illegal purpose whatsoever.
3.2. By merely using the Site or any part thereof, You represent and warrant that
You will not perform any of the following actions (whether yourself or through
anyone on your behalf) on the Site and/or on any part thereof: (A) Use the Site
for any illegal purpose and/or contrary to the Terms of Use or abuse the Site or
any part thereof; (B) Copy, reproduce, reverse engineer or create derivatives,
modify, translate the Site (including any Content or Service offered thereon);
(C) Bypass technical barriers on the Site, hide notices regarding the protection
of the intellectual property rights of the Company (and/or anyone on its behalf)
on the Site; (D) Perform an action of any kind whatsoever which will, or might,
interfere with the proper operation of the Site and/or prevent access to
Contents and/or Services by other Users of the Site; (E) You may not activate,
or allow the activation of, any computer application or any other means,
including software of the type of crawlers, robots and the like, for the purpose
of searching, scanning, copying or automatic retrieval of Contents from the
Site, and within this scope, You may not create a database by downloading
and/or storing Contents from the Site; (F) Plant or introduce into the Site any
virus, "worm", trojan horse, bug, code, application or software which might
harm, or which are intended to harm, the Site's operation; (G) Violate any of
the Terms of Use.
3.3. For the sake of the convenience of the Site's Users, the Site may offer
links/referrals to websites of third parties. In the event that You use such
links/referrals, You will leave the Site. The Company does not warrant that such
referrals and/or links will lead to active sites, has not checked all such
third-party sites, has no control over them and is not liable for such sites or for
the content thereof. Therefore, the Company does not make or provide any
representation whatsoever regarding them or regarding any Information,
Services or Contents located thereon, or regarding any consequence which
might arise from the use thereof, including from the Services offered thereby.
The decision to access any third-party site linked to the Site is at your own risk
alone. When you proceed to the links, you must review the Terms of Use and
the other terms and instructions provided on the linked site and follow the
provision thereof.

4. Intellectual property rights
4.1. The Company is the sole owner of the Site, its Content, design, planning, file
structure, the logo and the trademarks appearing thereon.
4.2. The Site's design and display, including the Contents located thereon, are
and/or may be protected by copyrights and/or trademarks, patents or other
intellectual property rights of the Company and/or of third parties, and You are
prohibited from copying them, all or part thereof, or reproducing the Site or
any part thereof, distributing them, selling them, permitting their use,
displaying them in public, creating derivatives of them, performing them,
advertising them, or using them for any commercial purpose whatsoever.
Additionally, you may not create frames for the Site or use metatags or any
other hidden text while using the Company's name or trademarks and/or of
anyone on its behalf. Any unauthorized use will incur, inter alia, the revocation
of the Terms of Use and the termination of your right to use the Site.
4.3. For the avoidance of doubt, other than the right of use granted to You in the
Terms of Use, the Company does not grant You any right whatsoever to the Site
and/or to part thereof, including to the intellectual property of the Company or
of third parties.

5. Use of the Company's Services
5.1. The use of the Services offered by the Company will be in accordance with law
and will be made in good faith and in a customary way. They may not be
abused or used in any way contrary to law or to public policy or used
unreasonably in any way or for purposes other than those for which the Service
is intended, including the making of illegal or prohibited money transfers or
transfers intended to bypass the restrictions pertaining to reports in connection
with the prohibition on money laundering and terrorist financing or
impersonation of any person or other legal entity. Without derogating from the
Company's rights, anyone infringing such prohibition will indemnify the
Company for any damage incurred by it in consequence thereof.
5.2. The source of the funds used for the performance of the Services is legitimate,
legal and is not related to any infringement of the laws of the State of Israel
and/or any other country. The liability for the nature of the action is imposed
on the User alone, and he is required, before making the transaction, to check
the meaning thereof and the provisions of law pertaining thereto.
5.3.
5.4. The Company and its activity are subject to various provisions of law whereby it
is obligated to prevent embezzlements and frauds pursuant to the provisions of
international law applying thereto by virtue of agreements with third parties.
The following cases may indicate fraud, and raise concern regarding the
transaction's authenticity, and therefore You are requested to avoid making
transactions under similar circumstances:
    5.4.1. If the transaction involves an entity unfamiliar to You and/or whose
    identity You cannot verify;
    5.4.2. If You have been asked by a third party who is unknown or unfamiliar to
    provide partial or full Information about your actions;
    5.4.3. If You are offered Services and/or products at prices significantly lower
    than the customary market prices;
    5.4.4. If You have been made offers and/or offered promotions and/or
    participation in raffles and/or have been notified of winning by an entity
    unfamiliar to you;
The above list of cases is a partial, non-exhaustive list, based on the Company's
experience. The Company and anyone on its behalf will not bear any liability
whatsoever should You choose to disregard the above warnings and make
transactions despite the concern arising therefrom.
5.5. Without derogating from the above, no use may be made of the Services for
the purpose of making transactions or paying for Services/products according
to the following specification:
    5.5.1. Transaction in virtual currencies / cryptocurrencies.
    5.5.2. Loan or credit transactions or the provision of financial services of any
    kind whatsoever, including money changing or money conversion as an
    occupation.
    5.5.3. Adult Content and Services.
    5.5.4. Gambling.
    5.5.5. Drugs.
    5.5.6. Diamond trade.
    5.5.7. Arms trade.
    5.5.8. Transactions in securities, binary options and forex.
    5.5.9. Paid sex Services and human trafficking.
    5.5.10. Any activity prohibited under law, including money laundering and
    terrorist financing and including any activity requiring licenses or
    permits – if the relevant parties do not have all the required licenses and
    permits or they are invalid.
5.6. In any event of use contrary to the provision of the above Section 4.2 and
contrary to the provision of the other provisions of the Terms of Use, including
in the event that the User's activity has been blocked/restricted, or in the event
that one or more of the means of payment listed in the User's personal account
are invalid, have been blocked or restricted for use in any way whatsoever, the
Company will not bear any liability toward any user or third party or any direct
or indirect damage incurred by them in connection therewith. For the
avoidance of doubt, it is clarified that the Company will not allow the making or
completion of transactions and payment for Services as set forth in this section
(including in the event of suspicion by the Company in connection therewith)
and/or any use whatsoever of funds, including withdrawal or transfer in any
way of funds originating in such transactions and Services.
5.7. The Company may contact any User at any time in order to clarify any detail
related to the uses made of the Services. In any such case, the User warrants to
cooperate and provide the Company with the Information required.
5.8. The Company may restrict and/or modify and/or revoke the provision of the
Services or part thereof from time to time (including limiting the amounts of
the transactions, making changes to the list of countries of destination, and so
forth). Without derogating from the aforesaid, it is clarified that since the
actions performed by the Company are subject to Israeli and international
provisions of law, and orders and guidelines promulgated by virtue thereof,
which refer, inter alia, to provisions regarding the prohibition on, and
prevention of, money laundering and terrorism and to provisions pertaining to
the taxation of the actions performed by the Company. In light thereof, there
may be situations in which actions will not be performed and/or will be
modified and/or reported to the relevant entities in accordance with the law
even before they are performed.
5.9. The Company may stipulate the use of the Services or prevent the Company's
Services to the Client, either generally or with respect to certain Users, when
use is made of certain means of identification, or with respect to a certain
transaction. The Company may also block the Client's activity on the Site or
anywhere else until general details of Information pertaining to the Client or in
connection with a certain transaction are supplemented.
5.10. Some of the Services cannot be cancelled, including in the event that the
cancellation request originates in an error such as the provision of erroneous
Information, a clerical error by the User or by the Company, and so forth. The
User represents that before confirming the action, he rechecked all the
Information requested for the purpose of the Services' provision, and that the
Company will not be liable for errors originating in inaccuracy and/or errors in
the provision of the details requested by the User.
5.11. The date of the actions' performance will be within two Business Days
following the Business Day on which the Client issued the order, and regarding
F/X transactions – two F/X Business Days following the F/X Business Day on
which the Client issued the order.
5.12. Some of the Services are provided using the systems of MoneyGram
International Ltd. Co. (hereinafter: "MG"). The User is aware that the
performance of the actions through the Company will be subject, inter alia, to
the MG's provisions and articles, including its provisions regarding protection of
privacy, which can be found at the link: https://start.moneygram.com/. In any
event of a contradiction between these articles and MG's articles, it is clarified
that the stricter provisions will prevail.

6. Taxes and reports
6.1. The User is liable for every tax payment arising from any payment or
transaction he made or received through it, and for any report required under
any law in connection with a transaction, and the Company is not liable for all
these.
6.2. The User is aware, and agrees in advance, that subject to law, the Company
may provide various details and reports regarding his actions to authorities in
Israel and abroad (including to the Tax Authority), insofar as the Company is
required to do so.
6.3. The User is aware that the Company may delay or revoke the making of a
transaction due to failure to provide Information regarding tax payments
relevant to the action.
6.4. The User is aware that the Company is a financial institution as regards VAT.

7. Commissions and additional payments
7.1. The Company will collect payments and/or commissions for the use of the
Service, at such rates and on such dates as are prescribed by the Company's
price list set forth on the Site, which may be updated from time to time
according to the Company's discretion, all subject to law.
7.2. Before making the transaction, the User must check all the costs and
commissions involved therein, and the alternative market costs for the
performance of the action, and the User will have no claim toward the
Company in this regard.
7.3. In the event that the making of the payment occurs on a date subsequent to
the date of the transaction's dispatch, changes may occur in the transaction's
price due to a change in the rate of exchange, and the User will bear the
payment according to the current rate.
7.4. In the event that the Company discovers that an error has occurred in the
calculation of the rate of exchange and/or in the amount of the commission
paid to the Company, according to its sole discretion, the Company will notify
the Client of the error (hereinafter: the "Correction Notice") and the Client may
revoke the transaction for no additional cost, or alternatively, may ratify the
transaction according to the terms presented to him in the Correction Notice.
The User represents that he is aware that the Services may involve additional
payments and/or commissions, which will be collected by third parties
independent of the Company, such as credit card companies, correspondents,
and so forth.

7.5. Payment in cash or by wire transfer:
    7.5.1. The option of paying in cash and/or by wire transfer is only available for
    some of the Services. The provision of the requested Services by the
    Company is contingent upon the receipt of the payment from the Client
    on the dates prescribed by the Company. You may remit the payment
    for the Services directly to the Company, by wire transfer from the
    User's account at an Israeli bank alone or by payment in cash to any of
    the Company's Service Providers. The Company may collect an
    additional commission for processing of cash, whether in NIS or in F/X.
    7.5.2. The User will have no claim toward the Company in the event of
    cancellation and/or a delay in the provision of the Services on the part of
    the Company in any event where the payment to the Company was not
    made by the User on the date defined in advance by the Company
    and/or is delayed in circumstances not dependent on the Company.
7.6. A payment by wire transfer may not be revoked. Funds received in the
Company's account by wire transfer for the purpose of the Services' provision
will not be returned to the User.

7.7. Payment by credit card:
    7.7.1. The User represents that he is aware that the credit card company's
    certification is a condition for the performance of the Service requested
    by him, and he will have no claim toward the Company in the event that
    the transaction is not carried out as a result of the credit card company's
    refusal to make the transaction.
    7.7.2. The User is aware that the Company may exercise mechanisms for the
    prevention and reduction of cases of fraud, bad faith denial of
    transactions, abuse of credit cards of various kinds, and so forth, such as
    sending SMS messages to the User for the purpose of certifying credit
    transactions.
    7.7.3. Other than the service commission which will be collected by the
    Company, it may be that You will be charged with additional
    commissions collected in accordance with the agreement between you
    and the credit card company or any other third party whom You
    engaged in connection with the credit card in your possession, and the
    credit card company may also charge You with a special commission
    such as a "commission for the purchase of foreign currency from a
    money changer". For the avoidance of doubt, the Company has no
    connection to and/or influence over the commissions collected by such
    third parties, and they will be paid by the User in accordance with the
    agreement between him and such third parties.
    7.7.4. Payment by credit card in an installment transaction:
        7.7.4.1. The User may pay for the Services by means of a credit
        payment plan subject to payment of interest which will be
        added to the price (hereinafter: "Payment Interest").
        7.7.4.2. By giving his consent to the articles of use, the Client
        approves the credit terms prescribed by the articles as credit
        terms supplementary to the specific terms applying to the
        transaction.
        7.7.4.3. Before making the transaction, you will be presented with
        the installment transaction's Information as required under
        law, including Information pertaining to interest, real cost,
        rate of interest in arrears, the payment schedule, and so
        forth. You must approve the transaction's Information in
        writing by means of frontally signing and/or by SMS
        (hereinafter: the "Installment Transaction").
        7.7.4.4. The Client is aware that the interest for the installment
        credit is collected entirely separately from the rate of the
        commission for the F/X conversion determined separately by
        the agent making the conversion, and is not part of the
        credit cost.
        7.7.4.5. In the event that the Fair Credit Law, 5753-1993 (hereinafter:
        the "Fair Credit Law") applies, the rate of the real cost of
        credit in the transaction will not exceed the rate of the
        credit's maximal cost, as defined in the Fair Credit Law, at
        the time of the specific transaction's making and as set forth
        in the transaction's Information. Additionally, the rate of
        interest in arrears known on the date of the transaction will
        not exceed the maximal rate of interest in arrears as defined
        in the Fair Credit Law and known on that date and as set
        forth in the transaction document.
        7.7.4.6. The Client hereby represents and warrants not to directly or
        indirectly cause the revocation of the credit card which is the
        subject of the transaction and not to cancel the transaction,
        all or part thereof, as long as it has not been paid in full.
        7.7.4.7. For the avoidance of doubt, failure to make any of the
        payments by credit card undertaken by the Client will
        constitute fundamental breach of the agreement between
        You and the Company, which will take all measures available
        to it in the event of a breach of the agreement on your part.
        7.7.4.8. Actual repayment on the day specified for making the
        payment will be deemed to be actual payment.
        7.7.4.9. A payment not made timely will bear interest in arrears at
        the rate prescribed in the transaction, calculated from the
        date of the payment's making until the actual repayment
        (hereinafter: "Interest in Arrears") and with additional
        collection expenses including costs of action and attorney's
        fees, if any apply (hereinafter: the "Collection Expenses").
        Furthermore, the Client warrants to pay the unpaid amount
        within 3 Business Days of the day of the first demand.
        7.7.4.10. The Client may accelerate the making of the
        payments, all or part thereof, by advance notice of
        at least 3 Business Days. In such a case, the
        Company will repay the Client the amount of the
        credit interest collected in advance after deduction
        of an early repayment commission, as determined
        at the time of the transaction's making. In the event
        that the Client wishes to accelerate the making of
        the payments by advance notice of less than 3
        Business Days, the Company may make the early
        repayment against collection of an additional
        commission for the failure to give the advance
        notice as determined at the time of the Installment
        Transaction's making.
        7.7.4.11. The Client warrants, within 21 days of the first
        demand, to pay to the Company, by means of a
        direct deposit to the Company's bank account, or by
        payment in cash at one of the Company's agents,
        the amount specified in each of the payments, even
        if the date for their making has not yet arrived (with
        the additional Interest in Arrears, linkage
        differentials and Collection Expenses), this being
        when one of the following cases occurs: In the event
        that one of the payments has not been made,
        despite a demand by the Company; if it becomes
        clear that the representations made by the Client
        and/or anyone on his behalf herein or in the
        transaction documents, all or part thereof, are
        untrue; if the risk of the non-making of the
        payments has increased at a considerable rate; if
        there has been another fundamental breach hereof
        or if other conditions requiring the immediate
        performance of such an action regarding the making
        of the balance of the payments have been met; if
        there has been appointed for the Client and/or for
        any of his shareholders or for their property or for
        part thereof, or if a motion has been filed to
        appoint, a receiver and/or administrator and/or
        liquidator and/or temporary liquidator or trustee or
        any other official appointment and the motion has
        not been removed within 14 days, or if a motion of
        liquidation or stay of proceedings or bankruptcy has
        been filed against any of them, or if a receivership
        order or liquidation order has been issued against
        him or if he has been declared bankrupt; if an
        attachment has been imposed over the Client's
        assets, and the attachment is not removed within 21
        days of the day of its imposition, or if the owners of
        charges over the Client's assets or a material part of
        the Client's assets realize the charges, or if an
        execution action is carried out against all the Client's
        assets or a material part of the Client's assets, and
        the action is not revoked within 21 days of the day
        of its making; if there is reasonable concern, in the
        Company's opinion, that such incident or
        circumstances as aforesaid are expected to occur,
        this being the case whether or not the occurrence of
        such incident or the formation of such
        circumstances are dependent on the Client,
        including, inter alia, absence from work and/or
        leaving the country for a period of over 14 days.
        7.7.4.12. The Company may sell and/or transfer and/or
        endorse and/or assign and/or deduct and/or
        securitize the payments, all or part thereof, to any
        entity whatsoever, on such terms as it deems fit,
        and the Client grants his advance consent thereto.
        The Client may not assign and/or transfer his
        obligations and/or rights hereunder, unless the
        lender has given its advance written consent
        thereto.

8. Terms of Use for various types of Services
The use of the Company's Services is subject, inter alia, and without derogating from
the provision of the general Terms of Use, to the terms specified in Annex A hereof
(appearing consecutively at the end hereof). By confirming the Terms of Use, you
also confirm the terms for such Services.

9. Liability
9.1. The Site, the Services and the Contents appearing thereon may be used as
available / as is, and without any warranty or representation on the part of the
Company regarding their suitability for the User's needs, for a certain purpose,
of the updatedness and/or correctness of the Contents and/or of there being
no breach of copyrights. The use of the Site and of the offered Services is made
at the User's risk alone, and he is aware that he is not obligated to use such
Services. The Company will not bear any liability toward the Users and toward
third parties on all that pertains to any transaction in the course of which the
Company's Services were used, including on all that pertains to the
transaction's purposes.
9.2. The Company does not warrant that the Site or any of its parts will operate
continuously, properly and that the access thereto will be available and/or free
of malfunctions and/or uninterrupted (including due to regular maintenance of
the Site or for the purpose of the treating of malfunctions). Accordingly, no
claim will be heard of being unable to timely perform actions on the Site (such
as a delay in the making of a transfer or any other action which has a target
performance date) due to a malfunction of the Site, and You exempt the
Company from any suit and/or claim and/or demand You may have in
connection with the aforesaid.
9.3. The Company is in no way liable for the disruption of a User's Information
provided on the Site and/or for the nonreceipt of an application on the Site,
failure to respond to an application, for any delay in responding to an
application and/or nonreceipt of sufficient Information on the Site. We will
make every effort to respond to your application to us as soon as possible.
9.4. You may not rely on the Contents and/or the Services and/or on any advice,
recommendation or opinion received through the Site (if and insofar as any
have been received) for the purpose of making any decisions whatsoever,
including on economic, professional, commercial, personal, financial and other
matters.
9.5. Without derogating from the above, in no case will the Company (including its
employees, managers, and anyone on its behalf) be liable for any damages
whatsoever (including, but not limited to, direct, indirect or consequential,
including damages arising from loss of profits, loss of data or disturbance of the
course of business, injury to goodwill and so forth in connection with, and/or as
a result of, use of the Company's services, of an inability to use the Services or
of the consequences of using the Services (including any of the Contents and/or
Services offered thereon), and/or use of, or inability to use, any other site
linked to the Site, or Contents included on the Site and on any of the sites
linked to the Site (including reliance on such Contents), and/or as a result of
exposure to computer hazards (including viruses) and/or due to prejudice of
intellectual property rights of any kind whatsoever (whether registered or not),
all being even if the Company has already received Information regarding the
possibility of the occurrence of such damages, whatever the cause of action or
the reason for the damage may be, whether contractual, tortious or other.
9.6. The Company will have a right of offset and lien over all the funds which the
User is, or will be, due and which are held by the Company from time to time,
and he may offset, from any amount which the User is due, any debt or
obligation it has toward the Company as a result of using the Services, and the
Company may also delay the making of any money transfer to the User's credit
until all his aforesaid obligations toward it are met, including in the event that
the date for their fulfillment has not yet arrived. It is clarified that an entry
specifying the offset/deduction on the account in the personal zone will be
deemed a notice of offset under law, so that no additional notice in this regard
will be required; and that the User will not have a right of lien/offset against
the Company.

10. Customer service and making contact
10.1. You are welcome to contact the Company through the support link provided
on the Site by email at gmt-info@gmtonline.co.il or by telephone at
03-3722800 on any question related to the Service.
10.2. Please note that telephone calls with Customer Service may be recorded, kept
in a database of the Company or of anyone on its behalf, and used for the
purpose of documentation, inquiry, improvement of the Service, control and
risk management.

11. Miscellaneous
11.1. The Site and the Company's Services are intended to be used by an adult (over
the age of 18) within the sovereign territory of the State of Israel, and are not
intended for minors. If you are a minor (under the age of 18), your very use of
the Site will constitute evidence to your having obtained approval from your
parents or a legal guardian appointed for you to make such use.
11.2. The Terms of Use are subject to Israeli law alone. The competent court in the
Tel Aviv District will have sole jurisdiction to hear any dispute and/or conflict
regarding this agreement, the enforcement and interpretation thereof, and no
other court will have jurisdiction.
11.3. You must visit the Site from time to time in order to review the updated Terms
of Use, seeing as they are the binding ones from the date of the update and
onward (the date of the latest update is listed at the top of the Terms of Use).
Notices and/or designated legal terms located on certain pages of the Site may
prevail over certain stipulations included in the Terms of Use.
11.4. A waiver of rights in any event wherein the Company does not use one or more
of its rights hereunder will not be deemed to be a waiver or acceptance on the
part of the Company and will not constitute an impediment against it.
11.5. If it is determined that any part of the Terms of Use is invalid or unenforceable
under law, including, but without limitation, the abovementioned limitation of
liability clauses, then the invalidated or unenforceable sections will be deemed
to have been superseded by valid and enforceable sections the Content
whereof matches, to the greatest extent of approximation, the intent of the
original sections, whereas the remaining sections of the Terms of Use will
remain in force and effect.
11.6. The Company may assign, endorse or transfer its rights or obligations, all or
part thereof, to any third party, but the aforesaid will not prejudice a User's
rights in connection with funds held at his disposal, all as set forth in the Terms
of Use.
11.7. The User may not assign, transfer, endorse or charge his rights and obligations
or part thereof, by virtue of these Terms of Use or any other agreement, to any
third party whatsoever.

Annex A –
Terms of Use for Various Services

1. Money transfers
1.1.The User (hereinafter: the "Transferor") will be fully liable for filling out true and
accurate Information and will ensure that the recipient chosen by him for the
making of the transfer is the correct recipient (hereinafter: the "Recipient"
and/or the "Beneficiary"). In the event of an error in entering the Beneficiary's
Information or the Information of the transferring User, the Company will bear
no liability whatsoever, including for the cancellation of the transfer and for the
return of the funds.
1.2.Upon completion of the making of the transfer order (hereinafter for the
purpose of this section: "Transaction" and/or "Transfer Request"), a Transaction
summary will be displayed to You, wherein will be displayed the details of the
Transfer requested by You, including the receipt amount expected to be
received by the Beneficiary and a secret code (hereinafter: the "Secret Code").
1.3.Please note that the receipt amount displayed to You is merely an estimated
amount, which may change due to various factors such as conversion rates at
the country of destination, correspondents' commission, a commission collected
by third parties as part of the relationship between them and the transfer's
Recipient, and so forth. The Company will make a money transfer in the amount
undertaken by it in the transaction summary, but it will not be liable for a
change in the amount actually received by the Recipient arising from such
factors that are beyond its control.
1.4.Transfers of funds withdrawn in cash. If You choose to make a money transfer
which will allow the Beneficiary to withdraw the funds in cash, you will have to
send the Secret Code to the Beneficiary. The Beneficiary will be able to withdraw
the funds through a correspondent, a business partner and/or a business abroad
subject to presenting the Secret Code. The responsibility to keep the Secret
Code and send it to the correct Beneficiary is the User's alone, and You will have
no claim toward the Company in any case of loss and/or theft and/or use of the
Secret Code by one who is not the Beneficiary, subject to the liability restrictions
prescribed by Sections 24-27 of the Payment Services Law, 5779-2019. In the
event of loss, theft and/or concern of abuse of the Secret Code, you must
contact the Company's customer service immediately upon learning thereof.
Customer service will process your application according to the circumstances of
the specific case. Processing by customer service may involve payment of
additional commissions.
1.5.Cancellation of a transaction. Subject to law, any money transfer may only be
cancelled up to the date of the funds' transfer to the Beneficiary's payment
Service Provider (which transfer may be made immediately following the
transfer request). In order to cancel a transfer request, you must contact the
Company's customer service. It is clarified that if you performed the action
through a Service Provider, you must ensure that the Service Provider has
contacted the Company to have the action cancelled. The cancellation process
may involve a commission. Your money will be returned to You by means of
crediting the credit card used to make the transfer request and/or in cash,
according to the means of payment selected by You when making the
transaction. The Company's certification at the end of the cancellation process
constitutes a reference of the transfer request's cancellation.
1.6.The Company may prescribe restrictions regarding transfer amounts, which may
change from time to time, according to the Company's sole discretion.
1.7.Money transfers using chargeable cards:
    1.7.1. The Company is not liable for the nature and quality of the chargeable
    card in your possession, for the Information You received regarding it
    when purchasing it, for your ability to use it and/or for the customer
    service in connection with the card.
    1.7.2. The money transfer using a chargeable card is subject to certification by
    the clearing company and to the balance accumulated on your card. In
    any event, you warrant not to try to make money transfers in amounts
    whose value exceeds the balance amount accumulated on your card at
    the time of making the transfer.
    1.7.3. A money transfer may not be paid for using a chargeable card under a
    payment plan.

2. F/X conversion
2.1.The conversion rates vary from time to time and are determined by the
Company according to its sole discretion, in accordance with various factors of
international commerce.
2.2.On converting currency at the Company's concessioners, the currency rates may
vary from one concessioner to another according to the concessioner's
discretion, which is subject to instructions and restrictions issued by the
Company.
2.3.On performing an action of purchasing F/X in cash through a Service Provider of
the Company, you must check the propriety and amount of the bills vis-à-vis the
Service Provider before leaving the counter. The Company will not be liable for
the propriety of the bills.

3. Prepaid cards
The Company, in collaboration with Max It Finances Ltd. (hereinafter: "Max"), allows
You to purchase and charge an international prepaid chargeable card service.
3.1.The purchase and use of a chargeable card are subject to the terms listed in the
transaction documents which You will be required to sign on purchasing the
card, which constitute an integral part of these Terms of Use (hereinafter jointly:
"Terms of Use for Chargeable Cards"). The Company is not liable for the card's
ongoing clearance.
3.2.The option of purchasing a chargeable card is by physically arriving at one of the
Company's agents or by any other way which the Company will advertise from
time to time.
3.3.The supply time of a chargeable card bearing an embossed name will be 5-6
Business Days.

4. Cashback
4.1.The "Cashback" service allows You to accumulate a monetary balance in your
personal zone (hereinafter: "Accumulated Balance" for money transfer services
you make through the Site and/or through the Company's agents (hereinafter:
"Entitling Transaction"). The Company may operate this Service for additional
Services provided by it, as it may determine from time to time according to its
sole discretion, and these terms will apply mutatis mutandis.
4.2.Not every money transfer will constitute an Entitling Transaction. The decision
which money transfers will constitute Entitling Transactions is given to the sole
discretion of the Company. Upon completion of every money transfer request,
the Company will notify You whether the transaction constitutes an Entitling
Transaction.
4.3.You may learn the Accumulated Balance available to you at any time by
presenting an ID to one of the Company's agents and/or in your personal zone
on the Company's Website.
4.4.The Accumulated Balance is personal and is not transferable from one User to
another.
4.5.The accumulation of the funds will be made according to the currency in which
the Entitling Transaction was made (dollar, shekel or euro).
4.6.The monetary balance can only be utilized for money transfers, according to the
currency of its accumulation, and the accumulated funds cannot be converted
from one currency to another.
4.7.The minimal amount of money utilizable in each transaction is 4 dollars. The
utilization of the funds in a specific transaction will not exceed 50% of the
transaction's amount.
4.8.The monetary balance will be available to You for a fixed period of time which
may change from time to time, according to the Company's discretion, for 3
months from the date of the latest action You made in your account. If you do
not utilize the balance of the funds accumulated by You within 3 months of the
last utilization date, the Accumulated Balance available to You will be written
off.
4.9.In the event of the cancellation of a transaction, the balance of the accumulated
funds utilized by You in the cancelled transaction will be returned to your
account in the personal zone.

5. Foreign transfer service by means of authorization to charge an account
5.1.The User may join the foreign transfer service by means of an account charge
authorization, provided that he has signed an order form and has authorized the
Company to charge his account.
5.2.Authorization to charge the Client's account will be subject to the terms
customary at the bank where the Client's account is maintained.
5.3.The Company will act to charge the Client's account, on such date, in such
amount and according to such additional Information as the Client will provide
to the Company in a form signed by his hand.
5.4.The transfer order will be carried out provided that the charge has been
honored in full, that no charge rebate has been received for any reason
whatsoever, and that the consideration for the transfer has been actually
received in the Company's account, pursuant to the order issued by the
Company.
5.5.In any case where the foreign transfer has been made but the consideration
received therefor has been cancelled or is insufficient, the Company will contact
the Client to have the consideration supplemented. The Company will act to
receive the consideration according to such means as are at its disposal and
according to any law.
5.6.The amount of the charge will be calculated according to the amount which the
Client requested to transfer + a transfer commission for such action, multiplied
by a conversion rate which will be determined by the Company.
5.7.A specification of the charge and the components thereof will be sent to the
User every month, according to the email Information which he provided to the
Company on the date of issuing the order.
5.8.Certification of the making of the transfer will also be sent to the Client by SMS
to the mobile phone number which he provided to the Company in the order
form.
5.9.The Company reserves the right not to add Users to the transfers by account
charge authorization Service and/or to terminate the Service at any time,
generally or for a specific Client.
5.10. The maximal monthly monetary transfer amount under the transfer by
account charge authorization service will be determined by the Company,
subject to the Company's procedures and the provisions of the law. The
Company reserves the right to change the maximal amount from time to
time, without advance notice.
5.11. Without derogating from the above, a transfer by account charge
authorization is subject to all the general sections set forth in the balance of
the Company's articles.

Privacy Policy
Last updated on: [February 25, 2020]

1. General
1.1.This website, maintained at the URL address https://gmtonline.co.il/
(hereinafter: the "Site" or the "Website"), is operated and maintained by GMT
Tech Innovation Ltd. (hereinafter: "GMT" or the "Company").
1.2.The purpose of this privacy policy is to set forth the manner in which the
Company collects information about and/or regarding the Users of its Services,
as defined below, and how and to what purposes the Company collects such
information. The document is written in the male gender for reasons of
convenience alone, but refers to both genders.
1.3.The terms of the privacy policy set forth below constitute an integral part of the
terms of use. The Company may from time to time modify the privacy policy and
the terms of use, jointly or severally, so that they reflect technological, business,
legal or regulatory changes. Such updates regarding the privacy policy will be
published in this document, which will be provided, in its up-to-date version, on
the Website.
1.4.Before using the Company's Services, please make sure to carefully read and
understand the terms of the privacy policy. Your use of the Company's Services,
including browsing the Site, will be deemed to be express and informed consent
on your part to all the terms, stipulations and notices included in the privacy
policy. If you do not consent to the terms of the privacy policy, all or part
thereof, do not use the Company's Services, including browsing the Site and/or
the app.

2. Definitions
2.1. "Law" – The Protection of Privacy Law, 5741-1981.
2.2. "Services" or "Company's Services" – All the services provided to the User
through the Company and/or its Agents, both services involving the provision of
information and services not involving the provision of information, including
browsing the Site.
2.3. "Agents" or "Company's Agents" – Authorized representatives on behalf of the
Company, who are deployed across the country and who have been authorized
by the Company and have obtained all the certificates required in order to act
on its behalf, including "concessioners" licensed under the Supervision of
Financial Services (Regulated Financial Services) Law, 5776-2016 and/or an
effective Continued Practice Certificate issued by the Capital Market Authority.
2.4. "Personal Information" – Means any datum or information which you will
provide to the Company in an initiated manner on the Site in accordance with
the purpose of your application to the Company, and which personally identifies
you, or enables your identification (such as your name, your email address, your
ID number, your means of payment, et cetera), as well as any information which
constitutes "Information" and/or "Sensitive Information" as defined in the Law.
2.5. "Anonymous Information" – Means statistical information, which does not
personally identify, or enable the identification of, the User, and which is linked
to the end device used by the User on the Site (such as the manner of using the
Site, the type of the User's browser, the date and time on which the User logged
into the Site, et cetera).
2.6. "You" or "User" – Any person who uses the Company's Services (as defined
above), including by means of a computer and/or other end device or any
purpose whatsoever.
3. Collection of Personal Information
As part of the use of the Services, you may be required to provide, and the Company
may collect, Personal Information of yours or about you by various technological
means. The type of Personal Information provided by you and/or collected by Us
may change, according to the reason and/or purpose for which you are contacting
us.
It is clarified that you are not required to provide such information under law, and
that the provision thereof is dependent only on your free will and consent, in
order to receive the requested Company's Services. However, the provision of part
of such information is required under the provisions of law (as set forth in Section
4.1.4), as well as in order to provide you with the Services requested by you, part or
all thereof.
By the mere use and by the provision of information to the Company, you hereby
represent and warrant as follows: (A) The Personal Information you will provide on
the Site is true, accurate and is your own. You are aware that knowingly providing
erroneous or false information could amount to a criminal offense; (B) You hereby
grant the Company an irrevocable, nonexclusive right of use, unlimited by time,
without consideration or any right to receive royalties, transferrable, to make any
use whatsoever of the information as set forth in the terms of service and in the
privacy policy, subject to any law and according to the Company's discretion,
without obtaining additional authorization therefor and without your having any
financial and/or other claim and/or demand and/or any suit in connection with such
right of use.

3.1.Personal Information as part of the Services
    3.1.1. Information provided by you for the purpose of performing actions on
    the Site and/or on the app: You must first sign up for the Site (if you are
    not signed up thereon) and create a User account. In that situation, you
    will be required to provide Personal Information of various kinds, such
    as: ID/passport number, telephone number, date of birth, occupation,
    address, email address.
    3.1.2. Personal Information provided by you in the course of submitting
    various applications to use the Company's Services (including by means
    of filling out forms): The Personal Information you will be required to
    provide may change according to the nature of the action you wish to
    perform through the Company. Thus, for example, actions related to the
    transfer of funds will require you to, inter alia, provide specification
    regarding the name of the beneficiary to whom the transfer of funds is
    requested, a country of destination for the transfer of funds, et cetera. If
    you are empowering another person to act in your name, you will be
    required to provide your own information and the attorney's
    information; applications related to insurances will require you to, inter
    alia, provide information regarding your medical condition, and so forth.
    3.1.3. Additional information about you may be collected and saved in the
    course of the use of the Services, such as: correspondences with
    representatives and/or automated representatives, personal content
    (such as: subjects regarding the transfer of the payment to the
    beneficiary), in-app chat, your bank account number and any
    information related to your use of the Services, for the purpose of their
    operation and in accordance with your request.

    3.2.Personal information you provide about another person. If, as part of the
    Services, you are required to provide information about another person (for
    example, when making money transfers you are required to provide information
    about the beneficiary), you represent and warrant that you are authorized, and
    have the consents required under any law, to provide the Company with
    Personal Information of such person for the purpose of the uses made by the
    Company of such information hereunder.
    3.3.Use of Personal Information
    In accordance with the Law, you are aware, and give your informed consent
    thereto, that the Company receives and collects the Personal Information
    regarding you for the following purposes and uses (as may be updated from
    time to time):
    3.3.1. Use of the information to improve, maintain and operate the Services
    offered to you, to make contact with you, to make decisions regarding
    You or others, including on subjects of fraud prevention, detection of
    deceits and/or other illegal activity, the security of the Services,
    operational purposes, evaluation and risk management.
    3.3.2. If you tried to sign up on the Site in order to use our Services and such
    attempt failed, the Company may contact you so as to assist in solving
    any issue You encountered in the signup process.
    3.3.3. For purposes of direct mailing: The Company may use, process, improve
    and enrich such information, including through an information provider,
    for purposes of maintaining, streamlining, adjustment and marketing of
    the Company's Services and products, inter alia by creating
    characterization regarding you, including by making marketing offers
    personalized for you, and may make use of statistical data as set forth in
    Section 5 below.
    3.3.4. The Company may send you operational and/or service messages and
    notices in various ways, inter alia by email or by SMS, such as an SMS
    message containing an identification code for accessing the Site, and
    various uses of the Services.
    3.3.5. The Company may contact you, including by way of sending promotional
    mailing, and may make offers to you and offer you benefits and
    promotions in connection with its products and Services, by email, SMS,
    telephone or other means. You may request that your information be
    removed from the marketing applications mailing list in general by
    sending an email titled "Request to unsubscribe" to the email address
    gmt-info@gmtonline.co.il.

4. Provision of information to third parties
The Company may provide information about you to third parties, from time to
time, in several cases, as set forth below, and/or in any other case wherein provision
is required, including provision of information to a location outside Israel:
4.1.As part of activity vis-à-vis third parties in order to complete the actions
requested by you and/or when required for the purpose of operating the
Services, including a cloud service provider; within this scope, it is also expected
that information will also have to be provided to any other party involved in
payment services, including banks, credit card companies or other issuers of
means of payment, clearers, consortiums and payment service providers, or
beneficiaries or payments.
4.2.As part of the outsourcing of an activity of the Company and/or the receipt of
Services from various providers, including computing and software services, data
processing and cloud services, and so forth.
4.3.As part of its activity vis-à-vis companies related in the business aspect, for the
purposes of use declared and permitted under any law, for the provision of the
Services.
4.4.The Company may provide Personal Information to competent authorities in
Israel or outside Israel and/or pursuant to a court order and/or insofar as
required in order to protect the Company's rights and assets and/or insofar as
such provision of information is required under any law, such as the information
required by virtue of money laundering laws or the making of reports (regular
and/or special) which the Company is required to make regarding its activities
and the actions performed by the Users of its Services, even without being
obligated to notify you before and/or after making the report, according to its
discretion.
4.5.Without derogating from all the above, the Company may provide third parties
with Anonymous Information as set forth in Section 7.4 below, without
restriction. Seeing as such information does not personally identify you, you
exempt the Company from any suit and/or demand and/or claim toward the
Company therefor.
4.6.You are aware that information may be provided to third parties located outside
Israel, including to countries granting lesser protection of privacy than the
protection granted by the laws of the State of Israel. By merely using the
Company's Services, you are giving your informed consent thereto.
4.7.The Company warrants that the transfer of information to third parties will be
limited solely to the purposes listed herein and only at the scope and extent
required according to the Company's discretion. The Company will make
adequate efforts to ensure that the recipients of the information will be subject
to a duty of confidentiality and information security with respect to the
information, under any law.

5. Collection of Anonymous Information
5.1.When you use the Site and/or the app, the Company collects information sent to
it by the computer, mobile phone or other device used by you to browse the Site
and/or the app, which is Anonymous Information – namely, which does not
personally identify you and cannot be attributed to you.
5.2.The Anonymous Information sent to us may include, inter alia: information
about the pages visited by Users, the type of the device, information about the
computer and the connection, mobile devices' network information, statistical
data about page views, the language of the interface, traffic to and from the
Site, the URL address from which the User got to the Site, et cetera.
5.3.The Company analyzes and uses Anonymous Information, itself or through
anyone on its behalf, for the purposes set forth below (as, and insofar as, they
may change from time to time): (A) Evaluating the manner in which the Users
use the Site, to maintain, improve and promote the Site; (B) Repairing
malfunctions; (C) Improving the Site's User experience; (D) Analyzing and
segmenting browsing patterns on the Site; (E) Personalizing advertisements
according to browsing habits; (F) Any other legal purpose which we deem fit.
5.4.The Company may provide Anonymous Information to third parties acting on its
behalf in order to realize the above purposes, and for any other legal purpose
which it deems fit. Seeing as such information does not personally identify the
User, the User hereby waives any suit and/or claim and/or demand toward the
Company in connection with the aforesaid.

6. Cookies
6.1.Cookies are text files created by the User's browser upon being issued a
command by the Company's computers. Some of the cookies will expire when
you close the browser or the relevant application, and others are saved on the
memory of your device or computer.
6.2.Be advised that in order to provide some of the Services, the Company uses
information retention software, such as cookies – for example, pixels, tags
(hereinafter jointly referred to by the generic name "Cookies") for the purpose
of the ongoing and proper operation thereof, for securing the data contained
therein and the information provided, and within this scope, to collect statistical
data about the use of the Services in order to make decisions regarding the
Services and/or your activity, for verification of information and for
identification, prevention of frauds and deceits, to improve the browsing
experience, while adjusting the contents and Services to your personal
preferences, to characterize the products appropriate for You, to personalize
advertisements relevant to you, even when you browse other sites; all for
statistical, research, marketing and commercial purposes, and naturally for
information security purposes.
6.3.The Cookies contain and collect varied information, such as the pages you
visited, the duration of your stay on the Site and/or on the app, where you came
to the Site and/or the page from, your IP address, your device's attributes, and
information You request to see on logging into the Site, et cetera. Furthermore,
it may be that the Cookies will also save information about your browsing habits
of other sites, including the sites you browsed, the pages on the sites and any
other action performed on them. The information contained in the Cookies is
encrypted, and the Company takes precautions to ensure that only its
computers or anyone on its behalf can read and understand the information
stored thereon.
6.4.The Services may also use third-party cookies – such as cookies of Google Co.,
like those embedded using Google Analytics, which help us personalize your
browsing experience or User experience and gain statistical insights regarding all
the uses made of the Services. For more information regarding said Cookies, visit
Google's website at https://www.google.com/intl/en/policies/technologies/ads,
as well as Facebook's policy on Cookies at
https://www.facebook.com/policies/cookies.

7. Information security
7.1.To create a personal area on the Site, you must enter, inter alia, a password
according to the instructions listed on the Site and/or on the app. Logging into
the Site is done using personal details and a one-time password (OTP) sent to
the mobile phone as listed on the Company's systems. You are required to
report any unauthorized use of your details to the Company.
7.2.Please note that any login to the Site done using your identifying information
(such as an ID number and/or your password (including any Personal
Information provided using such details) will be deemed to be a login done by
you, and you exempt the Company and anyone on its behalf from any liability or
responsibility for unauthorized use made of your details, and from the
consequences of such use.
7.3.The Company uses customary information security technologies, and will make a
reasonable effort to ensure that the Site is free and clean of viruses, worms or
trojan horses. The Company will exercise and implement procedures customary
in the market for securing your Personal Information against unauthorized
access, use or disclosure. However, you are aware that notwithstanding the
aforesaid, this does not provide complete security with respect to information
provided by you to the Company (whether such information is actually saved on
the Company's information systems or is saved and stored at a cloud provider /
cloud providers).
7.4.Therefore, it is your responsibility to take basic steps to protect your computer
or the end device from which You make the use (including installing antivirus
software, locking your computer, changing your passwords, et cetera), in order
to protect your Personal Information.

8. Retention of information, right of review, correction and deletion of information
8.1.In general, your Personal Information, or Personal Information about you, will be
retained on the Company's database/s for such period of time as the Company
requires in order to provide the Services requested by you, as applicable, and for
as long as the Company has a legitimate need or a legal or other obligation to
retain it under any law. Anonymous Information will be retained on the
Company's information systems without a time limit (as long as this is not
prohibited under any law).
8.2.You are entitled to review the Personal Information about you retained on the
Company's database/s, if any, to request that the information be corrected or
deleted, by applying to the Company by email at gmt-info@gmtonline.co.il, and
we will act to carry out your request according to the information imposed on us
under the Law. However, Personal Information required by the Company in
order to continue conducting its business and/or required for a legitimate
purpose such as defending itself against suits (all, to the extent required) will
continue to be retained by the Company for such time as is required by it under
law.
9. Contact us. You are welcome to contact us on any question, request or problem
related to the privacy policy by applying to us at gmt-info@gmtonline.co.il, and we
will make every effort to reply to your application as soon as possible.

       </textarea>
    </div>
</template>

<script>

    export default {
        name: 'Terms',
        methods: {
        }
    }
</script>

