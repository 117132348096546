<template>
    <div>
        <Header :onlyLogo="true">
            <template v-slot:close-button>
                <div class="go-back-wrapper">
                    <i v-if="step === 1" @click="prevStep()">✕</i>
                    <i @click="prevStep()" v-else aria-hidden="true"><img class="arrow-next"
                            :class="{ 'arrow-mirror': getLang === 'il' }"
                            src="../assets/images/icons/i-arrow-left.png"></i>
                </div>
            </template>
        </Header>
        <div v-show="loading" class="loader_page">
            <img src="../../src/assets/images/loading.gif" />
        </div>
        <div class="main-wrapper main-wrapper-logged-out" v-show="!loading">
            <h2>{{ $ml.with('VueJS').get('biometricsHeader') }}</h2>
            <div class="biometric-section legal" v-show="this.step === 1">
                <p>
                <ol>
                    <li>{{ $ml.with('VueJS').get('biometric_legal_clause_1') }}</li>
                    <li>{{ $ml.with('VueJS').get('biometric_legal_clause_2') }}</li>
                    <li>{{ $ml.with('VueJS').get('biometric_legal_clause_3') }}</li>
                    <li>{{ $ml.with('VueJS').get('biometric_legal_clause_4') }}</li>
                    <li>{{ $ml.with('VueJS').get('biometric_legal_clause_5') }}</li>
                    <li>{{ $ml.with('VueJS').get('biometric_legal_clause_6') }}</li>
                </ol>
                </p>
            </div>
            <div class="biometric-section sign-in" v-show="this.step === 2">
                <h4>{{ $ml.with('VueJS').get('biometricSectionSignIn') }}</h4>
                <div class="login">
                    <div class="form-login">
                        <form>
                            <div class="form-group">
                                <label for="identification" v-text="$ml.with('VueJS').get('loginLabel1')"
                                    class="floating-label" id="identification-floating-label"
                                    :class="{ 'label-ltr': getLang === 'en' }"></label>
                                <input v-model.trim="authData.identification" v-validate="'required'" type="text"
                                    name="identification" id="identification" class="form-control">
                                <span class="error">{{ errors.first('identification') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="mobileNumber" v-text="$ml.with('VueJS').get('loginLabel2')"
                                    class="floating-label" id="mobileNumber-floating-label"
                                    :class="{ 'label-ltr': getLang === 'en' }"></label>
                                <input v-model.trim="authData.mobileNumber"
                                    v-validate="{ required: 'required', numeric: 'numeric', regex: [/^05\d([-]{0,1})\d{7}$/] }"
                                    type="tel" id="mobileNumber" name="mobileNumber" class="form-control">
                                <span class="error">{{ errors.first('mobileNumber') }}</span>
                            </div>
                            <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" size="invisible"
                                :sitekey="sitekey"></vue-recaptcha>
                        </form>
                    </div>
                </div>
            </div>
            <div class="biometric-section otp" v-show="this.step === 3">
                <img src="../assets/images/sms_once.gif" alt="">
                <h4>{{ $ml.with('VueJS').get('messageCode') }}</h4>
                <input type="text" inputmode="numeric" maxlength="6" class="form-control sms-code" id="smsCode"
                    name="smsCode" v-model="authData.smsCode" :class="{ 'form-error': errorCode }"
                    @keyup.enter="login()" autocomplete="one-time-code">
                <span class="error">{{ error }}</span>
            </div>
            <div class="next-step-btn">
                <button class="btn" @click="nextStep()">{{ getTextByStep }}</button>
            </div>
        </div>
    </div>
</template>
<script>

import Header from "../components/Header";
import { loginSMS } from "../services/api.js";
import { addFloatingLabel, isApp } from "../services/utils";
import VueRecaptcha from 'vue-recaptcha';
import { login } from "../services/auth.js";
import { Validator } from "vee-validate";
import { localesDictionary } from "../services/utils";
import { EventBus } from "../event-bus";

export default {
    name: 'BiometricRegistration',
    components: { Header, VueRecaptcha },
    data() {
        return {
            step: 1,
            authData: {
                identification: '',
                mobileNumber: '',
                country: 'ISR',
                recaptchaToken: '',
                signature: '',
                timestamp: '',
                smsCode: '',
                jwt: '',
                isBiometricRegister: true
            },
            error: '',
            errorCode: false,
            user: {
                error: ''
            },
            currentLang: '',
            isActive: false,
            jwtAuth: false,
            firstVisited: false,
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            isApp: isApp(),
            isSubmitted: false,
            loading: false
        }
    },
    computed: {
        getDir() {
            return this.$store.getters.getDir;
        },
        getTextByStep() {
            switch (this.step) {
                case 1:
                    return this.$ml.get('biometricSectionApprove')
                case 2:
                    return this.$ml.get('btnSignIn')
                case 3:
                    return this.$ml.get('btnOk')
            }
        },
        getLang() {
            return this.$store.getters.getLang;
        },
    },
    watch: {
    },
    created() {
        EventBus.$on('changeLang', lang => {
            this.$validator.localize(this.dictionary());
            this.$validator.localize(lang);
        });
        if (!isApp()) { this.$router.push('/login') }
    },
    beforeDestroy() { },
    mounted() {
        Validator.localize(this.dictionary());
        Validator.localize(this.getLang);
        document.body.setAttribute('dir', this.getDir);
        addFloatingLabel('mobileNumber')
        addFloatingLabel('identification')
    },
    methods: {
        nextStep() {
            switch (this.step) {
                case 1:
                    this.step = 2;
                    break;
                case 2:
                    this.$refs.recaptcha.execute();
                    break;
                case 3:
                    this.login();
                    break;
            }
        },
        prevStep() {
            this.step === 1 ? (this.isLoggedIn() ? this.goToWallet() : this.goToLogin()) : this.step -= 1;
        },
        isLoggedIn() {
            return this.$store.getters.getLoggedIn && !this.$store.getters.getNotSigned;
        },
        goToLogin() {
            this.$router.push('login');
        },
        goToWallet() {
            this.$router.push('/wallet');
        },
        onVerify(recaptchaToken) {
            let self = this;
            self.loading = true;
            this.authData.recaptchaToken = recaptchaToken;
            this.$validator.validate().then(result => {
                if (result) {
                    loginSMS(this.authData, function (response) {
                        self.loading = false;
                        if (!response.data.errorCode) {
                            self.authData.signature = response.data.result.signature;
                            self.authData.timestamp = response.data.result.timestamp;
                            $('#smsCode').focus();
                            self.step = 3;
                        }
                    });
                } else {
                    self.loading = false;
                }
            })
        },
        onExpired() {
            this.$refs.recaptcha.reset();
        },
        login() {
            let self = this;
            if (!self.isSubmitted) {
                self.isSubmitted = true;
                self.loading = true;
                login(this.authData, function (response) {
                    self.loading = false;
                    self.isSubmitted = false;
                    if (response.data.errorCode == 'SMS_WRONG' || response.data.errorCode == 'SMS_EXPIRED')
                        self.error = self.$ml.with('VueJS').get(response.data.errorCode);
                });
            }
        },
        postUpdate() {
            Validator.localize(this.dictionary());
            Validator.localize(this.getLang);
        },
        dictionary() {
            const custom = {
                mobileNumber: {
                    regex: this.$ml.with('VueJS').get("E_REGEX_mobileNumber")
                },
            }
            const messages = {
                required: this.$ml.with('VueJS').get("E_REQUIRED"),
                numeric: this.$ml.with('VueJS').get("E_NUMERIC")
            }
            return localesDictionary({ custom: custom, messages: messages });
        }
    },
}

</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-weight: 600;
        margin-top: 20px;
        font-size: 30px;
        white-space: pre-wrap;
        text-align: center;
    }


    .biometric-section {
        h4 {
            font-weight: normal;
        }

        text-align: start;
        margin-top: 30px;
        width: 75%;
    }

    .legal {
        height: 520px;
        overflow-x: scroll;

        p {
            list-style-position: inside;
        }
    }

    .btn {
        width: 80%;
        margin: 20px auto;
        height: 40px;
    }

    .sign-in {
        text-align: center;
        margin-bottom: 20px;
    }

    form {
        max-width: 284px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }

    .form-group {
        text-align: right;
        margin-top: 20px;

        &+.form-group {
            margin-top: 34px;
        }

        .form-control {
            max-width: 98%;
            width: 100%;
            border: none;
            border-bottom: 2px solid #092086;
            background-color: transparent;
            font-size: 20px;
            color: black;
            padding: 7px 9px;
            margin-top: 5px;

            /*   &[aria-invalid=true]{
                 border-color: #f75151;
             }*/
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                font-size: 18px;
                color: black;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                font-size: 18px;
                color: black;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                font-size: 18px;
                color: black;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                font-size: 18px;
                color: black;
            }
        }
    }

    .error {
        display: block;
        max-width: 256px;
        text-align: right;
        position: static;
        margin-right: 0;
        margin-bottom: -10px;
        font-size: 16px;
        color: red;
    }

    .otp {
        margin-top: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px 0px;

        img {
            max-width: 93px;
        }

        .form-control {
            max-width: 85%;
            width: 85%;
            margin-top: 25px;
            letter-spacing: 5px;
            text-align: right;

            &[aria-invalid=true] {
                border-color: #ff6a68;
            }
        }

        .error {
            display: block;
            text-align: right;
            position: static;
            margin-right: 0;
            margin-bottom: -10px;
            font-size: 16px;
            max-width: 240px;
            margin: 0 auto;
            text-align: center;
        }

        img {
            width: 93px;
            margin: 15px 0px;
        }

        h4 {
            margin: 15px;
        }
    }

    .next-step-btn {
        width: 75%;

        .btn {
            width: 95%;
            height: 40px;
        }
    }
}

.arrow-mirror {
    transform: scale(-1, 1);
}

.arrow-next {
    width: 20px;
}

.label-ltr {
    text-align: left;
    margin-inline-start: 5px;
}
</style>