<template>

  <div class="grey-bgc">
    <Header />
    <div class="main-wrapper">
      <div class="block block-iframe">
        <div class="">
          <div v-if="loading" class="loader_page">
            <img src="../assets/images/loading.gif" />
          </div>
          <div v-show="!loading">
            <div class="wrap">
              <h1 class="title" v-text="$ml.with('VueJS').get('cashioPageTtile')"></h1>
            </div>
            <div class="before-iframe-box">
              <div class="iframe-box">
                <iframe height="900" width="1000" @load="loaded()" :src="url"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />
  </div>
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import Footer from "@/components/Footer.vue";
import { isMobile } from "../services/utils";


export default {
  name: "Cashio",
  components: { MainNav, Header, Footer },
  data() {
    return {
      loading: true,
      customerData: null,
      isMobileDevice: isMobile()
    };
  },
  created() {
    const data = JSON.parse(localStorage.getItem('registrationData'));
    this.customerData = {
      customer: {
        firstName: data.firstNameHebrew,
        lastName: data.lastNameHebrew,
        identification: data.identification,
        email: data.email,
        phone: data.phoneNumber.replace('+972', '0'),
        customerAddress: {
          city: data.cityCode,
          street: data.streetCode,
          streetNumber: data.streetNumber,
          apartment: data.appartmentNumber,
        }
      }
    };
  },
  methods: {
    loaded() {
      this.loading = false;
    }
  },
  computed: {
    url() {
      return process.env.VUE_APP_CASHIO_IFRAME + window.btoa(encodeURIComponent(JSON.stringify(this.customerData)));
    },
  }
};
</script>

<style lang="scss" scoped>
.block-iframe {
  text-align: center;
  margin: 0 auto;

  iframe {
    border: none;
    max-width: 100%;
    max-height: 90VH;
  }

  .before-iframe-box {
    padding-top: 10px;
    margin-top: 10px;
  }
}
</style>