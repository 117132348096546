<template>
  <div class="wallet-actions-wrapper">
    <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('wallet.main.depositActions.cash')" icon="Shtar.png"
      @lineAction="goToCashDepositPage" />
    <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('wallet.main.depositActions.bank')" icon="Bank.png"
      @lineAction="goToBankDepositPage" />
    <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('wallet.main.depositActions.card')" icon="Card-Small.png"
      @lineAction="goToTransferPage" />
  </div>
</template>
<script>

import ActionLine from "@/components/ActionLine.vue";
import { isMobile } from "@/services/utils";

export default {
  name: 'WalletActions',
  components: { ActionLine },
  props: {},
  data() {
    return {
      isMobileDevice: isMobile()
    }
  },
  created() {
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {
  },
  methods: {
    goToCashDepositPage() {
      if (this.$store.getters.isOrderActive) {
        this.$router.push('/wallet/walletOrder')

      } else {
        this.$router.push('/wallet/deposit/cash')
      }
    },

    goToBankDepositPage() {
      this.$router.push('/wallet/deposit/bank')
    },
    goToTransferPage() {
      this.$router.push('wallet/transfer')
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.wallet-actions-wrapper {
  .drawer-line-container {
    margin: 10px 0;
  }
}
</style>
