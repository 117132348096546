<template>
  <div class="mygmt2-page-wrapper" :class="{ 'h-100': isMobileDevice }">
    <div class="mygmt2-page-wrapper-container">
      <div class="mygmt2-page-wrapper-container-center">
        <div class="mygmt2-page-wrapper-container-desktop">
          <Drawer ref="filterDrawer" drawerId="filterDrawer" :title="$ml.get('filterTransactions')">
            <template v-slot:content>
              <div class="filter-drawer-wrapper">
                <div class="filter-container">
                  <h1>{{ $ml.get('transactionCharge') }}</h1>
                  <div class="mygmt2-line direction-column">
                    <button class="btn direction-btn btn-primary" @click="changeTransactionTypeFilter('All')"
                      id="transactionTypeAll">{{ $ml.get('seeAll') }}
                    </button>
                    <button class="btn direction-btn btn-border" @click="changeTransactionTypeFilter('IN')"
                      id="transactionTypeIN">{{ $ml.get('incoming') }}
                    </button>
                    <button class="btn direction-btn btn-border" @click="changeTransactionTypeFilter('OUT')"
                      id="transactionTypeOUT">{{ $ml.get('outgoing') }}
                    </button>
                  </div>
                </div>
                <div class="filter-container">
                  <h1>{{ $ml.get('transactionType') }}</h1>
                  <select name="transactionClass" id="transactionClass" class="transaction-class-select2"
                    style="width: 100%" v-model="filterData.transactionClass" multiple="multiple">
                    <option v-for="transactionClass in transactionClasses.filter(e=> e !== 'UPI_BANK_TRANSFER')" :value="transactionClass"
                      :key="transactionClass">
                      {{ $ml.get(`transactionClasses.${transactionClass}`) }}
                    </option>
                  </select>
                </div>
                <div class="filter-container">
                  <h1>{{ $ml.get('filterPaymentType') }}</h1>
                  <select name="paymentType" id="paymentType" class="payment-type-select2"
                    v-model="filterData.paymentType" style="width: 100%" multiple="multiple">
                    <option v-for="paymentType in paymentTypes.filter(e => e !== 'CREDITTING')" :value="paymentType"
                      :key="paymentType.name">
                      {{ $ml.get(`paymentTypes.${paymentType}`) | lowerCaseCapitalise }}
                    </option>
                  </select>
                </div>
                <div ref="date1" class="filter-container">
                  <h1>{{ $ml.get('date') }}</h1>
                  <div class="custom-date-picker datePickerFrom">
                    <div data-input>
                      <div v-if="!isMobileDevice">
                        <section v-if="filterData && filterData.fromDate" class="custom-date-picker-container">
                          <span>{{ filterData.fromDate }}</span>
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </section>
                        <section class="custom-date-picker-container" v-else>
                          <span>{{ $ml.get('from_date') }}</span>
                          <input type="text">
                          <span>{{ $ml.get('choose_date') }}
                            <i class="fa fa-calendar" aria-hidden="true"></i></span>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div ref="date2" class="filter-container">
                  <div class="custom-date-picker datePickerTo">
                    <div data-input>
                      <div v-if="!isMobileDevice">
                        <section v-if="filterData && filterData.toDate" class="custom-date-picker-container">
                          <span>{{ filterData.toDate }}</span>
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </section>
                        <section class="custom-date-picker-container" v-else>
                          <span>{{ $ml.get('to_date') }}</span>
                          <input type="text">
                          <span>{{ $ml.get('choose_date') }}<i class="fa fa-calendar" aria-hidden="true"></i></span>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <span id="transaction-filter-errors">{{ $ml.get('error') }}</span>
                <div class="mygmt2-line btn-line">
                  <button class="btn btn-primary" @click="loadFilteredTransactions()">{{ $ml.get('showResults') }}
                  </button>
                </div>
                <div class="mygmt2-line btn-line">
                  <button class="btn btn-border black-text" @click="clearFilter()">{{ $ml.get('clearFilter') }}</button>
                </div>
              </div>
            </template>
          </Drawer>
          <Header class="header-grid-box grid-fix" />
          <div class="payment-balances-card-grid-box ">
            <h1 class="title payment-balances-card-grid-box " v-if="!isMobileDevice">
              {{ $ml.get('recentActions') }}</h1>
            <div class="transaction-list-container">
              <div :class="{ 'mygmt2-line between': !isMobileDevice }">
                <div class="cards-page-desktop-flex">
                  <div v-if="!isMobileDevice">
                    <div v-show="this.customFilter" class="mygmt2-tag">
                      <span>{{ this.filterData.fromDate }} - {{ this.filterData.toDate }}</span>
                      <div class="go-back-wrapper pointer" @click="clearDates">✕</div>
                    </div>
                    <div v-show="!this.customFilter" class="custom-select month-select">
                      <select class="form-control mygmt2-select mygmt2-month-select" id="selectedMonth"
                        v-model="selectedMonth" @change="monthChangeHandler">
                        <option v-for="(month) in this.months" :key="month.text" :value="month">
                          {{ month.textShort }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="transaction-list-filter-container">
                  <h6 v-if="isMobileDevice">{{ $ml.get('recentActions') }}</h6>
                  <div class="transaction-list-flex transaction-list-filter-wrapper">
                    <div class="transaction-list-flex">
                      <img src="../assets/mygmt_v2_images/Search.svg" alt="search icon" />
                      <input class="transaction-list-placeholder" :placeholder="$ml.get('searchTransactions')"
                        v-model="searchTransactions" />
                    </div>
                    <button class="btn btn-action transaction-list-flex btn-flex"
                      @click="$refs.filterDrawer.openDrawer()">
                      <span>{{ $ml.get('filterTransactionsButton') }}</span>
                      <span v-if="this.getConditionsNum > 0">({{ this.getConditionsNum }})</span>
                      <img src="../assets/mygmt_v2_images/Filter.svg" alt="filter icon" />
                    </button>
                  </div>
                  <div class="transaction-list-carousel" v-if="isMobileDevice">
                    <div v-show="this.customFilter" class="mygmt2-tag">
                      <span>{{ this.filterData.fromDate }} - {{ this.filterData.toDate }}</span>
                      <div class="go-back-wrapper" @click="clearDates">✕</div>
                    </div>
                    <div v-show="!this.customFilter">
                      <section class="splide" aria-label="month picker" id="monthPicker">
                        <div class="splide__track">
                          <ul class="splide__list">
                            <li class="splide__slide slide-month" v-for="(month) in this.monthsReversed"
                              :key="month.text">
                              <span>{{ month.textShort }}</span>
                            </li>
                          </ul>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <div class="transaction-list-container">
                <TransactionList class="transaction-list-grid-box "
                  :transactionsList="JSON.stringify(filteredTransactions)" />
                <!-- <div v-show="this.loading" class="mygmt2-loader">
                  <img src="../assets/mygmt_v2_images/mygmt2-loader.gif" />
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <Footer v-if="!isMobileDevice" />
      </div>
    </div>
  </div>
</template>
<script>

import Header from "../components/Header.vue";
import Footer from "@/components/Footer.vue";
import { getPreviousMonths, isApp, isMobile } from "@/services/utils";
import { getFilteredTransactionsAsync, getTransactions } from "@/services/api";
import Drawer from "@/components/Drawer.vue";
import TransactionList from "@/components/TransactionsList.vue";
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { pickrLocales } from "../assets/flatpickr/translations.js"
import { lowerCaseCapitalise } from "../filters/AppFilters.js";

export default {
  name: 'TransactionsHistory',
  components: {
    TransactionList,
    Drawer,
    Header,
    Footer,
  },
  data() {
    return {
      isMobileDevice: isMobile(),
      months: getPreviousMonths(),
      monthsReversed: getPreviousMonths().reverse(),
      isApp: isApp(),
      busy: false,
      loading: false,
      page: 0,
      transactions: [],
      noTransactions: false,
      searchTransactions: '',
      newTransactions: [],
      selectedMonth: {},
      selectedCard: {},
      customFilter: false,
      max: false,
      monthPickerSplide: null,
      filterData: {
        direction: '',
        paymentType: [],
        transactionClass: [],
        fromDate: moment().startOf('month').format('YYYY/MM/DD'),
        toDate: moment().format('YYYY/MM/DD'),
      },
      filterCalendarInstances: {
        from: '',
        to: ''
      }
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    },
    getSelectedCard() {
      return this.selectedCard
    },
    getLang() {
      return this.$store.getters.getLang;
    },
    getConditionsNum() {
      let count = 0;
      Object.entries(this.filterData).forEach((item) => {
        if (item[1].length > 0 && (item[0] !== 'fromDate') && (item[0] !== 'toDate')) {
          count++;
        }
      })
      return count;
    },
    filteredTransactions() {
      return this.transactions
        .filter(item => {
          return item.otherSide.toLowerCase().indexOf(this.searchTransactions.toLowerCase()) > -1
            || item.correspondentReference == this.searchTransactions
            || item.country.toLowerCase().indexOf(this.searchTransactions.toLowerCase()) > -1;
        })
    },

  },
  created() {
    window.scrollTo(0, 0);
    if (!this.maxFetch) {
      this.addListener();
    }
    this.loadTransactions(this.page);
    this.$store.commit('setTransferStep1', {});

  },
  beforeDestroy() {
    this.removeListener();
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    document.body.setAttribute('dir', this.getDir);
    let currentMonthIndex = 0;
    if (this.isMobileDevice) {
      currentMonthIndex = this.monthsReversed.length - 1;
      let splideOptions = {
        isNavigation: true,
        direction: this.getLang === 'il' ? 'rtl' : 'ltr',
        arrows: false,
        pagination: false,
        type: 'slide',
        focus: 'center',
        perPage: 4,
        trimSpace: false,
        start: currentMonthIndex,
        classes: {
          pagination: 'splide__pagination carousel-slide-pagination',
          page: 'splide__pagination__page carousel-slide-pagination-page',
        },
      };
      this.monthPickerSplide = new Splide('#monthPicker', splideOptions);
      this.monthPickerSplide.mount();
      let self = this;
      this.monthPickerSplide.on('active', function (e) {
        self.selectedMonth = self.monthsReversed[e.index]
        self.monthChangeHandler()
      });
    } else {
      this.selectedMonth = this.months[currentMonthIndex]
    }
    this.filterCalendarInstances.from = flatpickr(".datePickerFrom", {
      wrap: true,
      locale: pickrLocales[this.getLang],
      minDate: new Date(moment().subtract(3, 'years')),
      maxDate: new Date(),
      defaultDate: new Date(moment().startOf('month')),
      onChange: (selectedDates, dateStr) => {
        this.filterData.fromDate = moment(dateStr).format('YYYY/MM/DD')
      },
      appendTo: this.$refs.date1.$el,
      altInputClass: '.datepicker-mobile'
    });
    this.filterCalendarInstances = flatpickr(".datePickerTo", {
      wrap: true,
      locale: pickrLocales[this.getLang],
      minDate: new Date(moment().subtract(3, 'years')),
      maxDate: new Date(),
      defaultDate: new Date(),
      onChange: (selectedDates, dateStr) => {
        this.filterData.toDate = moment(dateStr).format('YYYY/MM/DD')
      },
      appendTo: this.$refs.date2.$el,
      altInputClass: 'datepicker-mobile',
    });
    let self = this;
    let transactionClassElement = $(".transaction-class-select2");
    let paymentTypeElement = $(".payment-type-select2");
    transactionClassElement.select2({
      placeholder: this.$ml.get('pleaseSelect')
    }).on("change", function () {
      self.filterData.transactionClass = transactionClassElement.val()
    });
    paymentTypeElement.select2({
      placeholder: this.$ml.get('pleaseSelect')
    }).on("change", function () {
      self.filterData.paymentType = paymentTypeElement.val()
    });
  },
  methods: {
    handleClickOutside(event) {
      if (this.filterCalendarInstances && this.filterCalendarInstances.from && this.filterCalendarInstances.to) {
        this.filterCalendarInstances.from.close();
        this.filterCalendarInstances.to.close();
      }
    },

    infiniteScroll() {
      if (this.max) {
        this.page = 0;
        return
      }
      if ($(window).scrollTop() + window.innerHeight >= document.body.scrollHeight - 1) {
        if (this.busy) return;
        this.busy = true;
        this.loadTransactions(++this.page, true)
      }
    },
    async monthChangeHandler() {
      this.customFilter = false;
      let startDate = moment([this.selectedMonth.year, parseInt(this.selectedMonth.month) - 1, 1])
      let endDate = startDate.clone().endOf('month')
      this.filterData.fromDate = startDate.format('YYYY/MM/DD')
      this.filterData.toDate = endDate.format('YYYY/MM/DD')
      await this.loadTransactions();
    },
    clearDates() {
      this.filterData.fromDate = moment().startOf('month').format('YYYY/MM/DD');
      this.filterData.toDate = moment().format('YYYY/MM/DD');
      this.customFilter = false;
    },
    isDefaultDate() {
      const startOfMonth = moment().startOf('month').format('YYYY/MM/DD');
      const today = moment().format('YYYY/MM/DD');
      return (this.filterData.fromDate === startOfMonth && this.filterData.toDate === today)
    },
    addFilterParams(filterData){
      if (filterData.paymentType.includes('CREDIT_CARD')) filterData.paymentType = [...filterData.paymentType, 'CREDITTING']
      //Add this in the future when UPI is pushed in the backend
      // if (filterData.transactionClass.includes('BANK_TRANSFER')) filterData.transactionClass = [...filterData.transactionClass, 'UPI_BANK_TRANSFER']
      return filterData
    },
    async loadTransactions(page, scroll) {
      this.max = false
      this.loading = true;
      this.filterData = this.addFilterParams(this.filterData)
      let data = await getFilteredTransactionsAsync({ ...this.filterData, 'page': page });
      this.max = scroll && !data.result.length
      this.transactions = scroll ? [...this.transactions || [], ...data.result || []] : data.result
      if (this.maxFetch) {
        this.transactions = _.take(this.transactions, parseInt(this.maxFetch))
      }
      this.loading = false;
      this.busy = false;
    },
    async loadFilteredTransactions() {
      if (this.invalidFilter()) {
        $("#transaction-filter-errors").show();
        return;
        // this.clearFilter()
      } else {
        this.$refs.filterDrawer.closeDrawer()
        $("#transaction-filter-errors").hide();
      }
      await this.loadTransactions();
      if (!this.filterData.direction && this.isDefaultDate()) {
        this.clearDates()
      }

    },
    clearFilter() {
      this.max = false
      this.transactions = []
      this.filterData = {
        direction: '',
        transactionClass: [],
        fromDate: moment().startOf('month').format('YYYY/MM/DD'),
        toDate: moment().format('YYYY/MM/DD'),
        paymentType: [],
        id: 0
      }
      this.customFilter = false;
      this.$refs.filterDrawer.closeDrawer()
      this.selectedMonth = this.months[0]
      this.monthPickerSplide.go(this.months.length)
    },
    invalidFilter() {
      if (this.filterData.fromDate && this.filterData.toDate) {
        this.customFilter = true;
      }
      // if (!this.filterData.fromDate) {
      //   this.filterData.fromDate = moment().startOf('month').format('YYYY/MM/DD')
      // }
      // if (!this.filterData.toDate) {
      //   this.filterData.toDate = moment().format('YYYY/MM/DD')
      // }
      return this.filterData.fromDate > this.filterData.toDate
    },
    addListener() {
      window.addEventListener('scroll', this.infiniteScroll, true);
      window.addEventListener('touchmove', this.infiniteScroll, true);
    },
    removeListener() {
      window.removeEventListener('scroll', this.infiniteScroll, true);
      window.removeEventListener('touchmove', this.infiniteScroll, true);
      document.removeEventListener('click', this.handleClickOutside);
    },
    changeTransactionTypeFilter(type) {
      if (type === 'All') {
        this.filterData.direction = '';
      } else {
        this.filterData.direction = type;
      }
      $('.direction-btn').removeClass('btn-border')
      $('.direction-btn').addClass('btn-border')
      $('.direction-btn').addClass('btn-border black-text')
      $(`#transactionType${type}`).removeClass('btn-border')
      $(`#transactionType${type}`).removeClass('black-text')
      $(`#transactionType${type}`).addClass('btn-primary')
    }
  },
  watch: {
    transactions: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.transactions = val;
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";
@import "./../../node_modules/select2/dist/css/select2.css";

.header-grid-box {
  padding-top: 25px;
  background-color: white;
}




.mygmt2-page-wrapper-container-center {
  // background-color: #F6F8F9;
  padding-bottom: 10px !important;
  height: 100%;
}

.payment-balances-card-grid-box {
  // background-color: #f6f8f9;
  border-top: 1px solid #e9e9e9;
}

.mygmt2-page-wrapper-container {
  background-image: none;
  // background-color: #f6f8f9;
  height: 100%;

}

.filter-drawer-wrapper {
  font-size: 14px;
  color: black;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;

  h1 {
    font-size: 14px;
    font-weight: normal;
  }

  .custom-date-picker {
    width: 100%;

    .custom-date-picker-container {
      border: 1px solid #E4E8EB;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      font-weight: 400;
      font-size: 14px;
      padding: 10px;

      .fa-calendar {
        margin-inline-start: 2px;
      }
    }
  }

  .direction-column {
    .direction-btn {
      color: black;
      margin-inline: 5px;
      font-size: 14px;
      font-weight: normal;
      box-shadow: 0 0 14px 0 #0000000F;
      width: 100%;
      padding: 0 2px;
      height: 36px;
    }

    .btn-primary {
      font-weight: bold;
      color: white;
    }
  }

  .btn-line {
    padding: 10px 0;

    .btn {
      width: 100%;
      height: 45px;
    }
  }

  .filter-container {
    width: 100%;
    margin: 10px 0;

    h1 {
      margin-bottom: 10px;
    }
  }

  #transaction-filter-errors {
    display: none;
    color: red;
    font-weight: normal;
  }
}

.mygmt2-tag {
  direction: ltr;
  display: flex;
  background-color: $mainColor;
  color: white;
  border-radius: 72px;
  padding: 8px;
  font-weight: bold;
  align-items: center;
  width: 210px;
  margin: auto;

  .go-back-wrapper {
    width: auto;
    color: white;
    margin-inline-start: 5px;
    margin-block-start: 1px;
  }
}

.btn-border {
  font-weight: normal;
}

.black-text {
  color: black !important;
}

.mygmt2-select {
  width: 100%;
  border: 1px solid #E4E8EB;
  border-radius: 5px;
  text-align: start;
  font-weight: normal;
}

.mygmt2-month-select {
  border: none;
  border-radius: 50px;
  width: 175px;
  height: 55px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 600;
}

.transaction-list-carousel {
  padding-top: 25px;
}

.flex-actions {
  display: flex;
  height: 100px;
  padding: 10px;
  background-color: white;
  margin: 10px 0;
  border-radius: 20px;
}

.cards-page-desktop-flex {
  display: block;
}

.transaction-list-filter-container {
  padding-top: 15px;

  h6 {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .transaction-list-filter-wrapper {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin: auto;
    padding: 5px;
    border: 1px solid #EBEBEB;
    height: 55px;
  }

  .transaction-list-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px;

    img {
      padding-top: 4px;
    }

  }

  .btn-flex {
    width: 50px;
    height: 38px;
    padding: 0 10px;
    gap: 5px;
    justify-content: flex-end;
    background: linear-gradient(244.68deg, #137FE2 -2.13%, #2713A0 147.06%);

    span {
      font-size: 14px;
    }
  }

  .transaction-list-placeholder {
    border: none;
    color: black;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.6;
    width: 120px;
  }
}

.title {
  color: black;
}

.flatpickr-calendar {
  left: auto !important;
}

.datepicker-mobile {
  background-color: red;
  left: auto !important;
}

:v-deep.flatpickr-calendar.open {
  // display: inline-block;
  // z-index: 99999;
  left: auto !important;
}

@media only screen and (min-width: $screen-tablet) {
  .header-grid-box {
    background-color: inherit;
  }


  .transaction-list-container {
    height: 93%;


    .transaction-list-grid-box {
      height: 100%;
      background-color: white !important;
    }
  }

  .mygmt2-header-wrapper {
    padding: 0;
  }

  .cards-page-desktop-flex {
    display: flex;
    margin-block: 10px;
  }

  .payment-balances-card-grid-box {
    grid-area: 2 / 1 / 13 / 14;
    overflow-y: visible;
    overflow-x: clip;
    // background-color: #f6f8f9;
    border-top: none;
    max-height: 90vh;
  }

  .transaction-list-grid-box {
    grid-area: 6 / 1 / 13 / 14;
  }

  .transaction-list-filter-container {
    padding-top: 0;

    .transaction-list-filter-wrapper {
      width: 600px;
      padding: 0 10px;
      margin: 0;
      height: 55px;
    }
  }
}
</style>