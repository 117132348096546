<template>
    <div class="wrap step-1">
        <div v-show="loading" class="loader_page">
            <img src="../../assets/images/loading.gif"/>
        </div>
        <div v-show="!loading">
            <h1 class="title" v-text="$ml.get('itemNav1')"></h1>

            <lookup 
                ref="lookup"
                :next-btn="$ml.get('nextLevel')"
                :logged-in="true" 
                @ready="ready"
                @execute="execute" 
                @next-step="nextStep" 
                @back-step="backStep">
            </lookup>

<!-- 
1. display rates if lookup is not real ILS. (usd, eur, dummy ils)
2. display wallet if has balance by ILS currency.
3. display cashback if has balance by ILS currency or by dummyIls currency or by lookup currency.
4. display credit card message if does'nt have wallet
-->
            <div v-if="showWallet || !isShekel || !creditCardAllow || cashBackBalanceAvailable" class="message-box shield" style="margin-bottom:1px;">
                <div class="iconInfo">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
                        <circle cx="8" cy="4.5" r="1"/>
                    </svg>
                </div>
                <div v-if="showWallet">
                    <span v-text="$ml.get('wallet.balance')"></span>
                    <span v-format-title-amount="[walletBalance, '₪']"/>
                </div>
                <div v-if="cashBackBalanceAvailable">
                    <span v-text="$ml.get('cashBackBalance')"></span>
                    <span v-for="(balance, index) in cashBackBalance">
                        <span v-format-title-amount="[balance.amount, balance.symbol]"/>
                        <span v-if="cashBackBalance.length > 1 && index < cashBackBalance.length - 1">,&nbsp;</span>
                    </span>
                </div>
                <div v-if="!isShekel || dummyShekel" class="message">
                        <span> {{this.$ml.get("actualRate1")}} {{getRateSymbole}} {{this.$ml.get("actualRate2")}}: {{getRate}}</span>
                </div>
                <div v-if="!showWallet && !creditCardAllow" class="message">
                    <span v-text="$ml.get(this.creditLimit.message)"></span>
                </div>
                <div v-else-if="this.creditLimit.nationalityLimit">
                    <span>
                        {{this.$ml.with('amount', getCurrencyLimit).get('CREDIT_LIMIT_MONTHLY')}} {{getSymbole}}
                    </span>
                </div>
                <div v-else-if="this.creditLimit.risky">
                    <span>
                        {{this.$ml.with('country', countryName).get('CREDIT_LIMIT_RISKY')}} {{getSymbole}}{{this.getCurrencyLimit | formatComma}}
                    </span>
                    <div>
                        {{this.$ml.get('riskyLimitMsg')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Lookup from '../../components/Lookup.vue';

    export default {
        components: { Lookup },
        name: 'Step1',
        data() {
            return { loading:true }
        },
        methods: {
            ready(){ this.loading = false; },
            execute(){ this.$refs.lookup.doLookup(); },
            nextStep() {
                this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_1')
                this.$emit('onNextStep', 1);
            },
            backStep(){ this.$router.push('/transfer'); }
        },
        computed: {
            getSymbole(){
                return this.$store.getters.getLookupCurrencySymbol;
            },
            getRateSymbole(){
                return this.isShekel && this.dummyShekel ? this.$store.getters.getLookupDummyIlsSymbol : this.getSymbole;
            },
            getRate(){
                return this.isShekel && this.dummyShekel ? this.$store.getters.getDummyIls.rate : this.$store.getters.getLookupCurrencyRate;
            },
            getCurrencyLimit(){
                return this.$store.getters.getTransfetLimit;
            },
            creditLimit(){
                return this.$store.getters.getCreditLimit;
            },
            creditCardAllow(){
                return this.creditLimit && this.creditLimit.message == 'CREDIT_LIMIT'
            },
            hasWallet() {
                return this.$store.getters.hasWallet('ILS');
            },
            walletBalance() {
                return this.$store.getters.getWalletBalance('ILS');
            },
            cashBackBalance() {
                const secondCurr = this.isShekel && this.dummyShekel ? this.dummyShekel.currency : this.getCurrency;
                return (this.$store.getters.getCashBackBalances || []).filter(element => (element.currency === 'ILS' || element.currency === secondCurr) && element.amount > 0)
            },
            cashBackBalanceAvailable() {
                return this.cashBackBalance.length > 0;
            },
            showWallet() {
                return this.hasWallet && this.walletBalance > 0
            },
            getCurrency() {
                return this.$store.getters.getLookupCurrency;
            },
            countryName() {
                return this.$store.getters.getLookupCountryName;
            }, 
            isShekel() {
                return this.getCurrency === 'ILS'
            },
            dummyShekel() {
                return this.$store.getters.getDummyIls;
            },
        },
    }
</script>