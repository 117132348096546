<template>
  <div v-if="!skip" class="wrap wallet-send">
    <div v-if="mode === this.$walletTransferType.FRIEND">
      <h1 v-text="$ml.with('name', name).with('amount', amount).get('wallet.transfer.send.title')" class="title" />
    </div>
    <div v-else-if="mode === this.$walletTransferType.CARD">
      <h1 v-text="$ml.get('wallet.card.send.title')" class="title" />
    </div>
    <div v-else>
      <h1 v-text="$ml.get('wallet.load.send.title')" class="title" />
    </div>
    <div class="payment-type">
      <div class="wrapper" v-for="(option, index) in deliveryOptions" @click="nextStep(index)">
        <div class="item-title">
          <i :class="icon(option.transactionClass)" aria-hidden="true"></i>
          &nbsp;&nbsp;
          <strong>{{ $ml.get(option.transactionClass) }}</strong>
        </div>
        <div class="item-type">
          <div class="col">
            <ul class="captions">
              <li v-text="$ml.get('transferAmount')"></li>
              <li v-text="$ml.get('commission')"></li>
              <li v-text="$ml.get('amountReceived')"></li>
            </ul>
            <ul class="values">
              <li>{{ option.amount | formatComma }} {{ option.currency }}</li>
              <li>{{ option.commission | formatComma }} {{ option.currency }}</li>
              <li>{{ option.totalReceiveAmount | formatComma }} {{ option.payoutCurrency }}
              </li>
            </ul>
            <div class="pickTransferType">
              <span><img class="arrow-next" :src="blueArrow"> </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="group-btn">
      <a class="subtitle link" @click="changeAmount"
        v-text="$ml.get(mode === this.$walletTransferType.FRIEND ? 'changeAmount' : 'changeDepositAmount')"></a>
    </div>
    <div class="modal-mask modal-mask-message toast" @click="closeModalDialogue">
      <div class="modal-container modal-message container-dialogue" id="modal-calculate" @click.stop>
        <div class="modal-body body-dialogue">
          <div class="modal-header">
            <a class="a-close" @click="closeModalDialogue" :class="{ 'a-close-rtl': getDir === 'rtl' }">&#10005;</a>
          </div>
          <lookup ref="lookup" :logged-in="true"
            :label-names="{ amount: $ml.with('amount', $store.getters.getTransferLookupSymbol).get('changeAmountLabel') }"
            :next-btn="$ml.get('changeAmounBtn')" :fileds="{ 'amount': true }" btn-class="btn-yellow"
            :wallet-mode=this.mode @execute="execute">
          </lookup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Lookup from "@/components/Lookup";
import { startTransaction } from "@/services/api";
import app from "@/main";
import { getCurrencySymbol, modalHandler } from "@/services/utils";

export default {
  name: 'WalletSend',
  components: { Lookup },
  props: {
    mode: {
      type: String,
      required: false
    },
    skip: {
      type: Boolean,
      required: true
    }
  },
  date() {
    return {}
  },
  created() {
    if (this.skip && this.deliveryOptions.length === 1) {
      const item = this.deliveryOptions[0];
      if (item.amountLimited) {
        this.$store.commit('setShowModalError', true);
        const message = this.$ml.with('amount', item.amountLimit).with('currency', getCurrencySymbol(item.currency)).get("TRANSACTION_LIMIT");
        this.$store.commit('setErrorMessage', message);
        this.$emit('onPrevStep', 0);
      }
      this.nextStep(0);
    }
  },
  computed: {
    name() {
      const beneficiary = JSON.parse(localStorage.getItem('currentWalletBeneficiary'))
      return beneficiary.firstName + ' ' + beneficiary.lastName
    },
    amount() {
      return this.$store.getters.getTransferAmount;
    },
    blueArrow() {
      return require(this.$store.state.locale.lang === 'il' ? '../../../assets/images/icons/i-arrow-left.png' : '../../../assets/images/icons/i-arrow-right.png')
    },
    deliveryOptions() {
      return this.$store.getters.getTransferStep1Response;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
  },
  methods: {
    icon(transactionClass) {
      if (transactionClass === 'WALLET_DEPOSIT') {
        return 'fas fa-wallet'
      } else {
        return 'fa fa-users'
      }
    },
    execute() {
      if (this.mode === this.$walletTransferType.FRIEND) {
        this.$refs.lookup.doLookup('WALLET_TO_WALLET', 'ISR', 'GMT');
      } else {
        this.$refs.lookup.doLookup('WALLET_DEPOSIT', 'ISR', 'GMT');
      }
      this.closeModalDialogue();
    },
    changeAmount() {
      this.$refs.lookup.setAmount(null);
      modalHandler('.modal-mask-message', 'show', '092086', 'light');
      modalHandler('.modal-mask-message', 'addClassOpen', '092086', 'light');
      $('#amount').focus()
    },
    closeModalDialogue() {
      modalHandler('.modal-mask-message', 'removeClassOpen', 'ffffff', 'dark');
    },
    async nextStep(index) {
      app.$Progress.start();
      this.$store.commit('setCurrentOption', index);
      let selectedOption = this.deliveryOptions[index];
      const response = await startTransaction({
        "amount": selectedOption.amount,
        "currency": selectedOption.currency,
        "destinationCountry": selectedOption.destinationCountry,
        "payoutCurrency": selectedOption.payoutCurrency,
        "transactionClass": selectedOption.transactionClass,
        "correspondentId": selectedOption.correspondentId,
        "commission": selectedOption.commission,
        "amountLimit": selectedOption.amountLimit,
        "transactionRate": this.$store.getters.getSelectedExchangeRate,
        "lookupAmount": selectedOption.lookupDto && selectedOption.lookupDto.lookupAmount,
        "lookupRate": selectedOption.lookupDto && selectedOption.lookupDto.rate,
        "lookupBaseRate": selectedOption.lookupDto && selectedOption.lookupDto.baseRate
      });
      this.$store.commit('setCreditAvailabilityMap', response.creditAvailabilityMap);
      this.$emit('onNextStep')
      app.$Progress.finish();
    }
  }
}

</script>

<style lang="scss" scoped>
.title {
  white-space: pre-line;
}
</style>