<template>
  <div class="menu-action-container" @click="$emit('onActionClick')">
    <div class="icon-wrapper" :class="{ 'highlight-line': this.highlighted }">
      <img :src="imgSrc()" :alt="this.label" />
    </div>
    <label :class="{ 'highlight-text': this.highlighted }">{{ this.label }}</label>
  </div>
</template>
<script>
import { isApp } from "@/services/utils";

export default {
  name: 'MenuAction',
  components: {},
  props: {
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      isApp: isApp(),
      highlighted: false
    }
  },
  created() {
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {
    if (this.icon && this.icon.indexOf('Highlighted') > -1) {
      this.highlighted = true;
    }
  },
  methods: {
    imgSrc() {
      return require(`../assets/mygmt_v2_images/${this.icon}`);
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.highlight-text {
  color: $mainColor !important;
}

.highlight-line {
  background-color: rgba(9, 32, 134, 0.05);
  border-radius: 8px;
}

.menu-action-container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  //height: 50px;
  //min-height: 50px;
  //max-height: 50px;
  width: 60px;
  padding: 5px 5px 10px 5px;

  label {
    font-size: 12px;
    position: relative;
    top: 5px;
  }

  .icon-wrapper {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    cursor: pointer;

    img {
      width: 25px;
      height: 25px;
    }
  }
}</style>
