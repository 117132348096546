<template>
  <div v-if="isShown" class="wrapper" :class="{'longerTitle': isLongTitle, 'red': color == 'red', 'yellow': color == 'yellow'}" >
    <div class="text"> {{ title }}</div>
  </div>
</template>

<script>

export default {
  props: {
    isShown: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String
    },
    color: {
      type: String,
      required: true
    }
  },

  computed: {
  isLongTitle(){
   return this.title.length >= 12
  }
  }
}

</script>
<style lang="scss" scoped>
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";
.wrapper {
  position: absolute;
  right: -28px;
    top: 14px;
    z-index: 100;
  rotate: 45deg;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  .text {
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.longerTitle {
  &.wrapper {
    top: 25px;
    width: 132px;
  }
}

.red {
  &.wrapper {
    background: #FFD8D8; 
    .text {
      color: #FF0B0B;
    }
  }
}

.yellow {
  &.wrapper {
    background: #FDE199; 
    .text {
      color: #CD6200; 
    }
  }
}
</style>