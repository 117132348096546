<template>
  <div>
    <Header />
    <div class="main-wrapper">
      <div class="content block block-card-main">
        <div v-if="!loaded" class="loader_page">
          <img src="../../assets/images/loading.gif" />
        </div>
        <div v-else>
          <h1 class="title" v-text="$ml.get('wallet.friends.title')"></h1>
          <img class="gif" src="../../assets/images/gifs/wallet-transfer.gif" alt="">
          <button class="btn new-friend" v-text="$ml.get('wallet.friends.newFriend')" @click="sendMoneyToFriend()"
            v-if="friends.length >= 1" />
          <modal class="modal-wrapper" ref="friendSendMoneyModal" image="/img/gifs/add-contact.gif" :confirm="$ml.get('bntContinue')"
            @action="isApp() ? addFriend() : processSubmit()" :cancel="$ml.get('btnReturn')">
            <template v-slot:customModalBody>
              <section>
                <div  class="form-group friend-send-modal">
                  <h3 v-text="$ml.get('wallet.friends.addFriend')"></h3>
                  <div class="friend-send-modal-input-container">
                    <label for="mobile-number" v-text="$ml.get('wallet.friends.mobilePhone')"></label>
                    <input class="form-control" type="tel" id="mobile_phone" name="mobile_phone"
                      placeholder="0599999999" v-model="friendWalletPhone"
                      v-validate="{ required: 'required', regex: [/^05\d([-]{0,1})\d{7}$/] }" />
                    <span class="error">{{ errors.first('mobile_phone') }}</span>
                  </div>
                </div>
              </section>
            </template>
          </modal>
          <!--consider making component since is being used also in contacts the same way -->
          <div v-if="friends.length > 0">
            <h1 class="title" v-text="$ml.get('wallet.friends.list.title')"></h1>
            <div class="friends" v-for="(friend, index) in friends" :key="index">
              <friend-card class="friend-card" @sendToFriend="sendToFriend(friend)" @deleteFriend="openDialogue(index)"
                :mobile-number="friend.phoneNumber.replace('+972', '0')"
                :name="friend.firstName + ' ' + friend.lastName" />
            </div>
          </div>
          <div v-if="friends.length < 1">
            <p class="message-body">
              {{ $ml.get('wallet.friends.header') }}
            <ol class="ol-cash-deposit">
              <li>{{ $ml.get('wallet.friends.bulletsOne') }}</li>
              <li>{{ $ml.get('wallet.friends.bulletsTwo') }}</li>
              <li>{{ $ml.get('wallet.friends.bulletsThree') }}</li>
            </ol>
            <br />
            {{ $ml.get('wallet.friends.outro') }}
            </p>
          </div>
          <button class="btn new-friend" v-text="$ml.get('openWallet')" @click="$refs.openWalletActions.openDrawer()"
            v-if="friends < 1 && isIsraeli && !hasWallet" />
          <button class="btn new-friend" v-text="$ml.get('wallet.friends.newFriend')" @click="sendMoneyToFriend()"
            v-else-if="friends < 1" />
        </div>
      </div>
    </div>
    <Drawer ref="openWalletActions" :title="$ml.get('quickActions')" drawerId="openWalletActions">
      <template v-slot:content>
        <WalletActions />
      </template>
    </Drawer>
    <modal noCancelBtn ref="addFriendModal" image="/img/gifs/add-contact.gif"
      :title="$ml.get('wallet.friends.addModal.title')" :footer="$ml.get('wallet.friends.addModal.footer')"
      :confirm="$ml.get('wallet.friends.addModal.saveBtn')" @action="addFriend()"
      :cancel="$ml.get('wallet.friends.addModal.closeBtn')">
      <p>
        {{ this.friendToAdd.firstName + ' ' + this.friendToAdd.lastName }}
      </p>
      <p>
        {{ this.friendToAdd.phoneNumber }}
      </p>
    </modal>
    <div class="modal-mask modal-mask-message toast" @click="closeDialgue">
      <div class="modal-container container-dialogue modal-message" id="modal-message" @click.stop>
        <div class="modal-body body-dialogue">
          <div class="modal-header">
            <a class="a-close" @click="closeDialgue" :class="{ 'a-close-rtl': getDir === 'rtl' }">&#10005;</a>
          </div>
          <div class="title">
            <span
              v-text="$ml.with('beneficiary', friends[friendToDelete] ? (friends[friendToDelete].firstName + ' ' + friends[friendToDelete].lastName) : '').get('wallet.friends.friendCard.deleteDialogFirstLine')" />
          </div>
          <div class="title" v-text="$ml.get('wallet.friends.friendCard.deleteDialogSecondLine')"></div>
          <div class="form-group group-btn">
            <button class="btn btn-small btn-border" v-text="$ml.get('btnReturn')" @click="closeDialgue()"></button>
            <button type="submit" class="btn btn-small btn-yellow" v-text="$ml.get('btnOk')"
              @click="deleteFriend()"></button>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="!isMobileDevice" />
  </div>
</template>
<script>

import Header from "../../components/Header";
import MainNav from "../../components/MainNav";
import Action from "@/components/Action";
import Modal from "@/components/Modal";
import { addWalletFriend, deleteWalletFriend, getCustomerByPhone, walletFriends } from "@/services/api";
import FriendCard from "@/components/FriendCard";
import { Validator } from "vee-validate";
import { isApp, localesDictionary, modalHandler } from "@/services/utils";
import Footer from "@/components/Footer.vue";
import CardActions from "@/components/CardActions.vue";
import Drawer from "@/components/Drawer.vue";
import WalletActions from "@/components/WalletActions.vue";
import { isMobile } from "../../services/utils";

export default {
  name: 'WalletFriends',
  components: { WalletActions, Drawer, CardActions, FriendCard, Action, MainNav, Header, Modal, Footer },
  data() {
    return {
      loaded: false,
      friends: [],
      friendWalletPhone: '',
      friendToAdd: {
        id: '',
        firstName: '',
        lastName: '',
        phoneNumber: ''
      },
      friendToDelete: '',
      isMobileDevice: isMobile()
    }
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    phoneNumber() {
      return localStorage.getItem('phoneNumber').replace('+972', '0');
    },
    isIsraeli() {
      const rd = JSON.parse(localStorage.getItem('registrationData'));
      return rd && rd.country == 'ISR';
    },
    hasWallet() {
      return this.$store.getters.hasWallet('ILS');
    }
  },
  watch: {
    friendWalletPhone(newValue) {
      this.friendWalletPhone = newValue.replace('+972', '0')
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    document.body.setAttribute('dir', this.getDir);
    Validator.localize(this.dictionary());
    Validator.localize(this.getLang);
    walletFriends((result) => {
      this.friends = result.friends
      this.loaded = true
    })
  },
  methods: {
    isApp,
    openDialogue(index) {
      this.friendToDelete = index;
      modalHandler('.modal-mask-message', 'addClassOpen')
    },
    closeDialgue() {
      modalHandler('.modal-mask-message', 'removeClassOpen')
    },
    loadFriends() {
      walletFriends((result) => {
        this.friends = result.friends
      });
    },
    addFriend() {
      addWalletFriend(this.friendToAdd.id, () => {
        this.loadFriends()
        this.$refs.addFriendModal.closeModal()
        this.$refs.friendSendMoneyModal.closeModal()
      });
    },
    async sendMoneyToFriend() {
      if (isApp()) {
        window.location.replace('/#/wallet/contacts')
        window.location.reload()
      } else {
        this.$refs.friendSendMoneyModal.openModal()
      }
    },
    processSubmit() {
      this.$validator.validate().then(result => {
        if (result) {
          this.fetchCustomer(this.friendWalletPhone);
          this.friendWalletPhone = ''
        }
      });
    },
    fetchCustomer(phone) {
      if (!this.friends.find(friend => friend.phoneNumber.replace('+972', '0') === phone) && phone !== this.phoneNumber) {
        getCustomerByPhone(phone, (response) => {
          if (response.errorCode) {
            this.$store.commit('setShowModalError', true);
            if (response.errorCode === 'WALLET_FRIEND_NOT_REGISTERED')
              this.$store.commit('setErrorMessage', this.$ml.with('mobileNumber', phone).get(response.errorCode));
            else
              this.$store.commit('setErrorCode', response.errorCode);
          } else {
            const customer = response.result
            this.friendToAdd = {
              id: customer.id,
              firstName: customer.firstName,
              lastName: customer.lastName,
              phoneNumber: customer.phoneNumber
            }
            addWalletFriend(this.friendToAdd.id, () => {
              this.$refs.addFriendModal.closeModal()
              this.$refs.friendSendMoneyModal.closeModal()
              walletFriends((result) => {
                this.friends = result.friends
                this.friends.forEach(friend => {
                  localStorage.setItem('currentWalletBeneficiary', JSON.stringify(friend));
                  this.friendToAdd.phoneNumber.replaceAll('-', '') === friend.phoneNumber.replace('+972', '0') ? this.sendToFriend(friend) : null
                })
              });
            });
            // this.$refs.addFriendModal.openModal()
          }
        })
      } else {
        this.$store.commit('setShowModalError', true);
        this.$store.commit('setErrorCode', 'WALLET_FRIEND_ALREADY_ADDED');
      }
      this.$refs.friendSendMoneyModal.closeModal()

    },
    sendToFriend(friend) {
      if (friend.blocked || friend.status === 'DENIED') {
        this.$store.commit('setShowModalError', true);
        this.$store.commit('setErrorCode', 'WALLET_FRIEND_BLOCKED');
      } else {
        localStorage.setItem('currentWalletBeneficiary', JSON.stringify(friend));
        this.$router.push('/wallet/transfer/friend')
      }
    },
    deleteFriend() {
      deleteWalletFriend(this.friends[this.friendToDelete].id, (result) => {
        this.closeDialgue();
        if (!result.errorCode) {
          this.friends.splice(this.friendToDelete, 1)
        }
      });
    },
    dictionary() {
      const custom = {
        mobile_phone: {
          regex: this.$ml.get("E_REGEX_mobileNumber")
        }
      }
      const messages = {
        required: this.$ml.get("E_REQUIRED"),
        numeric: this.$ml.get("E_NUMERIC"),
        regex: this.$ml.get("E_REGEX"),
      }
      return localesDictionary({ custom: custom, messages: messages });
    }
  },
}

</script>

<style lang="scss" scoped>
@import "../../assets/styles/partials/variables";
@import "../../assets/styles/partials/mixins";

.modal-body.body-dialogue .body-title {
  margin-top: 6%;
}

body {
  .error {
    width: 100%;
    position: relative;
    text-align: center;
  }
}

.modal-wrapper > :first-child > :first-child{ 
  left: 260px;

}

[dir="rtl"] .modal-wrapper > :first-child > :first-child {
  left: -240px;
}

.block {
  text-align: center;
  
  display: block;
  margin-right: auto;
  margin-left: auto;


  .title {
    margin-top: 1%;
  }

  p {
    text-align: center;
  }

  .btn {
    margin: 0 auto;
  }

  .add-friend {
    margin-top: 2%;
    margin-bottom: 2%;
    width: 20%;
  }

  .form-group {
    color: #4a4a4a;
    width: 26%;
    margin: 2% auto 2% auto;

    label {
      text-align: start;
      color: #4a4a4a;
      margin-left: 10px;
    }

    .form-control {
      margin-top: 5px;
      display: block;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }
  }

  .new-friend {
    margin-top: 5%;
    margin-bottom: 2%;
    width: 45%;
  }

  .new-friend-footer {
    white-space: pre-line;
  }

  .friends {
    margin-top: 3px;
  }

  .friend-card {
    margin: auto 31% auto 31%;
  }

  .gif {
    width: 231px;
    height: 231px;
  }

  .friend-send-modal {
    width: 100%;
    margin: auto !important;
    

    .friend-send-modal-input-container {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 50%;
    }

    h3 {
      margin: 5px auto;
      color: white;
    }

    span,
    label {
      color: white !important;
      margin: auto 10px;
    }
  }

  #mobile_phone {
    text-align: center;
  }
}

@include max-screen($screen-phones) {
  .block {

    .add-friend {
      margin-top: 4%;
      margin-bottom: 6%;
      width: 60%;
    }

    .form-group {
      color: #4a4a4a;
      width: 80%;
      margin: 8% auto 2% auto;

      label {
        text-align: start;
        color: #4a4a4a;
        margin-left: 10px;
      }

      .form-control {
        margin-top: 5px;
        display: block;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
      }

    }

    .friend-send-modal {
      .friend-send-modal-input-container {
        width: 85%;
      }
    }

    .new-friend {
      margin-top: 5%;
      margin-bottom: 10%;
      width: 60%;
    }

    .friend-card {
      margin: auto 4% 2% 4%;
    }

    .gif {
      margin-top: -6%;
      margin-bottom: -4%;
    }
  }
}

.ol-cash-deposit {
  list-style: decimal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  margin-top: 20px;
}

.message-body {
  white-space: pre-line;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin: auto;
}
</style>