const state = {
    country: '',
    province: '',
    identification: '',
    mobileNumber: '',
    firstName: '',
    middleName: '',
    lastName: '',
    anotherLastName: '',
    city: '',
    address: '',
    cityCode: '',
    streetCode: '',
    streetNumber: '',
    birthDate: '',
    gender: '',
    zipCode: '',
    occupation: '',
    smsCode: '',
    language: '',
    email: '',
    isLoaded: false,
    type: '',
    status: '',
    invitedBy: ''
};

const mutations = {
    setRegistration(state, data) {
        state.country = data.country || state.country;
        state.province = data.province || state.province;
        state.identification = data.identification || state.identification;
        state.mobileNumber = data.mobileNumber || state.mobileNumber;
        state.firstName = data.firstName || state.firstName;
        state.middleName = data.middleName || state.middleName;
        state.lastName = data.lastName || state.lastName;
        state.anotherLastName = data.anotherLastName || state.anotherLastName;
        state.city = data.city || state.city;
        state.address = data.address || state.address;
        state.cityCode = data.cityCode || state.cityCode;
        state.streetCode = data.streetCode || state.streetCode;
        state.streetNumber = data.streetNumber || state.streetNumber;
        state.birthDate = data.birthDate || state.birthDate;
        state.gender = data.gender || state.gender;
        state.zipCode = data.zipCode || state.zipCode;
        state.occupation = data.occupation || state.occupation;
        state.smsCode = data.smsCode || state.smsCode;
        state.language = data.language || state.language;
        state.birthCountry = data.birthCountry || state.birthCountry;
        state.email = data.email || state.email;
        state.status = data.status || state.status;
        state.invitedBy = data.invitedBy || state.invitedBy;
    },
    resetRegistration(state) {
        state.country = '';
        state.province = '';
        state.identification = '';
        state.mobileNumber = '';
        state.firstName = '';
        state.middleName = '';
        state.lastName = '';
        state.anotherLastName = '';
        state.city = '';
        state.address = '';
        state.cityCode = '';
        state.streetCode = '';
        state.streetNumber = '';
        state.birthDate = '';
        state.gender = '';
        state.zipCode = '';
        state.occupation = '';
        state.smsCode = '';
        state.language = '';
        state.birthCountry = '';
        state.email = '';
        state.status = '';
        state.invitedBy = '';
    },
    setLoaded(state, data) {
        state.isLoaded = data;
    },
    setType(state, data) {
        state.type = data;
    },
};

const getters = {
    getLoaded(state) {
        return state.isLoaded;
    },
    isFirstStep(state) {
        return !state.country || !state.identification || !state.mobileNumber;
    },
    getRegistrationData(state) {
        //let date = moment(state.birthDate).format('DD/MM/YYYY');

        return {
            country: state.country,
            province: state.province,
            identification: state.identification,
            mobileNumber: state.mobileNumber,
            firstName: state.firstName,
            middleName: state.middleName,
            lastName: state.lastName,
            anotherLastName: state.anotherLastName,
            city: state.city,
            address: state.address,
            cityCode: state.cityCode,
            streetCode: state.streetCode,
            streetNumber: state.streetNumber,
            birthDate: state.birthDate,
            gender: state.gender,
            zipCode: state.zipCode,
            occupation: state.occupation,
            smsCode: state.smsCode,
            language: state.language,
            birthCountry: state.birthCountry,
            email: state.email,
            status: state.status,
            invitedBy: state.invitedBy
        };
    },
    getType(state) {
        return state.type;
    },

};

export default {
    state,
    mutations,
    getters
}