<template>
  <div v-if="isModalOpen" class="youtube-modal" @click.stop="toggleModal">
    <div  class="i-frame-container">
      <iframe width="560" height="315" :src="`${getUrl}?autoplay=1`" showinfo=0 frameborder="0" class="iframe-y"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
      <div class="closer">x</div>
    </div>
  </div>

</template>
<script>
import { isApp } from "@/services/utils";


export default {
  name: 'YoutubeCard',
  components: {},
  props: {},
  data() {
    return {
      isApp: isApp(),
      isModalOpen: false,
      url: ''
    }
  },
  created() {

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getUrl() {
      return this.url || `https://www.youtube.com/embed/${this.getVideoId}`
    },
    getVideoId() {
      const langUrl = {
        il: 'lU4NnpKP2HM',
        ru: 'i5V2_uwSgxM',
        th: 've4pWm4Jz_k',
        cn: '57t7XQtVNZM',
        en: 'lU4NnpKP2HM',
      }
      return langUrl[this.getLang]
    },
    customThumbnailUrl() {
      return 'http://img.youtube.com/vi/231321/maxresdefault.jpg'
    }
  },
  mounted() {

  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen
      if (this.isModalOpen) {
        document.body.style.overflow = 'hidden'; 
      } else {
        document.body.style.overflow = '';
      }
    }
  },
  beforeDestroy() {
    document.body.style.overflow = '';
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

  .youtube-modal {
    width: 100vw;
    height: 100vh;
    background-color: #00000099;
    overflow-y: auto;
    position: fixed;
    z-index: 99992;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow: hidden;

    .i-frame-container {
      width: 95%;
    height: 25%;
    position: relative;
      iframe {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }

    .closer{
      position: absolute;
      top: -40px;
      right: 13px;
      color: white;
      cursor: pointer;
     font-size: 30px;

    }
  }


@media only screen and (min-width: $screen-tablet) {
  .youtube-modal {
    .i-frame-container {
      width: 60%;
      height: 70%;
    }

    .closer{
     font-size: 30px;
     top: -40px;
      right: 20px;
    }
  }
}
</style>
