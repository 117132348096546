<template>
    <div class="wrap">
        <form class="profile-step-1 style-as-info">
            <div class="form-group business">
                <label for="email" v-text="$ml.with('VueJS').get('email')"></label>
                <input type="email" name="email" id="email" class="form-control" v-model="registrationData.email"
                    disabled>
            </div>
            <div class="form-group">
                <label for="firstName" v-text="$ml.with('VueJS').get('labelName')"></label>
                <input type="text" name="firstName" id="firstName" class="form-control" v-validate="'required'"
                    v-model="registrationData.firstName" disabled>
            </div>
            <div class="form-group" v-show="registrationData.middleName">
                <label for="middleName" v-text="$ml.with('VueJS').get('middleName')"></label>
                <input type="text" name="middleName" id="middleName" class="form-control"
                    v-model="registrationData.middleName" disabled>
            </div>
            <div class="form-group">
                <label for="lastName" v-text="$ml.with('VueJS').get('labelSurname')"></label>
                <input type="text" name="lastName" id="lastName" class="form-control" v-validate="'required'"
                    v-model="registrationData.lastName" disabled>
            </div>
            <div class="form-group" v-show="registrationData.anotherLastName">
                <label for="anotherSurname" v-text="$ml.with('VueJS').get('anotherSurname')"></label>
                <input type="text" name="anotherSurname" id="anotherSurname" class="form-control"
                    v-model="registrationData.anotherLastName" disabled>
            </div>
            <div class="form-group">
                <label for="dateBirth" v-text="$ml.with('VueJS').get('dateBirth')"></label>
                <input id="dateBirth" class="form-control" type="text" v-model="registrationData.birthDate" disabled />
            </div>
            <div class="form-group">
                <label for="gender" v-text="$ml.with('VueJS').get('gender')"></label>
                <div>
                    <select name="gender" id="gender" class="form-control" v-validate="'required'"
                        v-model="registrationData.gender" disabled>
                        <option v-for="gender in genders" :value="gender.iso"> {{ gender[$store.state.locale.lang] }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label for="city" v-text="$ml.with('VueJS').get('city')"></label>
                <input type="text" name="city" id="city" class="form-control" v-model="registrationData.city" disabled>
            </div>
            <div class="form-group">
                <label for="address" v-text="$ml.with('VueJS').get('streetName')"></label>
                <input type="text" name="address" id="address" class="form-control" v-model="registrationData.address"
                    disabled>
            </div>
            <div class="form-group">
                <label for="streetNumber" v-text="$ml.with('VueJS').get('houseNumber')"></label>
                <input type="text" name="streetNumber" id="streetNumber" class="form-control"
                    v-model="registrationData.streetNumber" disabled>
            </div>
            <!-- <div class="form-group">
                <label for="zipCode" v-text="$ml.with('VueJS').get('zipCode')"></label>
                <input type="text" name="zipCode" id="zipCode" class="form-control" v-model="registrationData.zipCode" disabled>
            </div> -->
            <div class="form-group">
                <label for="countryBirth" v-text="$ml.with('VueJS').get('countryBirth')"></label>
                <div>
                    <select name="countryBirth" id="countryBirth" class="form-control"
                        v-model="registrationData.birthCountry" disabled>
                        <option v-for="country in countries" :value="country.iso">{{ country[$store.state.locale.lang] }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group business">
                <label for="business" v-text="$ml.with('VueJS').get('business')"></label>
                <div>
                    <select name="business" id="business" class="form-control" v-validate="'required'"
                        v-model="registrationData.occupation" disabled>
                        <option v-for="occupation in occupations" :value="occupation.enum"> {{
                    occupation[$store.state.locale.lang] }}</option>
                    </select>
                </div>
            </div>
            <div v-if="isApp">
                <div v-if="this.$store.getters.getBiometricData.hasTouchId">
                    <div class="quick-access toggler" v-if="this.$store.getters.getBiometricData.hasSecret"
                        @click="unregisterBiometric(false)">
                        <img src="../../assets/images/toggle-on.svg" alt="toggle-on">
                        <label v-text="$ml.with('VueJS').get('biometricToggler')"></label>
                    </div>
                    <div class="quick-access toggler" v-else @click="goToBiometric()">
                        <img src="../../assets/images/toggle-off.svg" alt="toggle-off">
                        <label v-text="$ml.with('VueJS').get('biometricToggler')"></label>
                    </div>
                </div>
                <div class="quick-access profile-delete" @click="deleteProfile()">
                    <img class="arrow-next" :class="{ 'arrow-mirror': getLang !== 'il' }"
                        src="../../assets/images/icons/i-arrow-left.png">
                    <div class="delete-profile-box">
                        <i class="fas fa-heart-broken"></i>
                        <label v-text="$ml.with('VueJS').get('profileDelete')"></label>
                    </div>
                </div>
            </div>
        </form>
        <!--Modal unregisterBiometric dialogue -->
        <div class="modal-mask modal-mask-message toast" @click="closeUnregisterModal()"
            id="unregister-biometric-modal">
            <div class="modal-container container-dialogue modal-message" id="modal-message" @click.stop>
                <div class="modal-body body-dialogue">
                    <div class="modal-header">
                        <a class="a-close" @click="closeUnregisterModal()">&#10005;</a>
                    </div>
                    <div class="title">
                        <h4>{{ $ml.get('biometric_unregister_modal_title') }}</h4>
                    </div>
                    <div class="title">{{ $ml.get('biometric_modal_text') }}</div>
                    <div class="group-btn">
                        <button class="btn btn-small btn-border" @click="closeUnregisterModal()">{{
                    $ml.get('walletOrder.btnReturn') }}</button>
                        <button type="submit" class="btn btn-small btn-yellow" @click="unregisterBiometric(true)">{{
                    $ml.get('walletOrder.btnOk') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!--End Modal unregisterBiometric dialogue-->
        <!--Modal delete-profile-modal dialogue -->
        <div class="modal-mask modal-mask-message toast" @click="closeDeleteProfileModal()" id="delete-profile-modal">
            <div class="modal-container container-dialogue modal-message" id="modal-message" @click.stop>
                <div class="modal-body body-dialogue">
                    <div class="modal-header">
                        <a class="a-close" @click="closeDeleteProfileModal()"
                            :class="{ 'a-close-rtl': getDir === 'rtl' }">&#10005;</a>
                    </div>
                    <div class="title">
                        <h4>{{ $ml.get('profileDeleteModalTitle') }}</h4>
                    </div>
                    <div class="title">{{ $ml.get('profileDeleteModalText') }}</div>
                    <div class="group-btn">
                        <button class="btn btn-small btn-border" @click="closeDeleteProfileModal()">{{
                            $ml.get('walletOrder.btnReturn') }}</button>
                        <button type="submit" class="btn btn-small btn-yellow" @click="deleteProfile(true)">{{
                            $ml.get('walletOrder.btnOk') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!--End Modal delete-profile-modal dialogue-->
    </div>
</template>


<script>
import countries from '../../data/countries';
import occupations from '../../data/occupation';
import genders from '../../data/gender';
import { isApp, modalHandler } from '../../services/utils';
import { profileDeleteRequest, unregisterBiometric } from '../../services/api';

export default {
    name: 'Step1',
    data() {
        return {
            registrationData: JSON.parse(localStorage.getItem('registrationData')),
            occupations: occupations,
            genders: genders,
            countries: countries,
            isApp: isApp()
        }
    },
    created() { },
    computed: {
        getLang() {
            return this.$store.getters.getLang;
        },
        getDir() {
            return this.$store.getters.getDir;
        },
    },
    methods: {
        unregisterBiometric(isAfterModal) {
            if (isAfterModal) {
                unregisterBiometric((res) => {
                    gonative.auth.delete({ 'callbackFunction': (res) => { console.log(JSON.stringify(res)) } });
                    window.location.reload()
                })
            } else {
                modalHandler('#unregister-biometric-modal', 'addClassOpen', '092086', 'light')
            }
        },
        closeUnregisterModal() {
            modalHandler('#unregister-biometric-modal', 'removeClassOpen', 'ffffff', 'dark');
        },
        closeDeleteProfileModal() {
            modalHandler('#delete-profile-modal', 'removeClassOpen', 'ffffff', 'dark');
        },
        goToBiometric() {
            this.$router.push('/biometricRegistration')
        },
        deleteProfile(isAfterModal) {
            if (isAfterModal) {
                profileDeleteRequest((res) => {
                    console.log('success ' + res)
                })
                window.location.reload()
            } else {
                modalHandler('#delete-profile-modal', 'addClassOpen', '092086', 'light')
            }
        }
    }
}
</script>

<style lang="scss">
.quick-access {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 95%;
    margin: auto;
    height: 50px;
    background-color: white;
    margin-top: 10px;
    padding: 5px;

    i {
        font-size: 25px;
    }

    label {
        margin-inline-start: 5px;
        font-size: 18px;
    }

    img {
        width: 55px;
        margin-top: 2px;
        height: 27px;
        margin-right: 3px;
    }

    .arrow-next {
        width: 18px;
        height: 18px;
        margin-left: 5px;
    }
}

.toggler {
    border-top: 1px solid #8080807d;
}

form {
    input {
        color: black;
    }
}



.profile-delete {
    border-top: 1px solid #8080807d;
    border-bottom: 1px solid #8080807d;
    margin-top: 0px;

    .delete-profile-box {
        margin-right: 3px;
    }
}

.style-as-info {
    input {
        color: black;
        background-color: transparent !important;
    }

    select {
        color: black;
        background-color: transparent !important;
    }
}
</style>